import Swal from "sweetalert2";
export default {
  created() {},
  mounted() {},
  methods: {
    pushPage(data) {
      var dt = data;
      // if(location.pathname == '/parrot/' || location.pathname == '/parrot'){
      if (location.pathname.match("/parrot")) {
        dt.name = dt.name + "Parrot";
      }
      if (location.pathname.match("/rs_dev")) {
        dt.name = dt.name + "Rs_dev";
      }
      // this.$router.push(data);
      this.$router.push(dt);
    },
    showLogoutWindow() {
      Swal.fire({
        confirmButtonColor: "#29aba4",
        animation: false,
        html:
          "<p>ログイン状態に問題があります。</p>" +
          "<p>ログアウトし、ログイン画面に戻ります。</p>" +
          "<style>p{font-size: 16px;}</style>",
        width: 500,
        padding: 10,
        confirmButtonText: "OK",
        confirmButtonAriaLabel: "OK",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          // トークンを廃棄する処理
          localStorage.setItem("pageName", "");
          localStorage.setItem("token", "");
          //ログイン画面に遷移
          this.pushPage({ name: "Login" });
        }
      });
    },
    isCheckLogoutErrCode(code) {
      if (code <= 1994 && code >= 1999) {
        return true;
      }
      if (code <= 1929 && code >= 1931) {
        return true;
      }
      if (code == 1934) {
        return true;
      }
      return false;
    },
    /**
     * APIアクセスのサーバエラーを表示
     * @param { レスポンスJSON } response
     */
    showErrorWindow(response) {
      if (response != null) {
        if (this.isCheckLogoutErrCode(response.data.code)) {
          this.showLogoutWindow();
        } else {
          var code = "(" + response.data.code + ")";
          var message = response.data.message;
          Swal.fire({
            confirmButtonColor: "#29aba4",
            animation: false,
            html:
              "<p>エラーが発生しました" +
              code +
              "</p>" +
              "<p>" +
              message +
              "</p>" +
              "<style>p{font-size: 16px;}</style>",
            width: 500,
            padding: 10,
            confirmButtonText: "OK",
            confirmButtonAriaLabel: "OK",
            allowOutsideClick: false,
          });
        }
      } else {
        Swal.fire({
          confirmButtonColor: "#29aba4",
          animation: false,
          html:
            "<p>アクセスエラー</p>" +
            "<p>しばらく待っても解決しない場合は、お手数ですがエラーコードをカスタマー窓口（support@recstation.jp）までご連絡ください。</p>" +
            "<p>エラー内容：取得できませんでした</p>" +
            "<style>p{font-size: 14px;}</style>",
          width: 500,
          padding: 10,
          confirmButtonText: "OK",
          confirmButtonAriaLabel: "OK",
          allowOutsideClick: false,
        });
      }
    },
    /**
     * ネットワークエラーを表示
     * @param {*} err
     */
    showNetworkErrorWindow(err) {
      var viewerr;
      if (err) {
        viewerr = err.code || err.message || err.name || JSON.stringify(err);
      } else {
        viewerr = "取得できませんでした";
      }
      Swal.fire({
        confirmButtonColor: "#29aba4",
        animation: false,
        html:
          "<p>アクセスエラー</p>" +
          "<p>しばらく待っても解決しない場合は、お手数ですがエラーコードをカスタマー窓口（support@recstation.jp）までご連絡ください。</p>" +
          "<p>エラーコード：" +
          viewerr +
          "</p>" +
          "<style>p{font-size: 14px;}</style>",
        width: 500,
        padding: 10,
        confirmButtonText: "OK",
        confirmButtonAriaLabel: "OK",
        allowOutsideClick: false,
      });
    },
    isEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    isAlpNum(str) {
      str = str == null ? "" : str;
      if (str.match(/^[A-Za-z0-9]*$/)) {
        return true;
      } else {
        return false;
      }
    },
    isAuthNum(str) {
      str = str == null ? "" : str;
      if (str.length == 4 && str.match(/^[0-9]*$/)) {
        return true;
      } else {
        return false;
      }
    },
    isLeastLength(str, num) {
      if (str.length >= num) {
        return true;
      } else {
        return false;
      }
    },
    isMostLength(str, num) {
      if (str.length <= num) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * ファイル名の正規表現
     * 半角英数   0-9a-zA-Z
     * 全角英数   Ａ-Ｚａ-ｚ０-９
     * カタカナ   \u30a0-\u30ff
     * 半角カナ   ｦ-ﾟ
     * ひらがな   \u3040-\u309f
     * CJK統合漢字\u30e0-\u9fcf
     * 記号      _＿
     * @param {*} str
     */
    isPermissibleName(str) {
      str = str == null ? "" : str;
      if (
        str.match(
          /^[A-Za-z0-9Ａ-Ｚａ-ｚ０-９ｦ-ﾟ_＿\u30A1-\u30F6\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 画面表示用の日付表示を返す
     * できればComputedでやりたいところだけど、結局使い回すので
     * Utilにまとめることにしました
     * @param {*} date 2020-12-17 18:48
     * @returns yyyy年mm月dd日（0付き）
     */
    getShowDate(date) {
      const y = date.substr(0, 4);
      const m = date.substr(5, 2);
      const d = date.substr(8, 2);
      return y + "年" + m + "月" + d + "日";
    },

    /**
     * 議事録変種機能の説明
     */
    showHowtoDictexWindow() {
      // this.audio.pause();
      // this.currentTime = 0;
      // this.howtoTop();
      Swal.fire({
        animation: true,
        html: `
        <h2>議事録編集機能へようこそ</h2>
        <p>本画面では、様々な機能を使ってかんたんに文章の編集ができます。</p>
        <p>以下のリンクよりRECORDER STATIONの使い方資料がご覧いただけます。</P>
        <p><a href="https://www.iflytek.co.jp/wp-content/uploads/2022/09/RECORDER-STATIONの使い方-1.pdf" target="_blank" style="font-size: 24px !important;">RECORDER STATIONの使い方</a></p><br>
        <p>また機能はYoutubeでも紹介しています。<br>ぜひご確認・チャンネル登録をお願いいたします。</p>
        <p><a href="https://www.youtube.com/channel/UC7M3UPM-cg32nb9-pKiFF8g" target="_blank" style="font-size: 24px !important;"><i class="fa-brands fa-youtube"></i> VOITER公式</a></p>
        <style>
          .swal2-html-container{
            text-align: center;
          }
          .swal2-html-container > p{
            font-size: 16px;
            text-align: center;
          }
        </style>
        `,
        confirmButtonText: "とじる",
        confirmButtonAriaLabel: "とじる",
        confirmButtonColor: "#29aba4",
        width : '60%'
      });
    },

    howtoTop() {
      Swal.fire({
        html: `
        <h3>議事録編集機能へようこそ</h3>
        <p>本画面では、様々な機能を使ってかんたんに文章の編集ができます。さっそく見てみましょう。</p>
        <style>
          .swal2-html-container > p{
            font-size: 16px;
            text-align: center;
          }
        </style>
        `,
        cancelButtonText: "とじる",
        cancelButtonAriaLabel: "とじる",
        confirmButtonText: "次へ",
        confirmButtonAriaLabel: "次へ",
        confirmButtonColor: "#29aba4",
        showCancelButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (!result.isConfirmed) return false;
        this.howtoAudio();
      });
    },
    howtoAudio() {
      this.isDetailOpen = false;
      this.audio.play();
      setTimeout(() => {
        this.audio.pause();
      }, 5000);
      Swal.fire({
        animation: false,
        html: `
        <h3>オーディオ操作エリア</h3>
        <p>画面下側のオーディオ操作エリアでは、音声の再生についての各種操作をすることができます。</p>
        <p>中央部では、<i class="fa-solid fa-circle-play"></i> 再生や10秒20秒スキップ、巻き戻しなどができます。再生バーをつまんで左右に動かすと再生位置を調整することができます。</p>
        <p>左側では、再生スピードを変更することができます。</p>
        <p>右側では、編集エリアのモードを切り替えることができます。<br />こちらについてはあとで詳しくご案内いたします。</p>
        <style>
          .swal2-html-container > p{
            font-size: 14px;
            text-align: left;
          }
        </style>
        `,
        confirmButtonText: "次へ",
        confirmButtonAriaLabel: "次へ",
        confirmButtonColor: "#29aba4",
        cancelButtonText: "もどる",
        cancelButtonAriaLabel: "もどる",
        showCancelButton: true,
        reverseButtons: true,
        backdrop: `
          rgba(0,0,0,0.2)
          url("/point_btm.png")
          center bottom
          no-repeat
        `,
      }).then((result) => {
        this.audio.pause();
        if (result.isConfirmed) {
          this.howtoOriginal();
        } else {
          this.howtoTop();
        }
      });
    },
    howtoOriginal() {
      this.currentTime = 5;
      setTimeout(() => {
        this.currentTime = 10;
      }, 1000);
      setTimeout(() => {
        this.currentTime = 15;
      }, 2000);
      setTimeout(() => {
        this.currentTime = 20;
      }, 3000);
      setTimeout(() => {
        this.currentTime = 25;
      }, 4000);
      setTimeout(() => {
        this.currentTime = 30;
      }, 5000);
      Swal.fire({
        animation: false,
        html: `
        <h3>オリジナルエリア</h3>
        <p>画面左側には、文字起こしした内容の原文が表示されます。議事録編集機能では、文章が話者分類されます。<br />
        <p>音声を再生すると、その個所の文章が青色でハイライトされます。</p>
        <p>また、文章をクリックすると、その部分まで再生をジャンプすることができます。</p>
        <style>
          .swal2-html-container > p{
            font-size: 14px;
            text-align: left;
          }
        </style>
        `,
        confirmButtonText: "次へ",
        confirmButtonAriaLabel: "次へ",
        confirmButtonColor: "#29aba4",
        cancelButtonText: "もどる",
        cancelButtonAriaLabel: "もどる",
        showCancelButton: true,
        reverseButtons: true,
        backdrop: `
          rgba(0,0,0,0.2)
          url("/point_ccl.png")
          left
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          this.howtoEdit();
        } else {
          this.howtoAudio();
        }
      });
    },
    howtoEdit() {
      this.isSyncScrall = true;
      this.currentTime = 30;
      setTimeout(() => {
        this.currentTime = 40;
      }, 1000);
      setTimeout(() => {
        this.currentTime = 45;
      }, 2000);
      setTimeout(() => {
        this.currentTime = 50;
      }, 3000);
      setTimeout(() => {
        this.currentTime = 55;
      }, 4000);
      setTimeout(() => {
        this.currentTime = 60;
      }, 5000);
      this.isGrabMode = false;
      Swal.fire({
        animation: false,
        html: `
        <h3>編集画面（テキスト編集モード）</h3>
        <p>画面右側では、文字起こしした内容の編集ができます。</p>
        <p>画面右下のモード切替で「テキスト編集モード」のときには、キーボードで文章を直接編集することができます。</p>
        <p>また、モード切替の右の 「編集画面を再生位置へ」 ボタンを押すと、編集画面を現在再生中に位置に移動することができます。<br />
        <style>
          .swal2-html-container > p{
            font-size: 14px;
            text-align: left;
          }
        </style>
        `,
        confirmButtonText: "次へ",
        confirmButtonAriaLabel: "次へ",
        confirmButtonColor: "#29aba4",
        backdrop: `
          rgba(0,0,0,0.2)
          url("/point_ccl.png")
          right
          no-repeat
        `,
      }).then(() => {
        this.isSyncScrall = false;
        this.isGrabMode = true;
        Swal.fire({
          animation: true,
          html: `
          <h3>編集画面（UI編集モード）</h3>
          <p>画面右下のモード切替で「UI編集モード」のときには、マウスで単語をつまんで動かし、文章を編集することができます。</p>
          <p>単語ブロックはクリックすると複数個選択でき、まとめて動かすこともできます。また、「Shift」キーを使うと範囲の選択もできます。</p>
          <p>単語を選択した状態で右クリックすると、コンテキストメニューが開きます。<br />
          コンテキストメニューでは、選択した単語を置換したり、キーワードに登録したりなどの便利な機能を使うことができます。</p>
          <style>
            .swal2-html-container > p{
              font-size: 14px;
              text-align: left;
            }
          </style>
          `,
          confirmButtonText: "次へ",
          confirmButtonAriaLabel: "次へ",
          confirmButtonColor: "#29aba4",
          cancelButtonText: "もどる",
          cancelButtonAriaLabel: "もどる",
          showCancelButton: true,
          reverseButtons: true,
          backdrop: `
            rgba(0,0,0,0.2)
            url("/point_ccl.png")
            right
            no-repeat
          `,
        }).then((result) => {
          this.isGrabMode = false;
          if (result.isConfirmed) {
            this.howtoMinute();
          } else {
            this.howtoOriginal();
          }
        });
      });
    },
    howtoMinute() {
      this.isShowMeetingInfoPopup = true;
      Swal.fire({
        animation: false,
        position: "center-end",
        html: `
        <h3>会議情報エリア</h3>
        <p>ここでは会議の名前や時間を編集することができます。</p>
        <p>また、話者の名前を編集して色を付けたりなど、議事録を作る際に便利な機能がたくさん含まれています。</p>
        <p>「キーワード」は、言語解析技術によってこの文字起こし中の重要ワードがまとめて提案されます。適宜編集してご活用ください。</p>
        <style>
          .swal2-html-container > p{
            font-size: 14px;
            text-align: left;
          }
        </style>
        `,
        confirmButtonText: "次へ",
        confirmButtonAriaLabel: "次へ",
        confirmButtonColor: "#29aba4",
        backdrop: `
          rgba(0,0,0,0.2)
          url("/point_top.png")
          center top
          no-repeat
        `,
      }).then(() => {
        this.isShowMeetingInfoPopup = false;
        this.isShowMinutesInfoPopup = true;
        Swal.fire({
          animation: false,
          position: "center-end",
          html: `
          <h3>議事録情報エリア</h3>
          <p>ここでは会議の概要や、決定事項などを編集することができます。</p>
          <p>この情報は、Wordファイルへの議事録出力のときに使用されます。</p>
          <style>
            .swal2-html-container > p{
              font-size: 14px;
              text-align: left;
            }
          </style>
          `,
          confirmButtonText: "次へ",
          confirmButtonAriaLabel: "次へ",
          confirmButtonColor: "#29aba4",
          cancelButtonText: "もどる",
          cancelButtonAriaLabel: "もどる",
          showCancelButton: true,
          reverseButtons: true,
          backdrop: `
            rgba(0,0,0,0.2)
            url("/point_top.png")
            center top
            no-repeat
          `,
        }).then((result) => {
          this.isShowMinutesInfoPopup = false;
          if (result.isConfirmed) {
            this.howtoDL();
          } else {
            this.howtoEdit();
          }
        });
      });
    },
    howtoDL() {
      this.isShowDLTab = true;
      this.isShowExportWordPopup = true;
      Swal.fire({
        animation: false,
        position: "center-start",
        html: `
        <h3>議事録をダウンロード</h3>
        <p>編集したデータは、Word形式やテキスト形式でダウンロードすることができます。</p>
        <style>
          .swal2-html-container > p{
            font-size: 14px;
            text-align: left;
          }
        </style>
        `,
        confirmButtonText: "次へ",
        confirmButtonAriaLabel: "次へ",
        confirmButtonColor: "#29aba4",
        cancelButtonText: "もどる",
        cancelButtonAriaLabel: "もどる",
        showCancelButton: true,
        reverseButtons: true,
        backdrop: `
          rgba(0,0,0,0.2)
          url("/point_top.png")
          center top
          no-repeat
        `,
      }).then((result) => {
        this.isShowDLTab = false;
        this.isShowExportWordPopup = false;
        if (result.isConfirmed) {
          this.howtoEnd();
        } else {
          this.howtoMinute();
        }
      });
    },
    howtoEnd() {
      Swal.fire({
        animation: true,
        html: `
        <h3>さっそく試してみましょう</h3>
        <p>他にも、ブックマークや検索、単語の置換など、便利な機能がたくさんあります。</p>
        <p>新機能はYoutubeでも紹介しています。ぜひご確認・チャンネル登録をお願いいたします。</p>
        <p><a href="https://www.youtube.com/channel/UC7M3UPM-cg32nb9-pKiFF8g" target="_blank"><i class="fa-brands fa-youtube"></i> VOITER公式</a></p>
        <style>
          .swal2-html-container > p{
            font-size: 14px;
            text-align: left;
          }
        </style>
        `,
        confirmButtonText: "とじる",
        confirmButtonAriaLabel: "とじる",
      });
    },

    /**
     * **************************************************
     * 定数の返却処理など
     * **************************************************
     */
    getCheckStyle() {
      return (
        'input[type="checkbox"] { display: none; }' +
        ".checkbox { box-sizing: border-box; cursor: pointer; display: inline-block; padding: 5px 13px; position: relative; width: auto; top: -3px;}" +
        '.checkbox::before { background: #fff; border: 1px solid #ccc; border-radius: 3px; content: ""; display: block; height: 16px; left: 5px; margin-top: -8px; position: absolute; top: 50%; width: 16px; }' +
        '.checkbox::after { border-right: 6px solid #29aba4; border-bottom: 3px solid #29aba4; content: ""; display: block; height: 20px; left: 7px; margin-top: -16px; opacity: 0; position: absolute; top: 50%; transform: rotate(45deg) translate3d(0, 2px, 0) scale3d(0.7, 0.7, 1); transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; width: 9px; }' +
        'input[type="checkbox"]:checked + .checkbox::before { border-color: #666; }' +
        'input[type="checkbox"]:checked + .checkbox::after { opacity: 1; transform: rotate(45deg) scale3d(1, 1, 1); }' +
        ".checkwrapper { border-bottom: 1px #cccccc solid; margin-left: auto; margin-right: auto; padding: 10px 0px;}" +
        ".checkwrapper > span{ margin: 0px 15px; }" +
        ".checkwrapper i{ position: relative; top:4px }" +
        ".checkwrapper > span > label > span{ margin-left: 20px; font-size: 16px; background: #13618f; padding: 8px 16px; border-radius: 6px; color: #ffffff;}" +
        ".checkwrapper > span > label > span > i{ font-size: 24px; margin-right: 3px}"
      );
    },
    getFileCheck() {
      return (
        '<div class="checkwrapper">' +
        "<span>" +
        '<input type="checkbox" id="fs0" name="typeCB" value="0"/>' +
        '<label for="fs0" class="checkbox"><span><i class="fa-solid fa-file-lines"></i>テキスト</span></label > ' +
        "</span>" +
        "<span>" +
        '<input type="checkbox" id="fs1" name="typeCB" value="1"/>' +
        '<label for="fs1" class="checkbox"><span><i class="fa-solid fa-file-audio"></i>メディア</span></label>' +
        "</span>" +
        "</div>"
      );
    },
    getMediaOnlyCheck() {
      return (
        '<div class="checkwrapper">' +
        "<span>" +
        '<input type="checkbox" id="fs1" name="typeCB" value="1" checked="checked"/>' +
        '<label for="fs1" class="checkbox"><span><i class="fa-solid fa-file-audio"></i>メディア</span></label>' +
        "</span>" +
        "</div>"
      );
    },
    getRadioStyle() {
      return (
        ".radio-inline__input {clip: rect(1px, 1px, 1px, 1px);position: absolute !important;}" +
        ".radio-inline__label {display: inline-block; padding: 0.5rem 1rem; margin-right: 18px; border-radius: 5px; transition: all .2s; border: #1e90ff solid 1px; font-size: 14px; width: 85px;}" +
        ".radio-inline__input:checked + .radio-inline__label { background: #1e90ff; color: #fff; text-shadow: 0 0 1px rgba(0,0,0,.7); }" +
        ".radio-inline__input:focus + .radio-inline__label { outline-color: #4D90FE; outline-offset: -2px; outline-style: auto; outline-width: 5px; }"
      );
    },
    getTextSelect() {
      return (
        "<input id='item-1' class='radio-inline__input' type='radio' name='shareType' value='0'/>" +
        "<label class='radio-inline__label' for='item-1'>" +
        "  テキストのみ" +
        "</label>"
      );
    },
    getMediaSelect() {
      return (
        "<input id='item-2' class='radio-inline__input' type='radio' name='shareType' value='1'/>" +
        "<label class='radio-inline__label' for='item-2'>" +
        "  メディアのみ" +
        "</label>"
      );
    },
    getMediaOnlySelect() {
      return (
        "<input id='item-2' class='radio-inline__input' type='radio' name='shareType' value='1' checked='checked'/>" +
        "<label class='radio-inline__label' for='item-2'>" +
        "  メディア" +
        "</label>"
      );
    },
    getBothSelect() {
      return (
        "<input id='item-3' class='radio-inline__input' type='radio' name='shareType' value='2' checked='checked'/>" +
        "<label class='radio-inline__label' for='item-3'>" +
        "  両方  " +
        "</label>"
      );
    },
    /**
     * 日本の都道府県のリストを返す
     */
    getRegions() {
      return [
        { id: 1, name: "北海道" },
        { id: 2, name: "青森県" },
        { id: 3, name: "岩手県" },
        { id: 4, name: "宮城県" },
        { id: 5, name: "秋田県" },
        { id: 6, name: "山形県" },
        { id: 7, name: "福島県" },
        { id: 8, name: "茨城県" },
        { id: 9, name: "栃木県" },
        { id: 10, name: "群馬県" },
        { id: 11, name: "埼玉県" },
        { id: 12, name: "千葉県" },
        { id: 13, name: "東京都" },
        { id: 14, name: "神奈川県" },
        { id: 15, name: "新潟県" },
        { id: 16, name: "富山県" },
        { id: 17, name: "石川県" },
        { id: 18, name: "福井県" },
        { id: 19, name: "山梨県" },
        { id: 20, name: "長野県" },
        { id: 21, name: "岐阜県" },
        { id: 22, name: "静岡県" },
        { id: 23, name: "愛知県" },
        { id: 24, name: "三重県" },
        { id: 25, name: "滋賀県" },
        { id: 26, name: "京都府" },
        { id: 27, name: "大阪府" },
        { id: 28, name: "兵庫県" },
        { id: 29, name: "奈良県" },
        { id: 30, name: "和歌山県" },
        { id: 31, name: "鳥取県" },
        { id: 32, name: "島根県" },
        { id: 33, name: "岡山県" },
        { id: 34, name: "広島県" },
        { id: 35, name: "山口県" },
        { id: 36, name: "徳島県" },
        { id: 37, name: "香川県" },
        { id: 38, name: "愛媛県" },
        { id: 39, name: "高知県" },
        { id: 40, name: "福岡県" },
        { id: 41, name: "佐賀県" },
        { id: 42, name: "長崎県" },
        { id: 43, name: "熊本県" },
        { id: 44, name: "大分県" },
        { id: 45, name: "宮崎県" },
        { id: 46, name: "鹿児島県" },
        { id: 47, name: "沖縄県" },
      ];
    },
    /**
     * 業種のリストを返す
     * TODO: 将来的には他サービスと合わせてDBから取得したい
     */
    getIndustries() {
      return [
        { id: 1, name: "会社員" },
        { id: 2, name: "学生" },
        { id: 3, name: "メディア" },
        { id: 4, name: "教育" },
        { id: 5, name: "医療" },
        { id: 6, name: "法律" },
        { id: 7, name: "その他" },
      ];
    },
    getDummyInfos() {
      return [
        {
          index: 0,
          id: 0,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "これはダミーのお知らせです0",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 1,
          id: 1,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ1",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 2,
          id: 2,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ2",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 3,
          id: 3,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ3",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 4,
          id: 4,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ4",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 5,
          id: 5,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ5",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 6,
          id: 6,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ6",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 7,
          id: 7,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ7",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 8,
          id: 8,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ8",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 9,
          id: 9,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ9",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 10,
          id: 10,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ10",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 11,
          id: 11,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ11",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 12,
          id: 12,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ12",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 13,
          id: 13,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ13",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 14,
          id: 14,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ14",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 15,
          id: 15,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ15",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 16,
          id: 16,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ16",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 17,
          id: 17,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ17",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 18,
          id: 18,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ18",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 19,
          id: 19,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ19",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 20,
          id: 20,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ20",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 21,
          id: 21,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ21",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 22,
          id: 22,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ22",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 23,
          id: 23,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ23",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 24,
          id: 24,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ24",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 25,
          id: 25,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ25",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 26,
          id: 26,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ26",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 27,
          id: 27,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ27",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 28,
          id: 28,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ28",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 29,
          id: 29,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ29",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 30,
          id: 30,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ30",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
        {
          index: 31,
          id: 31,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "01",
          title: "サービス開始のお知らせ31",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n私共はこの度、AI技術を搭載した超高性能ボイスレコーダーを日本市場にリリースさせていただきます。",
        },
        {
          index: 32,
          id: 32,
          startdate: "2020-12-17 18:48",
          enddate: "",
          viewdate: "2020-12-17 18:48",
          category: "02",
          title: "メンテナンスのお知らせ32",
          content:
            "いつもアイフライテック製品をご利用いただき誠にありがとうございます。 \n以下の日程にて、音声認識サーバのアップデート・メンテナンスを行います。お客様には大変ご不便おかけいたしますが、何卒ご理解のほどよろしくお願いいたします。 \n○月○日、深夜0時30分〜1時30分",
        },
      ];
    },
    getDummyResult() {
      return {
        audioVO: {
          jsonUrl: "string",
          localFlg: false,
          txt: "データが取得できませんでした",
          txtNum: "00",
          version: 0,
          wavUrl: "string",
        },
        createTime: "0000-00-00 00:00:00",
        duration: 0,
        fileType: 999,
        fileName: "データが取得できませんでした",
        pictureVO: {
          pictureUrl: "string",
        },
        projectId: "dammy_project_20210101",
        size: "10",
        videoVO: {
          videoTxt: "string",
          videoUrl: "string",
        },
      };
    },
    getDummyTextJson() {
      return {
        datalist: [
          {
            content: "テキスト情報はありません",
            endTime: 1.65,
            index: 0,
            rl: "0",
            sc: "0.00",
            si: "0",
            speaker: "段落-0",
            startTime: 0.43,
          },
        ],
        intervalLayerCount: 0,
        intervalLayerName: ["a", "b"],
        issmooth: true,
        issubtitles: true,
        layNameKeyDic: { a: "speaker", bt: "sex" },
        layerCount: 0,
        overAllLayerCount: 0,
        overall: { sex: "A\u003dmale B\u003dmale" },
        totalLength: 0,
      };
    },
    getDummyAdvJSON() {
      return {
        keyword: [
          "加速器",
          "セブン",
          "ナビ",
          "入り口",
          "キャノン",
          "シーズン",
          "き話",
          "分かん",
          "アフリカ",
          "政治家",
        ],
        related_person: [],
        result: [
          {
            sentence_id: 0,
            speaker_id: "1",
            text_list: [
              {
                sentence: "えーと、今週ですね",
                word_list: ["えーと", "、", "今週", "です", "ね"],
              },
              {
                sentence:
                  "毎週楽しくやってるんですけど、俺先週何やってたっけ先週は加速き話をしてたんですよ",
                word_list: [
                  "毎週",
                  "楽しく",
                  "やっ",
                  "てる",
                  "ん",
                  "です",
                  "けど",
                  "、",
                  "俺",
                  "先週",
                  "何",
                  "やっ",
                  "て",
                  "た",
                  "っけ",
                  "先週",
                  "は",
                  "加速",
                  "き話",
                  "を",
                  "し",
                  "て",
                  "た",
                  "ん",
                  "です",
                  "よ",
                ],
              },
              {
                sentence:
                  "加速器の話そう加速器の話かもうなんか分かんないですけど、あのアフリカの民族にしあ手がない話までもはや世界うるるん大罪非常罪のよくわからない番組になってまいりましたけれどもあの政治家シーズンがあ1穴が開けてですね最近だいぶカルチャーシーズンに近づいておるところで非常に楽しみにしておるところでございます、さてえーとこっちからじゃあ今日のえーと今日もいつもご紹介しますえ？二チャンネル創設で実業家のひろゆきさんです",
                word_list: [
                  "加速器",
                  "の",
                  "話そ",
                  "う",
                  "加速器",
                  "の",
                  "話",
                  "かも",
                  "う",
                  "なんか",
                  "分かん",
                  "ない",
                  "です",
                  "けど",
                  "、",
                  "あの",
                  "アフリカ",
                  "の",
                  "民族",
                  "に",
                  "しあ",
                  "手",
                  "が",
                  "ない",
                  "話",
                  "まで",
                  "もはや",
                  "世界",
                  "うるる",
                  "ん",
                  "大罪",
                  "非常",
                  "罪",
                  "の",
                  "よく",
                  "わから",
                  "ない",
                  "番組",
                  "に",
                  "なっ",
                  "て",
                  "まいり",
                  "まし",
                  "た",
                  "けれども",
                  "あの",
                  "政治家",
                  "シーズン",
                  "が",
                  "あ",
                  "1",
                  "穴",
                  "が",
                  "開け",
                  "て",
                  "です",
                  "ね",
                  "最近",
                  "だいぶ",
                  "カルチャー",
                  "シーズン",
                  "に",
                  "近づい",
                  "て",
                  "おる",
                  "ところ",
                  "で",
                  "非常",
                  "に",
                  "楽しみ",
                  "に",
                  "し",
                  "て",
                  "おる",
                  "ところ",
                  "で",
                  "ござい",
                  "ます",
                  "、",
                  "さて",
                  "えーと",
                  "こっち",
                  "から",
                  "じゃあ",
                  "今日",
                  "の",
                  "えーと",
                  "今日",
                  "も",
                  "いつも",
                  "ご",
                  "紹介",
                  "し",
                  "ます",
                  "え",
                  "？",
                  "二チャンネル",
                  "創設",
                  "で",
                  "実業家",
                  "の",
                  "ひろゆき",
                  "さん",
                  "です",
                ],
              },
              {
                sentence: "本日よろしくお願いします",
                word_list: ["本日", "よろしくお願いします"],
              },
              {
                sentence: "はいウェブカムが映らないという表示がなくなりますね",
                word_list: [
                  "はい",
                  "ウェブカム",
                  "が",
                  "映ら",
                  "ない",
                  "という",
                  "表示",
                  "が",
                  "なくなり",
                  "ます",
                  "ね",
                ],
              },
              {
                sentence: "あすみませんえ",
                word_list: ["あ", "すみません", "え"],
              },
              {
                sentence: "イオスを使ってらっしゃるんですね",
                word_list: [
                  "イオス",
                  "を",
                  "使っ",
                  "て",
                  "らっしゃる",
                  "ん",
                  "です",
                  "ね",
                ],
              },
              {
                sentence: "あそうです",
                word_list: ["あ", "そう", "です"],
              },
              {
                sentence:
                  "あのキャノンのファイブディーマートフォーってあ、キャノンのライブディを使って再起動",
                word_list: [
                  "あの",
                  "キャノン",
                  "の",
                  "ファイブディーマートフォー",
                  "って",
                  "あ",
                  "、",
                  "キャノン",
                  "の",
                  "ライブディ",
                  "を",
                  "使っ",
                  "て",
                  "再起動",
                ],
              },
            ],
            time: "0:00:00.620000",
            txt:
              "えーと、今週ですね。毎週楽しくやってるんですけど、俺先週何やってたっけ先週は加速き話をしてたんですよ。加速器の話そう加速器の話かもうなんか分かんないですけど、あのアフリカの民族にしあ手がない話までもはや世界うるるん大罪非常罪のよくわからない番組になってまいりましたけれどもあの政治家シーズンがあ1穴が開けてですね最近だいぶカルチャーシーズンに近づいておるところで非常に楽しみにしておるところでございます、さてえーとこっちからじゃあ今日のえーと今日もいつもご紹介しますえ？二チャンネル創設で実業家のひろゆきさんです。本日よろしくお願いします。はいウェブカムが映らないという表示がなくなりますね。あすみませんえ。イオスを使ってらっしゃるんですね。あそうです。あのキャノンのファイブディーマートフォーってあ、キャノンのライブディを使って再起動。",
          },
          {
            sentence_id: 1,
            speaker_id: "2",
            text_list: [
              {
                sentence:
                  "としてると鉄を切れちゃうんですよねあ、そうなんですはいよろしくお願いします",
                word_list: [
                  "と",
                  "し",
                  "てる",
                  "と",
                  "鉄",
                  "を",
                  "切れ",
                  "ちゃう",
                  "ん",
                  "です",
                  "よ",
                  "ね",
                  "あ",
                  "、",
                  "そう",
                  "な",
                  "んで",
                  "すはい",
                  "よろしくお願いします",
                ],
              },
              {
                sentence:
                  "あのセルの加速比なんですけど、はい、僕たまたま道に迷ったらセブンに着いたんですよねあそこのえあのそのフランスのリオンからあのそのジュネーブに飛んでっていうのであのドライブをしますはいでなんかあのちょっとこうなんかココジかなんかがあってナビ通り進めなくてあそこのはさい***何て言うんですか？でなのナビを無視して適当に進んでてであの高速こっちかなってとこであ、間違えたって降りてなんか物申しゲートがあってしたらせるって書いてあって、うわせるこんなとこにあるんだってめちゃくちゃ感動したんですけど、うちの彼女にはその喜びが伝わらなかった",
                word_list: [
                  "あの",
                  "セル",
                  "の",
                  "加速",
                  "比",
                  "な",
                  "ん",
                  "です",
                  "けど",
                  "、",
                  "はい",
                  "、",
                  "僕",
                  "たまたま",
                  "道",
                  "に",
                  "迷っ",
                  "たら",
                  "セブン",
                  "に",
                  "着い",
                  "た",
                  "ん",
                  "です",
                  "よ",
                  "ね",
                  "あそこ",
                  "の",
                  "えあ",
                  "の",
                  "その",
                  "フランス",
                  "の",
                  "リオン",
                  "から",
                  "あの",
                  "その",
                  "ジュネーブ",
                  "に",
                  "飛ん",
                  "で",
                  "って",
                  "いう",
                  "ので",
                  "あの",
                  "ドライブ",
                  "を",
                  "し",
                  "ます",
                  "はい",
                  "で",
                  "なんか",
                  "あの",
                  "ちょっと",
                  "こう",
                  "なんか",
                  "ココジ",
                  "か",
                  "なんか",
                  "が",
                  "あっ",
                  "て",
                  "ナビ",
                  "通り",
                  "進め",
                  "なく",
                  "て",
                  "あそこ",
                  "の",
                  "はさ",
                  "い",
                  "***",
                  "何",
                  "て",
                  "言う",
                  "ん",
                  "です",
                  "か",
                  "？",
                  "で",
                  "な",
                  "の",
                  "ナビ",
                  "を",
                  "無視",
                  "し",
                  "て",
                  "適当",
                  "に",
                  "進ん",
                  "で",
                  "て",
                  "で",
                  "あの",
                  "高速",
                  "こっち",
                  "か",
                  "なっ",
                  "て",
                  "とこ",
                  "で",
                  "あ",
                  "、",
                  "間違え",
                  "た",
                  "って",
                  "降り",
                  "て",
                  "なんか",
                  "物申し",
                  "ゲート",
                  "が",
                  "あっ",
                  "て",
                  "し",
                  "たら",
                  "せる",
                  "って",
                  "書い",
                  "て",
                  "あっ",
                  "て",
                  "、",
                  "うわ",
                  "せる",
                  "こんな",
                  "とこ",
                  "に",
                  "ある",
                  "ん",
                  "だって",
                  "めちゃくちゃ",
                  "感動",
                  "し",
                  "た",
                  "ん",
                  "です",
                  "けど",
                  "、",
                  "うち",
                  "の",
                  "彼女",
                  "に",
                  "は",
                  "その",
                  "喜び",
                  "が",
                  "伝わら",
                  "なかっ",
                  "た",
                ],
              },
            ],
            time: "0:00:59.170000",
            txt:
              "としてると鉄を切れちゃうんですよねあ、そうなんですはいよろしくお願いします。あのセルの加速比なんですけど、はい、僕たまたま道に迷ったらセブンに着いたんですよねあそこのえあのそのフランスのリオンからあのそのジュネーブに飛んでっていうのであのドライブをしますはいでなんかあのちょっとこうなんかココジかなんかがあってナビ通り進めなくてあそこのはさい馬鹿何て言うんですか？でなのナビを無視して適当に進んでてであの高速こっちかなってとこであ、間違えたって降りてなんか物申しゲートがあってしたらせるって書いてあって、うわせるこんなとこにあるんだってめちゃくちゃ感動したんですけど、うちの彼女にはその喜びが伝わらなかった。",
          },
          {
            sentence_id: 2,
            speaker_id: "1",
            text_list: [
              {
                sentence:
                  "でも、なんかあの日本科学未来館みたいな子供達がよくわざわしますね",
                word_list: [
                  "でも",
                  "、",
                  "なんか",
                  "あの",
                  "日本科学未来館",
                  "みたい",
                  "な",
                  "子供達",
                  "がよく",
                  "わ",
                  "ざわ",
                  "し",
                  "ます",
                  "ね",
                ],
              },
              {
                sentence: "あそこね",
                word_list: ["あそこ", "ね"],
              },
            ],
            time: "0:01:42.660000",
            txt:
              "でも、なんかあの日本科学未来館みたいな子供達がよくわざわしますね。あそこね。",
          },
          {
            sentence_id: 3,
            speaker_id: "2",
            text_list: [
              {
                sentence:
                  "なんか表入り口でくそだと思うんすけどなんか裏入り口っぽいところね",
                word_list: [
                  "",
                  "なんか",
                  "表",
                  "入り口",
                  "でく",
                  "そ",
                  "だ",
                  "と",
                  "思う",
                  "ん",
                  "す",
                  "けど",
                  "",
                  "なんか",
                  "裏",
                  "入り口",
                  "っぽい",
                  "ところ",
                  "ね",
                ],
              },
              {
                sentence:
                  "なんかあの警備員だけいるみたいな感じあそうで、なんか変わった施設はあのセブンって書いてあって、あの加速器がここにみたいな、",
                word_list: [
                  "なんか",
                  "あの",
                  "警備員",
                  "だけ",
                  "いる",
                  "みたい",
                  "な",
                  "感じ",
                  "あ",
                  "そう",
                  "で",
                  "、",
                  "なんか",
                  "変わっ",
                  "た",
                  "施設",
                  "は",
                  "あの",
                  "セブン",
                  "って",
                  "書い",
                  "て",
                  "あっ",
                  "て",
                  "、",
                  "あの",
                  "加速器",
                  "が",
                  "ここに",
                  "みたい",
                  "な",
                  "、",
                  "",
                ],
              },
            ],
            time: "0:01:47.800000",
            txt:
              "多分なんか表入り口でくそだと思うんすけど多分なんか裏入り口っぽいところね。なんかあの警備員だけいるみたいな感じあそうで、なんか変わった施設はあのセブンって書いてあって、あの加速器がここにみたいな、まあまあ。",
          },
          {
            sentence_id: 4,
            speaker_id: "1",
            text_list: [
              {
                sentence: "でかいので楽しい",
                word_list: ["でかい", "ので", "楽しい"],
              },
              {
                sentence: "楽しいです",
                word_list: ["楽しい", "です"],
              },
              {
                sentence:
                  "私でもあの年一ぐらいで行ってたんですけど最近はころまで行けてないセブンでございます",
                word_list: [
                  "私",
                  "でも",
                  "あの",
                  "年",
                  "一",
                  "ぐらい",
                  "で",
                  "行っ",
                  "て",
                  "た",
                  "ん",
                  "です",
                  "けど",
                  "最近",
                  "は",
                  "ころ",
                  "まで",
                  "行け",
                  "て",
                  "ない",
                  "セブン",
                  "で",
                  "ござい",
                  "ます",
                ],
              },
              {
                sentence:
                  "えっとさて簡単に簡単にご挨拶くださいとかでも昼夜来たのよなんか1回は1回なんかプライベートでたまたま飲みに行ったことがあってひろきさんにこいねでその後からそうそう理事長の会議とかなんかいろんなところでこいつ頂いて、でもこれで対談するのが実は初めてというのは",
                word_list: [
                  "えっ",
                  "と",
                  "さて",
                  "簡単",
                  "に",
                  "簡単",
                  "に",
                  "ご挨拶",
                  "ください",
                  "とか",
                  "でも",
                  "昼夜",
                  "来",
                  "た",
                  "の",
                  "よ",
                  "なんか",
                  "1回",
                  "は",
                  "1回",
                  "なんか",
                  "プライベート",
                  "で",
                  "たまたま",
                  "飲み",
                  "に",
                  "行っ",
                  "た",
                  "こと",
                  "が",
                  "あっ",
                  "て",
                  "ひろき",
                  "さん",
                  "に",
                  "こい",
                  "ね",
                  "で",
                  "その後",
                  "から",
                  "そうそう",
                  "理事長",
                  "の",
                  "会議",
                  "とか",
                  "なんか",
                  "いろんな",
                  "ところ",
                  "で",
                  "こいつ",
                  "頂い",
                  "て",
                  "、",
                  "でも",
                  "これ",
                  "で",
                  "対談",
                  "する",
                  "の",
                  "が",
                  "実は",
                  "初めて",
                  "という",
                  "の",
                  "は",
                ],
              },
            ],
            time: "0:02:00.130000",
            txt:
              "でかいので楽しい。楽しいです。私でもあの年一ぐらいで行ってたんですけど最近はころまで行けてないセブンでございます。えっとさて簡単に簡単にご挨拶くださいとかでも昼夜来たのよなんか1回は1回なんかプライベートでたまたま飲みに行ったことがあってひろきさんにこいねでその後からそうそう理事長の会議とかなんかいろんなところでこいつ頂いて、でもこれで対談するのが実は初めてというのは。",
          },
          {
            sentence_id: 5,
            speaker_id: "2",
            text_list: [
              {
                sentence: "最近分かりました",
                word_list: ["最近", "分かり", "まし", "た"],
              },
              {
                sentence: "まああのそうですね、メディアデータが",
                word_list: [
                  "まあ",
                  "あの",
                  "そうですね",
                  "、",
                  "メディア",
                  "データ",
                  "が",
                ],
              },
            ],
            time: "0:02:26.170000",
            txt: "最近分かりました。まああのそうですね、メディアデータが。",
          },
        ],
      };
    },
  },
};
