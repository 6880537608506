<template>
  <div class="home">
    <Header pagename="Dictation"/>
    <div class="contentarea">
      <MainNavi/>
        <div class="maincontent">
          <div class="toollist">
            <div class="traillist handcursor" @click="pushPage({name: 'Home'})"><i class="fas fa-chevron-left"></i>もどる</div>
            <div class="operatelist">
              <span class="toolicon handcursor" @click="showDLWindow"><i class="fas fa-download fa-fw"></i></span>
              <!-- <span class="toolicon handcursor" @click="showShareWindow"><i class="fas fa-share-alt fa-fw"></i></span> -->
              <span class="toolicon handcursor" @click="showRenameWindow"><i class="fas fa-pen fa-fw"></i></span>
              <span class="toolicon handcursor" @click="showTrashWindow"><i class="far fa-trash-alt fa-fw"></i></span>
            </div>
          </div>
          <div class="itemarea">
            <table>
            <tbody>
              <tr>
                <td>
                  <span class="audioicon" v-show="item.fileType == '0'"><i class="material-icons">music_note</i></span>
                  <span class="imageicon" v-show="item.fileType == '2'"><i class="material-icons">insert_photo</i></span>
                  <span class="videoicon" v-show="item.fileType == '3'"><i class="material-icons">videocam</i></span>
                </td>
                <td>{{ item.fileName }}</td>
                <td>{{ item.createTime.slice(0, -3) }}</td>
                <td>{{ recordTime }}</td>
                <td>{{ recordMB }}MB</td>
              </tr>
            </tbody>
          </table>
          <div class="syncarea">
            <div class="stringcount">文字数：{{ item.audioVO.txtNum ? item.audioVO.txtNum : "--" }}文字</div>
          </div>

          <div v-show="item.fileType == '0'">

            <div v-show="item.audioVO.txt == null">
              <p>文字起こしテキストがアップロードされていません</p>
            </div>
            
            <div v-show="item.audioVO.txt !== null">
              <ul class="tab_list">
                <li @click="setMode('edit')" v-bind:class="{'active': mode == 'edit'}">編集</li>
                <li @click="setMode('original')" v-bind:class="{'active': mode == 'original'}">オリジナル</li>
                <li class="savebutton">
                  <span v-show="isTextChanged" class="button smallbutton" @click="save">保存する</span>
                </li>
              </ul>

              <div class="panel_area">
                <!-- 文章編集エリア -->
                <div v-show=" mode == 'edit' " id="editPanel" class="tab_panel">
                  <textarea 
                    v-model="afterText" 
                    class="ais_editer textcursor" 
                    id="ais_editer" >
                  </textarea>
                </div>

                <!-- オリジナル文章エリア -->
                <div v-show=" mode == 'original' " id="originalPanel" class="tab_panel">
                  <div class="ais_editer">
                    <span v-for="(block) in txtJson.datalist" 
                        :key="block.id" 
                        v-bind:class="{ 'highlight': isReadingBlock(block) }" 
                        @click="setCurrentTime(block.startTime)"
                        class="handcursor">
                      {{ block.content }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-show="audioPrepared" class="player">
              <div class="playdisplay">
                <span class="button playbutton" @click="playSwicth">
                  <i v-show="!playing" class="fas fa-play playicon"></i>
                  <i v-show="playing" class="fas fa-pause"></i>
                </span>
                <span class="currentTime">{{ currentTimeView }}</span>
              </div>
              <input 
                type="range"
                v-model="currentTime" 
                v-on:input="seek" 
                v-bind:max="seekDurationView" />
            </div>
            <audio id="audioObj"></audio>
          </div>
          <p v-show="item.fileType !== '0'">ダウンロードからファイルを取得できます。</p>
        </div>
      </div>
      
    </div>
    <Footer/>
    <Loading />
  </div>
</template>

<script>
// @ is an alias to /src
import Loading from '@/components/Loading.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainNavi from '@/components/MainNavi.vue'
import processing from '@/mixins/processing.js'
import utils from '@/mixins/utils.js'
import Swal from 'sweetalert2';
import api from '@/mixins/api.js'
import axios from 'axios'

export default {
  name: 'Dictation',
  components: {
    Header,
    Footer,
    MainNavi,
    Loading,
  },
  mixins: [utils, api, processing],
  props: {
    item_id: String,
  },
  data: function() {
    return {
      prj_id: "",
      item: {
        audioVO: {
          jsonUrl: '',
          localFlg: false,
          txt: '',
          txtNum: '',
          version: 0,
          wavUrl: '',
          aacurl: '',
        },
        createTime: '',
        duration: 100000,
        fileName: 'name',
        fileType: 0,
        pictureVO: {
          pictureUrl: ''
        },
        projectId: '',
        size: 10,
        videoVO: {
          videoTxt: '',
          videoUrl: ''
        }
      },
      txtJson: {},
      mode: 'edit',
      editted: false,
      editText: "",
      beforeText: "",
      afterText: "",
      audio: undefined,
      currentTime: 0,
      durationTime: 0,
      loaded: false,
      playing: false,
      waiting: true,
      audioPrepared: false,
      countControler: null,
    }
  },
  computed: {
		currentTimeView() {
      return this.convertTimeHHMMSS(this.currentTime * 1000);
      // return this.currentTime.toFixed(1)
		},
		durationTimeView() {
			return this.convertTimeHHMMSS(this.durationTime);
    },
    seekDurationView(){
      return this.durationTime.toFixed(0);
    },
		percentComplete() {
			return parseInt(this.currentTime / this.durationTime * 100);
    },
    recordTime(){
      if(this.item.duration){
        return this.convertTimeHHMMSS(this.item.duration)
      }else{
        return '--:--'
      }
    },
    recordMB(){
      const MB = 1024 * 1024
      if(this.item.size){
        return (this.item.size/MB).toFixed(2)
      }else{
        return '--'
      }
    },
    isTextChanged(){
      if(this.beforeText == this.afterText){
        return false
      }else{
        return true
      }
    },
	},
  created(){
    this.prj_id = this.item_id;
    this.recoveryItems()
  },
  mounted(){
    // this.audio = document.getElementById("audioObj")
    this.getItemData()
  },
  methods: {
    recoveryItems(){
      if(this.item_id){
        localStorage.setItem("item_id", this.item_id)
      }else{
        this.prj_id = localStorage.getItem('item_id')
      }
    },
    showLoading(){
      try{
        this.$modal.show('loading')
      }catch{
        console.log("loading icon isnot loaded...")
      }
    },
    hideLoading(){
      try{
        this.$modal.hide('loading')
      }catch{
        console.log("loading icon isnot loaded...")
      }
    },
    setMode(mode){
      this.mode = mode
    },
    getItemData(){
      this.showLoading()
      
      const data = {}
      const url = "/contents/" + this.prj_id + "/info"
      axios(this.getAxConf("get", url, data, this.$store.state.token))
      .then((response) => {
        
        if(response.data.success){
          this.item = response.data.data
          
          if(this.item.fileType == 0){

            // this.audio = new Audio()
            this.audio = document.getElementById("audioObj")
            this.audio.src = this.item.audioVO.aacUrl

            // オーディオの場合
            if(this.item.audioVO.txt){
              this.beforeText = this.item.audioVO.txt
              this.afterText = this.item.audioVO.txt
            }else{
              this.beforeText = "テキスト情報はありません"
              this.afterText = "テキスト情報はありません"
            }
            // this.audio = document.getElementById("audioObj")
            // this.audio = new Audio(this.item.audioVO.aacUrl)

            setTimeout(()=>{
              this.setEventListener()
            }, 2000)


          }else if(this.item.fileType == 2){
            // 画像の場合
            this.beforeText = "テキスト情報はありません"
            this.afterText = "テキスト情報はありません"

          }else if(this.item.fileType == 3){
            // ビデオの場合
            if(this.item.videoVO.videoTxt){
              this.beforeText = this.item.videoVO.videoTxt
              this.afterText = this.item.videoVO.videoTxt
            }else{
              this.beforeText = "テキスト情報はありません"
              this.afterText = "テキスト情報はありません"
            }

          }else{
            // エラーコードが帰ってきた場合
            this.item = this.getDummyResult()
          }
          this.getTextJSON()
        }else{
          this.showErrorWindow(response)
          // ↓ダミーデータ
          this.item = this.getDummyResult()
        }
        this.hideLoading()
      })
      .catch((err) => {
        this.hideLoading()
        this.showNetworkErrorWindow(err)
        // ↓ダミーデータ
        this.item = this.getDummyResult()
      });
    },
    getTextJSON(){
      var url
      if(this.item.fileType == 0){
        // オーディオの場合
        if(!this.item.audioVO.jsonUrl){
          this.txtJson = this.getDummyTextJson()
          return false
        }
        url = this.item.audioVO.jsonUrl
        url = url.replace('http:', 'https:')

      }else if(this.item.fileType == 2){
        // 画像の場合
        this.txtJson = this.getDummyTextJson()
        return false
      
      }else if(this.item.fileType == 3){
        // ビデオの場合
        this.txtJson = this.getDummyTextJson()
        return false
      }else{
        this.txtJson = this.getDummyTextJson()
        return false
      }

      axios.get(url)
      .then((response) => {
        this.txtJson = response.data
      })
      .catch((err) => {
        this.txtJson = err
      });
    },
    isReadingBlock(block){
      if(block.startTime <= this.currentTime && this.currentTime < block.endTime){
        return true
      }
      return false
    },
    setCurrentTime(time){
      this.currentTime = time
      this.audio.currentTime = time
    },
    save(){
      this.startProcessing()
      
      const data = {
        "newTxt": this.afterText,
        "projectId": this.prj_id,
        "version": this.item.audioVO.version ? this.item.audioVO.version : 0
      }
      console.log("data..." + JSON.stringify(data))

      axios.put(this.getURL('/contents/' + this.prj_id), data, this.getHeaders(this.$store.state.token))
        .then((response) => {
          if (response.data.success) {
            this.editted = false
            if(this.item.audioVO.version == null){
              this.item.audioVO.version = 0
            }else{
              this.item.audioVO.version += 1
            }
            this.beforeText = this.afterText
            Swal.fire({
              html: "更新しました",
              toast: true,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            })
          }else{
            alert(response.data.data.message)
          }
          this.endProcessing()
        })
        .catch((response) => {
          alert(response.data.message);
          this.endProcessing()
        })
    },

    /**
     * *****************************************
     * ツールウィンドウ
     * *****************************************
     */
    
    showDLWindow(){
      
      var mediaDLUrl = "#"
      var buttonStr =""
      if(this.item.fileType == 0){
        mediaDLUrl = this.item.audioVO.aacUrl
        buttonStr = this.getFileCheck()
      }else if(this.item.fileType == 2){
        mediaDLUrl = this.item.pictureVO.pictureUrl
        buttonStr = this.getMediaOnlyCheck()
      }else if(this.item.fileType == 3){
        mediaDLUrl = this.item.videoVO.videoUrl
        buttonStr = this.getMediaOnlyCheck()
      }else{
        return false
      }
      const textname = this.item.fileName + ".txt"
      const textDLUrl =  this.generateDownloadFile(this.afterText, textname)

      Swal.fire({
        title: "ダウンロード",
        html: 
          buttonStr + 
          "<style>" + 
          this.getCheckStyle()+
          "</style>" ,
        input: "hide",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor : '#68CFC3',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        confirmButtonText: "ダウンロード",
        confirmButtonAriaLabel: "ダウンロード",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        preConfirm: () => {
          var elms = document.getElementsByName("typeCB")
          var contentType = ''

          if(this.item.fileType == 2 || this.item.fileType == 3){
            if(elms[0].checked){
              contentType = 1
            }else{
              return false
            }
          }else if(this.item.fileType == 0){
            if(elms[0].checked && !elms[1].checked){
              contentType = 0
            }else if(!elms[0].checked && elms[1].checked){
              contentType = 1
            }else if(elms[0].checked && elms[1].checked){
              contentType = 2
            }else{
              return false
            }
          }
          
          const a = document.createElement("a")
          document.body.appendChild(a)
          if(contentType == 0){
            a.download = textname
            a.href = textDLUrl
            a.click()
            a.remove()
          }else if(contentType == 1){
            a.download = this.item.fileName
            a.href = mediaDLUrl
            a.click()
            a.remove()
          }else if(contentType == 2){
            const data = { 
            projectIdList: [this.item.projectId],
            contentFlg: contentType
          }
          return axios.post(this.getURL('/download/files'), data, this.getBlobHeaders(this.$store.state.token) )
          .then((response) => {

            if(response){
              const filename = "recstation_" + this.getNowDate() + ".zip"
              const blob = new Blob([response.data], { type: 'application/zip' })
              const uri = URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.download = filename
              link.href = uri
              link.click()
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
          });
          }
          
        }
      })
    },
    showShareWindow(){
      var buttonStr =""
      if(this.item.fileType == 0){
        buttonStr = this.getFileCheck()
      }else if(this.item.fileType == 2){
        buttonStr = this.getMediaOnlyCheck()
      }else if(this.item.fileType == 3){
        buttonStr = this.getMediaOnlyCheck()
      }else{
        return false
      }

      Swal.fire({
        title: "メールで共有",
        html: 
          "<p>メールアドレスを一行ずつ入力してください</p>" + 
          "<textarea id='mailinput' class='mailinput' placeholder='example@recstation.jp'></textarea>" + 
          buttonStr + 
          "<style>" + 
          ".mailinput {width:100%; height:70px; font-family:'メイリオ', 'Meiryo','ＭＳ ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;}" +
          this.getCheckStyle() +
          "</style>" ,
        input: "hide",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor : '#68CFC3',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        confirmButtonText: "送信",
        confirmButtonAriaLabel: "送信",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        showLoaderOnConfirm: true,
        inputValidator: () => {
          return new Promise((resolve) => {
            var value = document.getElementById('mailinput').value
            if (value) {
              var mailArr = value.split(/\n/)
              mailArr.forEach(elm => {
                if (this.isEmail(elm)) {
                  // resolve()
                } else {
                  resolve('メールアドレスの形式で入力してください')
                }
              });
              resolve()
            } else {
              resolve('メールアドレスを入力してください')
            }
          })
        },
        preConfirm: () => {
          var elms = document.getElementsByName("typeCB")
          var contentType = ''

          if(this.item.fileType == 2 || this.item.fileType == 3){
            if(elms[0].checked){
              contentType = 1
            }else{
              return false
            }
          }else if(this.item.fileType == 0){
            if(elms[0].checked && !elms[1].checked){
              contentType = 0
            }else if(!elms[0].checked && elms[1].checked){
              contentType = 1
            }else if(elms[0].checked && elms[1].checked){
              contentType = 2
            }else{
              return false
            }
          }

          // 「;」を追加
          var mailstr = document.getElementById('mailinput').value
          var mailArr = mailstr.split(/\n/)
          var trimmedMailstr = ""
          mailArr.forEach(elm => {
            trimmedMailstr = trimmedMailstr + elm + ";"
          });
        
          const data = { 
            mail: trimmedMailstr,
            projectIdList: [this.prj_id],
            shareFlg: contentType
          }
          return axios.post(this.getURL('/contents/share'), data, this.getHeaders(this.$store.state.token))
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                confirmButtonColor : '#68CFC3',
                animation : false,
                html: "<p>送信が完了しました</p>",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
            }else{
              return Swal.showValidationMessage(response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })
    },
    showRenameWindow(){
      Swal.fire({
        html:
          "<p>アイテム名を変更</p>",
        input: "text",
        confirmButtonColor : '#68CFC3',
        animation : false,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "更新",
        confirmButtonAriaLabel: "更新",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if(value){
              if(this.isPermissibleName(value)){
                resolve()
              }else{
                resolve('_以外の記号や、特殊な文字は使用できません')
              }
            }else{
              resolve('１文字以上入力してください')
            }
          })
        },
        preConfirm: (value) => {
          var data = {
            projectName: value,
          }
          const url = '/contents/' + this.prj_id + '/info'
          return axios.put(this.getURL(url), data, this.getHeaders(this.$store.state.token))
          .then((response) => {
            if (response.data.success) {
              this.item.fileName = value
              Swal.fire({
                confirmButtonColor : '#68CFC3',
                animation : false,
                html: "<p>更新しました</p>",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
            }else{
              return Swal.showValidationMessage(response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
          });
        },
      })
    },
    showTrashWindow(){
      Swal.fire({
        tilte: 'ファイルを削除',
        html: '<p>削除したデータを元に戻すことはできません。</p><p>本当に削除してよろしいですか？</p>',
        confirmButtonColor : '#ff4500',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        showCancelButton: true,
        confirmButtonText: "はい",
        confirmButtonAriaLabel: "はい",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            projectIds: [this.prj_id]
          }
          axios(this.getAxConf("delete", "/contents", data, this.$store.state.token))
          .then((response) => {

            if (response.data.success) {
              Swal.fire({
                confirmButtonColor : '#68CFC3',
                animation : false,
                html: '<p>削除しました</p>',
                confirmButtonText: '一覧にもどる'
              }).then(
                this.pushPage({name: 'Home'})
              )
            }else{
              this.showErrorWindow(response)
            }
          })
          .catch((err) => {
            console.log(err);
            this.showNetworkErrorWindow(err)
          });
        } 
      })
    },

    /**
     * *****************************************
     * オーディオ制御
     * *****************************************
     */
    load() {
			if (this.audio.readyState >= 2) {
				this.loaded = true
				this.durationTime = this.audio.duration
				return this.playing = this.autoPlay
			}
			// throw new Error('Failed to load sound file.');
    },
    playSwicth(){
      if(this.playing){
        this.audio.pause()
      }else{
        this.audio.play()
      }
    },
    seek() {
      this.audio.currentTime = this.currentTime
    },
    stop() {
      this.audio.pause()
			this.playing = false
			this.audio.currentTime = 0
		},
    update() {
      this.currentTime = this.audio.currentTime
    },
    setEventListener(){
      this.audioPrepared = true
      this.audio.addEventListener('loadeddata', this.load())
      this.audio.addEventListener('pause', () => { this.playing = false })
      this.audio.addEventListener('play', () => { this.playing = true })
      this.audio.addEventListener('timeupdate', () => { this.update() })
      
      this.audio.addEventListener('waiting', () => { this.showLoading() })
      this.audio.addEventListener('canplay', () => { this.hideLoading() })
    },
    
    /**
     * audioのonupdateがうまくいかないときに作成した代替ロジック
     * web audioの仕様変更時に使う可能性があるので残しておきます
    startTimeupdateInterval(){
      this.countControler = setInterval(()=>{
        this.currentTime = this.audio.currentTime
      }, 200)
    },
    stopTimeoutInterval(){
      if(this.countControler !== null){
        clearInterval(this.countControler)
        this.countControler = null
      }
    },
     */

    /**
     * *****************************************
     * ツール、ダミーデータなど
     * *****************************************
     */
    getNowDate(){
      const date  = new Date()
      const y  = date.getFullYear()
      const mo = ( '00' + date.getMonth() + 1 ).slice( -2 );
      const d = ( '00' + date.getDate()).slice( -2 );
      return "" + y + mo + d
    },
    convertTimeHHMMSS(val){
      var hhmmss = new Date(val).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },
    generateDownloadFile(content, filename) {
      var blob = new Blob([ content ], { "type" : "text/plain" });

      if (window.navigator.msSaveBlob) { 
        // Edgeの場合（サポートしていないが、参考までに）
        window.navigator.msSaveBlob(blob, filename); 
        window.navigator.msSaveOrOpenBlob(blob, filename); 
      } else {
          // document.getElementById("download").href = window.URL.createObjectURL(blob);
          return window.URL.createObjectURL(blob);
      }
    },
    getDummyResult(){
      return {
        "audioVO": {
          "jsonUrl": "string",
          "localFlg": false,
          "txt": "データが取得できませんでした",
          "txtNum": "00",
          "version": 0,
          "wavUrl": "string"
        },
        "createTime": "0000-00-00 00:00:00",
        "duration": 0,
        "fileType": 999,
        "fileName": "データが取得できませんでした",
        "pictureVO": {
          "pictureUrl": "string"
        },
        "projectId": "dammy_project_20210101",
        "size": "10",
        "videoVO": {
          "videoTxt": "string",
          "videoUrl": "string"
        }
      }
    },
    getDummyTextJson(){
      return {
        "datalist":[{
          "content":"テキスト情報はありません",
          "endTime":1.65,
          "index":0,
          "rl":"0",
          "sc":"0.00",
          "si":"0",
          "speaker":"段落-0",
          "startTime":0.43}],
        "intervalLayerCount":0,
        "intervalLayerName":["a","b"],
        "issmooth":true,
        "issubtitles":true,
        "layNameKeyDic":{"a":"speaker","bt":"sex"},
        "layerCount":0,
        "overAllLayerCount":0,
        "overall":{"sex":"A\u003dmale B\u003dmale"},
        "totalLength":0
      }
    },

  },
  beforeRouteLeave (to, from, next) {
    if(this.item.fileType == 0){
      this.stop()
    }
    if(this.isTextChanged){
      Swal.fire({
        confirmButtonColor : '#68CFC3',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        html:
          "<p>" +
          "ページを離れようとしています。よろしいですか？" + 
          "</p>" +
          "<style>" +
          "p{font-size: 14px;}" +
          "</style>",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "移動する",
        confirmButtonAriaLabel: "移動する",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        allowOutsideClick : false,
      }).then(result=>{
        if (result.isConfirmed) {
          next()
        } else {
          next(false)
        }
      })
    }else{
      next()
    }
  },

}
</script>

<style scoped>
.contentarea {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.operatelist {
  margin-left: auto;
  margin-right: 0px;
}
.toolicon {
  margin-left: 8px;
}
.itemarea {
  margin-bottom: 20px;
  padding: 20px;
}
.itemarea table {
  width: 100%;
}
.itemarea thead {
  color: #a9a9a9;
  font-size: 14px;
}
.itemarea tr:nth-child(even) {
  background: #f0f1f2;
}
.itemarea tr {
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.itemarea td {
  padding: 10px 0px;
}
.itemarea p {
  padding: 20px;
}
.itemarea i {
  vertical-align: middle;
}
.pagecontrol {
  text-align: center;
  padding: 40px;
}
.pagecontrol span {
  position: sticky;
  padding: 5px;
  border: solid 1px;
  margin-right: 5px;
  margin-bottom: 30px; /*適当*/
}
#editPanel{
  height: 312px;
}
/* テキストエリア */
textarea {
  resize: none;
  margin: 2em;
  width: 90%;
  height: 200px;
}
.ais_editer {
  overflow: auto;
  width: 100%;
  height: 300px;
  padding: 5px;
  border: 1px ridge #ccc;
  color: #000000;
  background-color: #ffffff;
  line-height: 1.5em;
  margin: 0px;
  font-size: 16px;
  font-family:'メイリオ', 'Meiryo','ＭＳ ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;
}
.noteditable{
  pointer-events: none;
}

.player{
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 30px 10px 10px 30px;
  margin-top: 5px;
  padding: 2px;
}
.playdisplay{
  width: 150px;
}
.playbutton{
  width: 54px;
  font-size: 23px;
  border-radius: 50%;
}
.playicon{
  margin-left: 4px;
}
.currentTime{
  margin-left: 10px;
}

/* 再生シークバー */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #cccccc;
  height: 10px;
  width: 85%;
  border-radius: 6px 6px 6px 6px;
  display: inline-block;
  vertical-align: middle;
  margin: 22px 0px;
}

/* 再生シークバー移動時 */
input[type="range"]:focus, :active {
    outline: none;
}

/* 再生シークバーつまみ */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 50px;
  background-color: #1F91BE;
  border-radius: 3px;
}

.stringcount{
  text-align: right;
}

.tab_list {
  overflow: hidden;
  margin-bottom: -1px;
  padding-left: 0px;
}
.tab_list li {
  float: left;
  margin-right: 5px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #ccc;
  color: #ffffff;
  cursor: pointer;
  transition: .3s;
  list-style: none;
}
.tab_list li:not(:first-child) {
  border-left: none;
}
.tab_list li.active {
  border: 1px solid #ccc;
  border-bottom: 1px solid #ffffff !important;
  background-color: #ffffff;
  border-color: #ccc;
  color: #000000;
  cursor: auto;
}
.tab_list li.savebutton{
  float: right;
  background-color: #ffffff;
  color: #000000;
  /* border-left: 1px solid #ccc; */
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  padding: 0px;
  padding-top: 10px;
  
}
.attention{
  background-color: #1e90ff !important;
  color: #ffffff !important;
}

.highlight{
  color: #1e90ff;
  font-weight: bold;
}

.disablecolor, .disablecolor:hover{
  background: #808080 !important;
  border: none;
}

</style>
