<template>
<div class="login_bg">

  <div class="login_contentarea">  
    <div class="deviceiamge">
        <img src="../assets/recorder.png" />
    </div>
    
    <div class="loginarea">
      <div class="loginlogo">
        <img src="../assets/RECSTATION_logo.png" />
      </div>
      <div class="loginwindow">
        <h4>
          <span class="titleselect linklike handcursor" @click="pushPage({name: 'Login'})">ログイン</span>
          <span class="titleselect">新規登録</span>
        </h4>
        <form>
          <div>
            <input v-model="email" name="email" type="email" placeholder="メールアドレス">
            <p class="errortext" v-if="errormessages[0]">
              {{ errormessages[0] }}
            </p>
          </div>
        </form>

        <span class="button registerbutton" 
          v-bind:disabled="isProcessing()" @click="submit">
          アカウント登録
        </span>
    <Loading />
      </div>
    </div>
  </div>

</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import processing from '@/mixins/processing.js'
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
  name: 'Register',
  mixins: [utils, api, processing],
  components:{
    Loading,
  },
  data() {
    return {
      errors: [],
      errormessages: [],
      email: "",
      password: ""
    };
  },
  computed: {
  },
  mounted(){ 
  },
  methods:{
    loadingtest(){
      this.$modal.show('loading')
    },
    submit(){
      this.startProcessing()

      this.errors = 0;
      this.errormessages = [];
      if(!this.email){
        this.errors++;
        this.errormessages.push('メールアドレスを入力してください');
      }else{
        if(!this.isEmail(this.email)){
          this.errors++;
          this.errormessages.push('メールアドレスの形式で入力してください');
        }else{
          var data = {
            mail: this.email
          }
          axios.post(this.getURL('/users/code'), data, this.getHeaders())
          .then((response) => {
            if (response.data.success) {
              // 規約ダイアログを表示
              this.openTermWindow();
            }else{
              this.errors++;
              this.errormessages.push(response.data.message);
            }
          })
          .catch((err) => {
            return this.showNetworkErrorWindow(err)
          });
        }
      }
      this.endProcessing()
    },
    openTermWindow() {
      Swal.fire({
        confirmButtonColor : '#68CFC3',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        title: "利用規約をご確認ください",
        html:
          "<p>" +
          "RECORDER STATIONのアカウントを作成するには、<br>次の利用規約に同意する必要があります。<br>" + 
          "利用規約を確認して、[同意して続行]をクリックしてください。</p>" +
          "<iframe src='/terms_sr502j.html' class='registertermarea'></iframe>" +
          "<style>" +
          "p{font-size: 14px;}" +
          ".registertermarea {width: 100%; height: 300px; overflow-y: scroll; padding: 3px; box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.6) inset; margin-top: 30px;}" +
          "</style>",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "同意して続行",
        confirmButtonAriaLabel: "同意して続行",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        allowOutsideClick : false,
      }).then((result) => {
        if(result.isConfirmed){
          Swal.fire({
            confirmButtonColor : '#68CFC3',
            cancelButtonColor : '#808080',
            reverseButtons : true,
            animation : false,
            title: `メールアドレスの認証`,
            html:
              "<h4>1. 認証コードを受信するには通知ボタンをクリックしてください。</h4>" +
              "<p>登録アドレス</p>" +
              "<p class='grayout'>" + this.email + "</p>" +
              "<!--<span class='button' >通知</span>-->" +
              "<h4 class='mt40'>2. 認証コードを入力してください。</h4>" +
              "<p>認証コード</p>",
            input: "text",
            showCancelButton: true,
            confirmButtonText: "認証",
            confirmButtonAriaLabel: "認証",
            cancelButtonText: "キャンセル",
            cancelButtonAriaLabel: "キャンセル",
            allowOutsideClick : false,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value) {
                  if (this.isAuthNum(value)) {
                    resolve()
                  } else {
                    resolve('数字４桁の認証コードを入力してください')
                  }
                } else {
                  resolve('数字４桁の認証コードを入力してください')
                }
              })
            },
            preConfirm: (value) => {
              var data = {
                code: value,
                mail: this.email
              }
              return axios.post(this.getURL('/users/verificationCode'), data, this.getHeaders())
              // return axios(this.getConfig('post', '/users/verificationCode', '', param))
              .then((response) => {
                if (response.data.success) {
                  //return response.json(); 
                }else{
                  return Swal.showValidationMessage(response.data.message);
                }
              })
              .catch((err) => {
                console.log(err);
                return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
              });
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                confirmButtonColor : '#68CFC3',
                animation : false,
                title: `パスワードの設定`,
                html:
                  '<p>パスワード（大小英数字8字以上32文字以下）</p>' +
                  '<p><input id="pw1" type="password" class="swinput"></p>' +
                  '<p>パスワード（確認）</p>' +
                  '<p><input id="pw2" type="password" class="swinput"></p>' +

                  '<i id="showeye" class="far fa-eye showpassicon handcursor" onclick="' +
                    'document.getElementById(`pw1`).setAttribute(`type`, `text`); ' +
                    'document.getElementById(`pw2`).setAttribute(`type`, `text`); ' +
                    'document.getElementById(`showeye`).classList.add(`hide`); ' +
                    'document.getElementById(`hideeye`).classList.remove(`hide`); ' +
                    '"></i>' +
                  '<i id="hideeye" class="far fa-eye-slash showpassicon handcursor hide" onclick="' +
                    'document.getElementById(`pw1`).setAttribute(`type`, `password`); ' +
                    'document.getElementById(`pw2`).setAttribute(`type`, `password`); ' +
                    'document.getElementById(`hideeye`).classList.add(`hide`); ' +
                    'document.getElementById(`showeye`).classList.remove(`hide`); ' +
                    '"></i>' +
                  '<style>' +
                  'i{position: relative; bottom: 57px; left: 185px;}' +
                  '.hide{display: none;}' +
                  '</style>',
                input: "hidden",
                confirmButtonText: "送信",
                confirmButtonAriaLabel: "送信",
                allowOutsideClick : false,
                inputValidator: (value) => {
                  var value1 = document.getElementById('pw1').value;
                  var value2 = document.getElementById('pw2').value;
                  value = value1;
                  return new Promise((resolve) => {
                    if(value != value2){
                      resolve('入力した値が異なります')
                    }
                    if (value1 || value2) {
                      if (this.isAlpNum(value1) && this.isLeastLength(value1, 8)) {
                        if (this.isAlpNum(value1) && this.isMostLength(value1, 32)) {
                          resolve()
                        } else {
                          resolve('半角英数32文字以下で入力してください')
                        }
                      } else {
                        resolve('半角英数８文字以上で入力してください')
                      }
                    } else {
                      resolve('パスワードを入力してください')
                    }
                  })
                },
                preConfirm: () => {
                  var data = {
                    toMail: this.email,
                    password: document.getElementById('pw1').value
                  }
                  return axios.post(this.getURL('/users'), data, this.getHeaders())
                  .then((response) => {
                    if (response.data.success) {
                      //return response.json(); 
                      this.password = document.getElementById('pw1').value
                    }else{
                      return Swal.showValidationMessage(response.data.message);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
                  });
                },
              }).then((result)=>{
                if (result.isConfirmed) {

                  var data = {
                    "email": this.email,
                    "password": this.password
                  }
                  axios.post(this.getURL('/users/token'), data, this.getHeaders(""))
                  .then((response) => {
                    if (response.data.success) {

                      var param = {
                        name: 'Home',
                        params: {
                          token: response.data.data.token
                        }
                      }
                      this.$store.commit('setToken', response.data.data.token);
                      localStorage.setItem('token', response.data.data.token);
                      
                      Swal.fire({
                        width: '700px',
                        confirmButtonColor : '#68CFC3',
                        animation : false,
                        title: `アカウント登録完了`,
                        html:
                          "<p>RECORDER STATIONにようこそ</p>" +
                          "<p>あなたのアカウントは、まだレコーダーデバイスと連携されていません。<br />AIボイスレコーダー「VOITER」にこのアカウントでログインして、<br />無料のクラウドスペースを取得しましょう。</p>" +
                          "<p>VOITERと連携することで、RECORDER STATIONから<br />文章の編集や管理を行うことができます。</p>" + 
                          "<style>p{font-size: 18px;}</style>",
                        confirmButtonText: "ホームへ",
                        confirmButtonAriaLabel: "ホームへ",
                        allowOutsideClick : false
                      }).then(()=>{
                        this.pushPage(param)
                      })
                    }else{
                      this.showErrorWindow(response)
                    }
                  })
                  .catch((response) => {
                    this.showNetworkErrorWindow(response)
                  })
                }
              });
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>

.login_bg {
  background-image: url(../assets/bg_login.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  margin: 0px;
}
.login_contentarea {
  position: relative;
  display: flex;
  width: 1000px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.deviceiamge{
  position: absolute;
  top: calc(50% - 250px);
  left: 150px;
}
.deviceiamge > img {
  height: 500px;
}

.loginarea {
  position: absolute;
  top: calc(50% - 250px);
  right: 50px;
  width: 300px;
  z-index: 2;
  text-align: center;
}
.loginlogo > img {
  height: 50px;
}
.loginwindow{
  background-color: rgba(70,130,180,0.8);
  box-shadow: 5px 5px 15px #000000;
  padding: 20px;
  color: #ffffff;
  height: 350px;
}
.titleselect {
  margin: 0px 20px;
}
.loginwindow form {
  margin-top: 40px;
}
.loginwindow form p {
  text-align: left;
}
.loginwindow div {
  height: 80px;
}
.loginwindow input {
  border: 0px;
  border-bottom: 1px solid #ffffff;
  background: rgba(11,11,11,0);
  width: 240px;
  padding: 5px 10px;
  color: #ffffff;
  font-size: 16px;
}
.loginwindow input::placeholder{
  color: #ffffff;
}
.registermailinput{
  margin-top: 20px;
}
.registerbutton{
  margin-top: 119px;
}
.pwresetnote {
  display: flex;
}
.pwresetnote > div {
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: auto;
  color: #808080;
  font-size: 12px;
}
.registernote {
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  font-size: 12px;
}
</style>