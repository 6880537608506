<template>
  <div class="home">
    <!-- 上部より表示されるToastメッセージ -->
    <div id="toast">
      <p id="toast_message">基本情報を更新しました</p>
    </div>
    <!-- 画面右側より表示されるサイドウィンドウ -->
    <div class="side_window" id="side_window">
      <!-- サイドウィンドウタイトル -->
      <div class="side_window_title">{{side_window_title}}</div>
      <div class="side_window_content_area" v-show="side_window_content_type == 'None'"></div>
      <!-- サイドウィンドウコンテンツ: アイコンの説明 -->
      <div class="side_window_content_area" v-show="side_window_content_type == 'icon_tips'">
          <div style="width: 100%; display: flex;  justify-content: center; flex-direction: column;">
            <div style="width: 100%; margin: 3px; align-items: center; display: flex;  justify-content: center;">
              <i class="fa-solid fa-user-plus" style="margin-left: 4px; width:10%; font-size: 20px; color: #13618f;"></i>
              <p style="font-size: 16px; margin-left:2px;  margin-right:3px; text-align: left; width:90%;">現在の発言ブロックの直前に新しいブロックを追加します</p>
            </div>

            <div style="width: 100%; margin: 5px; align-items: center; display: flex;  justify-content: center; font-size:28px;">
              <i class="fa-solid fa-star" style="width:10%; font-size: 20px; color: #fff462;"></i>
              <p style="font-size: 16px; margin-left:3px;  margin-right:3px; text-align: left; width:90%;">現在の発言ブロックをブックマークに追加します</p>
            </div>

            <div style="width: 100%; margin: 3px; align-items: center; display: flex;  justify-content: center; font-size:28px;">
              <i class="fa-solid fa-trash" style="width:10%; font-size: 20px; color: #dd4157;"></i>
              <p style="font-size: 16px; margin-left:3px;  margin-right:3px; text-align: left; width:90%;">現在の発言ブロックをブックマークに追加します</p>
            </div>

            <div style="width: 100%; margin: 3px; align-items: center; display: flex;  justify-content: center; font-size:28px;">
              <i class="fa-solid fa-copy" style="width:10%; font-size: 20px; color: #3b3b3b;"></i>
              <p style="font-size: 16px; margin-left:3px;  margin-right:3px; text-align: left; width:90%;">現在の発言ブロックの内容をコピーします</p>
            </div>

            <div style="width: 100%; margin: 3px; align-items: center; display: flex;  justify-content: center; font-size:28px;">
              <i class="fa-solid fa-turn-up" style="width:10%; font-size: 20px; color: #34b8e0;"></i>
              <p style="font-size: 16px; margin-left:3px;  margin-right:3px; text-align: left; width:90%;">現在の発言ブロックの直前の発言ブロックと結合します</p>
            </div>

            <div style="width: 100%; margin: 3px; align-items: center; display: flex;  justify-content: center; font-size:28px;">
              <i class="fa-solid fa-spell-check" style="width:10%; font-size: 20px; color: #ff00aa;"></i>
              <p style="font-size: 16px; margin-left:3px;  margin-right:3px; text-align: left; width:90%;">現在の発言ブロックの内容を校正します</p>
            </div>
          </div>
      </div>
      <!-- サイドウィンドウコンテンツ: 文字起こし原文表示 -->
      <div class="side_window_content_area" id="original_area" v-show="side_window_content_type == 'original_dictation'"></div>

      <div class="side_window_content_area" v-show="side_window_content_type == 'gec'">
        <div class="candidateItemAreaMessage"  v-show="isGecTimeout && gecCandidateList.length == 0">現在、サーバが混雑している為、少々時間をおいてから再度お試しください。</div>
        <div class="candidateItemAreaMessage"  v-show="!isGecTimeout && gecCandidateList.length == 0">修正候補の結果がありません。文章が長すぎる、もしくは元々の文章が崩れすぎているなどの原因が考えられます。</div>
        <div class="candidateItem" v-for="(sentence, index) in gecCandidateList" :key="index" @click="clickCandidateSentence(sentence)" v-html="sentence">
        </div>
      </div>
      <div class="side_window_content_area" v-show="side_window_content_type == 'load'">
        <div class="side_window_load"></div>
        <div>読み込み中</div>
      </div>

      <!-- サイドウィンドウフッター -->
      <div class="side_window_footer">
        <div class="side_window_close_btn" @click="closeSideWindow">閉じる</div>
      </div>
    </div>
    <!-- 会議情報編集POPUP -->
    <div class="overlay" v-show="isShowMeetingInfoPopup" @click.self="isShowMeetingInfoPopup = false">
      <div class="popupWindow">
        <div class="popupHeader">
          <div class="popupTitle">
            <i class="fa-solid fa-circle-info" style="margin-right: 10px;"></i>会議情報の編集
          </div>
        </div>
        <div class="popupMainContent">
          <!-- 1行目 会議名入力 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-receipt"></i>
                </div>
                <div class="popupInputLabel">会議名</div>
              </div>
              <input class="popupInputBox" id="title_txt" type="text" v-model="advJson.meetInfo.meetTitle" placeholder="会議名を入力してください">
            </div>
          </div>
          <!-- 2行目開始 会議場所-->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-location-dot"></i>
                </div>
                <div class="popupInputLabel">場所</div>
              </div>
              <input class="popupInputBox" id="title_txt" type="text" v-model="advJson.meetInfo.meetPlace" placeholder="会議場所を入力してください">
            </div>
          </div>
          <!-- 3行目開始 日付 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-regular fa-calendar"></i>
                </div>
                <div class="popupInputLabel">日付</div>
              </div>
              <datetime v-model="advJson.meetInfo.meetDate" type="datetime" input-class="popupInputBox" format="yyyy/MM/dd HH:mm"
                class="calendar-theme"></datetime>
            </div>
          </div>
          <!-- 4行目開始 キーワード -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-tags"></i>
                </div>
                <div class="popupInputLabel">キーワード</div>
              </div>
              <div class="popupLinerArea" id="keywordarea">
                <div class="keyword_item" v-for="(kw, index) in advJson.meetInfo.tagList" :key="index">
                  <input type="text"  class="keyword_text" @keydown.enter="rejectEnter" placeholder="キーワードを入力"
                    v-model="advJson.meetInfo.tagList[index]">
                  <i class="dangericon fa-solid fa-circle-minus" style="cursor:pointer;"
                    @click="advJson.meetInfo.tagList.splice(index, 1)"></i>
                </div>
                <i class="fa-solid fa-plus plus_btn" style="margin-top:10px;"
                  @click="advJson.meetInfo.tagList.length < 10 ? advJson.meetInfo.tagList.push('') : showToast('キーワードは最大で10個までしか登録できません', true)"
                  id="keyword_plus_icon"></i>
              </div>
            </div>
          </div>
          <!-- 5行目開始 会議参加者 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-user-group"></i>
                </div>
                <div class="popupInputLabel">会議参加者</div>
              </div>
              <div class="popupLinerArea">
                <span class="popupSpeakerBtn" v-for="(value, key, index) in advJson.speakerInfo.speakerName"
                  :key="index" :id="value" @click="openSpeakerEditWindow(key)">
                  {{value}}
                </span>
                <i class="fa-solid fa-plus plus_btn" style="margin-top:10px;" @click="addSpeaker" id="speaker_plus_icon"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="popupFooter">
          <div class="popupFooterBtnFill" @click="isShowMeetingInfoPopup = false">
            <i class="fa-solid fa-check" style="margin-right: 10px;"></i>閉じる
          </div>
        </div>
      </div>
    </div>

    <!-- 議事情報編集POPUP -->
    <div class="overlay" v-show="isShowMinutesInfoPopup" @click.self="isShowMinutesInfoPopup = false">
      <div class="popupWindow">
        <div class="popupHeader">
          <div class="popupTitle">
            <i class="fa-solid fa-book-open" style="margin-right: 10px;"></i>議事情報の編集
          </div>
        </div>
        <div class="popupMainContent">
          <!-- 1行目開始 議題/内容 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-regular fa-clipboard"></i>
                </div>
                <div class="popupInputLabel">議題 / 内容 </div>
              </div>
              <input type="text" placeholder="会議の議題/内容を1文で入力" maxlength="100" size="100" style="font-size: 16px;"
                v-model="advJson.minutesInfo.agenda" />
            </div>
          </div>
          <!-- 2行目開始 会議概要 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-regular fa-rectangle-list"></i>
                </div>
                <div class="popupInputLabel">会議概要</div>
              </div>
              <textarea rows="5" cols="100" placeholder="会議の概要を200文字以内で入力" style="resize: none; font-size: 16px;"
                v-model="advJson.minutesInfo.abstractTxt"></textarea>
            </div>
          </div>
          <!-- 3行目開始 決定事項  -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-circle-check"></i>
                </div>
                <div class="popupInputLabel">決定事項</div>
              </div>
              <ul class="decisionList">
                <li v-for="(value, index) in advJson.minutesInfo.decisionTxtList" v-bind:key="index">
                  <input type="text" placeholder="会議での決定事項を入力" maxlength="50" size="50"
                    v-model="advJson.minutesInfo.decisionTxtList[index]" />
                  <i class="dangericon fa-solid fa-circle-minus" @click="advJson.minutesInfo.decisionTxtList.splice(index, 1)"></i>
                </li>
              </ul>
              <i class="fa-solid fa-plus plus_btn" @click.stop="addDecision"  id="decision_plus_icon"></i>
            </div>
          </div>
          <!-- 4行目開始 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-clipboard-check"></i>
                </div>
                <div class="popupInputLabel">備考</div>
              </div>
              <textarea rows="5" cols="100" placeholder="備考を200文字以内で入力" style="resize: none; font-size: 16px;"
                v-model="advJson.minutesInfo.remarks"></textarea>
            </div>
          </div>
          <!-- 5行目開始 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-regular fa-calendar"></i>
                </div>
                <div class="popupInputLabel">次回予定 </div>
              </div>
              <datetime v-model="advJson.minutesInfo.nextMeetDate" type="datetime" input-class="popupInputBox" format="yyyy/MM/dd HH:mm"
                class="calendar-theme"></datetime>
            </div>
          </div>
        </div>
        <div class="popupFooter">
          <div class="popupFooterBtnFill" @click="isShowMinutesInfoPopup = false">
            <i class="fa-solid fa-check" style="margin-right: 10px;"></i>閉じる
          </div>
        </div>
      </div>
    </div>
    <!---- TXT出力POPUP ---->
    <div class="overlay" v-show="isShowExportTxtPopup" @click.self="isShowExportTxtPopup = false">
      <div class="popupWindow">
        <div class="popupHeader">
          <div class="popupTitle">
            <i class="fa-solid fa-file-lines" style="margin-right: 10px;"></i>テキストとして出力
          </div>
        </div>
        <div class="popupMainContent">
          <!-- 1行目開始 -->
          <div class="popupRow">
            <label class="popupDescribe">編集結果をテキストファイルとして出力します。</label>
          </div>
          <!-- 2行目開始 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-list-check"></i>
                </div>
                <div class="popupInputLabel">出力オプション</div>
              </div>
              <div class="popupLinerArea">
                <input type="checkbox" name="popupCheckbox" id="showSpeakerCheckbox" v-model="isShowSpeaker4exp" />
                <label for="showSpeakerCheckbox" class="popupCheckbox">話者名を表示する</label>
                <input type="checkbox" name="popupCheckbox" id="showTimeCheckbox" v-model="isShowTime4exp" />
                <label for="showTimeCheckbox" class="popupCheckbox">時間を表示する</label>
              </div>
            </div>
          </div>
          <div class="popupFooter">
            <div class="popupFooterBtn" @click="exportText()">
              <i class="fa-solid fa-download" style="margin-right: 10px;"></i>ダウンロード
            </div>
            <div class="popupFooterBtnCancel" @click="isShowExportTxtPopup = false">
              <i class="fa-solid fa-xmark" style="margin-right: 10px;"></i>キャンセル
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 議事録出力POPUP -->
    <div class="overlay" v-show="isShowExportWordPopup" @click.self="isShowExportWordPopup = false">
      <div class="popupWindow">
        <div class="popupHeader">
          <div class="popupTitle">
            <i class="fa-solid fa-file-lines" style="margin-right: 10px;"></i>議事録として出力
          </div>
        </div>
        <div class="popupMainContent">
          <!-- 1行目開始 -->
          <div class="popupRow">
            <label class="popupDescribe">議事録を出力します。事前に議事情報を入力することで出力される議事録に反映します。</label>
          </div>
          <!-- 2行目開始 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-list-check"></i>
                </div>
                <div class="popupInputLabel">出力オプション</div>
              </div>
              <div class="popupLinerArea">
                <input type="checkbox" name="popupCheckbox" id="showSpeakerCheckbox" v-model="isShowSpeaker4exp" />
                <label for="showSpeakerCheckbox" class="popupCheckbox">話者名を表示する</label>
                <input type="checkbox" name="popupCheckbox" id="showTimeCheckbox" v-model="isShowTime4exp" />
                <label for="showTimeCheckbox" class="popupCheckbox">時間を表示する</label>
              </div>
            </div>
          </div>
          <!-- 3行目開始 -->
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-list-check"></i>
                </div>
                <div class="popupInputLabel">高度なオプション</div>
              </div>
              <div class="popupLinerArea">
                <input type="checkbox" name="popupCheckbox" id="showFavBoldCheckbox" v-model="isFavBold" />
                <label for="showFavBoldCheckbox" class="popupCheckbox">ブックマークの文を太字で表示する</label>
                <input type="checkbox" name="popupCheckbox" id="showMinutesHeaderCheckbox"
                  v-model="isShowMinutesHeader" />
                <label for="showMinutesHeaderCheckbox" class="popupCheckbox">会議/議事情報を表示する</label>
              </div>
            </div>
          </div>
          <div class="popupRow">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-user"></i>
                </div>
                <div class="popupInputLabel">議事録作成者</div>
              </div>
              <input class="popupInputBox" id="title_txt" type="text" v-model="createBy" placeholder="作成者名を入力">
            </div>
          </div>
          <div class="popupFooter">
            <div class="popupFooterBtn" @click="exportWord()">
              <i class="fa-solid fa-download" style="margin-right: 10px;"></i>ダウンロード
            </div>
            <div class="popupFooterBtnCancel" @click="isShowExportWordPopup = false">
              <i class="fa-solid fa-xmark" style="margin-right: 10px;"></i>キャンセル
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 編集json削除POPUP -->
    <div class="overlay" v-show="isShowRevertPopup" @click.self="isShowRevertPopup = false">
      <div class="popupWindow">
        <div class="popupHeaderError">
          <div class="popupTitle">
            <i class="fa-solid fa-circle-exclamation" style="margin-right: 10px;"></i>全ての編集を取り消す
          </div>
        </div>
        <div class="popupMainContent">
          <!-- 1行目開始 -->
          <div class="popupRow">
            <label class="popupDescribe">全ての編集を取り消し、元の状態にもどします。</label>
          </div>
          <!-- 1行目開始 -->
          <div class="popupRow">
            <label class="popupDescribe" style="color:#dd4157;">
              <i class="fa-solid fa-circle-exclamation" style="margin-right:10px;"></i>本操作を実行すると編集したデータは削除されます。よろしいですか？
            </label>
          </div>
          <div class="popupFooter">
            <div class="popupFooterBtn" @click="revertData()">
              <i class="fa-solid fa-check" style="margin-right: 10px;"></i>はい
            </div>
            <div class="popupFooterBtnCancel" @click="isShowRevertPopup = false">
              <i class="fa-solid fa-xmark" style="margin-right: 10px;"></i>キャンセル
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 検索POPUP -->
    <div class="overlay" v-show="isShowSearchPopup" @click.self="isShowSearchPopup = false">
      <div class="popupWindow" style="width: 80% !important;">
        <div class="popupHeader">
          <div class="popupTitle">
            <i class="fa-solid fa-magnifying-glass" style="margin-right: 10px;"></i>議事録データの検索
          </div>
        </div>
        <div class="popupMainContent">
          <!-- 1行目開始 -->
          <div class="popupRow" style="text-align: center; justify-content: center;">
            <div class="popupInputArea">
              <div class="popupIconArea">
                <div class="popupInputIcon">
                  <i class="fa-solid fa-keyboard"></i>
                </div>
                <div class="popupInputLabel">検索文字列</div>
              </div>
              <input class="popupInputBox" style="margin-top:5px;" id="search_txt" type="text" placeholder="検索文字列" v-model="searchQuery">
              <div class="serachBtn" @click="searchQuery=''">クリア</div>
            </div>
          </div>
          <div class="popupRow" v-show="searchQuery.length == ''">
            <label class="popupDescribe">
              <i class="fa-solid fa-circle-exclamation" style="margin-right:10px;"></i>検索したい文字列を入力してください
            </label>
          </div>
          <div v-if="searchItems.length">
            <div v-for="result in searchItems" :key="result.id">
              <div class="popupRow">
                <div class="popupSearchListRow">
                  <div class="popupSearchSpeakerArea">{{ convert2SpeakerName(result.speakerId) }} :
                    {{ trim000ms(result.time) }}</div>
                  <i class="fa-solid fa-circle-play searchPlayBtn" @click.stop="playSearchItem(result.time)"></i>
                  <a class="popupSearchTextArea" v-html="result.short_sentence"
                    @click="selectSearchItem(result.index, result.time)"></a>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="popupRow" v-show="searchQuery.length != ''">
            <label class="popupDescribe">
              <i class="fa-solid fa-circle-exclamation" style="margin-right:10px;"></i>該当する文字列が見つかりません
            </label>
          </div>
        </div>
        <div class="popupFooter">
          <div class="popupFooterBtnFill" @click="isShowSearchPopup = false">
            <i class="fa-solid fa-check" style="margin-right: 10px;"></i>閉じる
          </div>
        </div>
      </div>
    </div>
    <!-- お気に入りPOPUP -->
    <div class="overlay" v-show="isShowFavoritPopup" @click.self="isShowFavoritPopup = false">
      <div class="popupWindow" style="width: 80% !important;">
        <div class="popupHeader">
          <div class="popupTitle">
            <i class="fa-solid fa-star" style="margin-right: 10px;"></i>ブックマーク一覧
          </div>
        </div>
        <div class="popupMainContent">
          <div class="popupRow">
            <label class="popupDescribe">ブックマーク一覧</label>
          </div>
          <div v-for="(item, index) in advJson.resultData" :key="index">
            <div class="popupRow" v-show="item.isFav">
              <div class="popupSearchListRow">
                <div class="popupSearchSpeakerArea">
                  {{ convert2SpeakerName(item.speakerId) }} : {{ trim000ms(item.time) }}
                </div>
                <i class="fa-solid fa-circle-play searchPlayBtn" style="margin-right: 10px;"
                  @click.stop="playSearchItem(item.time)"></i>
                <a class="popupSearchTextArea"
                  @click="selectbookMark(index, item.time)">{{ creaateBookmarkShortSentence(item.content) }}</a>
                <i class="fa-solid fa-circle-minus searchPlayBtn searchDeleteBtn"
                  @click="toggleBookmark(index)"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="popupFooter">
          <div class="popupFooterBtnFill" @click="isShowFavoritPopup = false">
            <i class="fa-solid fa-check" style="margin-right: 10px;"></i>閉じる
          </div>
        </div>
      </div>
    </div>


    <!-- 設定POPUP -->
    <div class="overlay" v-show="isShowEditSettingPopup" @click.self="isShowEditSettingPopup = false">
      <div class="popupWindow">
        <div class="popupHeader">
          <div class="popupTitle">
            <i class="fa-solid fa-gear" style="margin-right: 10px;"></i>編集設定
          </div>
        </div>
        <div class="popupMainContent">
          <!-- 3行目開始 -->
          <div class="popupRow" style="">
              <div style="width:15%;"></div>
              <input type="checkbox" name="popupCheckbox" id="isConfirmDeleteCheck" v-model="isConfirmdeleteBlock" />
              <label for="isConfirmDeleteCheck" class="popupCheckbox">発言ブロックの削除時に確認ダイアログを表示する</label>
          </div>
           <div class="popupRow" >
              <div style="width:15%;"></div>
              <input type="checkbox" name="popupCheckbox" id="isAutoSaveCheck" v-model="autoSave"/>
              <label for="isAutoSaveCheck" class="popupCheckbox">自動保存の有効化</label>
          </div>
          <div class="popupRow" >
              <div style="width:15%;"></div>
              <label style="margin-left:15px; margin-top:10px;">編集テキストのフォントサイズ</label>
               <select style="margin-left:15px; margin-top:10px;" v-model="editEditorFontSize">
                  <option value="10.5">10.5</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="14">14</option>
                  <option value="16">16</option>
                  <option value="18">18</option>
                  <option value="20">20</option>
                  <option value="22">22</option>
                  <option value="24">24</option>
                  <option value="26">26</option>
              </select>
          </div>

            <div class="popupRow" >
              <div style="width:15%;"></div>
              <label style="margin-left:15px; margin-top:10px;">音声早送り時に進める秒数</label>
              <select style="margin-left:15px; margin-top:10px;" v-model="audioForwardSec">
                  <option value="3">3</option>
                  <option value="5">5</option>
                  <option value="8">8</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
              </select>
              <label style="margin-left:15px; margin-top:10px;">秒</label>
          </div>

          <div class="popupRow" >
              <div style="width:15%;"></div>
              <label style="margin-left:15px; margin-top:10px;">音声巻き戻し時に戻す秒数</label>
              <select style="margin-left:15px; margin-top:10px;" v-model="audioBackwardSec">
                  <option value="3">3</option>
                  <option value="5">5</option>
                  <option value="8">8</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
              </select>
              <label style="margin-left:15px; margin-top:10px;">秒</label>
          </div>

          <div class="popupFooter">
            <div class="popupFooterBtnFill" @click="isShowEditSettingPopup = false">完了</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 各種POPUP終了 -->

    <Header pagename="Dictex" />
    <div class="contentarea">
      <div class="dictexcontent">
        <!-- 上部ツールバー upperToolbar-->
        <div class="upperToolbar">
          <div class="upperToolbarItem handcursor" @click="pushPage({ name: 'Home' })">
            <i class="fas fa-chevron-left upperToolbarIcon"></i>
            <label class="upperToolbarLabel">ホームに戻る</label>
          </div>
          <div class="upperToolbarItem handcursor" @click="isShowMeetingInfoPopup = true">
            <i class="fa-solid fa-circle-info upperToolbarIcon"></i>
            <label class="upperToolbarLabel">会議情報編集</label>
          </div>
          <div class="upperToolbarItem handcursor" @click="isShowMinutesInfoPopup = true">
            <i class="fa-solid fa-book-open upperToolbarIcon"></i>
            <label class="upperToolbarLabel">議事情報編集</label>
          </div>
          <div class="upperToolbarItem handcursor" @click="save()" @mouseenter="isShowSaveTimestamp=true" @mouseleave="isShowSaveTimestamp=false">
            <i class="fa-solid fa-floppy-disk upperToolbarIcon" :class="{ isDataChanged: isDataChanged }"></i>
            <label class="upperToolbarLabel">保存</label>
            <div class="toolbar_tips" v-show="isShowSaveTimestamp && lastSaveTimestamp != '' ">最終に保存した時間<br>{{lastSaveTimestamp}}</div>
          </div>
          <div class="upperToolbarItem handcursor" @click="isShowSearchPopup = true">
            <i class="fa-solid fa-magnifying-glass upperToolbarIcon"></i>
            <label class="upperToolbarLabel">検索</label>
          </div>

          <div class="upperToolbarItem handcursor" @click="isShowFavoritPopup = true">
            <i class="fa-solid fa-star upperToolbarIcon"></i>
            <label class="upperToolbarLabel">ブックマーク</label>
          </div>
          <div class="upperToolbarItem handcursor" @mouseenter="isShowDLTab=true" @mouseleave="isShowDLTab=false"
            :class="{ openTabSelected: isShowDLTab }" @click.left.stop="">
            <i class="fa-solid fa-file-arrow-down upperToolbarIcon"></i>
            <label class="upperToolbarLabel">ダウンロード</label>
            <div class="toolbarTab" v-show="isShowDLTab" @mouseover="isShowDLTab=true">
              <ul>
                <li @click="isShowExportTxtPopup = true" class="upperToolbarIcon handcursor"><i
                    class="fa-solid fa-file-lines"></i><span class="upperToolbarLabel"> テキストファイル</span></li>
                <li @click="isShowExportWordPopup = true" class="upperToolbarIcon handcursor"><i
                    class="fa-solid fa-file-word"></i><span class="upperToolbarLabel"> 議事録Wordファイル</span></li>
                <li @click="downloadSound()" class="upperToolbarIcon handcursor"><i
                    class="fa-solid fa-file-audio"></i><span class="upperToolbarLabel"> 音声ファイル</span></li>
              </ul>
            </div>
          </div>
<!-- 
          <div class="upperToolbarItem handcursor" @click="showHowto()">
            <i class="fa-solid fa-circle-question upperToolbarIcon"></i>
            <label class="upperToolbarLabel">使い方</label>
          </div> -->

          <div class="upperToolbarItem handcursor" @click="isShowRevertPopup = true">
            <i class="fa-solid fa-rotate-left upperToolbarIcon"></i>
            <label class="upperToolbarLabel">全ての編集を削除</label>
          </div>
          <div class="upperToolbarItem handcursor" @click="isShowEditSettingPopup = true">
            <i class="fa-solid fa-gear upperToolbarIcon"></i>
            <label class="upperToolbarLabel">編集設定</label>
          </div>
        </div>

        <!-- 話者情報変更ウィンドウ -->
        <div class="overlay" id="overlay" v-show="isShowSpeakerEditWindow">
          <div class="speaker_edit_window">
            <div class="cp_iptxt">
              <label class="speaker_popup_title">話者情報を入力してください</label><br />
              <label>話者名</label>
              <input class="ef" id="title_txt" type="text" v-model="advJson.speakerInfo.speakerName[change_speaker_id]" placeholder="" />
            </div>
            <div>
              <label>表示色</label>
              <p>
                <input type="color" id="color_picker" list="color_list" :value="getSpeakerColor(change_speaker_id)" />
              </p>
              <datalist id="color_list">
                <option value="#d2cb7f"></option>
                <option value="#6ee3b7"></option>
                <option value="#e4856d"></option>
                <option value="#e76a83"></option>
                <option value="#52fff8"></option>
                <option value="#d978b6"></option>
                <option value="#6495ed"></option>
                <option value="#547ac1"></option>
                <option value="#27314a"></option>
                <option value="#221816"></option>
              </datalist>
            </div>
            <span class="toolbutton" @click="closeSpeakerModal">OK</span>
            <!-- <span class="toolbutton" @click="closeSpeakerModal">キャンセル</span> -->
            <span class="toolbutton_delete" @click="isSpeakerRemoveMode = !isSpeakerRemoveMode" style="float:right;"
              v-show="canDeleteSpeaker">この話者を削除</span>
            <div v-show="isSpeakerRemoveMode" class="speakerRemoveArea">
              <div class="speakerRemoveAreaRow">
                <label class="speaker_popup_title speaker_popup_danger_title">話者を削除します</label>
              </div>
              <div class="speakerRemoveAreaRow">
                <p>「{{ advJson.speakerInfo.speakerName[change_speaker_id] }}」を削除後、「{{ advJson.speakerInfo.speakerName[change_speaker_id] }}」の発言を誰に割り当てますか？(この操作は取り消せません)</p>
              </div>
              <div class="speakerRemoveAreaRow">
                <select v-model="replaceTargetSpkVal" class="margeSpeakerSelect">
                  <option class="popupSpeakerBtn" v-for="(value, key, index) in advJson.speakerInfo.speakerName" :key="index" :id="value"
                    v-show="change_speaker_id !== value">{{ advJson.speakerInfo.speakerName[key] }}</option>
                </select>
                <span class="toolbutton_delete" @click="removeSpeaker(change_speaker_id)">削除実行</span>
                <span class="toolbutton" @click="isSpeakerRemoveMode = false">キャンセル</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 話者追加ウィンドウ -->
        <div class="overlay" id="overlay" v-show="isShowAddSpeakerWindow">
          <div class="speaker_edit_window">
            <div class="cp_iptxt">
              <label class="speaker_popup_title">追加する話者の情報を入力してください</label><br />
              <label>追加する話者名</label>
              <input class="ef" id="title_txt" type="text" v-model="addSpeakerName" placeholder="話者を入力" />
            </div>
            <div>
              <label>表示色</label>
              <p>
                <input type="color" id="color_picker" list="color_list" v-model="addSpeakerColor" />
              </p>
              <datalist id="color_list">
                <option value="#d2cb7f"></option>
                <option value="#6ee3b7"></option>
                <option value="#e4856d"></option>
                <option value="#e76a83"></option>
                <option value="#52fff8"></option>
                <option value="#d978b6"></option>
                <option value="#6495ed"></option>
                <option value="#547ac1"></option>
                <option value="#27314a"></option>
                <option value="#221816"></option>
              </datalist>
            </div>
            <span class="toolbutton" @click="saveAddSpeaker" style="margin-bottom:10px">確定</span>
            <span class="toolbutton" @click="isShowAddSpeakerWindow = false" style="margin-bottom:10px">キャンセル</span>
          </div>
        </div>

        <!-- メインパネル -->
        <div class="panel_area">
          <!-- 文章編集エリア -->
          <div id="editPanelWrapper" style="width:max-content; margin-left:10px; margin-right:10px;">
            <div class="editorHeader">
              <label class="editor_label">{{advJson.meetInfo.meetTitle}}</label>
              <label class="how_to_use_area" @click="showHowto()">
                <span class="how2text" style="color: #29aba4; flex-wrap: wrap;">編集画面の使い方</span>
                <i class="how2use_btn handcursor fa-solid fa-circle-question"></i>
              </label>
              <div class="original_confirm_btn" @click="opneOriginalDictation()">
                <!-- <i class="fa-solid fa-font editor_tool_icon editor_tool_icon" @click="upFontSize('edit')"
                  style="font-size:10px;"><i class="fa-solid fa-arrow-up" style="font-size:16px;"></i></i>
                <i class="fa-solid fa-font editor_tool_icon editor_tool_icon" @click="downFontSize('edit')"
                  style="font-size:10px;"><i class="fa-solid fa-arrow-down" style="font-size:16px;"></i></i> -->
                  編集前を確認
              </div>
            </div>
            <div id="editPanel" class="ais_editer">
              <div v-for="(item, index) in advJson.resultData" :key="index" ref="edit" :id="['block' + index]" v-bind:class="{ favItem: item.isFav }" v-on:mouseover="mouseoverBlock(index)"  @mouseleave="mouseleaveBlock(index)">
                <!-- 話者名 - 時刻 - ツールバー -->
                <div class="speaker_area">
                  <!-- 話者名 -->
                  <div class="show_speaker_name" @click.stop="openSpeakerSelectPopup(index, $event)" 
                    :style="{ color: convert2SpeakerColor(item.speakerId) }">
                    {{ convert2SpeakerName(item.speakerId) }}
                  </div>

                  <!-- 時刻エリア -->
                  <div class="show_time handcursor" @click.stop="setCurrentTime(getMsTime(item.time))">
                    {{ trim000ms(item.time) }}
                  </div>

                <!-- ツールバー -->
                <div :id="['toolbar' + index]" style="visibility:hidden">
                  <i class="fa-solid fa-user-plus add_speaker_btn handcursor" @click.stop="addSentenceArea(index)" id="block_plus_icon"></i>

                  <i class="fa-solid fa-star add_decision_btn handcursor" @click="toggleBookmark(index)" id="block_fav_icon"></i>

                  <i class="fa-solid fa-trash delete_sentene_btn handcursor" @click="delSentence(index)" id="block_del_icon"></i>

                  <i class="fa-solid fa-copy sentence_copy_btn handcursor" @click="copySentence(index)" id="block_copy_icon"></i>

                  <i class="fa-solid fa-turn-up block_join_btn handcursor" @click="joinPrevBlock(index)" id="block_join_icon"></i>

                  <i class="fa-solid fa-spell-check gec_btn handcursor" @click="openGecWindow(index)" id="block_join_icon"></i>

                  <i class="icon_desc_btn handcursor fa-solid fa-question " @click="show_icon_tips()" id="block_join_icon"><span class="icon_desc_text" style="color: #29aba4; flex-wrap: wrap;">アイコンの説明</span></i>
                </div> 

                </div>

                <!-- テキストエリア -->
                <textarea :id="['editarea_' + index]" v-bind:class="{ highlight: item.isRead, text_edit_area: !item.isRead}" v-model="item.content" @keydown="autoExpandTextarea(['editarea_' + index])" placeholder="文章を入力してください" :style="('font-size: ' + editEditorFontSize + 'px')"></textarea>
                <!-- <div>{{item.content}}</div> -->
              </div>
            </div>

            <!-- 話者変更POPUP -->
            <div id="speaker_popup" class="speaker_popup" v-show="isShowSpeakerPopup">
              <div class="popup_close_btn" @click.stop="close_speaker_popup">×</div>
              <label class="speaker_popup_title">この発言の話者を変更</label>
              <ul style="padding: 1px; overflow-y: auto; height:50%">
                <li v-for="(value, key) in advJson.speakerInfo.speakerName" v-bind:key="key" id="popup_speaker_list"
                  style="list-style-type: none; text-align: left; margin-bottom: 5px; height: max-content; ">
                  <span class="speaker_select_btn" @click.stop="changeSpeakerOne(key)">
                    <i class="fa-solid fa-user" style="margin-right:5px;"></i>{{ value }}
                  </span>
                  <i class="fa-solid fa-pen" style="cursor: pointer; margin-left: 5px;"
                    @click.stop="openSpeakerEditWindow(key)" id="speaker_edit_icon"></i>
                </li>
                <li style="list-style-type: none; text-align: left; margin: 3px">
                  <i class="fa-solid fa-plus plus_btn" @click="addSpeaker" id="speaker_plus_icon"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- スライダー -->
        <div class="player">
          <input type="range" v-model="currentTime" v-on:input="seek" v-bind:max="seekDurationView" />
        </div>
        <!-- コントロールエリア -->
        <div class="playcontrol_area">
          <div class="speedarea">
            <div class="speed_control_area">
              <div class="miniswitchbutton" v-bind:class="{ msb_on: audiospeed == 0.5 }" @click="setAudiospeed(0.5)">
                0.5x </div>
              <div class="miniswitchbutton" v-bind:class="{ msb_on: audiospeed == 0.7 }" @click="setAudiospeed(0.7)">
                0.7x </div>
              <div class="miniswitchbutton" v-bind:class="{ msb_on: audiospeed == 1.0 }" @click="setAudiospeed(1.0)">
                1.0x </div>
              <div class="miniswitchbutton" v-bind:class="{ msb_on: audiospeed == 1.5 }" @click="setAudiospeed(1.5)">
                1.5x </div>
              <div class="miniswitchbutton" v-bind:class="{ msb_on: audiospeed == 2.0 }" @click="setAudiospeed(2.0)">
                2.0x </div>
            </div>
          </div>
          <div class="playdisplay">
            <div class="audio_skip_btn" @click="addCurrentTime(-audioBackwardSec)" ><i class="fa-solid fa-backward"></i> </div>
            <div class="button playbutton" style="padding: 5px 20px !important; width:50%;" @click="playSwicth" id="play_icon">
              <i v-show="!playing" class="fas fa-play"></i>
              <i v-show="playing" class="fas fa-pause"></i>
              <span class="currentTime"> {{ updateCurrentTime(currentTime) }} / {{ durationTimeView }}</span>
            </div>
            <div class="audio_skip_btn" @click="addCurrentTime(audioForwardSec)" ><i class="fa-solid fa-forward"></i></div>
          </div>
          <div class="togglearea">
            <div class="flexarea">
              <input type="checkbox" name="popupCheckbox" id="playSyncCheck" v-model="isSyncPlay" />
              <label for="playSyncCheck" class="popupCheckbox">自動スクロール</label>
            </div>
            <div class="flexarea">
              <div class="syncEditWindowBtn" @click="clickSyncWindow()" id="scroll_icon" v-show="!isSyncPlay">
                <i class="fa-solid fa-arrow-down-short-wide" id="scroll_icon">現在の再生位置へ</i>
              </div>
            </div>
          </div>
        </div>
        <audio id="audioObj"></audio>
      </div>
    </div>
    <Footer />
    <Loading />
    <Process :message="processMsg" />
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "@/components/Loading.vue";
import Process from "@/components/ProcessModal.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import processing from "@/mixins/processing.js";
import utils from "@/mixins/utils.js";
import Swal from "sweetalert2";
import api from "@/mixins/api.js";
import axios from "axios";
import $ from "jquery";
import saveAs from "file-saver";

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: "Dictex",

  components: {
    Header,
    Footer,
    Loading,
    Process,
    Datetime,
  },
  mixins: [utils, api, processing],
  props: {
    item_id: Number,
  },
  data: function () {
    return {
      prj_id: 0,

      /** --------- 音声関連 ---------*/
      //Audioオブジェクト本体
      audio: undefined,
      // 音声再生速度
      audiospeed: 1.0,
      //現在の再生時間(ms)
      currentTime: 0,
      //音声の長さ(ms)
      durationTime: 60,
      //音声読込完了フラグ
      loaded: false,
      //再生フラグ
      playing: false,
      //下部再生ボタン内再生時間
      durationTimeView: "00:00",
      /** ----------------------------*/
      /** --------- POPUP/サイドウィンドウ表示制御フラグ ---------*/
      //話者名編集POPUP
      isShowSpeakerEditWindow: false,
      //話者個別変更POPUP
      isShowSpeakerPopup: false,
      //会議情報POPUP
      isShowMeetingInfoPopup: false,
      //議事情報POPUP
      isShowMinutesInfoPopup: false,
      //テキスト出力ウィンドウ
      isShowExportTxtPopup: false,
      //WORD出力ウィンドウ
      isShowExportWordPopup: false,
      //全ての変更取り消しPOPUP
      isShowRevertPopup: false,
      //検索PUPUP
      isShowSearchPopup: false,
      //ブックマークPOPUP
      isShowFavoritPopup: false,
      //話者削除ダイアログ
      isSpeakerRemoveMode: false,
      //ダウンロードタブ
      isShowDLTab: false,
      //話者追加ウィンドウ
      isShowAddSpeakerWindow: false,
      //編集設定POPUP
      isShowEditSettingPopup : false,
      /** ----------------------------*/

      //読込中メッセージ
      processMsg: "",
      isDataChanged: false,
      speakblocks: "",
      toolbar_x:0,
      toolbar_y:0,
      toolBar_show_idx: -1,
      //↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑削除予定


      /** --------- 議事録WORD出力時 ---------*/
      //WORD出力時に話者名を表示するかのフラグ
      isShowSpeaker4exp: true,
      //WORD出力時に時間を表示するかのフラグ
      isShowTime4exp: true,
      //WORD出力時にブックマークを太字にするか
      isFavBold: true,
      //WORD出力時に1ページ目を出力するか
      isShowMinutesHeader: true,
      //議事録作成者名
      createBy: "",
      /** ----------------------------*/

      /** --------- 検索 ---------*/
      //検索文字列
      searchQuery:"",
      /** ----------------------------*/
      /** --------- 検索 ---------*/
      lastSaveTimestamp: "",
      isShowSaveTimestamp: false,
      /** ----------------------------*/

      /** --------- 編集設定 ---------*/
      //削除時に確認ダイアログを表示するか？
      isConfirmdeleteBlock : true,
      //音声早送りの秒数
      audioForwardSec : 10,
      //音声早送りの秒数
      audioBackwardSec : 10,
      //自動保存の有効化
      autoSave: false,

      /** --------- 話者編集関連 ---------*/
      //現在編集中の話者ID
      change_speaker_id: "",
      //話者個別変更POPUPの表示位置
      speakerPopupPosition: {
        left: 0,
        top: 0,
      },
      //話者個別変更時にクリックされた要素
      currentSelectSpeakerElm: null,
      //話者削除時に削除後設定する話者ID
      replaceTargetSpkVal: "",
      //話者追加時表示色
      addSpeakerColor: "#808080",
      //話者追加時話者名
      addSpeakerName: "",
      //話者ID最大値(話者追加時に利用)
      speakerNextId: "-1",
      //話者個別編集時のクリックされた要素のID
      editBlockSpkTargetIndex: -1,
      //原文エリアフォントサイズ(削除予定)
      originalEditorFontSize: 16,
      //編集エリアフォントサイズ
      editEditorFontSize: 16,
      //再生とスクロールを同期するかのフラグ
      isSyncPlay:false,
      /** ----------------------------*/
      
      /** --------- サイドウィンドウ ---------*/
      //サイドウィンドウタイトル
      side_window_title: "",
      //サイドウィンドウに表示するコンテンツタイプ
      side_window_content_type: "icon_tips",
      original_time_list: [],
      /** ----------------------------*/
      /** --------- GEC ---------*/
      isGecLoad: true,
      currentGecTarget: -1,
      gecCandidateList: [],
      isGecTimeout: false,
      
      
      /** --------- JSONデフォルトデータ ---------*/
      //議事録データ情報JSON
      advItem: {
        projectId: 0,
        originalJsonPath: "",
        minutesJsonPath: "",
        editJson: "",
        aacPath: "",
        status: 0,
      },
      //editJsonデフォルトデータ
      advJson: {
        meta: {
          duration: "151680",
          place: "",
          rec_date: "",
          title: "    ",
        },
        meetInfo: {
          meetTitle: "",
          meetDate: "",
          meetPlace: "",
          tagList: [""],
          duration: 0,
        },
        minutesInfo: {
          agenda: "",
          abstractTxt: "",
          decisionTxtList: [""],
          nextMeetDate: "",
          remarks: "",
        },
        speakerInfo: {
          speakerName: {
            "0": "話者0",
          },
          speakerColor: {
            "0": "#29aba4"
          }
        },
        resultData: [
          {
            //segmentData↓
            segmentId: 0,
            time: "0:00:00",
            speakerId: "1",
            isFav: false,
            content:"読み込み中です・・・・・",
            isRead: true,
            textDataList: [
              {
                textDataId: "t_1_0",
                sentence: "読み込み中です・・・・・",
                isChange: false,
                wordDataList: [ // wordDataListは使用しない
                  {
                    wordId: "w_1_0_0",
                    word: "   ",
                    isMark: false,
                    candidate: []
                  },
                ],
              },
            ],
          },
        ],
        time: "0:00:00.000000",
        txt: "",
        lang: "ja"
      },
    };
  },
  computed: {
    /**
     * 再生シークバーの値を計算します。
     */
    seekDurationView() {
      return this.durationTime.toFixed(0);
    },
    /**
     * 該当の話者を削除可能か評価返却します。
     * 
     */
    canDeleteSpeaker() {
      //話者が1名のみの場合削除不可
      return Object.keys(this.advJson.speakerInfo.speakerName).length !== 1
    },
    /**
     * 検索結果を返戻します。
     * 検索対象の文字列searchQueryが文章中に含まれる
     * アイテムを抽出し、表示用に整形したデータのリストを返却します。
     * 
     * テキストボックスの入力(searchQueryの値が変更)を検知し、リアルタイムに検索
     * 
     */
    searchItems(){
      //検索結果保存リスト
      var searchResult = new Array()
      var id_count = 1;
      if(this.searchQuery == ""){
        //検索対象文字列が空文字の場合空のリストを返却
        return searchResult;
      }

      /**JSONのデータを1つずつ走査していく */
      this.advJson.resultData.forEach((result_item, index) => {
          // indexOfを用いることで対象の文字列と一致するインデックスを獲得できる(存在しない場合-1が返却)
          var result = result_item.content.indexOf(this.searchQuery, 0);
          var showSentence = result_item.content;
          var sbStrStart = 0;
          var sbStrEnd = 0;
          var preText = ""
          var afterText = ""
          if (result !== -1) {
            //対象の文章が30文字以上の場合、表示を最適化する
            if (showSentence.length > 30) {
              if (result > 10) {
                sbStrStart = result - 10;
                preText = ".... "
              } else {
                sbStrStart = 0;
              }
              if ((result + 10 + this.searchQuery.length) > showSentence.length) {
                sbStrEnd = showSentence.length;
              } else {
                sbStrEnd = result + 10 + this.searchQuery.length;
                afterText = " ...."
              }
              showSentence = preText + showSentence.substring(sbStrStart, sbStrEnd) + afterText;
              sbStrStart = 0;
              sbStrEnd = 0;
            }
            //対象の文字列の部分を黄色くハイライト
            showSentence = showSentence.replace(this.searchQuery, "<b style='background:linear-gradient(transparent 60%, #fff462 0%);'>" + this.searchQuery + "</b>")
            //結果リストに入れる
            searchResult.push({
              "index":index,
              "id": id_count,
              "short_sentence": showSentence,
              "speakerId": result_item.speakerId,
              "time": result_item.time,
            })
            id_count += 1;
          }
      });
      //検索結果を返却
      return searchResult;
    },
  },
  created() {
    //projectIDを設定
    this.recoveryProjectId();

    //画面全体にイベントリスナーを設定
    window.addEventListener("keydown", this.keyAction);
  },
  mounted() {
    //議事録データを取得する
    this.getItemData();
    //初めて本画面に到達するユーザに対して使い方のヒントを表示する
    this.showFirstHowto();
    //自動保存設定
    this.setAutoSave();
  },
  watch: {
  },
  methods: {
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // キーイベント関連
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    /**
     * キー入力の制御
     * @param {*} e 
     */
    keyAction(e) {
      // console.log(e.keyCode) // キーの確認用ログ
      // Ctrl + F
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
        //Ctrl + Fが押下された場合検索ウィンドウを表示
        this.isShowSearchPopup = true;
        //デフォルトのCtrl + Fを伝播しない
        e.preventDefault();
      }

      // Ctrl + ←
      else if (((e.ctrlKey || e.metaKey) && e.keyCode === 37)) {
        //10秒戻す
        this.addCurrentTime(-this.audioBackwardSec)
        e.preventDefault();
      }

      // Ctrl + →
      else if (((e.ctrlKey || e.metaKey) && e.keyCode === 39)) {
        // 10秒進める
        this.addCurrentTime(this.audioForwardSec)
        e.preventDefault();
      }
      // Ctrl + ↓
      else if (((e.ctrlKey || e.metaKey) && e.keyCode === 40)) {
        this.clickSyncWindow();
        e.preventDefault();
      }
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // バックグラウンド処理
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

    /**
     * ローカルストレージから前回情報を取得する
     * 更新ボタンなどを押下された際に、ProjectIDがないため
     */
    recoveryProjectId() {
      if (this.item_id) {
        //前の画面よりprojectIDが受けれた場合
        localStorage.setItem("item_id", this.item_id);
        this.prj_id = this.item_id;
      } else {
        //前の画面よりprojectIDが受け取れなかった場合、ローカルストレージに保存していたIDを利用。
        //例として、更新ボタンを押した場合や、履歴から本画面に到達した場合
        this.prj_id = localStorage.getItem("item_id");

        if(this.prj_id == undefined || this.prj_id == null){
          //ローカルストレージよりprojectIDを復元できなかった場合Homeに戻る
          //TODO
        }
      }
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // Auidio機能
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    /**
     * 再生時点をセット
     * @param {*} ms 
     */
    setCurrentTime(ms) {
      this.currentTime = ms;
      this.audio.currentTime = ms / 1000;
    },
    /**
     * 再生時刻を○○秒進める
     * @param {*} sec 
     */
    addCurrentTime(sec) {
      /** sec -> 秒単位 */
      if (this.currentTime + sec <= 0) {
        this.currentTime = 0;
        this.audio.currentTime = this.currentTime / 1000;
        return;
      }
      if (this.currentTime + sec >= this.durationTime) {
        this.currentTime = this.durationTime;
        this.audio.currentTime = this.currentTime / 1000;
        return;
      }
      this.currentTime += sec * 1000;
      this.audio.currentTime = this.currentTime / 1000;
    },
    /**
     * 時刻表記をミリ秒へと変bン関する
     * 0:00:59 -> ms
     */
    getMsTime(hmmss) {
      if (hmmss == null) {
        return "0";
      }
      const h = (hmmss.substr(0, 1) - 0) * 60 * 60
      const m = (hmmss.substr(2, 2) - 0) * 60
      const s = hmmss.substr(5, 2) - 0
      return (h + m + s) * 1000
    },
    /**
     * ミリ秒を時刻表記へと変換する
     * ms -> 0:00:59
     */
    getViewSpeakTime(ms) {
      if (ms == null) {
        return "-:--:--";
      }
      const s = String(Math.floor(ms / 1000) % 60).padStart(2, '0')
      const m = String(Math.floor(ms / 1000 / 60) % 60).padStart(2, '0')
      const h = Math.floor(ms / 1000 / 60 / 60) % 24;
      return h + ":" + m + ":" + s;
    },
    /**
     * 時刻表記の付与部分を削除
     */
    trim000ms(time) {
      if (time) {
        return time.substr(0, 7);
      }
      return "";
    },
    /**
     * 音声を読み込む
     */
    load() {
      if (this.audio.readyState >= 2) {
        this.loaded = true;
        this.durationTime = this.audio.duration;
        return (this.playing = this.autoPlay);
      }
    },
    /**
     * 音声を再生を開始/停止する
     */
    playSwicth() {
      if (this.playing) {
        this.audio.pause();
      } else {
        this.audio.play();
      }
    },
    /**
     * 再生シークバーを移動する
     */
    seek() {
      this.audio.currentTime = this.currentTime / 1000;
    },
    /**
     * 音声を停止する
     */
    stop() {
      this.audio.pause();
      this.playing = false;
      this.audio.currentTime = 0;
    },
    /**
     * 音声の再生時間を更新する
     */
    update() {
      this.currentTime = this.audio.currentTime * 1000;
    },
    /**
     * 音声の再生速度を変更する
     */
    setAudiospeed(spd) {
      this.audiospeed = spd;
      this.audio.playbackRate = spd;
    },
    /**
     * Audioオブジェクトに対し、リスナーを設定する
     */
    setAudioEventListener() {
      this.audio.addEventListener("pause", () => {
        this.playing = false;
      });
      this.audio.addEventListener("play", () => {
        this.playing = true;
      });
      this.audio.addEventListener("timeupdate", () => {
        console.log("timeupdate");
        this.update();
      });
      this.audio.addEventListener("seeked", () => {
        this.hideProcessing();
      });
      this.audio.addEventListener("seeking", () => {
        this.showProcessing("音声読み込み中です。");
      });
    },
    /**
     * 音声の再生秒数を1秒ごとに更新する設定を行う
     */
    setAudioUpdate() {
      // setInterval(() =>{
      //   //音声の読み込みが完了状態であれば、1秒毎に現在の再生時間(this.currentTime)を更新
      //   if (this.audio.readyState >= 2) {
      //     this.currentTime = this.audio.currentTime * 1000;
      //   }
      // }, 1000)
    },
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 会議情報編集機能
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■


    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 議事情報編集機能
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

    addDecision() {
      this.advJson.minutesInfo.decisionTxtList.push("")
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 保存機能
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    /**
     * 現在のJSONを保存する
     */
    save() {
      var saveJson = this.advJson;
      saveJson.version = "v2";
      saveJson.meetInfo.meetDate = this.convert2SaveTime(saveJson.meetInfo.meetDate)
      saveJson.minutesInfo.nextMeetDate = this.convert2SaveTime(saveJson.minutesInfo.nextMeetDate)

      const advurl = "/contents/" + this.prj_id + "/minutesItem/all";
      axios.put(this.getURL(advurl), saveJson, this.getHeaders(this.$store.state.token))
        .then((response) => {
          if (response.data.success) {
            this.showToast("データの保存に成功しました。");
            const currentDatatime = new Date();
            this.lastSaveTimestamp = currentDatatime.toLocaleString();
          } else {
            return this.showErrorWindow(response);
          }
        })
        .catch((err) => {
          console.log(err);
          return this.showErrorWindow(
            "通信に失敗しました。しばらくしてからお試しください。"
          );
        });
    },
    /**
     * 自動保存の設定
     */
    setAutoSave() {
      setInterval(() =>{
        console.log("this.autoSave : " + this.autoSave)
        if (this.autoSave) {
          this.save();
        }
      }, 3 * 60 * 1000)//3分毎
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    //  ダウンロード関連
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    /**
     * ワード出力
     */
    exportWord() {
      this.isShowExportWordPopup = false;
      this.showLoading();
      const data = {
        isShowSpeaker: this.isShowSpeaker4exp,
        isShowTime: this.isShowTime4exp,
        isFavBold: this.isFavBold,
        isShowMinutesHeader: this.isShowMinutesHeader,
        createBy: this.createBy
      }

      // TODO: ローカル開発用に、開発サーバ向けのURLを指定
      //const url = "contents/" + this.prj_id + "/minutesItem/word";
      //axios(this.getDevAxConf("post", url, data, this.$store.state.token))
      axios.post(this.getURL('/contents/' + this.prj_id + "/minutesItem/word"), data, this.getHeaders(this.$store.state.token))
        .then(
          (response) => {
            if (response.data.success) {
              axios
                .get(response.data.data.downlaodUrl, { responseType: "blob" })
                .then((res) => {
                  var mineType = res.headers["content-type"];
                  const name =
                    "議事録_" + this.advJson.meetInfo.meetTitle + ".docx";
                  const blob = new Blob([res.data], { type: mineType });
                  saveAs(blob, name);
                  this.hideLoading();
                }).catch(err => {
                  this.showNetworkErrorWindow(err);
                });
            } else {
              this.hideLoading();
              return this.showErrorWindow(response);
            }
          }
        )
        .catch(err => {
          this.hideLoading();
          Swal.fire({
            confirmButtonColor: '#29aba4',
            animation: false,
            html: `
          <p>データが作成できません。しばらく待ってから再度お試しください</p>
          <p>` + err + `</p>
          `,
            confirmButtonText: "とじる",
            confirmButtonAriaLabel: "とじる",
          })
        })
    },
    /**
     * テキスト出力
     */
    exportText() {
      this.isShowExportTxtPopup = false
      this.showLoading();
      const data = {
        isShowSpeaker: this.isShowSpeaker4exp,
        isShowTime: this.isShowTime4exp,
      }
      axios.post(this.getURL('/contents/' + this.prj_id + "/minutesItem/text"), data, this.getHeaders(this.$store.state.token))
        .then(
          (response) => {
            if (response.data.success) {
              axios
                .get(response.data.data.downlaodUrl, { responseType: "blob" })
                .then((res) => {
                  if (res.status !== 200) {
                    return this.showErrorWindow(res);
                  }
                  var mineType = res.headers["content-type"];
                  const name =
                    "outputText_" + this.advJson.meetInfo.meetTitle + ".txt";
                  const blob = new Blob([res.data], { type: mineType });
                  saveAs(blob, name);
                  this.hideLoading();
                }).catch(err => {
                  return this.showNetworkErrorWindow(err);
                });
            } else {
              this.hideLoading();
              return this.showErrorWindow(response);
            }
          }
        )
        .catch(err => {
          this.hideLoading();
          Swal.fire({
            confirmButtonColor: '#29aba4',
            animation: false,
            html: `
          <p>データが作成できません。しばらく待ってから再度お試しください</p>
          <p>` + err + `</p>
          `,
            confirmButtonText: "とじる",
            confirmButtonAriaLabel: "とじる",
          })
        })
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 検索関連
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

    playSearchItem(time) {
      this.setCurrentTime(this.getMsTime(time))
      if (!this.playing) {
        this.playSwicth()
      }
    },
    selectSearchItem(id, time) {
      this.isShowSearchPopup = false
      this.move2selectItem(id,time)
    },
    move2selectItem(id, time){
      var targetElm = document.getElementById("block"+id);
      if(this.isSyncPlay && this.playing){
        this.playSearchItem(time)
      }else{
        targetElm.scrollIntoView({
          behavior : 'smooth',
          block    : 'center',
          inline   : 'center'
        });
      }
      this.appealBlock(id)
    },
    appealBlock(id){
      var targetElm = document.getElementById("editarea_"+id);
      var appealBlockAnime = [
        {
            color: "#29aba4",
            offset: 0.0
        },
        // {
        //     color: "#808080",
        //     offset: 0.5,
        // },
        // {
        //     color: "#29aba4",
        //     offset: 0.5
        // },
        // {
        //     color: "#808080",
        //     offset: 0.9,
        // },
        // {
        //     color: "#29aba4",
        //     offset: 0.95
        // },
        {
            color: "#808080",
            offset: 1.0,
        }
      ]

      const effect = new KeyframeEffect(
        targetElm, appealBlockAnime, 3000
      );
      const animation = new Animation(effect, document.timeline)
      // target.animate(fadeleft, anime_options); 
      // animation.onfinish = () => target.style.visibility = "hidden";
      animation.play()
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // ブックマーク関連
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

    /**
     * ブックマーク一覧に表示する文言を最適化する。
     * 30文字以上のテキストの場合、31文字目以降を切り捨て
     */
    creaateBookmarkShortSentence(txt){
        if (txt.length > 30) {
          txt = txt.substring(0, 30) + ".....";
        }
        return txt;
    },
    selectbookMark(id, time){
      this.isShowFavoritPopup = false;
      this.move2selectItem(id,time)
    },
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 編集全取り消し
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

    /**
     * 編集内容の全取り消し
     */
    revertData() {
      this.showLoading();
      // ダウンロードURL取得
      // 以下のパラメータは仮です
      const data = {}
      // TODO: ローカル開発用に、開発サーバ向けのURLを指定
      const url = "/contents/" + this.prj_id + "/minutesItem/editData";
      axios(this.getAxConf("delete", url, data, this.$store.state.token))
        .then(
          (response) => {
            if (response.data.success) {
              // データダウンロード・ファイル生成
              var advurl = response.data.data.minutesJsonPath;
              axios
                .get(advurl)
                .then((res) => {
                  // this.advJson = res.data;
                  this.setAdvJson(res);
                })
                .catch((err) => {
                  console.log(err)
                  this.showErrorWindow(err);
                });
              this.hideLoading();
            } else {
              this.hideLoading();
              return this.showErrorWindow(response);
            }
          }
        )
        .catch(err => {
          this.hideLoading();
          Swal.fire({
            confirmButtonColor: '#29aba4',
            animation: false,
            html: `
          <p>削除に失敗しました。しばらく待ってから再度お試しください</p>
          <p>` + err + `</p>
          `,
            confirmButtonText: "とじる",
            confirmButtonAriaLabel: "とじる",
          })
        })
      this.isShowRevertPopup = false;
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 使い方関連
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

    /**
     * 使い方ウィンドウを表示
     */
    showFirstHowto() {
      const hadshowbeta = localStorage.getItem('hadshowbeta')
      if (hadshowbeta) return
      localStorage.setItem('hadshowbeta', true);
      this.showHowtoDictexWindow()
    },
    showHowto() {
      this.showHowtoDictexWindow()
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // サイドウィンドウ関連
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    opneOriginalDictation(){
      this.side_window_title =  "文字起こし原文"
      // this.side_window_content_type = "original_dictation"
      this.openSideWindow("original_dictation");

      var target_index = -1;
      var nextBg = -1;
      var current = this.currentTime;
      
      this.original_time_list.forEach((bg, index) => {
           if(this.original_time_list.length-1 == index){
            //最後の要素
            target_index = index;
            return;
          }

          nextBg = this.original_time_list[index+1]
          //json_1best.st.bg->単位 : mili秒
          if (bg <= current && current < nextBg) {
            target_index = index;
            return;
          }
      });

      const target = document.getElementById('orgBlock'+target_index);

      target.scrollIntoView({
        behavior : 'instant',
        block    : 'center',
        inline   : 'center'
      });
    },
    createOriginalHtml(originalJson){
      this.original_time_list = new Array();
      // var html = ""
      // var time_change_func = this.setCurrentTime
      var parentObj = this;
      const target = document.getElementById('original_area');
      var index = 0;
      originalJson.lattice.forEach(function (result_item) {
        var bg = result_item.json_1best.st.bg
        var spk = result_item.json_1best.st.rl
        var time = ""
        var tmpSentence = "";
        result_item.json_1best.st.rt[0].ws.forEach(function (text) {
          tmpSentence += text.cw[0].w
        });

        if (bg == null) {
          time = "-:--:--";
        }

        const s = String(Math.floor(bg / 1000) % 60).padStart(2, '0')
        const m = String(Math.floor(bg / 1000 / 60) % 60).padStart(2, '0')
        const h = Math.floor(bg / 1000 / 60 / 60) % 24;
        time = h + ":" + m + ":" + s;

        var time_area = document.createElement('div');
        time_area.setAttribute('style', 'margin-left:10px; font-size:11px; color:#7a7a7a; cursor:pointer;');
        time_area.textContent  = time;

        var spk_area = document.createElement('div');
        spk_area.setAttribute('style', 'font-size:11px; color:#7a7a7a');
        spk_area.textContent  = "話者" + spk;

        var content_area = document.createElement('p');
        content_area.setAttribute('style', 'width:100%; text-align: left; font-size:14px; color:#7a7a7a; margin:0px 0px 0px 15px;');
        content_area.textContent  = tmpSentence;

        var head_block = document.createElement('div');
        head_block.id = "orgBlock"+index;
        head_block.setAttribute('style', 'display: flex; justify-content: left; width:100%; margin-top:15px; margin-left:15px; font-size:14px; color:#7a7a7a;');

        head_block.appendChild(spk_area);
        head_block.appendChild(time_area);

        // html += head + content_area
        time_area.onclick = function() {
          console.log(bg);
          parentObj.setCurrentTime(parentObj.getMsTime(time))
        }

        target.appendChild(head_block);
        target.appendChild(content_area);
        parentObj.original_time_list.push(Number(bg))
        index++;
      });
    },
    openSideWindow(content_type){
      this.side_window_content_type = "load"
      var fadeleft = [
        {
            opacity: 0,
            width: "0%",
        },
        {
            opacity: 1,
            width: "40%"
        }
      ]

      const target = document.getElementById('side_window');
      target.style.visibility = "visible"

      const effect = new KeyframeEffect(
        target, fadeleft, 500
      );
      const animation = new Animation(effect, document.timeline)
      animation.onfinish = () => this.side_window_content_type = content_type;
      animation.play()
    },
    execGec(){
      var data ={contents: this.advJson.resultData[this.currentGecTarget].content}
      axios.post(this.getURL("/ai/gec"), data, this.getHeaders(this.$store.state.token))
        .then(
          (response) => {
            if (response.data.success) {
                this.side_window_content_type = "gec"
                response.data.data.originalScore
                var candidateList = new Array()
                this.isGecTimeout = response.data.data.timeout;
                response.data.data.candidateList.forEach(function (result_item) {
                  candidateList.push(result_item["sentence"])
                });
                this.gecCandidateList = candidateList;
            }
          }
        )
        .catch(err => {
          console.log(err)
        })
    },
    clickCandidateSentence(sentence){
      var text = sentence.replace(/<span(?: .+?)?>.*?<\/span>/g, '');
      text = text.replace(/(<([^>]+)>)/gi, '');
      this.$set(this.advJson.resultData[this.currentGecTarget], "content", text);
      this.closeSideWindow();
    },
    openGecWindow(index){
      this.side_window_title =  "修正候補"
      // this.side_window_content_type = "original_dictation"
      this.isGecLoad = true;
      this.currentGecTarget = index;
      this.gecCandidateList = []
      this.openSideWindow("load");
      // setTimeout(this.execGec, 5000);
      this.execGec()
    },
    closeSideWindow(){
      this.side_window_content_type = "None"
      var fadeleft = [
        {
            opacity: 1,
            width: "40%",
        },
        {
            opacity: 0,
            width: "0%"
        }
      ]

      const target = document.getElementById('side_window');

      const effect = new KeyframeEffect(
        target, fadeleft, 500
      );
      const animation = new Animation(effect, document.timeline)
      // target.animate(fadeleft, anime_options); 
      animation.onfinish = () => {
        target.style.visibility = "hidden";
        this.isGecLoad = true;
        this.currentGecTarget = -1;
        this.gecCandidateList = []
      }
      animation.play()
    },


    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // API通信関連処理系
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    /**
     * 各議事録アイテムのデータを取得する
     */
    getItemData() {
      //ローディング表示
      this.showLoading();
      //Audioオブジェクトを取得(TODO: 位置はここ？)
      this.audio = document.getElementById("audioObj");

      const url = "/contents/" + this.prj_id + "/minutesItem";
      axios(this.getAxConf("get", url, {}, this.$store.state.token))
      .then((res) => {
        if (!res.data.success) {
          return this.showErrorWindow(res);
        }
        // Audioオブジェクトに対して、AACパスを設定
        this.audio.src = res.data.data.aacPath;
        // 事前にMETAデータのみ読み込む
        this.audio.preload = "metadata";
        this.advItem = res.data.data;
        this.getEditJson();
        this.setAudioEventListener();
        //ローディング終了
        this.hideLoading();
      }).catch(err=>{
        this.showNetworkErrorWindow(err);
        this.setAudioEventListener();
        this.hideLoading();
      })
    },

    setAdvJson(res){
      // 全部ぶち込み
          this.advJson = res.data;

          //v1の場合
          //json形式の違いを吸収する為、整形する
          if(this.advJson.version == "v1"){
            this.advJson.resultData.forEach(function (result_item) {
              var tmpSentence = "";
              result_item.textDataList.forEach(function (txtListItem) {
                tmpSentence = tmpSentence + txtListItem.sentence.trim()+"\n";
              });
              result_item.content = tmpSentence;
              result_item.isRead = false;
              delete result_item.textDataList;
              delete result_item.segmentId;
            });
          }
           if (!res.data.minutesInfo) {
            this.advJson.minutesInfo = {};
            this.advJson.minutesInfo.agenda = "";
            this.advJson.minutesInfo.abstract = "";
            this.advJson.minutesInfo.decisionTxtList = [""];
            this.advJson.minutesInfo.remarks = "";
            this.advJson.minutesInfo.nextMeetDate = "";
          } 

          this.advJson.meetInfo.meetDate = res.data.meetInfo.meetDate ? this.convert2UtcDate(res.data.meetInfo.meetDate) : "";
          this.advJson.minutesInfo.nextMeetDate = res.data.minutesInfo.nextMeetDate ? this.convert2UtcDate(res.data.minutesInfo.nextMeetDate) : "";

          //取得時の話者の人数をカウント
          var max_num = -1;
          Object.keys(this.advJson.speakerInfo.speakerName).filter((key) => {
            var tmp_num = Number(key);
            if(tmp_num > max_num){
              max_num = tmp_num;
            }
          });
          this.speakerNextId = max_num+1;

          // 話者情報をセット

          // 初期状態ではmiutesInfo自体が無いので初期化
          if (!this.advJson.minutesInfo) {
            this.advJson.minutesInfo = {};
            this.advJson.minutesInfo.agenda = "";
            this.advJson.minutesInfo.abstract = "";
            this.advJson.minutesInfo.decisionTxtList = [""];
            this.advJson.minutesInfo.remarks = "";
            this.advJson.minutesInfo.nextMeetDate = "";
          }

          //音声の長さを設定
          this.durationTime = res.data.meetInfo.duration ? res.data.meetInfo.duration : 0;
          this.durationTimeView = this.convertTimeHHMMSS(this.durationTime);
          
          //表示が完了したらtextareaの高さを文字の数に応じて調整
          this.$nextTick(function() {
            this.advJson.resultData.forEach((result_item, index) => {
              const element = document.getElementById("editarea_"+index)
              element.style.height = 'auto'
              element.style.height = element.scrollHeight + 'px'
            });
          });
    },

    /**
     * 原文JSONと編集JSONを取得する
     */
    getEditJson() {
      var advurl = this.advItem.minutesJsonPath;
      var orgurl = this.advItem.originalJsonPath;
      axios
        .get(advurl)
        .then((res) => {
          // // 全部ぶち込み
          // this.advJson = res.data;

          // //v1の場合
          // //json形式の違いを吸収する為、整形する
          // if(this.advJson.version == "v1"){
          //   this.advJson.resultData.forEach(function (result_item) {
          //     var tmpSentence = "";
          //     result_item.textDataList.forEach(function (txtListItem) {
          //       tmpSentence = tmpSentence + txtListItem.sentence.trim()+"\n";
          //     });
          //     result_item.content = tmpSentence;
          //     result_item.isRead = false;
          //     delete result_item.textDataList;
          //     delete result_item.segmentId;
          //   });
          // }
          //  if (!res.data.minutesInfo) {
          //   this.advJson.minutesInfo = {};
          //   this.advJson.minutesInfo.agenda = "";
          //   this.advJson.minutesInfo.abstract = "";
          //   this.advJson.minutesInfo.decisionTxtList = [""];
          //   this.advJson.minutesInfo.remarks = "";
          //   this.advJson.minutesInfo.nextMeetDate = "";
          // } 

          // this.advJson.meetInfo.meetDate = res.data.meetInfo.meetDate ? this.convert2UtcDate(res.data.meetInfo.meetDate) : "";
          // this.advJson.minutesInfo.nextMeetDate = res.data.minutesInfo.nextMeetDate ? this.convert2UtcDate(res.data.minutesInfo.nextMeetDate) : "";

          // //取得時の話者の人数をカウント
          // var max_num = -1;
          // Object.keys(this.advJson.speakerInfo.speakerName).filter((key) => {
          //   var tmp_num = Number(key);
          //   if(tmp_num > max_num){
          //     max_num = tmp_num;
          //   }
          // });
          // this.speakerNextId = max_num+1;

          // // 話者情報をセット

          // // 初期状態ではmiutesInfo自体が無いので初期化
          // if (!this.advJson.minutesInfo) {
          //   this.advJson.minutesInfo = {};
          //   this.advJson.minutesInfo.agenda = "";
          //   this.advJson.minutesInfo.abstract = "";
          //   this.advJson.minutesInfo.decisionTxtList = [""];
          //   this.advJson.minutesInfo.remarks = "";
          //   this.advJson.minutesInfo.nextMeetDate = "";
          // }

          // //音声の長さを設定
          // this.durationTime = res.data.meetInfo.duration ? res.data.meetInfo.duration : 0;
          // this.durationTimeView = this.convertTimeHHMMSS(this.durationTime);
          
          // //表示が完了したらtextareaの高さを文字の数に応じて調整
          // this.$nextTick(function() {
          //   this.advJson.resultData.forEach((result_item, index) => {
          //     const element = document.getElementById("editarea_"+index)
          //     element.style.height = 'auto'
          //     element.style.height = element.scrollHeight + 'px'
          //   });
          // });
          this.setAdvJson(res);
        })
        .catch((err) => {
          this.showErrorWindow(err);
        });

      //以下より原文Jsonを取得
      axios
        .get(orgurl)
        .then((res) => {
          this.createOriginalHtml(res.data);
        })
        .catch((err) => {
          console.log(err)
          this.showErrorWindow(err);
        });
      this.hideLoading();
    },
    autoExpandTextarea(id) {
      // const element = document.getElementById(id)
      // element.style.height = element.scrollHeight + 'px'
      const element = document.getElementById(id)
      element.style.height = 'auto'
      element.style.height = element.scrollHeight + 'px'
    },
    mouseoverBlock(index){
      var target_tool_bar = document.getElementById('toolbar'+index);
      target_tool_bar.style.visibility ="visible";
    },
    mouseleaveBlock(index){
      var target_tool_bar = document.getElementById('toolbar'+index);
      target_tool_bar.style.visibility ="hidden";
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 話者関連機能機能
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    /**
     * スピーカーモーダルを閉じる
     */
    closeSpeakerModal() {
      const element = document.getElementById("color_picker")
      this.advJson.speakerInfo.speakerColor[this.change_speaker_id] = element.value;
      this.change_speaker_id = "";
      this.isShowAddSpeakerWindow = false;
      this.isShowSpeakerEditWindow = false;
    },
    /**
     * スピーカー編集画面を開く
     */
    openSpeakerEditWindow(spk_id) {
      console.log("****** " + spk_id)
      this.change_speaker_id = spk_id;
      this.isShowSpeakerEditWindow = true;
    },
    /**
     * 
     */
    getSpeakerColor(spk_id) {
      var color = "";
      color = this.advJson.speakerInfo.speakerColor[spk_id];
      if ((color == null) | (color == "")) {
        color = "#808080";
      }
      return color;
    },
    /**
     * 話者追加ウィンドウを閉じる
     */
    saveAddSpeaker() {
      // const newNo = Number(this.speakerNum) + 1
      // this.speakerData[newNo] = this.addSpeakerName;
      // this.speakerColor[newNo] = this.addSpeakerColor;
      // this.speakerNum = Number(this.speakerNum) + 1;
      // this.save("speakerInfo")
      this.$set(this.advJson.speakerInfo.speakerName, String(this.speakerNextId), this.addSpeakerName );
      this.$set(this.advJson.speakerInfo.speakerColor, String(this.speakerNextId), this.addSpeakerColor );

      this.isShowAddSpeakerWindow = false;
      this.addSpeakerName = "";
      this.addSpeakerColor = "#808080";
      this.speakerNextId+=1;
    },
    /**
     * 話者追加ウィンドウを開く
     */
    addSpeaker() {
      if (Object.keys(this.advJson.speakerInfo.speakerName).length >= 64) {
        this.showToast("話者は64名までしか登録できません", true);
        return;
      }
      this.isShowAddSpeakerWindow = true;
      this.addSpeakerName = "話者" + String(this.speakerNextId);
    },
    removeSpeaker(remove_id) {
      var replaceTargetId = this.convert2SpeakerId(this.replaceTargetSpkVal)

      this.advJson.resultData.forEach(function (result_item) {
        if(result_item.speakerId == remove_id){
            result_item.speakerId = replaceTargetId
        }
      });

      this.$delete(this.advJson.speakerInfo.speakerName, remove_id)
      this.$delete(this.advJson.speakerInfo.speakerColor, remove_id)

      this.replaceTargetSpkVal = "";
      this.change_speaker_id = "";
      this.isSpeakerRemoveMode = false;
      this.isShowSpeakerEditWindow = false;

    },
    addSentenceArea(target_index) {
      var target_block = this.advJson.resultData[target_index]

      var add_data = {"isfav":false,"speakerId": target_block.speakerId,"content":"","time":target_block.time};

      this.advJson.resultData.splice(target_index, 0, add_data);
    },

    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // UI編集モードエディタ機能
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■


    openSpeakerSelectPopup(index,e) {
      this.editBlockSpkTargetIndex = index;
      // ポップアップウィンドウの高さは取れないので決め打ち
      const POPUP_HEIGHT = 300;
      var yPosition = e.pageY;
      if (screen.availHeight - e.pageY < POPUP_HEIGHT) {
        yPosition = screen.availHeight - POPUP_HEIGHT
      }
      this.speakerPopupPosition = {
        top: yPosition + "px",
        left: e.pageX + 20 + "px",
      };
      this.currentSelectSpeakerElm = e.target;
      this.isShowSpeakerPopup = true;
    },
    close_speaker_popup() {
      this.isShowSpeakerPopup = false;
    },
    changeSpeakerOne(id) {
      this.advJson.resultData[this.editBlockSpkTargetIndex].speakerId = id
      this.isShowSpeakerPopup = false;
    },
    delSentence(index) {

      if(!this.isConfirmdeleteBlock){
        this.advJson.resultData.splice(index, 1)
        return;
      }
      
      Swal.fire({
        confirmButtonColor: '#dd4157',
        cancelButtonColor: '#808080',
        reverseButtons: true,
        animation: false,
        html: `
          <p>
            この発言ブロックを削除しますか？<br />
            (元に戻せません)
          </p>
          <style>
            p{font-size: 14px;}
          </style>
          `,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "削除する",
        confirmButtonAriaLabel: "削除する",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.advJson.resultData.splice(index, 1)
        } else {
          return;
        }
    })
    },
    toggleBookmark(index) {
      // this.advJson.resultData[index].isFav = !this.advJson.resultData[index].isFav
      this.$set(this.advJson.resultData[index], "isFav", !this.advJson.resultData[index].isFav);
    },
    joinPrevBlock(index){
      if(index != 0){
        var text = this.advJson.resultData[index].content;
        var prev_content = this.advJson.resultData[index-1].content;
        this.$set(this.advJson.resultData[index-1], "content", prev_content + text);
        this.advJson.resultData.splice(index, 1)
      }
    },
    copySentence(index) {
      var copyText = this.advJson.resultData[index].content
      if (navigator.clipboard) {
        navigator.clipboard.writeText(copyText).then(function () {
          Swal.fire({
            animation: true,
            html: "コピーしました",
            toast: true,
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
        });
      } else {
        Swal.fire({
          animation: true,
          html: "ブラウザが対応していません",
          toast: true,
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 4000,
        });
      }
    },
    upFontSize(type) {
      if (type == "original") {
        if (this.originalEditorFontSize < 48) {
          this.originalEditorFontSize++;
        }
      } else if (type == "edit") {
        if (this.editEditorFontSize < 48) {
          this.editEditorFontSize++;
          this.advJson.resultData.forEach((result_item, index) => {
            this.autoExpandTextarea('editarea_' + index);
          });
        }
      } else {
        console.log("不明な種別です")
      }
    },
    downFontSize(type) {
      if (type == "original") {
        if (this.originalEditorFontSize > 9) {
          this.originalEditorFontSize--;
        }
      } else if (type == "edit") {
        if (this.editEditorFontSize > 9) {
          this.editEditorFontSize--;
          this.advJson.resultData.forEach((result_item, index) => {
            this.autoExpandTextarea('editarea_' + index);
          });
        }
      } else {
        console.log("不明な種別です")
      }
    },
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // 議事情報関連
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    // ツール系
    // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
    showLoading() {
      try {
        this.$modal.show("loading");
      } catch {
        console.log("loading icon isnot loaded...");
      }
    },
    hideLoading() {
      try {
        this.$modal.hide("loading");
      } catch {
        console.log("loading icon isnot loaded...");
      }
    },
    showProcessing(msg) {
      this.processMsg = msg;
      try {
        this.$modal.show("process");
      } catch {
        console.log("process modal failed...");
      }
    },
    hideProcessing() {
      try {
        this.$modal.hide("process");
      } catch {
        console.log("process modal failed...");
      }
      this.processMsg = "";
    },
    showToast(message, isError) {
      if (isError) {
        $("#toast").css("background-color", "rgba(255, 108, 108, 0.8)");
        $("#toast_message").css("color", "#FFFFFF");
      } else {
        $("#toast").css("background-color", "rgba(104, 207, 195, 0.8)");
        $("#toast_message").css("color", "#FFFFFF");
      }
      $("#toast").css("visibility", "visible");
      $("#toast_message").html(message)
      setTimeout(function () {
        $("#toast").css("visibility", "hidden");
      }, 3000);
    },
    show_icon_tips(){
      this.side_window_title =  "各アイコンの説明"
      // this.side_window_content_type = "icon_tips"
      this.openSideWindow("icon_tips");
    },
    updateCurrentTime(val){
      var target_index = 0;
      this.advJson.resultData.forEach((result_item, index) => {
          var st = this.getMsTime(result_item.time);
          var ed = -1;
          if(this.advJson.resultData.length-1 == index){
            //最後の要素
            ed = this.durationTime.time;
          }else{
            ed = this.getMsTime(this.advJson.resultData[index+1].time)
          }
          //json_1best.st.bg->単位 : mili秒
          if (st <= val && val < ed) {
            // this.setOrgScroll(block.json_1best.st.bg);
            target_index = index;
            this.$set(this.advJson.resultData[target_index], "isRead", true);
            return;
          }else{
            this.$set(this.advJson.resultData[index], "isRead", false);
            // result_item.isRead = false;
          }
      });
      // this.$set(this.advJson.resultData[target_index], "isRead", true);
      // this.advJson.resultData[target_index].isRead = true;
      if(this.isSyncPlay){
        this.scrollToBlock(target_index)
      }
      return this.convertTimeHHMMSS(val);
    },
    convertTimeHHMMSS(val) {
      var hhmmss = new Date(val).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },
    scrollToBlock(index) {
      // if(!this.playing){
      //   return;
      // }
      var target_block = document.getElementById('block'+index); // 移動させたい位置の要素を取得
      if(target_block == undefined || target_block == null){
        return;
      }
      target_block.scrollIntoView({
        behavior : 'smooth',
        block    : 'center',
        inline   : 'center'
      });
    },
    clickSyncWindow(){
      var target_index = 0;
      this.advJson.resultData.forEach((result_item, index) => {
        if( result_item.isRead == true){
          target_index = index;
          return;
        }
      });
      this.scrollToBlock(target_index)
    },
    /**
     * マウスオーバーで表示する、POPUPの位置を計算する
     * 
     * @function
     * @param  {HTMLelement}} マウスオーバーイベントを発火した要素
     * @param  {Number} popupWidth 表示するPOPUPの横幅
     * @param  {Number} popupHeight 表示するPOPUPの高さ
     * @param  {Number} margin  表示するPOPUPのマージン
     */
    calcPopupShowPosition(e, popupWidth, popupHeight, margin) {
      var showPositionX = 0;
      var showPositionY = 0;

      if ((screen.availWidth - e.screenX - margin) < popupWidth || (document.documentElement.clientWidth - e.clientX - margin) < popupWidth) {
        //そのまま出せない場合
        showPositionX = e.pageX - popupWidth - margin;
      } else {
        showPositionX = e.pageX + margin;
      }

      if ((screen.availHeight - e.screenY - margin) < popupHeight || (document.documentElement.clientHeight - e.clientY - margin) < popupHeight) {
        showPositionY = e.pageY - popupHeight - margin;
      } else {
        showPositionY = e.pageY + margin;
      }

      var position = {
        top: `${showPositionY}px`,
        left: `${showPositionX}px`,
      };
      return position;
    },
    /**
     * 話者IDから表示色を取得します。
     *
     * @function
     * @param  {string} id 話者ID
     * @return {Boolean}  話者名
     */
    convert2SpeakerColor(spk_id) {
      // var color = "";
      // color = this.speakerColor[id];
      // if ((color == null) | (color == "")) {
      //   color = "#808080";
      // }
      // return color;

      var color = "";
      color = this.advJson.speakerInfo.speakerColor[spk_id];
      if ((color == null) | (color == "")) {
        color = "#808080";
      }
      return color;
    },
    /**
     * 話者IDから話者名に変換します。
     *
     * @function
     * @param  {string} id 話者ID
     * @return {Boolean}  話者名
     */
    convert2SpeakerName(spk_id) {
      var speakerName = "    ";
      speakerName = this.advJson.speakerInfo.speakerName[spk_id];
      return speakerName;
    },
    /**
     * 話者名から話者IDに変換します。
     *
     * @function
     * @param  {string} speakerName 話者名
     * @return {Boolean}  話者ID
     */
    convert2SpeakerId(speakerName) {
      var result = ""
      Object.keys(this.advJson.speakerInfo.speakerName).filter((key) => {
        console.log("keyyyyy: " + key)
        console.log("Name: " + this.advJson.speakerInfo.speakerName[key])
        if (this.advJson.speakerInfo.speakerName[key] == speakerName) {
          result = key;
          return;
        }
      });
      return result;
    },

    vCalendarOpen: function () {
      if (!this.$refs.picker.isOpen) {
        this.$refs.picker.$el.querySelector("input").focus();
        this.$refs.picker.showCalendar();
      }
    },
    vCalendarClose: function () {
      if (this.$refs.picker.isOpen) {
        this.$refs.picker.close(true);
      }
    },
    date2Numdate(date) {
      var numdate = "";
      if (date) {
        var year = date.getFullYear();
        var month = 1 + date.getMonth();
        var day = date.getDate();
        month = ('0' + month).slice(-2);
        day = ('0' + day).slice(-2);
        numdate = year + "年" + month + "月" + day + "日　";
      }
      return numdate
    },
    downloadSound() {
      const mediaDLUrl = this.advItem.aacPath;
      const now = this.getNowDatetime();
      const filename = "sound_download_" + now + ".aac"

      const a = document.createElement("a")
      document.body.appendChild(a)
      a.download = filename
      a.href = mediaDLUrl
      a.click()
      a.remove()
    },
    getNowDatetime() {
      const date = new Date()
      const y = date.getFullYear()
      const mo = ('00' + date.getMonth() + 1).slice(-2);
      const d = ('00' + date.getDate()).slice(-2);
      const h = ('00' + date.getHours()).slice(-2);
      const m = ('00' + date.getMinutes()).slice(-2);
      return "" + y + mo + d + "" + h + m
    },
    convert2SaveTime(utcdate) {
      if (!utcdate) {
        return ""
      }
      const date = Date.parse(utcdate);
      const dt = new Date(date)
      const y = dt.getFullYear()
      const M = ('00' + (Number(dt.getMonth()) + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      const h = ('00' + dt.getHours()).slice(-2);
      const m = ('00' + dt.getMinutes()).slice(-2);
      return "" + y + "-" + M + "-" + d + " " + h + ":" + m + ":00"
    },
    convert2UtcDate(date) {
      if (!date) {
        return ""
      }
      const dt = new Date(date)
      return dt.toJSON()
    },
  },
  /**
   * 画面遷移前に注意する処理
   */
  beforeRouteLeave(to, from, next) {
    this.save();
    return next()
  },
};
</script>
<style scoped>
textarea::placeholder {
  font-family: "メイリオ", "Meiryo", "ＭＳ ゴシック",
    "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
}

.dictexcontent {
  width: 100%;
  background: #ffffff;
  overflow-y: auto;
  font-size: 14px;
}

.contentarea {
  /* margin-top: 55px; ヘッダーの分 */
  display: flex;
  width: 100vw;
  height: 100vh;
}

.toolbutton {
  border: 1px #13618f solid;
  color: #fff;
  background-color: #13618f;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
}

.toolbutton:hover {
  border: 1px #29aba4 solid !important;
  border-radius: 5px !important;
  background: #fff;
  color: #29aba4 !important;
  font-weight: bold;
}

.toolbutton_delete {
  border: 1px #dd4157 solid;
  color: #fff;
  background-color: #dd4157;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
}

.toolbutton_delete:hover {
  border-radius: 5px !important;
  background: #fff;
  color: #dd4157 !important;
  font-weight: bold;
}

.serachBtn {
  border: 1px #29aba4 solid;
  margin-left: 20px;
  color: #fff;
  background-color: #29aba4;
  border-radius: 5px;
  width: max-content;
  height: min-content;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

.serachBtn:hover {
  border: 1px #29aba4 solid !important;
  border-radius: 5px !important;
  background: #fff;
  color: #29aba4 !important;
  font-weight: bold;
}

.panel_area {
  display: flex;
  justify-content: space-evenly;
  max-width: 99vw;
}

.editor_label {
  margin-left: 1px;
  padding: 3px 15px;
  text-align: center;
  background-color: #13618f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: max-content;
  color: #FFF;
  /* font-size: smaller; */
}

.ais_editer {
  overflow: auto;
  width: 100%;
  height: 70vh;
  min-width: 180px;
  padding: 5px;
  border: 1px ridge #ccc;
  color: #221816;
  background-color: #ffffff;
  line-height: 1.5em;
  margin: 0px;
  font-size: 14px;
  font-family: "メイリオ", "Meiryo", "ＭＳ ゴシック",
    "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
  position: relative;
}

.editorHeader {
  display: flex;
  justify-content: left;
  height: 4vh;
  position: relative;
  align-items: flex-end;
}

.how_to_use_area{
  position: absolute;
  /* top: calc(4vh- 5px) !important; */
  right: 0px;
  width: max-content;
  z-index: 2;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.how_to_use_area:hover .how2text{
  visibility: visible;
}

.how_to_use_area:hover .how2use_btn{
  font-weight: bold;
  color: #29aba4;
  border-radius: 3px;
}


.how2use_btn {
  color: #808080;
  flex-wrap: wrap;
  text-align: center;
}

.how2text{
  font-weight: bold;
  margin: auto 0;
  visibility: hidden;
  padding: 3px;
  text-decoration-line: underline;
  text-underline-offset: 5px; 
}

.original_confirm_btn {
  position: absolute;
  top: calc(4vh + 5px);
  right: 5px;
  width: max-content;
  height: 30px;
  background-color: #f8f8f8;
  border: 2px #8d8d8d solid;
  box-shadow: 3px 3px 3px 3px rgba(138, 138, 138, 0.4);
  z-index: 2;
  color: #8d8d8d;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 10px;
  cursor: pointer;
}

.original_confirm_btn:hover {
  color: #13618f;
  font-weight: bold;
  border: 2px #13618f solid;
}

.editor_tool_icon {
  margin: auto;
  cursor: pointer;
  color: #333333;
}

.editor_tool_icon:hover {
  color: #29aba4;
}

.show_speaker_name {
  font-weight: bold;
  font-size: 16px;
  color: #808080;
  flex-wrap: wrap;
  cursor: pointer;
}

.show_speaker_name:hover{
  font-weight: bold;
  text-decoration-line: underline;
  text-underline-offset: 5px; 
  text-decoration-style: dotted;
}

.show_time {
  font-size: 12px;
  color: #808080;
  flex-wrap: wrap;
  margin-left: 10px;
  width: 50px;
}
.show_time:hover{
  color: #13618f;
  font-weight: bold;
}

.add_decision_btn {
  font-size: 16px;
  color: #808080;
  flex-wrap: wrap;
  margin-left: 15px;
}

.add_decision_btn:hover {
  font-weight: bold;
  color: #fff462;
}

.add_speaker_btn {
  font-size: 16px;
  color: #808080;
  flex-wrap: wrap;
  margin-left: 15px;
}

.add_speaker_btn:hover {
  font-weight: bold;
  color: #13618f;
}

.delete_sentene_btn {
  font-size: 16px;
  color: #808080;
  flex-wrap: wrap;
  margin-left: 18px;
}

.delete_sentene_btn:hover {
  font-weight: bold;
  color: #dd4157;
}

.block_join_btn {
  font-size: 16px;
  color: #808080;
  flex-wrap: wrap;
  margin-left: 18px;
}

.block_join_btn:hover {
  font-weight: bold;
  color: #34b8e0;
}

.sentence_copy_btn {
  font-size: 16px;
  color: #808080;
  flex-wrap: wrap;
  margin-left: 18px;
}

.sentence_copy_btn:hover {
  font-weight: bold;
  color: #3b3b3b;
}

.gec_btn {
  font-size: 16px;
  color: #808080;
  flex-wrap: wrap;
  margin-left: 18px;
}

.gec_btn:hover {
  font-weight: bold;
  color: #ff00aa;
}

.icon_desc_btn {
  font-size: 16px;
  color: #808080;
  flex-wrap: wrap;
  margin-left: 18px;
  text-align: center;
}

.icon_desc_btn:hover {
  font-weight: bold;
  color: #29aba4;
  border-radius: 3px;
}


.icon_desc_btn:hover .icon_desc_text{
  visibility: visible;
}

.icon_desc_text{
  font-weight: bold;
  font-size: 12px;
  margin: 0 auto;
  visibility: hidden;
  padding: 3px;
}

.speaker_area {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 0px;
  margin-left: 3px;
}

.text_edit_line {
  margin: 0px;
  /* margin-bottom: 16px; */
  display: flex;
  flex-wrap: wrap;
}

.spoken_area_edit {
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
}

.text_edit_area{
  margin: 0px;
  border:none;
  resize: none;
  width: 95vw;
  height: s;
  overflow: hidden;
  min-height: 20px;
  color:#808080;
  margin-left: 3px;
  line-height: 1.3em;
}


.spoken_area {
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}

/*
.word_block {
  user-select: none;
  padding: 0px;
  margin-right: 2px;
  cursor: text;
}
.word_block:active {
  cursor: grabbing !important;
}
*/
.playcontrol_area {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5vh;
  background: #ffffff;
  margin-bottom: 5px;
  align-items: center;
}

.speedarea {
  width: 33%;
}

.togglearea {
  text-align: center;
  width: 33%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-flow: row;
}

.speedarea>p,
.togglearea>p {
  font-size: 16px;
  color: #808080;
  margin: 2px;
}

.currentTime {
  font-size: 14px;
  margin-left: 5px;

}

.syncEditWindowBtn {
  border: 1px #13618f solid;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 14px;
  color: #13618f;
  cursor: pointer;
  margin-left: 15px;
  width: fit-content;
  text-align: center;
}

.syncEditWindowBtn:hover {
  border: 1px #29aba4 solid !important;
  background: #29aba4;
  color: #ffffff !important;
}

.miniswitchbutton {
  border: 1px #13618f solid;
  border-radius: 12px;
  padding: 3px 12px;
  font-size: 11px;
  color: #13618f;
  cursor: pointer;
  margin-left: 5px;
  width: fit-content;
  text-align: center;
}

.miniswitchbutton:hover {
  border: 1px #29aba4 solid !important;
  border-radius: 5px !important;
  background: #29aba4;
  color: #ffffff !important;
}

.msb_on {
  background: #29aba4 !important;
  color: #ffffff !important;
  border: 1px #ffffff solid !important;
}

.playdisplay {
  display: flex;
  justify-content: center;
  text-align: center;
  /* padding: 0px 20px; */
  width: 33%;
  align-items: center;
}

.playbutton {
  display: flex;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  border-radius: 22px;
  margin: 2px;
  padding: auto;
  text-align: center;
  line-height: 50%;
  align-items: center;
  width: max-content;
}

.miniskipbutton {
  width: 28px;
  height: 25px;
  font-size: 11px;
  vertical-align: middle;
  line-height: 24px;
}

.msb-right {
  border-radius: 2px 13px 13px 2px;
}

.msb-left {
  border-radius: 13px 2px 2px 13px;
}

.miniskipbutton>div {
  position: relative; 
  top: -4px;
  left: -2px;
  font-size: 12px;
}

.playicon {
  margin-left: 4px;
}

.miniskipbutton>.playicon {
  vertical-align: super;
}

.flexarea {
  width: 50%;
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-flow: row; */
  text-align: center;
  margin: auto;

}

/* 再生シークバー */
input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #cccccc;
  height: 8px;
  width: calc(100% - 20px);
  border-radius: 6px 6px 6px 6px;
  display: inline-block;
  vertical-align: middle;
  margin: 10px 10px;
  cursor: pointer;
}

/* 再生シークバー移動時 */
input[type="range"]:focus,
:active {
  outline: none;
}

/* 再生シークバーつまみ */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  width: 12px;
  height: 12px;
  background-color: #13618f;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb:hover {
  width: 20px;
  height: 20px;
  background-color: #13618f !important;
  border-radius: 30px;
}

.highlight {
  color: #13618f;
  font-weight: bold;
  margin: 0px;
  border:none;
  resize: none;
  width: 90vw;
  height: auto;
  overflow: hidden;
  min-height: 20px;
  margin-left: 3px;
  line-height: 1.5em;
}

.overlay {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/** PopUp関連 */

.speaker_edit_window {
  position: relative;
  margin: 2em 0;
  padding: 2em 1em;
  border: solid 3px #13618f;
  border-radius: 8px;
  z-index: 4;
  width: 50%;
  background: rgb(255, 255, 255);
}

.cp_iptxt {
  position: relative;
  width: 80%;
  margin: 25px 3%;
}

.cp_iptxt input[type="text"] {
  font: 15px/24px sans-serif;
  box-sizing: border-box;
  width: 100%;
  padding: 0.3em;
  transition: 0.3s;
  letter-spacing: 1px;
  color: #221816;
  border: 1px solid #1b2538;
  border-radius: 4px;
}

.speaker_popup {
  
  
  position: fixed;
  inset: 0;
  margin: auto 200px;

  padding: 0.5em 1em;
  border: solid 3px #13618f;
  border-radius: 8px;
  z-index: 2;
  width: 250px;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  /* 追加 */
}

.popup_close_btn {
  font-size: 25px;
  font-weight: bold;
  color: #13618f;
  cursor: pointer;
  position: sticky;
  /* 変更 */
  top: 0;
  height: 0;
  /* 追加 */
  text-align: right;
  /* 追加 */
  margin-bottom: 10px;
}

.speaker_popup_title {
  color: #13618f;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.speaker_popup_danger_title {
  color: #dd4157;
}

.speed_control_area{
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.speaker_select_btn {
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(255, 255, 255);
  background-color: #13618f;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #13618f;
  border-radius: 5px;
}

.speaker_select_btn:hover {
  color: #13618f;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
}

.speaker_select_btn:hove::after {
  border-top: 3px solid #2ba0ff;
  border-right: 3px solid #2ba0ff;
}

.plus_btn {
  color: #13618f;
  font-size: 20px;
  cursor: pointer;
}

.plus_btn:hover {
  color: #29aba4;
}

.plus_btn:hove::after {
  border-top: 3px solid #13618f;
  border-right: 3px solid #13618f;
}

.is-hide {
  display: none;
}

.side_window{
  position: fixed;
  visibility: hidden;
  width: 100%;
  height:85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  top: 0; /* 基準の位置を画面の一番下に指定する */
  right: 0; /* 基準の位置を画面の一番右に指定する */
  margin-top: 5px;
  /* visibility: hidden; */
  width: 40%;
  border-left: 20px solid #13618f;
  /* border-bottom: 5px solid #29aba4; */
  /* border-top: 5px solid #29aba4; */
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: 999;
  background-color: #13618f;
  box-shadow: -10px 10px 8px 4px rgba(0, 0, 0, 0.22);
}


.side_window_title{
  width: 100%;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  margin: 3px auto;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5px; */
}

.side_window_content_area{
  width: 100%;
  height: 89%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 0px;
  margin-right: 0px;
  background-color: #dbe3ec;
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
  box-shadow: 8px 12px 4px rgba(0, 0, 0, 0.22) inset;
}
.side_window_footer{
  text-align: center;
  width: 100%;
  height: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side_window_close_btn{
  background-color:  #dbe3ec;;
  width: 30%;
  height: max-content;
  /* border: 2px solid #515151; */
  color: #13618f;
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  margin: 3px auto;
  font-size: 16px;
}

.side_window_close_btn:hover {
  color: #dbe3ec;
  background-color:  #4682b4;;
}

.candidateItemArea{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.side_window_load,
.side_window_load:before,
.side_window_load:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: gec_load_anime 1.8s infinite ease-in-out;
  animation: gec_load_anime 1.8s infinite ease-in-out;
}
.side_window_load {
  color: #29aba4;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.side_window_load:before,
.side_window_load:after {
  content: '';
  position: absolute;
  top: 0;
}
.side_window_load:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.side_window_load:after {
  left: 3.5em;
}
@-webkit-keyframes gec_load_anime {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes gec_load_anime {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.candidateItemAreaMessage{
  width: 90%;
  height: max-content;
  text-align: center;
  margin:auto;
  margin-top: 50px;
  color: #dd4157;
}

.candidateItem{
  width: 90%;
  height: max-content;
  text-align: left;
  margin-top: 20px;
  margin-left: 5%;
  padding: 10px 5px;
  background-color:  #FFF;
  border: 2px solid #FFF;
  border-radius: 10px;
  box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}

.candidateItem:hover{
  border: 2px solid #13618f;
}

.audio_skip_btn{
  background-color:  #29aba4;;
  height: max-content;
  width: 15%;
  border: 2px solid #29aba4;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  margin: auto 3px;
  font-size: 16px;
}

.audio_skip_btn:hover {
  color: #29aba4;
  background-color:  #fff;;
}



#toast {
  position: fixed;
  inset: 0;
  margin: auto;
  visibility: hidden;
  height: 60px;
  width: 600px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  z-index: 999;
  background-color: #c2f8cf;
}

#toast>p {
  margin: 24px 0;
  margin-left: 8px;
  font-family: "Noto Sans CJK JP";
  font-size: 14px;
  vertical-align: center;
  color: #155724;
  letter-spacing: 0;
  line-height: 18px;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
  color: #979797;
}

.icon_tips_row{
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
}

.tooltip_box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0.5em 1em;
  /* border: solid 1px #24fc48; */
  color: #13618f;
  border-radius: 8px;
  z-index: 5;
  width: fit-content;
  height: max-content;
  background-color: #FFF;
  border: 2px #13618f solid;
  display: flex;
  ;
  flex-wrap: nowrap;
  flex-direction: column;
  /* 追加 */
  font-size: 14px;
}

/* スクロールバー */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #13618f;
  border-radius: 5px;
}

::-webkit-resizer {
  width: 0;
  height: 0;
  background-color: #13618f;
  border-left: 30px solid #ffffff;
  border-right: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

/* 上部ツールバー */
.upperToolbar {
  width: 100%;
  height: 10vh;
  /* background-color: rgb(118, 205, 255, 0.5); */
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: center;
}

.upperToolbarItem {
  display: flex;
  flex-direction: column;
  height: 50px;
  text-align: center;
  justify-content: center;
  /* margin-left: 5px;
  margin-right: 5px; */
  width: 150px;
  border-radius: 5px;
  margin: auto;
}

.upperToolbarItem:hover {
  background: #eeeeee;
}

.upperToolbarIcon {
  font-size: 20px;
  color: #13618f;
}

.upperToolbarIcon:hover {
  color: #29aba4;
}

.upperToolbarLabel {
  margin-top: 5px;
  padding: 0px;
  font-size: 12px;
  color: #8d8d8d;
}

.toolbar{
  position: absolute;
  left: v-bind(toolbar_x);
  top: v-bind(toolbar_y);;
  right: 0;
  bottom: 0;
  width: fit-content;
  height: max-content;
}

.toolbar_tips{
  position: absolute;
  top: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 5px #808080;
  border-radius: 5px;
  padding: 5px 20px;
  width: max-content;
  color: #808080;
  z-index: 3;
  font-size: 10.5px;
}

.toolbarTab {
  position: absolute;
  top: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 5px #808080;
  border-radius: 5px;
  padding: 10px 20px;
  width: max-content;
  z-index: 3;
}

.toolbarTab>ul {
  list-style: none;
  text-align: left;
  padding-left: 0px;
  margin: 0px;
}

/* POPUP メイン */
.popupWindow {
  position: fixed;
  inset: 0;
  margin: auto;
  height: max-content;
  width: max-content;
  border: 0px solid rgb(255, 255, 255);
  border-radius: 25px;
  z-index: 999;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  max-width: 80%;
  min-width: 50%;
}

.popupHeader {
  width: 100%;
  height: max-content;
  background: #13618f;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  text-align: center;
}

.popupTitle {
  color: #FFF;
  text-align: center;
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.popupHeaderError {
  width: 100%;
  height: max-content;
  background: #dd4157;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  text-align: center;
}

.popupMainContent {
  overflow-y: auto;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.popupRow {
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.popupFooter {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-top: 20px;
}

.popupFooterBtn {
  border: 2px #13618f solid;
  border-bottom-left-radius: 22px;
  padding: 4px 8px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  background-color: #13618f;
  cursor: pointer;
  /* margin: 15px; */
  width: 50%;
  text-align: center;
}

.popupFooterBtn:hover {
  background-color: rgb(255, 255, 255);
  color: #13618f;
}

.popupFooterBtnCancel {
  border: 2px #aaaaaa solid;
  border-bottom-right-radius: 22px;
  padding: 4px 8px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  background-color: #aaaaaa;
  color: #ffffff;
  cursor: pointer;
  /* margin: 15px; */
  width: 50%;
  /* height: max-content; */
  text-align: center;
}

.popupFooterBtnCancel:hover {
  background-color: rgb(255, 255, 255);
  color: #dd4157;
  border: 2px #dd4157 solid;
}

.popupFooterBtnFill {
  border: 2px #13618f solid;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 4px 8px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  background-color: #13618f;
  cursor: pointer;
  /* margin: 15px; */
  width: 100%;
  text-align: center;
}

.popupFooterBtnFill:hover {
  background-color: rgb(255, 255, 255);
  color: #13618f;
}

.popupInputArea {
  width: max-content;
  height: max-content;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.popupIconArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: max-content;
  height: max-content;
  margin-right: 10px;
}

.popupInputBox {
  font: 15px/24px sans-serif;
  box-sizing: border-box;
  transition: 0.3s;
  letter-spacing: 1px;
  color: #221816;
  border: 1px solid #1b2538;
  border-radius: 4px;
  width: max-content;
  height: max-content;
}

.popupInputLabel {
  font-size: 12px;
  text-align: center;
  width: 100px;
  height: max-content;
  color: #7a7a7a;
}

.popupInputIcon {
  font-size: 16px;
  text-align: center;
  color: #29aba4;
}

.popupLinerArea {
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.keyword_text {
  /* border-radius: 5px; */
  border:none;
  border-bottom: 2px solid #7a7a7a;
  font-size: 16px;
  height: min-content;
  text-align: center;
  width: max-content !important;
}

.keyword_item {
  display: flex;
  flex-direction: row;
  width: max-content;
  height: max-content;
  margin-right: 10px;
  margin-top: 10px;
}

.popupSpeakerBtn {
  /* border-radius: 5px; */
  border: 3px #29aba4 solid;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  height: min-content;
  text-align: center;
  font-size: 14px;
  color: #221816;
  background-color: #ffffff;
  cursor: pointer;
  padding: 5px;
}

.popupSpeakerBtn:hover {
  background-color: rgb(255, 255, 255);
  border: 3px #13618f solid;
  color: #13618f;
}

.popupSearchListRow {
  width: 100%;
  height: min-content;
  /* border: 3px #29aba4 solid; */
  color: #221816;
  display: flex;
}

.popupSearchSpeakerArea {
  text-align: center;
  font-size: 14px;
  margin-left: 10px;
  height: min-content;
  padding: 5px;
  color: #7a7a7a;
  width: 20%;
}

.searchPlayBtn {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  height: min-content;
  font-size: 18px;
  color: #7a7a7a;
  cursor: pointer;
  padding: 5px;
  width: 5%;
}

.searchPlayBtn:hover {
  color: #29aba4;
}

.searchDeleteBtn:hover {
  color: #dd4157 !important;
}

.popupSearchTextArea {
  font-size: 14px;
  width: 75%;
  height: min-content;
  width: max-content;
  padding: 5px;
  color: #7a7a7a;
  cursor: pointer;
}

.popupSearchTextArea:hover {
  height: min-content;
  border-radius: 10px;
  color: #ffffff;
  background-color: #29aba4;
}

.popupDescribe {
  text-align: center;
  font-size: 18px;
  color: #7a7a7a;
  width: 100%;
  height: max-content;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

/* チェックボックス */
input[type="checkbox"] {
  display: none;
}

.popupCheckbox {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 13px;
  position: relative;
  width: max-content;
  margin: 10px;
  margin-left: 30px;
}

.popupCheckbox::before {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  content: "";
  display: inline-block;
  height: 16px;
  left: -12px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 16px;
}

.popupCheckbox::after {
  border-right: 6px solid #13618f;
  border-bottom: 3px solid #13618f;
  content: "";
  display: block;
  height: 20px;
  left: -12px;
  margin-top: -16px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translate3d(0, 2px, 0) scale3d(0.7, 0.7, 1);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  width: 9px;
}

.popupCheckbox:hover{
  font-weight: bold;
  color: #13618f;
}

input[type="checkbox"]:checked+.popupCheckbox::before {
  border-color: #666;
}

input[type="checkbox"]:checked+.popupCheckbox::after {
  opacity: 1;
  transform: rotate(45deg) scale3d(1, 1, 1);
}

/* UIモードのコンテンツエディタブルの抑制 */
[contenteditable] b {
  font-weight: normal;
}

[contenteditable] i {
  font-style: normal;
}

[contenteditable] img {
  display: none;
}

.spoken_area_edit>img {
  display: none;
}

.favItem {
  /* background: #c2f8cf; */
  border: 3px solid #29aba4;
  border-radius: 15px;
  /* border-top: 20px solid #29aba4; */
  margin-top: 3px;
}

.speakerRemoveArea {
  /* 上要素にあわせるため、値が決め打ちになっています */
  position: absolute;
  right: -2px;
  background: #ffffff;
  border: 2px solid #dd4157;
  border-radius: 7px;
  margin: 15px 0px;
  padding: 10px 25px 15px;
  width: calc(50vw - 22px);
  height: max-content;
  display: flex;
  flex-direction: column;
}

.speakerRemoveAreaRow {
  width: 100%;
  height: max-content;
  margin-top: 5px;
}

.margeSpeakerSelect {
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
}

.dangericon {
  color: rgba(0, 0, 0, 0);
}

.dangericon:hover {
  color: #dd4157 !important;
}

ul.decisionList {
  padding: 0px;
  height: 50%;
  margin: 0px;
}

ul.decisionList>li {
  list-style-type: none;
  text-align: left;
  margin: 10px 3px;
}

ul.decisionList>li>input {
  font-size: 16px;
  padding: 3px;
  border: 0px;
  border-bottom: 1px solid #221816;
}

#decision_plus_icon {
  margin-top: 10px;
  margin-left: 5px;
}

.deleteTarget {
  border: 3px #dd4157 dotted;
  margin: -3px;
}

.openTabSelected {
  background: #cccccc;
  border-radius: 5px;
}

.isDataChanged {
  cursor: pointer;
  color: #dd4157;
  animation: isDataChanged 3s ease infinite;
}

@keyframes isDataChanged {
  0% {
    transform: translateY(0)
  }

  5% {
    transform: translateY(-10px)
  }

  10% {
    transform: translateY(0)
  }

  20% {
    transform: translateY(-10px)
  }

  25% {
    transform: translateY(0)
  }

  30% {
    transform: translateY(-10px)
  }

  50% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(0)
  }
}

.player {
  height: 5vh;
}

.syncTargetArea {
  animation: syncTargetAreaAnime 2s 1 alternate;
  margin: -3px;
}

@keyframes syncTargetAreaAnime {
  0% {
    background: rgba(150, 187, 255, 0.7);
  }

  20% {
    background: #ffffff
  }

  40% {
    background: rgba(150, 187, 255, 0.7);
  }

  60% {
    background: #ffffff
  }

  80% {
    background: rgba(150, 187, 255, 0.7);
  }

  100% {
    background: #ffffff
  }
}
</style>
<style>
/* このカレンダーウィジェットはグローバルに指定する必要がある */
.popupInputBox {
  font: 15px/24px sans-serif;
  box-sizing: border-box;
  transition: 0.3s;
  letter-spacing: 1px;
  color: #221816;
  border: 1px solid #1b2538;
  border-radius: 4px;
  width: max-content;
  height: max-content;
}

.calendar-theme .vdatetime-popup__header,
.calendar-theme .vdatetime-calendar__month__day--selected>span>span,
.calendar-theme .vdatetime-calendar__month__day--selected:hover>span>span {
  background: #29aba4;
}

.calendar-theme .vdatetime-year-picker__item--selected,
.calendar-theme .vdatetime-time-picker__item--selected,
.calendar-theme .vdatetime-popup__actions__button {
  color: #29aba4;
}

.text_edit_line {
  caret-color: tomato;
}
</style>