<template>
  <modal name="guide-modal" :draggable="false" :resizable="false" width="950px" height="70%">
    <div class="itemarea modalitemarea">
      <h3>{{ category_name}}</h3>
      <div class="content-wrapper" v-show="category_id == 1">
        <div v-show="contNo == 1">
          <h3>RECORDER STATIONの使い方</h3>
          <p>
            RECORDER STATIONを使用すると、コンピュータでより簡単に、クラウドスペース内のデータを整理することができます。クラウドスペースには、10GBの無料のストレージが用意されています。
          </p>
          <h4>1. ファイルをアップロードする</h4>
          <p>
            レコーダーからファイルをアップロードできます。
            <ul>
              <li>クラウドスペースにファイルをアップロードする</li>
            </ul>
          </p>
          <h4>2. www.recstation.jp にアクセスする</h4>
          <p>
            コンピュータでwww.recstation.jpを開きます。次の内容を表示する<b>「マイスペース」</b>にアクセスします。
            <ul>
              <li>レコーダーで録音したオーディオ</li>
              <li>レコーダーで撮影したビデオ、画像</li>
            </ul>
            ファイルを選択すると詳細画面を開くことができます。
            <ul>
              <li>生成した文字起こしテキスト</li>
            </ul>
            <img src="/guide_image/guide_1-1.png">
          </p>
          <h4>3. ファイルを共有、編集する</h4>
          <p>
            ファイルを共有したり、編集したりできます。
            <ul>
              <li>クラウドスペース内のファイルを管理する</li>
              <li>ファイルを共有する</li>
              <li>テキストファイルを編集、共有する</li>
            </ul>
          </p>
        </div>
        <div v-show="contNo == 2">
          <h3>推奨環境</h3>
          <p>
            RECORDER STATIONの現在のバージョンは、次のブラウザに対応しています。
            <ul>
              <li>Google Chrome</li>
            </ul>
            他のブラウザでも動作する場合がありますが、機能の一部を使用できない可能性があります。CookieとJavaScriptがオフになっている場合は、機能の一部を使用できない可能性があります。
          </p>
        </div>
        <div v-show="contNo == 3">
          <h3>クラウドスペース内のファイルを管理する</h3>
          <p>
            クラウドスペースのファイルを削除するには、削除アイコンを使用します。ファイルは完全に削除され、容量を確保することができます。一度に複数のファイルを削除する場合、変更が反映されるまでに時間がかかることがあります。
          </p>
          <h4>保存容量はお使いのアカウントに紐づいています</h4>
          <p>
            <b>注：</b>お使いのアカウントでクラウドスペースの保存容量の上限に達した場合、クラウドスペースへのアップロードができなくなります。
          </p>
          <h4>不要なファイルを削除する</h4>
          <p>
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>削除したいファイルのチェックボックスを選択します。</li>
              <li>削除アイコンを選択します。</li>
            </ol>
            <img src="/guide_image/guide_1-3-1.png">
            <b>TIP：</b>ファイルが完全に削除され、アカウントのクラウドスペースの容量が増えます。
          </p>
          <h4>保存容量を使用しない内容</h4>
          <p>
            レコーダーとクラウド間の通信には、クラウドスペースの保存容量を使用しません。クラウドスペースの空き容量にかかわらず、レコーダー上で文字起こしテキストを表示することができます。
          </p>
          <h4>ファイルを表示する</h4>
          <p>
            RECORDER STATION上では、オーディオ、ビデオ、テキスト、画像ファイルなどのコンテンツを表示、再生することができます。
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>メールアドレスとパスワードを使用してアカウントにログインします。</li>
              <li>表示したいファイルをクリックします。</li>
            </ol>
            <img src="/guide_image/guide_1-3-2.png">
          </p>
        </div>
      </div>

      <div class="content-wrapper" v-show="category_id == 2">
        <div v-show="contNo == 1">
          <h3>クラウドスペースにファイルをアップロードする</h3>
          <p>
            クラウドスペースではレコーダーで作成したファイルのアップロード、表示、共有、編集を行うことができます。クラウドスペースにファイルをアップロードすると、その容量がお客様のアカウントで使用されます。
          </p>
          <h4>ファイルの種類</h4>
          <p>
            <ul>
              <li>オーディオ</li>
              <li>ビデオ</li>
              <li>テキスト</li>
              <li>画像</li>
            </ul>
          </p>
          <h4>ファイルをアップロードする</h4>
          <p>
            レコーダーからファイルをアップロードできます。テキストおよび画像ファイルは、対応するオーディオ・ビデオをアップロードする際、一緒にアップロードされます。
            <ol>
              <li>レコーダーで<b>「ファイル」</b>を選択しファイルリストを表示します。</li>
              <li>アップロードするファイルを長押しして<b>「クラウド保存」</b>を選択します。</li>
              <li>※VOITER内で不要な場合は、削除アイコンを選択して削除します。</li>
            </ol>
            <img src="/guide_image/guide_2-1.png">
            <b>TIP：</b>ファイルリストを開き、右上の…アイコンから複数選択（全選択）をして一括アップロードをすることができます。
          </p>
        </div>

        <div v-show="contNo == 2">
          <h3>ファイルをダウンロードする</h3>
          <p>
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>ダウンロードしたいファイルのチェックボックスを選択します。</li>
              <li>ダウンロードアイコンを選択します。</li>
            </ol>
            <img src="/guide_image/guide_2-2.png">
            <b>TIP：</b>2つ以上のファイルを選択することで、複数のファイルを一括ダウンロードできます。
          </p>
          <p>
            <b>注：</b>ファイルはデスクトップに直接ドラッグできません。
          </p>
          <h4>ファイルをダウンロードできない場合</h4>
          <p>
            ファイルをダウンロードできない場合は、ブラウザでCookieが無効になっていないか確認してください。RECORDER STATIONでは、機能を提供するためにCookieを使用します。お使いのブラウザでCookieをブロックすると、RECORDER STATIONからファイルをダウンロードできなくなることがあります。
          </p>
        </div>

        <div v-show="contNo == 3">
          <h3>サポートされているファイル形式</h3>
          <p>
            RECORDER STATIONで保存、表示、共有、ダウンロードできるファイル形式は次のとおりです。
            <ul>
              <li>オーディオファイル（.WAV）</li>
              <li>画像ファイル（.JPEG）</li>
              <li>テキストファイル（.TXT）</li>
              <li>ビデオファイル（.MPEG4）</li>
            </ul>
          </p>
        </div>

        <div v-show="contNo == 4">
          <h3>ファイルを共有する</h3>
          <p>
            RECORDER STATIONに保存しているファイルは、メールで簡単に共有することができます。
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>共有したいファイルのチェックボックスを選択します。</li>
              <li>共有アイコンを選択します。</li>
              <li>ダイアログに共有先のメールアドレスを入力します。</li>
            </ol>
            <img src="/guide_image/guide_2-4.png">
            <b>TIP：</b>2つ以上のファイルを選択することで、複数のファイルを共有できます。
          </p>
        </div>
      </div>

      <div class="content-wrapper" v-show="category_id == 3">
        <div v-show="contNo == 1">
          <h3>クラウドスペースのファイルを削除する</h3>
          <p>
            クラウドスペースのファイルを削除するには、削除アイコンを使用します。一度に複数のファイルを削除する場合、変更が反映されるまでに時間がかかることがあります。ファイルを削除する際は、次の点にご留意ください。
            <ul>
              <li>ファイルの削除を選択すると、一度で完全に削除され、復元できません。</li>
              <li>ファイルが削除されると、以降そのファイルにアクセスできなくなります。</li>
            </ul>
          </p>
          <h4>ファイルを削除する</h4>
          <p>
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>削除したいファイルのチェックボックスを選択します。</li>
              <li>削除アイコンを選択します。</li>
            </ol>
            <b>TIP：</b>2つ以上のファイルを選択することで、複数のファイルを削除できます。
          </p>
        </div>
        <div v-show="contNo == 2">
          <h3>テキストファイルを編集、共有する</h3>
          <p>
            RECORDER STATIONを使用して、テキストファイルの編集、保存、ダウンロード、共有を行うことができます。
          </p>
          <h4>RECORDER STATIONでテキストファイルを編集、保存する</h4>
          <p>
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>編集したいテキストファイルを選択します。</li>
              <li>画面中央に表示された「編集」タブをクリックします。</li>
              <li>テキストを編集します。</li>
              <li><b>「保存する」</b>を押すと編集内容を保存します。</li>
            </ol>
            <img src="/guide_image/guide_3-2-1.png">
          </p>
          <h4>保存したテキストファイルをダウンロード、共有する</h4>
          <p>
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>共有したいテキストファイルを選択します。</li>
              <li>画面右上の共有アイコンをクリックします。</li>
              <li>ダイアログに共有したいメールアドレスを入力します。</li>
              <li>共有したいファイルを選択します。</li>
              <li><b>「送信」</b>を選択すると、ファイルのダウンロード招待メールが送信されます。</li>
            </ol>
          </p>
          <h4>編集前のテキストファイルを表示する</h4>
          <p>
            テキストファイルの編集内容を保存しても、編集前のテキストファイルはオリジナルとして別タブで保持されます。テキストエリア上部のタブを切り替えることで、オリジナルを表示することができます。
          </p>
        </div>
        <div v-show="contNo == 3">
          <h3>ファイルを検索する</h3>
          <p>
            RECORDER STATIONでは、次の項目でファイルを検索できます。
            <ul>
              <li>ファイル形式</li>
              <li>日時</li>
              <li>ファイル名</li>
            </ul>
            <img src="/guide_image/guide_3-3-1.png">
          </p>
          <h4>フィルターで簡単にファイルを探す</h4>
          <p>
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>左側のナビゲーションエリアから探すファイルの形式を選択します。</li>
              <li>フィルターがかけられ、選んだ形式のファイルのみが表示されます。</li>
           </ol>
          </p>
          <h4>検索ボックスでファイルを探す</h4>
          <p>
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>上部の検索ボックスに語句を入力します。</li>
              <li>キーボードの<b>Enter</b>を押します。</li>
            </ol>
          </p>
          <h4>ファイル名、形式、日時で検索する</h4>
          <p>
            検索結果を絞り込んで検索すると、クラウドスペース内のファイルを見つけやすくなります。
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>検索ボックスの右端のアイコンを選択します。</li>
              <li>ファイル名、形式、日時を任意で設定します。</li>
              <li>検索する語句を入力します。</li>
              <li><b>「検索」</b>をクリックします。</li>
            </ol>
            <img src="/guide_image/guide_3-3-2.png">
          </p>
        </div>
        <div v-show="contNo == 4">
          <h3>ファイル名を編集する</h3>
          <p>
            <ol>
              <li>コンピュータでwww.recstation.jpを開きます。</li>
              <li>編集したいファイルを選択します。</li>
              <li>画面右上の編集アイコンを選択します。</li>
              <li>ダイアログにファイル名を入力して保存します。</li>
            </ol>
            <img src="/guide_image/guide_3-4.png">
          </p>
        </div>
      </div>

      <div class="content-wrapper" v-show="category_id == 4">
        <div v-show="contNo == 1">
          <h3>レコーダーの通信に関する問題を修正する</h3>
          <p>
            レコーダーとクラウドスペース間でのやり取りに問題がある場合は、以下の方法をお試しください。
          </p>
          <h4>アップロードしてもRECORDER STATIONに反映されない</h4>
          <p>
            レコーダーからアップロードすると、データは即時に反映されます。
            RECORDER STATIONのファイル一覧画面で再検索を行うと、新しくアップロードしたデータが表示されます。
          </p>
          <h4>レコーダーからクラウドスペースへのアップロードに失敗する</h4>
          <p>
            クラウドスペースの保存容量が少なくなると、アップロードできなくなることがあります。クラウドスペース内のファイルを管理するをご確認ください。
          </p>
          <!--
          <h4>○○○○</h4>
          <p>
            テキストテキストテキスト。
          </p>
          <h4>発生する可能性があるエラーメッセージ</h4>
          <p>
            <ul>
              <li>エラーメッセージ：解決方法リンク</li>
            </ul>
          </p>
          -->
        </div>
        <div v-show="contNo == 2">
          <h3>共有メールが届かない場合</h3>
          <p>
            RECORDER STATIONからファイルを共有する際にメールが受信できない場合、お使いのメーラーの受信設定に原因がある可能性があります。メールの受信設定より、末尾「@recstation.jp」からのメールを受信拒否に設定していないか、ご確認ください。
          </p>
        </div>

        <div v-show="contNo == 3">
          <h3>アカウントにログインできない</h3>
          <p>
            <ol>
              <li>パスワードを忘れてしまった場合は、ログイン画面からパスワードの再発行を行ってください。</li>
              <li>メールアドレスがわからない、アカウントを使用できないなどでお困りの場合は、弊社サービス窓口（support@recstation.jp）までご連絡ください。</li>
            </ol>
            <img src="/guide_image/guide_4-3.png">
          </p>
        </div>
      </div>

    </div>
    <div class="leftarrow handcursor" @click="backcontents" v-show="1 < contNo">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div class="rightarrow handcursor" @click="nextcontents" v-show="contNo < content_max">
      <i class="fas fa-chevron-right"></i>
    </div>
    
    <div class="ta-c backmbutton">
      <span v-show="isOpen" class="mbutton canselmbutton handcursor" @click="closeModal">一覧へ戻る</span>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'GuideModal',
  components: {
  },
  props: {
    category_id: Number,
    content_id: Number,
    category_name: String,
    content_max: Number,
  },
  data: function () {
    return {
      page: 0,
      isOpen: false
    }
  },
  computed:{
    contNo(){
      return this.content_id + this.page
    }
  },
  created(){
  },
  mounted(){
    this.isOpen = true
  },
  watch: {
  },
  methods: {
    backcontents(){
      this.page--
    },
    nextcontents(){
      this.page++
    },
    closeModal(){
      this.page = 0
      this.$modal.hide('guide-modal');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img{
  width: 800px;
}

.modalitemarea{
  height: 100%;
  padding: 20px 35px;
  background: #f0f8ff;
}
.backbutton{
  position: absolute;
  bottom: 20px;
  left: calc(50% - 100px);
}
.content-wrapper{
  background: #ffffff;
  overflow: scroll;
  height: calc(70vh - 205px);
  padding: 15px;
}
.content-wrapper h4{
  margin-top: 50px;
}
.leftarrow{
  position: absolute;
  bottom: 30vh;
  left: 10px;
  padding: 20px 5px;
  font-size: 20px;
  color: #808080;
}
.rightarrow{
  position: absolute;
  bottom: 30vh;
  right: 10px;
  padding: 20px 5px;
  font-size: 20px;;
  color: #808080;
}
.backmbutton{
  position: absolute;
  bottom: 20px;
  left: 375px;
}
/* ボタン */
.mbutton {
  display: inline-block;
  width: 200px;
  height: 54px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  line-height: 54px;
  outline: none;
  font-size: 14px;
}
.mbutton::before,
.mbutton::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: "";
}
.mbutton,
.mbutton::before,
.mbutton::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.mbutton {
  /* 初期デザインカラー
  background-color: #1e90ff;
  border: 2px solid #1e90ff;
  */
  background-color: #68CFC3;
  border: 2px solid #68CFC3;
  color: #fff;
  line-height: 50px;
  cursor: pointer;
}
.mbutton:hover {
  background-color: #fff;
  /* 初期デザインカラー
  border-color: #009999;
  color: #009999;
  */
  border-color: #68CFC3;
  color: #68CFC3;
}
.canselmbutton {
  background-color: #ffffff;
  border: 2px solid #808080;
  color: #808080;
}
.canselmbutton:hover {
  background-color: #fff;
  /* 初期デザインカラー
  border-color: #009999;
  color: #009999;
  */
  border-color: #68CFC3;
  color: #68CFC3;
}

</style>
