<template>
  <div class="home_bg">
    <Header pagename="User" />
    <div class="contentarea">
      <MainNavi pagename="user" />
      <div class="maincontent">
        <div class="documentcontent">
          <h2 style="text-align: center;">アカウント情報の確認/変更</h2>

          <!-- 固定表示 -->
          <div class="userinfoarea" v-show="isViewMode">
            <table>
              <tr>
                <td class="itemheader">登録アドレス</td>
                <td>{{ userdata.email }}</td>
              </tr>
              <tr>
                <td>ユーザ名</td>
                <td>{{ userdata.userName ? userdata.userName : "未設定" }}</td>
              </tr>
              <tr>
                <td>生年月日</td>
                <td>{{ viewDate }}</td>
              </tr>
              <tr>
                <td>性別</td>
                <td>{{ viewSex }}</td>
              </tr>
              <tr>
                <td>利用地域</td>
                <td>{{ userdata.region ? userdata.region : "未設定" }}</td>
              </tr>
              <tr>
                <td>業種</td>
                <td>{{ userdata.industry ? userdata.industry : "未設定" }}</td>
              </tr>
              <tr>
                <td>契約プラン</td>
                <td>{{ userPlan.planName }}</td>
              </tr>
            </table>
            <div class="ta-c buttonarea">
              <span class="button" @click="changeEditMode">編集</span>
            </div>
            
            <span class="button canselbutton mr-5 attentionbutton" @click="showDeleteWindow">ユーザ削除</span>

          </div>

          <!-- 編集表示 -->
          <div class="userinfoarea" v-show="isEditMode">
            <table>
              <tr>
                <td class="itemheader">登録アドレス</td>
                <td>{{ editdata.email }}
                  <span class="button smallbutton ml-20" @click="openMailSettingWindow">
                    登録アドレスを変更
                    </span>
                  </td>
              </tr>
              <tr>
                <td>ユーザ名</td>
                <td>
                  <input v-model="editdata.userName" type="text" class="nameinput"/>
                  <span v-show="validatemessage">
                    <br/>
                    <span class="errortext">{{validatemessage}}</span>
                  </span>
                </td>
              </tr>
              <tr>
                <td>生年月日</td>
                <td>
                  <datepicker 
                    v-model="dpdate" 
                    :language="ja"
                    :format="DatePickerFormat">
                  </datepicker>
                </td>
              </tr>
              <tr>
                <td>性別</td>
                <td>
                  <select v-model="editdata.sex">
                    <option value="00">未選択</option>
                    <option value="01">男性</option>
                    <option value="02">女性</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>利用地域</td>
                <td>
                  <select v-model="editdata.region">
                    <option value="">未選択</option>
                    <option v-for="option in regionOptions" v-bind:value="option.name" v-bind:key="option.id">
                        {{ option.name }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>業種</td>
                <td>
                  <select v-model="editdata.industry">
                    <option value="">未選択</option>
                    <option v-for="option in industryOptions" v-bind:value="option.name" v-bind:key="option.id">
                        {{ option.name }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>契約プラン</td>
                <td>{{ userPlan.planName }}
                  <span class="button smallbutton ml-20" @click="pushPage({name: 'Plan'})">
                    プラン変更画面へ
                    </span>
                  </td>
              </tr>
            </table>

            <div class="ta-c buttonarea">
              <span class="button canselbutton mr-5" @click="changeViewMode">キャンセル</span>
              <span class="button" @click="saveData" v-bind:disabled="isProcessing()">変更</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainNavi from '@/components/MainNavi.vue'
import processing from '@/mixins/processing.js'
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import axios from 'axios'
import Swal from 'sweetalert2';
import datepicker from 'vuejs-datepicker'
import { ja } from 'vuejs-datepicker/dist/locale'
import _ from 'lodash'

export default {
  name: 'User',
  props: {
    pagename: String
  },
  mixins: [utils, api, processing],
  components: {
    Header,
    Footer,
    MainNavi,
    datepicker
  },
  data: function() {
    return {
      isViewMode: false,
      isEditMode: false,
      userid: 111111,
      userdata: {
        userid: '',
        email: '',
        userName: '',
        dateOfBirth: '', // yyyymmdd
        sex: '',
        region: '',
        industry: '',
        planname: '',
        version: ','
      },
      editdata: {
        userid: '',
        email: '',
        userName: '',
        dateOfBirth: '',
        sex: '',
        industry: '',
        region: '',
        planname: '',
        version: '',
      },
      dpdate: "", // 2020-12-31 00:00:00
      ja: ja,
      DatePickerFormat: 'yyyy/MM/dd',
      regionOptions: this.getRegions(),
      industryOptions: this.getIndustries(),
      validatemessage: "",
      userPlan: {
        planId: 1,
        planName: "プラン名称",
        planExpireDate: "2024-03-31 23:59:59",
        nextPlan: 0,
        nextPlanName: "次のプラン名称",
        nextPlanApplicableDate: "2024-03-31 23:59:59",
      }
    }
  },
  computed: {
    viewDate: function(){
      var viewDate = "未設定"
      if(this.userdata.dateOfBirth){
        const y = Number(this.userdata.dateOfBirth.substr(0, 4))
        const m = Number(this.userdata.dateOfBirth.substr(4, 2))
        const d = Number(this.userdata.dateOfBirth.substr(6, 2))
        viewDate = y + "/" + m + "/" + d
      }
      return viewDate
    },
    viewSex(){
      var result = "未選択";
      if(this.userdata.sex == '01'){
        result = "男性"
      }else if(this.userdata.sex == '02'){
        result = "女性"
      }
      return result;
    }
  },
  created(){
    this.isViewMode = true;
  },
  mounted(){
    this.setUserData();
    this.getUserPlan()
  },
  methods: {
    setUserData(){
      axios(this.getAxConf("get", "/users/data", {}, this.$store.state.token))
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data
          this.userdata = {
            email: data.email ? data.email : "",
            userName: data.userName ? data.userName : "",
            dateOfBirth: data.birth ? data.birth : "",
            sex: data.sex ? data.sex : "00",
            industry: data.industry ? data.industry : "",
            region: data.ipRegion ? data.ipRegion : "",
            version: data.version ? data.version : "0",
            planname: '使い放題プラン（無料特典）' // 今は全員ベーシックプラン
          }
        }else{
          return this.showErrorWindow(response)
        }
      })
      .catch((err) => {
        console.log(err);
        return this.showNetworkErrorWindow(err);
      });
    },
    getUserPlan(){
      const url = "/users/plan";
      axios(this.getAxConf("get", url, {}, this.$store.state.token))
      .then((result)=>{
        if(result.data.success){
          this.userPlan = result.data.data
        }else{
          this.userPlan.planName = "取得できませんでした"
        }
      })
      .catch((err) => {
        console.log(err)
        this.userPlan.planName = "取得できませんでした"
      });
    },
    changeEditMode(){
      this.editdata = _.cloneDeep(this.userdata)
      this.dpdate = this.numdate2Date(this.editdata.dateOfBirth);
      this.isViewMode = false;
      this.isEditMode = true;
    },
    
    /**
     * 日付変換Util
     * 多分この２つだけでいいはず
     */
    numdate2Date(numDate){
      var y, m, d
      if(numDate){
        y = Number(numDate.substr(0, 4))
        m = Number(numDate.substr(4, 2)) - 1
        d = Number(numDate.substr(6, 2))
      }else{
        y = 1990
        m = 4
        d = 1
      }
      return new Date(y, m , d)
    },
    date2Numdate(date){
      var numdate = "";
      if(date){
        var year = date.getFullYear();
        var month = 1 + date.getMonth();
        var day = date.getDate();
        month = ('0' + month).slice(-2);
        day = ('0' + day).slice(-2);
        numdate = year + month + day;
      }
      return numdate
    },

    // TODO: 日付周りがごちゃごちゃしてるが、これは日付の仕様変更によるもの。直したい…
    /**
     * @param {String} 20201231
     * @returns {String} 2000/12/31
     */
    numDate2PuncDate(numDate){
      var y, m, d
      if(numDate){
        y = numDate.substr(0, 4)
        m = numDate.substr(4, 2)
        d = numDate.substr(6, 2)
      }else{
        y = '1990'
        m = '04'
        d = '01'
      }
      return y + '/' + m + '/' + d
    },
    /**
     * @param {String} 2000/12/31
     * @returns {String} 20201231
     */
    puncDate2NumDate(puncDate){
      const dd = puncDate.split('/');
      return dd[0] + dd[1] + dd[3]
    },
    /**
     * @param {String} 2000/12/31
     * @returns {Date} 2020-12-31 00:00:00
     */
    getDate(daystr){
      var dpdate;
      if(daystr){
        const dd = daystr.split('/');
        dpdate = new Date(dd[0], dd[1] -1 , dd[2])
      }else{
        // 空欄の場合は適当に1990/5/1固定
        dpdate = new Date(1990, 4, 1)
      }
      return dpdate
    },
    /**
     * @param {Date} 2020-12-31 00:00:00
     * @returns {String} 2000/12/31
     */
    getDatestr(date){
      var datestr = "";
      if(date){
        var year = date.getFullYear();
        var month = 1 + date.getMonth();
        var day = date.getDate();
        month = ('0' + month).slice(-2);
        day = ('0' + day).slice(-2);
        // datestr = year + '/' + month + '/' + day;
        datestr = year + month + day;
      }
      return datestr
    },
    changeViewMode(){
      this.isViewMode = true;
      this.isEditMode = false;
      this.validatemessage = ""
    },
    saveData(){
      this.startProcessing()

      this.validatemessage = ""
      const uname = this.editdata.userName
      if(uname){
        if(!this.isPermissibleName(uname)){
          this.validatemessage = "_以外の記号や、特殊な文字は使用できません。"
          return false
        }
      }else{
        this.validatemessage = "１文字以上入力してください。" 
        return false 
      }

      var data = {
        "birth": this.date2Numdate(this.dpdate),
        "industry": this.editdata.industry,
        "ipRegion": this.editdata.region,
        "sex": this.editdata.sex,
        "userName": this.editdata.userName,
        "version": this.editdata.version,
      }

      axios.put(this.getURL('/users/data'), data, this.getHeaders(this.$store.state.token))
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            confirmButtonColor : '#29aba4',
            animation : false,
            html: "<p>更新しました。</p>",
            confirmButtonText: "OK",
            confirmButtonAriaLabel: "OK",
          });
          this.editdata.version++; 
          this.editdata.dateOfBirth = this.date2Numdate(this.dpdate)
          this.userdata = this.editdata;
          this.changeViewMode();
        }else{
          this.showErrorWindow(response)
        }
        this.endProcessing()
      })
      .catch((err) => {
        console.log(err);
        this.endProcessing()
        this.showNetworkErrorWindow(err)
      });
    },
    openMailSettingWindow(){
      Swal.fire({
        confirmButtonColor : '#29aba4',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        html: "<h4>新しいメールアドレスを入力してください。</h4>",
        input: "text",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "認証コードを送信",
        confirmButtonAriaLabel: "認証コードを送信",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              if (this.isEmail(value)) {
                resolve()
              } else {
                resolve('メールアドレスの形式で入力してください。')
              }
            } else {
              resolve('メールアドレスを入力してください。')
            }
          })
        },
        preConfirm: (value) => {
          const data = { mail: value };
          return axios.post(this.getURL('/users/code'), data, this.getHeaders(this.$store.state.token))
          .then((response) => {
            if (response.data.success) {
              this.email = value
              // return response.json(); 
            }else{
              return Swal.showValidationMessage(response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            confirmButtonColor : '#29aba4',
            cancelButtonColor : '#808080',
            reverseButtons : true,
            animation : false,
            title: `メールアドレスを確認します。`,
            html:
              "<h4>認証コードを送信しました。</h4>" +
              "<p>認証コード</p>",
            input: "text",
            showCancelButton: true,
            confirmButtonText: "次へ",
            confirmButtonAriaLabel: "次へ",
            cancelButtonText: "キャンセル",
            cancelButtonAriaLabel: "キャンセル",
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value) {
                  if (this.isAuthNum(value)) {
                    resolve()
                  } else {
                    resolve('数字４桁の認証コードを入力してください。')
                  }
                } else {
                  resolve('数字４桁の認証コードを入力してください。')
                }
              })
            },
            preConfirm: (value) => {
              var data = {
                code: value,
                mail: result.value
              }
              return axios.post(this.getURL('/users/verificationCode'), data, this.getHeaders(this.$store.state.token))
              .then((response) => {
                if (response.data.success) {

                  const data ={
                    email: result.value,
                    version: this.editdata.version
                  }
                  axios.put(this.getURL('/users/address'), data, this.getHeaders(this.$store.state.token))
                  .then((response) => {
                    if (response.data.success) {
                      this.editdata.version++
                      this.editdata.mail = result.value
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
                  });
                }else{
                  return Swal.showValidationMessage(response.data.message);
                }
              })
              .catch((err) => {
                console.log(err);
                return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
              });
            },
          }).then((result) => {
            if (result.isConfirmed) {

              Swal.fire({
                confirmButtonColor : '#29aba4',
                animation : false,
                html: 
                  "<p>メールアドレスを更新しました。ログイン画面に戻ります。</p>" + 
                    "<p>レコーダーにログイン中の場合は、<br />一度ログアウトしてからご利用ください。</p>",
                confirmButtonText: "OK",
                confirmButtonAriaLabel: "OK",
              }).then(res => {
                if(res.isConfirmed){
                  // トークンを廃棄する処理
                  localStorage.setItem('pageName', "")
                  localStorage.setItem('token', "")
                  this.pushPage({name: 'Login'})
                }
              });
            }
          });
        }
      });
    },
    showDeleteWindow(){
      Swal.fire({
        reverseButtons : true,
        animation : false,
        icon: 'info',
        title: '本当に削除してよろしいですか？',
        text: '一度削除すると全てのデータが完全に消去され、元に戻すことはできません。',
        showCancelButton: true,
        confirmButtonColor: '#dd4157',
        //cancelButtonColor: '',
        confirmButtonText: '削除',
        cancelButtonText: '戻る'
      })
      .then((result) => {
        if (result.isConfirmed) {
          // ユーザデータのバージョンを取得して削除実行
          axios(this.getAxConf("get", "/users/data", {}, this.$store.state.token))
          .then((response) => {
            if (response.data.success) {
              const version = response.data.data.version ? response.data.data.version : 0
              this.deleteUserData(version);
            }else{
              return this.showErrorWindow(response)
            }
          })
          .catch((err) => {
            this.showNetworkErrorWindow(err)
          });
        }
      })
    },
    deleteUserData(version){
      const data = {
        "userVersion": version
      }
      axios(this.getAxConf("delete", "/users/data", data, this.$store.state.token))
      .then((response) => {

        if (response.data.success) {
          this.$store.commit('setToken', "")
          localStorage.setItem('pageName', "")
          localStorage.setItem('readcount', "")
          localStorage.setItem('token', "");
          
          Swal.fire({
            confirmButtonColor : '#29aba4',
            animation : false,
            title: 'ご利用ありがとうございました',
            confirmButtonText: 'ログイン画面に戻る',
            allowOutsideClick : false,
          }).then(()=>{
            this.pushPage({name: 'Login'})
          })
        }else{
          this.showErrorWindow(response)
        }
      })
      .catch((err) => {
        console.log(err);
        this.showNetworkErrorWindow(err)
      });
    }
  },

}
</script>

<style>
.userinfoarea {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  padding: 20px;
  background: #f0f8ff;
}
.userinfoarea table {
  width: 100%;
}
.userinfoarea td {
  padding: 10px;
}
.itemheader{
  width: 140px;
}
.userinfoarea input,
.userinfoarea select {
  padding: 5px;
  width: 100%;
}
.userinfoarea .storagearea {
  margin: 50px 0px;
  padding-top: 20px;
}
.userinfoarea .storagetext {
  font-size: 20px;
}
.buttonarea {
  margin: 40px;
}
.ml-20{
  margin-left: 20px;
}
input.nameinput{
  width: calc(100% - 14px);
}

</style>
