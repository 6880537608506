<template>
  <div>
    <Loading />
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import api from '@/mixins/api.js'
import utils from '@/mixins/utils.js'
import axios from 'axios'

export default {
  name: 'Controller',
  props: {
  },
  mixins: [api, utils],
  components: {
    Loading,
  },
  data: function() {
    return {
    }
  },
  created(){
  },
  mounted(){
    this.recoveryToken()
  },
  methods: {
    recoveryToken(){
      const token = localStorage.getItem('token')
      //const pageName = localStorage.getItem('pageName')

      if(token){
        axios(this.getAxConf("get", "/notice", {}, token))
        .then((response) => {
          if(response.data.code == '500'){
            return this.pushPage({name: 'Login'})
          }
          // if(pageName){
          //   const data = {
          //     name: pageName, 
          //     params:{
          //       token: token
          //     }
          //   }
          //   return this.pushPage(data)
          // }else{
          //   return this.pushPage({name: 'Login'})
          // }
          return this.pushPage({name: 'Home'})
        })
        .catch((err) => {
          console.log(err);
          return this.pushPage({name: 'Login'})
        });

      }else{
        return this.pushPage({name: 'Login'})
      }
    },
    
  },
}

</script>

<style>
</style>


