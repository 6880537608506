<template>
  <!-- <footer>
      <div class="footer_inner">
        <div>
          <a href="https://www.iflytek.co.jp/policies/terms_sr502j/" target="_blank">利用規約</a>
          <a href="https://www.iflytek.co.jp/policies/privacy_sr502j/"  target="_blank">プライバシーポリシー</a>
          <a href="https://www.iflytek.co.jp/company/" target="_blank">運営会社</a>
          <a href="https://www.iflytek.co.jp/policies/notation_sr502j/" target="_blank">特定商取引法に基づく表記</a>
        </div>
        <div class="copyright">
          ©2021 iFLYTEK JAPAN AI SOLUTIONS. All Rights Reserved.
        </div>
      </div>
    </footer> -->
    <div></div>
</template>

<script>
import utils from '@/mixins/utils.js'
export default {
  name: 'Footer',
  props: {
  },
  mixins: [utils],
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  /* position: fixed;
  bottom: 0px;
  padding: 5px;
  width: 100%;
  border-top: solid 1px #dcdcdc; */
  width: 100%;
  height: 3%;
  background: #13618f;
  font-size: 10px;
  color: #ffffff;
}
.footer_inner {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.footer_inner > div {
  display: inline-block;
}
footer a {
  margin-right: 10px;
}
footer a:link,
footer a:visited,
footer a:hover,
footer a:active {
  color: #ffffff;
}
.copyright {
  margin-left: auto;
  margin-right: 10px;
}
</style>
