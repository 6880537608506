<template>
  <div class="home_bg">
    <Header pagename="Faq" />
    <div class="contentarea">
      <MainNavi pagename="faq" />
      <div class="maincontent">
        <div class="documentcontent">

        <h3>よくあるご質問</h3>

        <h4>VOITERデバイス</h4>
        <!--
        <input id="acd-check1" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check1"
          >Q. ノイズキャンセル機能はありますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
		あります。
          </p>
          <p>
		AGC（自動ゲイン制御）による雑音処理で空調やファンの音などのノイズを除去しクリアに録音します。
          </p>
        </div>
        -->
        <div style="overflow-y: auto; height:95vh;">
        <input id="acd-check2" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check2"
          >Q. ノイズ除去の効果はどのくらいですか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            全12項目のノイズを選択的に除去するインテリジェントアクティブノイズリダクション技術「Focus++」を採用し、あらゆるシーンでの録音に対応しています。
          </p>
          <p>
            録音品質をリアルタイムに自動識別しアルゴリズムを変えることで、2つの指向性マイクと6つの無指向性マイクの収音における役割を変化させ、環境による録音品質のバラつきを低減しています。
          </p>
        </div>
        <!--
        <input id="acd-check3" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check3"
          >Q. 連続で録音/撮影できる時間はどれくらいですか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            録音：10時間以上です（省電力モードの場合）。
          </p>
          <p>
            撮影：●時間です。
          </p>
          <p>
            *上記は実験室での試験データであり、実際の利用環境により異なる場合があります。
          </p>
        </div>
        <input id="acd-check4" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check4"
          >Q. 電源を入れたままの状態で使用せずに置いておくと、バッテリーはどのくらいもちますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            約●●時間です。
          </p>
        </div>
        -->
        
        <input id="acd-check5" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check5"
          >Q. 録音/撮影できる上限ファイル数を教えてください。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            VOITERデバイスのストレージは16GBです。ファイル数に上限はありません。
          </p>
        </div>
        
        <input id="acd-check6" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check6"
          >Q. 同一のオーディオファイルに続けて録音できますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            一時停止中であれば同一のオーディオファイルで録音を再開できます。
          </p>
          <p>
            一時停止するには画面下の中央にある赤いボタンをタップしてください。一時停止中は、録音時と同じように画面上部の赤いランプが点滅します。
          </p>
          <p>
            一度オーディオファイルを保存した場合は新しい録音を開始してください。
          </p>
        </div>
        
        <input id="acd-check7" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check7"
          >Q. 録音レベルの切り替えはできますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            録音中に画面上部に表示される「マイク音量」をタップして、録音レベルを調節することができます。
          </p>
        </div>
        
        <input id="acd-check8" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check8"
          >Q. イヤホンは使用できますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            Bluetoothイヤホンを使用できます。Bluetoothデバイスの接続の仕方は<a href="https://www.recstation.jp/resources/502j_usermanual.pdf" target="_blank" class="handcursor linklike">こちら</a>をご確認ください。
          </p>
          <p>
            レコーダーにはイヤホンジャックは搭載されていません。
          </p>
        </div>
        
        <!--
        <input id="acd-check9" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check9"
          >Q. 充電にはどれぐらい時間がかかりますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            約●●分です。5分間の充電で2時間録音できる急速充電にも対応しています。
          </p>
          <p>
            *付属のUSBケーブルを使用して充電した場合。使用環境や設定などにより変動します。
          </p>
        </div>
        -->
        
        <input id="acd-check10" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check10"
          >Q. レコーダーが充電できません。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            レコーダーにUSBケーブルを挿入しても充電できない場合、デバイスの接続端子に原因がある可能性があります。以下の手順で確認してください。
            <ol>
              <li>USBケーブルを通電している電源プラグに挿入する</li>
              <li>USBケーブルを本体内部まで挿入する</li>
              <li>付属品以外のUSBケーブルを使用する（USBケーブルの新品交換はいたしかねますので、予めご了承ください。）</li>
            </ol>
            上記をお試しになった後も問題が解決しない場合は、
            <span class="linklike handcursor" @click="pushPage({name: 'Contact'})">こちら</span>
            からカスタマーサポートまで連絡してください。
          </p>
        </div>
        
        <input id="acd-check11" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check11"
          >Q. 同梱物は何ですか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            VOITERデバイスの同梱物は以下の通りです。
            <ul>
              <li>本体</li>
              <li>USB Type-Cケーブル</li>
              <li>SIMカードピックピン</li>
              <li>ハードウェア保証書</li>
            </ul>
          </p>
        </div>
        
        <input id="acd-check12" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check12"
          >Q. レコーダーから外部にファイルを出力することはできますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            以下の3通りの出力ができます。
            <ol>
              <li>
                レコーダーからメール共有する：<br />
                共有したいファイルを開き、ナビゲーションバーの「メール共有」をタップしてください。共有する形式を選択し、共有先のメールアドレスを入力してください。
              </li>
              <li>
                クラウドスペースからダウンロードする：<br />
                詳しくは操作ガイドのファイルをダウンロードするをご覧ください。
              </li>
              <li>
                コンピュータから直接アクセスする：<br />
                電源を入れた状態のレコーダーをUSBケーブルでお使いのコンピュータに接続し、レコーダーのロックを解除します。表示されるアクセス要求のポップアップウィンドウで「許可」を選択すると、コンピューターからレコーダー内のファイルを表示することができます。
              </li>
            </ol>
          </p>
        </div>
        
        <input id="acd-check13" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check13"
          >Q. ネットワークに繋がりません。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            ネットワーク接続にエラーが発生する場合、以下の手順で確認してください。
            <ol>
              <li>
                4GまたはWi-Fiネットワークの電源が入っていない場合は、4Gカードを挿入したか、Wi-Fiネットワークの電源を入れたかを確認してください。
              </li>
              <li>
                SIMカードを挿入している場合は、お使いのSIMの残りの通信量をご確認ください。
              </li>
              <li>
                Wi-Fiが故障している可能性があります。Wi-Fiネットワークが動作しているか確認してください。
              </li>
            </ol>
            上記をお試しになった後も問題が解決しない場合は、
            <span class="linklike handcursor" @click="pushPage({name: 'Contact'})">こちら</span>
            からカスタマーサポートまで連絡してください。
          </p>
        </div>
        
        <input id="acd-check14" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check14"
          >Q. オーディオ、ビデオ、テキストの形式を教えてください。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            取り扱う各ファイルの形式は以下です。
            <ul>
              <li>オーディオ：WAV, AAC</li>
              <li>ビデオ：mp4</li>
              <li>テキスト：txt</li>
            </ul>
            WAVファイルはVOITER本体に保存されます。付属のUSBでPCに接続し、取得することができます。
          </p>
        </div>
        
        <input id="acd-check14-1" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check14-1"
          >Q. 録音中に画面が真っ黒になってしまいますが、途中までのデータは保存されますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            録音は継続しておりますので、保存ボタンを押せば、データは保存されます。
          </p>
          <p>
            画面が暗くなる理由は、自動ロックの設定によるものです。<br />
            設定されている時間内に操作を行わなかった場合、節電のため、画面が暗く表示される仕様になっております。<br />
            自動ロックの時間設定を変更されたい場合、以下の手順で変更お願いいたします。
          </p>
          <ol>
            <li>
              画面左下の「アカウント」タブを開く
            </li>
            <li>
              画面右上の「ギアアイコン」を押す
            </li>
            <li>
              「プライバシー」を押す
            </li>
            <li>
              時間を指定する
            </li>
          </ol>
          <p>
            最長時間は、20分となっております。<br />
            とくに操作をしなければ最大20分まで画面の表示を継続することができます。
            また、途中で画面に触れたりすると、自動ロックまでの時間が延長されます。
          </p>
        </div>

        <input id="acd-check14_2" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check14_2"
          >Q. パスコードを忘れた場合はどうすればよいですか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            VOITERデバイスの画面ロックで使用する４桁のパスコードを忘れてしまった場合は、以下の手順をお試しください。
            <ol>
              <li>パスコード入力画面の左下の「忘れた場合」を選択する。</li>
              <li>最後にログインしていたアカウントのメールアドレスを入力し、リセットを選択する。</li>
              <li>メールアドレスが正しければ、再起動するかを確認されるので、「はい」を選択する。</li>
              <li>パスコードロックが解除された状態で再起動します。必要に応じて、再度パスコードを設定してください。</li>
              <li>どうしても解除できない場合は、<span class="linklike handcursor" @click="pushPage({name: 'Contact'})">こちら</span>
            からカスタマーサポートまで連絡してください。ご登録いただいている情報で照合し、お手続きをさせていただきます。</li>
            </ol>
          </p>
        </div>

        <h4>Webアプリ</h4>
        <input id="acd-check15" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check15"
          >Q. RECORDER STATIONとはどのようなものですか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            お使いのコンピュータからレコーダーで録音したオーディオやビデオ、文字起こしテキストを管理できるWebアプリです。
          </p>
          <p>
            オーディオファイル、ビデオファイル、およびテキストファイルをダウンロード、共有できます。テキストファイルは編集したり、編集内容を保存したりすることができます。
          </p>
          <p>
            また、ファイルをダウンロードあるいは共有することで、任意のプログラムから展開することが可能です。
          </p>
        </div>

        <input id="acd-check16" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check16"
          >Q. レコーダーからアップロードができなくなりました。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            クラウドスペースは、1アカウントに対して10GBの保存容量が付与されます。
          </p>
          <p>
            保存容量が無くなると、アップロードできなくなりますので、ほぼ満杯になる前に不要なファイルをクリアしてください。
          </p>
        </div>

        <input id="acd-check17" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check17"
          >Q. Webアプリで出力できるオーディオ、ビデオ、およびテキストファイルの形式を教えてください。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            Webアプリからダウンロードおよび共有できる各ファイルの形式は以下です。
            <ul>
              <li>オーディオ：AAC</li>
              <li>ビデオ：mp4</li>
              <li>テキスト：txt</li>
            </ul>
          </p>
        </div>

        <input id="acd-check18" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check18"
          >Q. ブラウザが正常に表示されません。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            推奨ブラウザはGoogle Chrome(最新版)です。左記外からのアクセスについては、動作保証を行っておりません。
          </p>
        </div>

        <h4>文字起こしテキスト作成</h4>
        <input id="acd-check21" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check21"
          >Q. 文字起こしに失敗してしまいます。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            ネットワークやその他の理由で文字起こし処理に失敗した場合は、レコーダーでファイルを長押しして「アップロード」をクリックして、再度文字起こし処理を行ってください。
          </p>
        </div>

        <input id="acd-check22" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check22"
          >Q. テキストが表示されないのは何か理由があるのでしょうか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            録音が保存された後、文字起こし処理を行っていますので、しばらくお待ちください（テキストの完全性と正確性を修正します）。
          </p>
          <p>
            完全でより正確な内容の文字起こしテキストを取得できます。
          </p>
        </div>

        <input id="acd-check23" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check23"
          >Q. 文字起こし処理にはどの程度の時間がかかりますか。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            サーバが混雑状況ではない場合、1時間のオーディオファイルは10分以内に文字起こしテキストに変換されます。
          </p>
        </div>

        <!--
        <input id="acd-check24" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check24"
          >Q. アップロード可能時間とは何ですか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            文字起こしテキストをクラウドスペースへアップロードできる時間です。
          </p>
          <p>
            クラウドスペースへアップロードするオーディオまたはビデオファイルの時間の合計で計算されます。
          </p>
          <p>
            無料ベーシックプランでは、毎月20時間のリミットがあり、毎月1日に20時間のリミットが更新されます。プランをアップグレードすると、その場でリミットは解除され、無制限で文字起こしテキストをクラウドスペースにアップロードすることができます。
          </p>
        </div>

        <input id="acd-check25" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check25"
          >Q. アップロード可能時間が無くなりました。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            リミットに達した場合は、プランをアップグレードして制限を解除するか、リミットの更新までお待ちください（毎月1日に20時間が付与されます）。
          </p>
          <p>
            なお、アップロード可能時間の翌月への繰り越しはできません。
          </p>
        </div>
        -->

        <h4>コンテンツの共有と削除</h4>
        <input id="acd-check26" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check26"
          >Q. コンテンツとは何ですか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            レコーダーを使用してクラウドスペースへアップロードしたオーディオ、ビデオ、画像、テキストを含めたすべてのファイルを総称してコンテンツと表記しています。
          </p>
        </div>

        <input id="acd-check27" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check27"
          >Q. コンテンツを複数の宛先に一斉共有することはできますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            可能です。
          </p>
          <p>
            詳しくは操作ガイドのファイルを共有するをご覧ください。
          </p>
        </div>

        <input id="acd-check28" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check28"
          >Q. 複数のコンテンツを一括して共有できますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            可能です。
          </p>
          <p>
            詳しくは操作ガイドのファイルを共有するをご覧ください。
          </p>
        </div>

        <input id="acd-check29" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check29"
          >Q. コンテンツは削除できますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            可能です。
          </p>
          <p>
            詳しくは操作ガイドのクラウドスペースのファイルを削除するをご覧ください。
          </p>
        </div>

        <input id="acd-check30" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check30"
          >Q. 削除したコンテンツを復元することはできますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            削除されたコンテンツはデータベース上からも完全に削除されるため、一度削除したコンテンツを復元することはできません。ご了承ください。
          </p>
        </div>

        <h4>アカウントの管理</h4>
        <input id="acd-check31" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check31"
          >Q. ログインのプロンプトが繰り返される。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            アカウントにアクセスできないまま繰り返しログインを求められる場合、お使いのブラウザのクッキーの設定に原因がある可能性があります。このような場合、この設定を修正することでログインの問題は解決します。
          </p>
          <p>
            www.recstation.jpを常にCookieを使用できるサイトに指定してください。
          </p>
          <p>
            また、第三者のコンピューターを利用している場合は、管理者または該当のヘルプデスクサポートに問い合わせて、特定のサイト機能を遮断するようなファイアウォールやセキュリティブロックがインストールされていないかを確認してください。
          </p>
          <p>
            上記をお試しになった後も問題が解決しない場合は、
            <span class="linklike handcursor" @click="pushPage({name: 'Contact'})">こちら</span>
            からカスタマーサポートまで連絡してください。
          </p>
        </div>

        <input id="acd-check32" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check32"
          >Q. 同一のアカウントから複数のレコーダーへログインできますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            二重ログインできません。複数のレコーダーで同一のアカウントをご利用の場合は、お使いのレコーダーでログアウトしてから、ログインしなおしてください。
          </p>
        </div>

        <input id="acd-check33" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check33"
          >Q. アカウントを複数作成できますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            アカウントを複数作成することは可能ですが、特典のクラウドスペースを受け取ることができるのは、1つのレコーダーで1回限りです。
          </p>
          <p>
            クラウドスペースがご利用いただけなくなりますので、クラウドスペースを受け取ったアカウントを削除しないように十分に注意してください。
          </p>
        </div>

        <input id="acd-check34" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check34"
          >Q. 登録情報を変更したい。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            レコーダーからは、以下の手順でアカウント登録情報を更新できます。
            <ol>
              <li>ホーム画面で右上のアカウントアイコンを選択し、「アカウント管理」を選択します。</li>
              <li>個人情報画面で「登録情報を変更」ボタンを選択します。</li>
              <li>画面上の指示にしたがって項目を入力します。</li>
              <li>変更が終わったら「保存する」を選択します。</li>
            </ol>

            Webアプリでは、以下の手順でユーザネーム、その他のアカウント登録情報を更新できます。
            <ol>
              <li>ホーム画面で右上のアカウントアイコンを選択し、「アカウント管理」をクリックします。</li>
              <li>個人情報画面で「登録情報を変更」ボタンをクリックします。</li>
              <li>画面上の指示にしたがって項目を入力します。</li>
              <li>変更が終わったら「保存する」を選択します。</li>
            </ol>
          </p>
        </div>

        <input id="acd-check35" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check35"
          >Q. 登録メールアドレスを変更したい。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            登録メールアドレスを変更するには、コード認証が必要です。以下の手順で登録メールアドレスを変更できます。
            <ol>
              <li>ホーム画面で右上のアカウントアイコンを選択し、「アカウント管理」をクリックします。</li>
              <li>個人情報画面で「登録アドレスを変更」ボタンをクリックします。</li>
              <li>新しいメールアドレスを入力し、認証コードを受け取ってください。</li>
              <li>認証が完了するとメールアドレスが変更されます。</li>
            </ol>
          </p>
        </div>

        <input id="acd-check36" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check36"
          >Q. 登録したメールアドレスにアクセスできなくなった場合はどうすればよいですか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            お客様がご利用のメールサービスで、メールアドレスを削除・変更した場合、本サービスのアカウント変更などが利用できなくなります。
            上記「Q. 登録メールアドレスを変更したい。」を参考に、速やかに現在ご利用中のメールアドレスにご変更ください。
          </p>
          <p>
            もしご自身がログインに使用しているメールアドレスを忘れてしまった場合は、
            <span class="linklike handcursor" @click="pushPage({name: 'Contact'})">こちら</span>
            からカスタマーサポートまで連絡してください。ご登録いただいている情報で照合し、お手続きをさせていただきます。
          </p>
        </div>

        <input id="acd-check37" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check37"
          >Q. パスワードを変更したい。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            以下の手順でパスワードを更新できます。
            <ol>
              <li>ホーム画面で右上のアカウントアイコンを選択し、「アカウント管理」をクリックします。</li>
              <li>画面左側のナビゲーションエリアから「パスワード変更」を選択します。</li>
              <li>画面上の指示にしたがって項目を入力します。</li>
              <li>変更が終わったら「続行」ボタンをクリックします。</li>
            </ol>
          </p>
        </div>

        <input id="acd-check38" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check38"
          >Q. アカウントを削除する。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
            アカウントが削除されると、お客様はそのアカウントにアクセスできなくなり、コンテンツへのアクセスや、領収書の印刷ができなくなります。これは、お客様の登録メールアドレスにリンクされているデバイス機器、サービスにも影響します。
          </p>
          <p>
            アカウントを削除すると、以下にアクセスできなくなります。
            <ul>
              <li>保存されているオーディオ、ビデオ、テキストファイルなどすべてのコンテンツ</li>
              <li>ファイルリストと登録個人情報</li>
              <li>RECORDER STATIONのアカウント</li>
            </ul>
            <span class="attention">アカウントを削除する前に、以下の操作を確認してください。</span>
            <ul>
              <li>クラウドスペース、アップロード可能時間が残っていないかをご確認ください：<br />
                  クラウドスペースやアップロード可能時間を他のアカウントに移行することはできません。アカウントを削除すると、そのアカウントに残されている保存容量やアップロード可能時間は失われます。</li>
              <li>ダウンロードをしていないコンテンツがないかご確認ください：<br />
                  アカウントを削除した後は、アカウントに保存されているコンテンツ（オーディオファイル、ビデオファイル、テキストファイルなど）をダウンロードすることができなくなります。</li>
              <!--
              <li>継続購入をキャンセルしてください：<br />
                  アカウントで継続購入をされている場合はキャンセルしてください。</li>
              -->
              <li>削除されたアカウントに関する対応はできません：<br />
                  コンテンツ、注文履歴、ご登録の個人情報などの確認は承れません。</li>
              <li>今後本サービスをご利用いただく際には、新たにアカウントを作成いただきます：<br />
                  削除したアカウントのデータを引き継ぐことは一切できません。</li>
              <li>特典の付与は1回限りです<br />
                  現在のアカウントで特典のクラウドスペースをすでに受け取っている場合は、新たにアカウントを作成いただいても特典の付与はありません。</li>
              <!--
              <li>アップロード可能時間は毎月1日に更新されます：<br />
                  ベーシックプランをご利用の場合、新たにアカウントを作成いただいても、アップロード可能時間の上限はリセットされませんので、ご了承ください。</li>
              -->
            </ul>
            登録メールアドレスの変更、契約プランの変更など、ご登録情報を変更する際は、アカウントを削除する必要はありません。詳細は登録情報の変更をご覧ください。アカウントを削除する場合は、
            <span class="linklike handcursor" @click="showDeleteWindow">こちら</span>
            からお手続きを行ってください。
          </p>
        </div>

        <input id="acd-check39" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check39"
          >Q. アカウントを削除するとコンテンツはどうなりますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
		アカウントを削除すると、データベースからアカウントに関連するデータが削除され、マイスペースに保存されているオーディオ、ビデオ、テキストファイルなどすべてのコンテンツにアクセスできなくなります。
          </p>
        </div>

<!--
        <h4>請求とお支払い</h4>
        <input id="acd-check1" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check1"
          >Q. 領収書を印刷する。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
		領収書にアクセスして印刷するには、「アカウント管理」ページから支払い明細にアクセスします。
		1.	ホームの右上にある「アカウント」アイコンをクリックします。
		2.	ドロップダウンから「アカウント管理」を選択します。
		3.	左側のナビゲーションエリアから「支払い明細」をクリックします。
		4.	領収書を印刷したい購入履歴の右の「領収書」をクリックします。
		5.	画面上の指示にしたがって項目を入力します。
		6.	領収書を表示して印刷できます。
		*紙による領収書は提供していません。また、第三者にメールで領収書を送付することもありません。
		*購入履歴には、アカウントに関連した請求の領収書のみが表示されます。レコーダーステーションから覚えのない請求があった場合は、こちらをご確認ください。
          </p>
        </div>

        <input id="acd-check2" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check2"
          >Q. 心あたりのない料金請求が届きました。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
		テキストテキストテキストテキストテキストテキストテキストテキスト。
          </p>
        </div>

        <input id="acd-check3" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check3"
          >Q. 有料プランをキャンセルしたい。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
		テキストテキストテキストテキストテキストテキストテキストテキスト。
          </p>
        </div>

        <input id="acd-check4" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check4"
          >Q. 有料プランをキャンセルした後はどうなりますか？
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
		できます。
		詳しくは操作ガイドの「コンテンツ管理」をご覧ください。
          </p>
        </div>

        <input id="acd-check5" class="acd-check" type="checkbox" />
        <label class="acd-label handcursor" for="acd-check5"
          >Q. レコーダーステーションの返金ポリシー。
          <div><i class="fas fa-chevron-down"></i></div
        ></label>
        <div class="acd-content">
          <p>
		削除されたコンテンツはデータベース上からも完全に削除されるため、一度削除したコンテンツを復元することはできません。ご了承ください。
          </p>
        </div>
--> 
        </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainNavi from '@/components/MainNavi.vue'
import Swal from 'sweetalert2';
import api from '@/mixins/api.js'
import utils from '@/mixins/utils.js'
import axios from 'axios'

export default {
  name: 'Faq',
  props: {
    pagename: String,
  },
  mixins: [api, utils],
  components: {
    Header,
    Footer,
    MainNavi,
  },
  data: function() {
    return {
    }
  },created(){
  },
  methods: {
    showDeleteWindow(){
      Swal.fire({
        reverseButtons : true,
        animation : false,
        icon: 'info',
        title: '本当に削除してよろしいですか？',
        text: '一度削除すると全てのデータが完全に消去され、元に戻すことはできません。',
        showCancelButton: true,
        confirmButtonColor: '#ff4500',
        //cancelButtonColor: '',
        confirmButtonText: '削除',
        cancelButtonText: '戻る'
      })
      .then((result) => {
        if (result.isConfirmed) {
          // ユーザデータのバージョンを取得して削除実行
          axios(this.getAxConf("get", "/users/data", {}, this.$store.state.token))
          .then((response) => {
            if (response.data.success) {
              const version = response.data.data.version ? response.data.data.version : 0
              this.deleteUserData(version);
            }else{
              return this.showErrorWindow(response)
            }
          })
          .catch((err) => {
            this.showNetworkErrorWindow(err)
          });
        }
      })
    },
    deleteUserData(version){
      const data = {
        "userVersion": version
      }
      axios(this.getAxConf("delete", "/users/data", data, this.$store.state.token))
      .then((response) => {

        if (response.data.success) {
          this.$store.commit('setToken', "")
          localStorage.setItem('pageName', "")
          localStorage.setItem('readcount', "")
          localStorage.setItem('token', "");
          
          Swal.fire({
            confirmButtonColor : '#68CFC3',
            animation : false,
            title: 'ご利用ありがとうございました',
            confirmButtonText: 'ログイン画面に戻る',
            allowOutsideClick : false,
          }).then(()=>{
            this.pushPage({name: 'Login'})
          })
        }else{
          this.showErrorWindow(response)
        }
      })
      .catch((err) => {
        console.log(err);
        this.showNetworkErrorWindow(err)
      });
    }
  },
}
</script>

<style>
.acd-check {
  display: none;
}
.acd-label {
  border-bottom: solid 1px #dcdcdc;
  display: flex;
  margin-bottom: 1px;
  margin-top: 10px;
  padding: 10px;
}
.acd-content {
  border: 1px solid #ffffff;
  height: 0;
  opacity: 0;
  padding: 0 10px;
  transition: 0.5s;
  visibility: hidden;
  box-shadow: 0px 1px 10px -5px black;
  border-radius: 10px;
}
.acd-check:checked + .acd-label + .acd-content {
  height: fit-content;
  opacity: 1;
  /* padding: 10px; */
  visibility: visible;
}
.acd-label div {
  margin-left: auto;
}
.attention{
  color: #ff6666;
  font-weight: bold;
}
</style>
