<template>
  <div class="home_bg">
    <Header pagename="Info" />
    <div class="contentarea">
      <MainNavi pagename="contact"/>
        <div class="infocontent">
          <h2 style="text-align: center;">お知らせ</h2>
          <div class="itemarea">
            <table>
              <tbody>
                <tr v-for="(item) in items" :key="item.id" @click="showDetailModal(item.index)" class="handcursor">
                  <td class="datecell">{{ item.viewdate }}</td>
                  <td class="categorycell">
                    <span v-show="item.category == '01'" class="infocategory cateinfo">
                      お知らせ
                    </span>
                    <span v-show="item.category == '02'" class="infocategory catedisorder">
                      障害情報
                    </span>
                    <span v-show="item.category == '03'" class="infocategory cateother">
                      重要
                    </span>
                    <span v-show="item.category == '99'" class="infocategory cateother">
                      その他
                    </span>
                  </td>
                  <td>{{ item.title }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="pageNum > 0" class="pagecontrol">
            <!--
            <span class="handcursor" v-show="pageNum > 1" @click="setPage(-1)" v-bind:disabled="isProcessing()">{{ pageNum - 1}}</span>
            <span class="currentpagebutton" v-bind:disabled="isProcessing()">{{ pageNum }}</span>
            <span class="handcursor" v-show="totalCount/10 > pageNum" @click="setPage(1)" v-bind:disabled="isProcessing()">{{ pageNum + 1}}</span>
            -->
            <span v-bind:disabled="isProcessing()" @click="setStaticPage(1)" v-bind:class="{ currentpagebutton: pageNum == 1 }">1</span>
            <span v-bind:disabled="isProcessing()" @click="setStaticPage(2)" v-bind:class="{ currentpagebutton: pageNum == 2 }" v-show="totalCount/10 > 1">2</span>
            <span v-bind:disabled="isProcessing()" @click="setStaticPage(3)" v-bind:class="{ currentpagebutton: pageNum == 3 }" v-show="totalCount/10 > 2">3</span>

          </div>
        </div>
    </div>

    <Footer />
    <InfoModal :index="target.index" :id="target.id" :date="target.viewdate" :category="target.category" :title="target.title" :content="target.content" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import InfoModal from '@/components/InfoModal.vue'
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import processing from '@/mixins/processing.js'
import MainNavi from '@/components/MainNavi.vue'
import axios from 'axios'

export default {
  name: 'Info',
  props: {
    pagename: String,
  },
  mixins: [utils, api, processing],
  components: {
    Header,
    Footer,
    InfoModal,
    MainNavi
  },
  data: function() {
    return {
      target: {},
      items:[],
      pageNum: 1,
      totalCount: 0,
    }
  },created(){
  },
  mounted(){
    this.setItems();
  },
  methods: {
    setItems(){
      this.items = []

      axios(this.getAxConf("get", "/notice", {}, this.$store.state.token))
      .then((response) => {

        if (response.data.success) {

          var originalList = response.data.data
          var nowDate = new Date().getTime()

          // startDateがすでに過ぎているものをフィルタ
          var respList = originalList.filter((item)=>{
            const t = item.startDate
            const y = t.substring(0, 4)
            const m = t.substring(5, 7) - 1
            const d = t.substring(8, 10)
            const h = t.substring(11, 13)
            const mn = t.substring(14, 16)
            const s = t.substring(17, 18)
            var stDate = new Date(y, m, d, h, mn, s).getTime()

            const et = item.endDate
            const ey = et.substring(0, 4)
            const em = et.substring(5, 7) - 1
            const ed = et.substring(8, 10)
            const eh = et.substring(11, 13)
            const emn = et.substring(14, 16)
            const es = et.substring(17, 18)
            var edDate = new Date(ey, em, ed, eh, emn, es).getTime()

            if(stDate < nowDate && nowDate < edDate){
              return item
            }
          })

          if(respList.length > 0){
            // 1件以上の場合
            var tempList = []
            respList.forEach((item, idx) => {
              if(idx < 30){
                var tempItem = {
                  index: idx,
                  id: item.id,
                  startdate: item.startDate,
                  enddate: item.endDate,
                  viewdate: item.startDate.slice(0, -3),
                  category: item.type,
                  title: item.title ? item.title : item.total,
                  content: item.content,
                }
                tempList.push(tempItem)
              }
            });
            this.items = tempList
            this.totalCount = this.items.length
            localStorage.setItem('readcount', originalList.length)
          
          }else{
            // 0件の場合
            var blankitem = {
              index: 0,
              id: 0,
              startdate: "2021-04-01 00:00",
              enddate: "",
              viewdate: "",
              category: "",
              title: "お知らせはありません",
              content: "お知らせはありません",
            }
            this.items.push(blankitem);
            this.totalCount = 1
            localStorage.setItem('readcount', 1)
          }
          
        }else{
          var erroritem = {
            index: 0,
            id: 0,
            startdate: "2021-04-01 00:00",
            enddate: "",
            viewdate: "",
            category: "",
            title: "データを取得できませんでした",
            content: "データを取得できませんでした",
          }
          this.items.push(erroritem);
          this.totalCount = 1
          localStorage.setItem('readcount', 1)
        }
      })
      .catch((err) => {
        console.log(err);
        var erroritem = {
          index: 0,
          id: 0,
          startdate: "2021-04-01 00:00",
          enddate: "",
          viewdate: "",
          category: "",
          title: "データを取得できませんでした",
          content: "データを取得できませんでした",
        }
        this.items.push(erroritem);
        this.totalCount = 1
        localStorage.setItem('readcount', 1)

      });
    },
    showDetailModal(index){
      this.items.forEach(item => {
        if(item.index == index)
        this.target = item
      });
      this.$modal.show('info-modal');
    },
    isOnPage(index){
      const pg = this.pageNum * 10
      if((pg - 10) <= index && index < pg){
        return true
      }
        return false
    },
    /* 仕様変更により不使用
    setPage(inc){
      this.pageNum += inc
      this.endProcessing()
    },
    */
    setStaticPage(num){
      this.pageNum = num
      this.endProcessing()
    }
  },
}

</script>

<style scoped>
/* スクロールバー */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #13618f;
  border-radius: 5px;
}

.infocontent {
  margin-left: 10px;
  width: 80%;
  background: #f0f8ff;
  font-size: 14px;
  height: 95vh;
  
}
.infocontent::-webkit-scrollbar {
  display: none;
}
.infocategory {
  border: 1px solid #333333;
  padding: 5px;
  margin-right: 10px;
  border-radius: 3px;
}
.cateinfo {
  border: 3px solid #4682b4;
  color: #4682b4;
}
.catedisorder {
  border: 3px solid #dd4157;
  color: #dd4157;
}
.cateother{
  border: 3px solid #29aba4;
  color: #29aba4;
}
.infosubdata {
  margin-bottom: 80px;
  display: flex;
}
.infocatedate {
  margin-left: auto;
}
.itemarea {
  margin-bottom: 20px;
  padding: 20px;
  overflow-y: scroll;
  height: 80%;
}
.itemarea table {
  width: 100%;
}
.itemarea thead {
  color: #a9a9a9;
  font-size: 14px;
}
.itemarea tr:nth-child(even) {
  background: #f9f9f9;
}
.itemarea tr {
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
}
.itemarea tr:hover {
  background: #cce6ff;
  border: #13618f 1px solid !important;
}
.itemarea td {
  padding: 11px 0;
  font-size: 14px;
}
.itemarea p {
  padding: 20px;
}
.itemarea i {
  vertical-align: middle;
}
.pagecontrol {
  display: none;
  text-align: center;
  padding: 30px;
}
.pagecontrol span {
  padding: 5px;
  border: solid 1px #cccccc;
  margin-right: 10px;
}
.currentpagebutton{
  background: #cccccc;
}
.datecell{
  width: 200px;
}
.categorycell{
  width: 200px;
}
</style>


