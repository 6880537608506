import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VModal from "vue-js-modal";
import swal from "sweetalert2";
window.Swal = swal;
import "vue-loaders/dist/vue-loaders.css";
import VueLoaders from "vue-loaders";
import store from "./store";
import jQuery from "jquery";

Vue.config.productionTip = false;

Vue.use(VModal);
Vue.use(VueLoaders);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

// SweetAlertボタンの色をグローバルに設定
const options = {
  // confirmButtonColor: '#41b882',
  // cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);

// Vue.use(axios);
/*  使いたいコンポーネント内で
  import axios from 'axios';
  axios.get('https://xxxx.com/api/aaaa').then(response => {
    this.anydataobj = response.data
  })
*/
// axiosをインスタンスプロパティとして定義
// Vue.prototype.$axios = axios

// jQueryを使えるように（あまり使わないこと）
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require("jquery");
