<template>
  <div class="home_bg">
    <Header pagename="Payment" />
    <div class="contentarea">
      <MainNavi pagename="payment" />
      <div class="maincontent">
        <div class="documentcontent">
          <h2 style="text-align: center;">決済方法</h2>
          <p>ご購入に使用するクレジットカードの登録、変更を行います。</p>
          <div class="paymentarea">
            <span class="button" @click="openEditCreditPage">
              登録・編集画面を開く
            </span>
            <p>
              （GMOペイメントの管理画面が開きます）
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainNavi from '@/components/MainNavi.vue'
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import axios from 'axios'

export default {
  name: 'Payment',
  props: {
    pagename: String,
  },
  mixins: [utils, api],
  components: {
    Header,
    Footer,
    MainNavi,
  },
  data: function() {
    return {
    }
  },created(){
  },
  methods: {
    openEditCreditPage(){
      // URL取得
      axios(this.getAxConf("get", "/users/credit", {}, this.$store.state.token))
      .then((response) => {
        if (response.data.success) {
          const url = response.data.data.url;
          window.open(url, '_blank');
        }else{
          return this.showErrorWindow(response);
        }
      })
      .catch((err) => {
        return this.showNetworkErrorWindow(err);
      });
    }
  },
}
</script>

<style>
</style>
