/**
 * TODO: これだとAPIの呼び出し元が返却data形式を理解していないといけないので、
 * あまり良い設計ではないと思う… ただ、エラーメッセージの表示をView側でやる場合
 * 返却Obj丸ごと渡した方が理にかなっているので、そちらを優先しました。
 * なので、今後、もしかしたらView側とAPImixinの間に一枚挟んだ方がいいのかも
 * しれない。
 * TODO: APIの分離がうまくいかないので、やむなく各アクセスメソッドは使わずに
 * 実装を進めることにした（config設定は使う）。ベターではないので、できれば
 * 整理したい
 * 共通レスポンス仕様
 *  {
      "code": 200,
      "data": {},
      "message": "メッセージ内容",
      "success": true
    }
 */
import axios from "axios";
export default {
  data: function() {
    return {
      // domain: 'http://47.74.56.36:8080', // テストサーバ
      // domain: "http://47.74.56.36", // テストサーバ
      domain: "https://aistest.recstation.jp",
      // domain: "https://www.test.recstation.jp",
      // domain: 'https://recstation.jp', // 本番サーバ
      // domain: 'https://' + document.domain, // サーバによって切り替え
      // path: this.getPathByDirectory(),
      path: "/api/v1",
      // path: "/cms14/v1",
      //path: "/cms11/v1", // ←
      // エラーコードとメッセージの対象リスト（使わないかも？）
      errorcode: {
        200: "SUCCESS",
        301: "恒久的に移動した。",
        404: "未更新",
        500: "サーバ内部エラーです。しばらく待ってから再度お試しください",
        1979: "サーバ内部のファイル処理エラーです。しばらく待ってから再度お試しください",
        1989: "サーバ内部SQLエラーです。しばらく待ってから再度お試しください",
        1994: "ログイン異常です。再度ログインしてください。",
        1995: "不正なアクセスです。",
        1996: "tokenのシークレットキーが異なります。",
        1997: "tokenの有効期限が切れています。",
        1998: "tokenの署名アルゴリズムが異なります。",
        1999: "tokenに異常があります。",
        1900: "",
        1901: "認証コードが違います。再度入力してください。",
        1902: "認証コードの有効期間が過ぎています。認証コードを再取得してください。",
        1903: "メールアドレスまたはパスワードが違います、再度入力してください。",
        1904: "すでに登録されているメールアドレスです。別のアドレスを入力してください。",
        1905: "メール配信が失敗しました。再度配信してください。",
        1906: "重複発信リクエストです。しばらく待ってから再度お試しください。",
        1907: "旧パスワードが違います。再度入力してください。",
        1908: "支払いに失敗しました。",
        1909: "入力したメールアドレスは存在しません、先に登録してください。",
        1910: "操作権限がないので、該当処理が実行できません。",
        1911: "書き起こし情報の更新に失敗しました。",
        1912: "書き起こし結果の削除に失敗しました。",
        1913: "内容に変更がないため、更新の必要はありません。", // ローカルで判定するので使わない想定
        1914: "同じメールアドレスは、削除から一か月間は再登録できません。",
        1915: "ログイン設備異常、登録失敗しました。",
        1916: "登録設備異常、登録失敗しました。",
        1917: "クラウドストレージの取得に失敗しました。",
        1918: "クラウド項目リストの取得に失敗しました。",
        1919: "指定されたファイルを取得できませんでした。",
        1920: "該当するデータは別のユーザーから変更されました。",
        1921: "ファイル内容を読む時、エラーが発生しました。",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    getPathByDirectory() {
      // if (location.pathname == '/parrot/' || location.pathname == '/parrot') {
      if (location.pathname.match("/parrot")) {
        return "/api1/v1";
      } else if (location.pathname.match("/res_dev")) {
        return "/dev1/v1";
      } else {
        return "/api/v1";
      }
    },

    /**
     * ヘッダーに設定する情報を取得
     * @param {string} tk token
     * @returns headerJSONオブジェクト
     */
    getHeaders(tk) {
      const headers = {
        headers: {
          Authorization: `Bearer ${tk}`,
        },
      };
      return headers;
    },
    /**
     * ファイルダウンロード時のヘッダーに設定する情報を取得
     * @param {string} tk token
     * @returns headerJSONオブジェクト
     */
    getBlobHeaders(tk) {
      const headers = {
        dataType: "binary",
        // responseType: 'blob',
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${tk}`,
          // 'Accept': 'application/zip,'
        },
      };
      return headers;
    },
    /**
     * APIのURLを取得
     * @param {*} uri
     * @returns フルURL
     */
    getURL(uri) {
      return this.domain + this.path + uri;
    },
    /**
     * axios.getメソッドの実行
     * TODO: 今後リファクタリングします
     * @param {*} method getを想定
     * @param {*} url /xxx
     * @param {*} data {}
     * @param {*} token Hash部のみ
     */
    getAxConf(method, url, data, token) {
      const config = {
        data: data,
        method: method,
        url: this.domain + this.path + url,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      return config;
    },
    /**
     * Axios通信実行とエラー処理
     * @param {*} config getConfig()
     */
    executeAxios(config) {
      axios(config)
        .then(function(response) {
          console.log(JSON.stringify(response.data));
          return Promise.resolve(response);
        })
        .catch(function(error) {
          // alert(this.errorcode[error])
          return Promise.reject(error);
        });
    },

    /**
     * 書き起こし情報の更新
     * @param {*} param 
     * param = {
        "createDate": "2020-12-15T15:15:28.903Z",
        "createUserId": "string",
        "deleteFlg": "string",
        "fileId": "string",
        "id": "string",
        "name": "string",
        "newTxt": "string",
        "oldTxt": "string",
        "txtUrl": "string",
        "updateDate": "2020-12-15T15:15:28.903Z",
        "updateUserId": "string",
        "version": 0
      }
     */
    updateContents(token, param) {
      return this.executeAxios(
        this.getConfig("put", "/contents", token, param)
      );
    },

    /**
     * 書き起こし情報の削除
     * @param token
     * @param {[String]} fileId 配列で渡すと複数ファイル同時に操作可能
     */
    deleteContents(token, fileId) {
      const param = {
        fileIdList: fileId,
      };
      return this.executeAxios(
        this.getConfig("delete", "/contents", token, param)
      );
    },

    /**
     * 書き起こし詳細情報の更新
     * @param token
     * @param {*} param 
     * 
      var param = {
        "fileName": "string",
        "projectId": "string",
        "userId": "string"
      }
     */
    updateContentsDetail(token, param) {
      return this.executeAxios(
        this.getConfig("put", "/contents", token, param)
      );
    },

    /**
     * 文字起こし一覧の取得
     * @param token
     * @param pagenum
     * @param pagerow
     */
    getContentsList(token, pagenum, pagerow) {
      const param = "/" + pagenum + "/" + pagerow;
      return this.executeAxios(
        this.getConfig("get", "/contents/list/" + param, token)
      );
    },

    /**
    * お知らせ一覧の取得
    * @param token
    * @param {*} param 
    * "data": [
        {
          "content": "情報は処理されません。",
          "endDate": "2020-12-17 18:48:11",
          "id": "asdasasd12_785521_asa9a9sd_23@#966",
          "startDate": "2020-12-17 18:48:11",
          "total": "情報",
          "type": "メモ"
        }
      ],
    */
    getNotice(token) {
      return this.executeAxios(this.getConfig("get", "/notice", token));
    },

    /**
     *
     * 認証チェック
     * @param token
     */
    async checkToken(token) {
      return this.executeAxios(this.getConfig("get", "/token", token));
    },

    /**
     * トークンの破棄（ログアウト）
     * @param token
     * @param {*} param
     */
    deleteToken(token) {
      return this.executeAxios(this.getConfig("delete", "/token", token));
    },

    /**
     * ユーザの新規作成
     * @param token
     * @param {*} param 
     * param = {
          "password": 123456,
          "toMail": "1006993@vi.com"
        }
     */
    createUser(token, param) {
      return this.executeAxios(this.getConfig("post", "/users", token, param));
    },

    /**
     * ユーザ情報の取得
     * @param {*} token 
     * @returns data
     * "data": {
        "age": 30,
        "createDate": "2020-12-17 18:48:11",
        "createUserDate": "2020-12-17 18:48:11",
        "createUserId": 199010251305532540,
        "deleteFlg": 0,
        "deleteUserDate": "2020-12-17 18:48:11",
        "email": "123@123.com.jp",
        "firstUploadTimestamp": "2020-12-17 18:48:11",
        "industry": "農業",
        "ipRegion": "東京都",
        "lastLoginTimestamp": "2020-12-17 18:48:11",
        "lastUploadTimestamp": "2020-12-17 18:48:11",
        "numLoggedin": 3,
        "role": "ROLE_USER",
        "sex": "01",
        "spaceAvailable": 1045700,
        "spaceUsed": 100,
        "totalSpace": 1045800,
        "updateDate": "2020-12-17 18:48:11",
        "updateUserId": 199010251305532540,
        "userName": "江崎茂樹",
        "version": 2
      },
     */
    getUser(token) {
      return this.executeAxios(this.getConfig("get", "/users", token));
    },

    /**
     * ユーザデータの更新
     * @param {*} token
     * @param {*} param 
     * param = {
        "birth": 19900101,
        "industry": "農業",
        "ipRegion": "東京都",
        "sex": "01",
        "userName": "test",
        "version": 2
      }
     */
    updateUser(param, token) {
      return this.executeAxios(this.getConfig("put", "/users", token, param));
    },

    /**
     * ユーザデータの削除
     * @param {*} token 
     * @param {*} param 
     * param = {
          "userVersion": 2
        }
     */
    deleteUser(token, param) {
      return this.executeAxios(
        this.getConfig("delete", "/users", token, param)
      );
    },

    /**
     * メールアドレスの変更
     * @param {*} token 
     * @param {*} param 
     * param = {
          "email": "100639@vi.com",
          "version": 2
        }
     */
    updateEmail(token, param) {
      return this.executeAxios(
        this.getConfig("put", "/users/address", token, param)
      );
    },

    /**
    * 認証コードの発行（新規登録）
    * @param {*} token 
    * @param {*} param 
    * param = {
        "mail": "1006993@vi.com"
      }
    */
    sendAuthcode(token, param) {
      return this.executeAxios(
        this.getConfig("post", "/users/code", token, param)
      );
    },

    /**
    * 認証コードの発行（パスワード更新、パスワード再発行用）
    * @param {*} param 
    * param = {
        "mail": "1006993@vi.com"
      }
    */
    sendAuthcodePW(param) {
      return this.executeAxios(
        this.getConfig("post", "/users/code/password", "", param)
      );
    },

    /**
    * パスワードの再設定
    * @param {*} param 
    * param = {
        "email": "100639@VI.com",
        "password": 123456
      }
    */
    resetPassword(param) {
      return this.executeAxios(
        this.getConfig("put", "/users/password", "", param)
      );
    },

    /**
    * パスワードの更新
    * @param {*} token 
    * @param {*} param 
    * param = {
        "email": "100639@VI.com",
        "password": 123456
      }
    */
    updatePassword(token, param) {
      return this.executeAxios(
        this.getConfig("put", "/users/security", token, param)
      );
    },

    /**
    * ログイン
    * @param {*} param 
    * param = {
        "email": "100639@VI.com",
        "password": 123456
      }
      @returns token
      "data": {
        "token": "string"
      },
    */
    sendLogin(param) {
      return this.executeAxios(
        this.getConfig("post", "/users/token", "", param)
      );
    },

    /**
    * 認証コード検証
    * @param {*} param 
    * param = {
        "code": 1234,
        "mail": "100639@VI.com"
      }
    */
    verifyAuthcode(param) {
      return this.executeAxios(
        this.getConfig("post", "/users/verificationCode", "", param)
      );
    },

    testMovePage() {
      alert("");
      this.$router.push("login");
    },
  },
};
