<template>
  <div></div>
  <!-- <header>
      <div class="header_inner">
        <div class="header_column_left">
          <div class="logoimage handcursor">
            <img src="../assets/RECSTATION_logo.png" @click="logoClick"/>
          </div>
        </div>
         <div class="header_column_center">
          <div class="searchbar">
            <input 
              @keypress.prevent.enter.exact="enable_submit" 
              @keyup.prevent.enter.exact="submit" 
              v-model="fileName"
              type="text" placeholder='' v-show="isHomepage">
            <i @click="toggleSearchOption" class="fas fa-align-justify searchinput handcursor" v-show="isHomepage"></i>
            <div v-show="isVisibleSearch" class="searchwindow">
              <div @click="toggleSearchOption" class="closecross closecrosssearch handcursor">×</div>
              <div class="searchwinndowinner">
                <table>
                  <tr>
                    <td>形式</td>
                    <td>
                      <select v-model="fileType">
                        <option value="">指定しない</option>
                        <option value="0">オーディオ</option>
                        <option value="3">ビデオ</option>
                        <option value="2">画像</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>日時</td>
                    <td>
                      <datepicker 
                        v-model="dpdate" 
                        :language="ja" 
                        :format="DatePickerFormat">
                      </datepicker>
                    </td>
                  </tr>
                  <tr>
                    <td>アイテム名</td>
                    <td>
                      <input
                        v-model="fileName"
                        type="text"
                        placeholder="ファイル名のキーワードを入力"
                      />
                    </td>
                  </tr>
                  
                </table>
                <div class="mt-20 ta-c">
                  <span @click="clear" class="button smallbutton mr-5">クリア</span>
                  <span @click="search" class="button smallbutton">検索</span>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="header_column_right">
            <div class="accountbar">
              <span @click="pushPage({name: 'Info', params:{token: token}})" class="handcursor"
                ><i class="material-icons-outlined">info</i
                ><span v-show="existUnread" class="badge" v-bind:class="{ winbadge: isWinOS }">・</span></span
              >
              <span @click="toggleAccountOption" class="handcursor"
                ><i class="material-icons-outlined">account_circle</i></span
              >
              <div v-show="isVisibleAccount" class="accountoption">
                <div @click="toggleAccountOption" class="closecross handcursor">×</div>
                  <div class="optioninner">
                    <div class="smallpopupusername">{{ userName }}</div>
                    <ul class="handcursor">
                      <li @click="pushPage({name: 'User', params:{token: token}})">アカウント管理</li>
                      <li @click="pushPage({name: 'Guide', params:{token: token}})">操作ガイド</li>
                      <li @click="pushPage({name: 'Faq', params:{token: token}})">ヘルプ</li>
                      <li @click="logout" class="liborder">ログアウト</li>
                    </ul>
                  </div>
                  </div>
              </div>
            </div>
        </div>
    </header> -->
</template>

<script>
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import axios from 'axios'
//import datepicker from 'vuejs-datepicker'
import { ja } from 'vuejs-datepicker/dist/locale'
import Swal from 'sweetalert2'

export default {
  name: 'Header',
  props: {
    token: String,
    pagename: String
  },
  mixins: [utils, api],
  components: {
    //datepicker
  },
  data: function () {
    return {
      isHomepage: false,
      isVisibleSearch: false,
      isVisibleAccount: false,
      existUnread: false,
      userName: "",
      isWinOS: false,
      can_submit_search: false,
      createTime : "",
      fileName: "",
      fileType: "",
      dpdate: "",
      ja: ja,
      DatePickerFormat: 'yyyy/MM/dd',
    }
  },
  created(){
    console.log("Header.vue created")
    this.recoveryToken();
    this.setSearchShowFlg();
    this.setOSInfo();
  },
  mounted(){
    console.log("Header.vue mounted")
    this.checkToken()
   // this.setUnreadFlg();
  },
  methods: {
    recoveryToken(){
      console.log("call recoveryToken")
      if(!this.$store.state.token){
        this.$store.commit('setToken', localStorage.getItem('token'))
        console.log("recoveryToken: " + this.$store.state.token)
      }
    },
    toggleAccountOption() {
     this.isVisibleAccount = !this.isVisibleAccount;
     if(this.isVisibleAccount && this.isVisibleSearch){
       this.isVisibleSearch = false
     }
    },

    toggleSearchOption() {
      this.isVisibleSearch = !this.isVisibleSearch;
      if(this.isVisibleSearch && this.isVisibleAccount){
        this.isVisibleAccount = false
      }
    },

    setSearchShowFlg(){
      if(this.pagename === "Home") {
        this.isHomepage = true;
      }
    },

    setUnreadFlg(){
      var localCount = localStorage.getItem('readcount')
      if(!localCount){
        localCount = 0
      }
      axios(this.getAxConf("get", "/notice", {}, this.$store.state.token))
      .then((response) => {

        const dataArr = response.data.data
        const infoCount = dataArr.length;
        if(Number(infoCount) > Number(localCount)){
          this.existUnread = true;
          const recentType = dataArr[0].type; // 順番はAPI側で保障されている
          if(recentType == "03"){
            this.showInfoToast(true);
          }else{
            this.showInfoToast(false);
          }
        }
        localStorage.setItem('pageName', this.pagename);
      })
      .catch((err) => {
        console.log(err);
      });
    },

    checkToken(){
      axios(this.getAxConf("get", "/users/data", {}, this.$store.state.token))
      .then((response) => {
        if(response.data.success){
          console.log("token state..." + JSON.stringify(response.data.code))
          var un = response.data.data.userName
          if(un.length > 8){
            un = un.substr( 0, 7 ) + "…";
          }
          this.userName = un
        }else{
          console.log("token state..." + JSON.stringify(response.data.code))
          
          Swal.fire({
            confirmButtonColor : '#68CFC3',
            animation : false,
            html: "<p>アクセスエラー</p>" + 
            "<p>他のブラウザからログインされたため、ログアウトしました。</p>" +
            "<style>p{font-size: 14px;}</style>",
            width: 500,
            padding: 10,
            confirmButtonText: "OK",
            confirmButtonAriaLabel: "OK",
            allowOutsideClick : false,
          })
        
          this.logout()
        }
      })
      .catch(()=>{
        this.logout()
      }
        //this.logout()
      );
    },

    setOSInfo(){
      var ua = window.navigator.userAgent.toLowerCase()
      if(ua.indexOf("windows nt") !== -1){
        this.isWinOS = true;
      }
    },

    search(){
      this.createTime = this.date2Numdate(this.dpdate)
      const data = {
        createTime: this.createTime ? this.createTime : "",
        fileName: this.fileName ? this.fileName : "",
        fileType: this.fileType ? this.fileType : ""
      }
      this.toggleSearchOption()
      this.$emit('ds', data)
    },

    clear(){
      this.dpdate = ""
      this.createTime = ""
      this.fileName = ""
      this.fileType = ""
    },

    enable_submit() {
      this.can_submit_search = true;
    },
    submit() {
      if (!this.can_submit_search) return;

      this.search()
      this.can_submit_search = false;
    },
    logoClick(){
      if(this.isHomepage){
        const data = {
          createTime: "",
          fileName: "",
          fileType: ""
        }
        this.clear()
        this.$emit('ds', data)
      }else{
        this.pushPage({
          name: 'Home'
        })
      }
    },

    logout(){
      // トークンを廃棄する処理
      localStorage.setItem('pageName', "");
      localStorage.setItem('token', "");
      this.pushPage({name: 'Login'});
    },

    /**
     * Util
     */
    date2Numdate(date){
      var numdate = "";
      if(date){
        var year = date.getFullYear();
        var month = 1 + date.getMonth();
        var day = date.getDate();
        month = ('0' + month).slice(-2);
        day = ('0' + day).slice(-2);
        numdate = year + month + day;
      }
      return numdate
    },
    showInfoToast(isImportant){
      if(isImportant){
        Swal.fire({
          position: "top-end",
          icon : 'warning',
          title: "重要なお知らせがあります",
          html:`
          <style>
            .swal2-title{
            }
            .swal2-popup{
              border: 5px solid #f8bb86;
            }
          </style>`,
          toast: true,
          showConfirmButton: true,
          confirmButtonColor: '#f8bb86',
        }).then(()=>{
          this.pushPage({name: 'Info'});
        })
      }else{
        Swal.fire({
          position: "top-end",
          icon : 'info',
          title: "あたらしいお知らせがあります",
          html:`
          <style>
            .swal2-title{
            }
            .swal2-popup{
              border: 5px solid #68CFC3;
            }
          </style>`,
          toast: true,
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 4000,
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  position: fixed;
  z-index: 1;
  top: 0px;
  padding: 5px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 5px #000000;
}
.header_inner {
  display: flex;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.header_column_left{
  display: flex;
  width: 33%;
  justify-content: left;
}
.header_column_center{
  display: flex;
  width: 33%;
  justify-content: center;
}
.header_column_right{
  display: flex;
  width: 33%;
  justify-content: right;
}

.logoimage {
  position: relative;
}
.logoimage > img {
  height: 30px;
  width: auto;
}
.searchbar {
  margin-left: 50px;
  margin-right: auto;
}
.searchbar > input {
  padding: 8px;
  border-radius: 18px;
  border: 1px solid #dcdcdc;
  margin: 2px;
  width: 250px;
}
.accountbar {
  margin-top: 3px;
  margin-right: 5px;
  color: #808080;
}
.accountbar > a {
  padding: 6px;
  background: #c0c0c0;
  border-radius: 6px;
  font-size: 10px;
}
.accountbar > a:link,
.accountbar > a:visited,
.accountbar > a:hover,
.accountbar > a:active {
  color: #ffffff;
}
.accountbar i {
  font-size: 30px;
}
.accountbar > a,
.accountbar i {
  vertical-align: middle;
}
.badge {
  position: relative;
  bottom: 8px;
  right: 10px;
  margin-right: -6px;
  font-size: 12px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #ff0000;
}
.closecross {
  position: relative;
  font-size: 20px;
  left: 130px;
  width: 20px;
}
.closecrosssearch {
  left: 490px;
}

.optioninner {
  margin-top: -20px;
}
.smallpopupusername {
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
}
.accountoption {
  position: absolute;
  margin-top: 8px;
  margin-left: -90px;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 3px 5px -3px black;
  font-size: 12px;
  width: 152px;
}
.accountoption ul {
  list-style: none;
  padding-inline-start: 15px;
}
.accountoption li {
  padding: 4px 0px;
}
.liborder {
  border-top: solid 1px #dcdcdc;
  margin-right: 20px;
  margin-top: 10px;
}
.searchinput {
  position: absolute;
  top: 15px;
  margin-left: -26px;
  color: #808080;
}
.searchwindow {
  position: absolute;
  margin-top: 8px;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 3px 5px -3px black;
  font-size: 12px;
  width: 500px;
  padding: 15px 15px;
  margin-left: 80px;
}
.searchwindow table {
  width: 100%;
}
.searchwindow td {
  padding: 5px;
}
.searchwindow select {
  padding: 5px;
  width: 100%;
}
.searchwindow input{
  padding: 5px;
  width: 96%;
}
.winbadge{
  padding: 0px 3px;
  font-size: 10px !important;
}
</style>
<style>
div.vdp-datepicker > div > input{
  padding: 5px;
  width: 100%;
}
</style>
