<template>
  <div class="leftnav">
    <!-- <div class="storagearea">
      <p>文字起こし可能時間</p>
        <DoughnutChart ref="timechart" :chartData="timeChartData"  class="chart" />
      <p>クラウド容量</p>
        <DoughnutChart ref="cloudchart" :chartData="cloudChartData" class="chart" />
      <p>現在のプラン</p>
      <span class="button smallbutton mt-10 " style="margin-bottom: 15px; height: max-content; font-size: 12px;" @click="pushPage({name: 'Plan'})">
        {{ userData.planData.planName }}
      </span>
    </div> -->
      <!-- <img src="../assets/RECSTATION_logo.png" @click="logoClick"/> -->
    <img src="../assets/RS_logo_white.png" class="logo_area"/>
    <div class="navi_area" id="navi_menu">
      <!-- プラン詳細 --> 
      <div class="navi_item_row" @click="pushPage({ name: 'Home' })">
        <i class="fa-solid fa-house-chimney" style="margin-left:20px; cursor: pointer;"></i>
        <label style="margin-left:10px; cursor: pointer;">ホーム</label>
      </div>

      <!--  -->
      <div class="navi_item_row" id="thissite"  @click="isShowInfoSubRow=!isShowInfoSubRow;isShowAccountSubRow=false;" @mouseover="isHoverThisSite=true" @mouseleave="isHoverThisSite=false">
        <img v-show="isHoverThisSite" src="../assets/recstation_logo_big.png" class="wave_logo"/>
        <img v-show="!isHoverThisSite" src="../assets/logo_wave_white.png" class="wave_logo"/>
        <label style="margin-left:10px; cursor: pointer;">本サイトについて</label>
      </div>

      <div class="navi_item_row_sub" v-show="isShowInfoSubRow" @click="openLink('https://www.iflytek.co.jp/policies/terms_sr502j/')">利用規約</div>
      <div class="navi_item_row_sub" v-show="isShowInfoSubRow" @click="openLink('https://www.iflytek.co.jp/policies/privacy_sr502j/')">プライバシーポリシー</div>
      <div class="navi_item_row_sub" v-show="isShowInfoSubRow" @click="openLink('https://www.iflytek.co.jp/policies/notation_sr502j/')">特定商取引法に基づく表記</div>
      <!-- <div class="navi_item_row_sub" v-show="isShowInfoSubRow" @click="openLink('https://www.iflytek.co.jp/company/')">運営会社</div> -->
      <div class="navi_item_row_sub" v-show="isShowInfoSubRow" @click="openLink('https://www.iflytek.co.jp/wp-content/uploads/2022/09/RECORDER-STATIONの使い方-1.pdf')">本アプリの使い方</div>

      <!-- アカウント管理 -->
      <div class="navi_item_row" @click="isShowAccountSubRow=!isShowAccountSubRow;isShowInfoSubRow=false;">
        <i class="fa-solid fa-user" style="margin-left:20px; cursor: pointer;"></i>
        <label style="margin-left:10px; cursor: pointer;">アカウント管理</label>
      </div>

      <div class="navi_item_row_sub" v-show="isShowAccountSubRow" @click="pushPage({name: 'User', params:{token: token}})">アカウント情報の確認/変更</div>
      <div class="navi_item_row_sub" v-show="isShowAccountSubRow" @click="pushPage({name: 'Security'})">パスワード変更</div>
      <div class="navi_item_row_sub" v-show="isShowAccountSubRow" @click="pushPage({name: 'Payment'})">支払い方法の管理</div>
      <div class="navi_item_row_sub" v-show="isShowAccountSubRow" @click="pushPage({name: 'Purchase'})">領収書の発行</div>

      <!-- プランの確認/変更 -->
      <div class="navi_item_row"  @click="pushPage({name: 'Plan'})">
        <i class="fa-solid fa-file-invoice-dollar" style="margin-left:20px; cursor: pointer;"></i>
        <label style="margin-left:10px; cursor: pointer;">プランの確認/変更</label>
      </div>

      <!-- お知らせ -->
      <div class="navi_item_row"  @click="pushPage({name: 'Info', params:{token: token}})">
        <i class="fa-solid fa-circle-info" style="margin-left:20px; cursor: pointer;"></i>
        <label style="margin-left:10px; cursor: pointer;">お知らせ</label>
        <i class="fa-solid fa-circle-exclamation new_notice_icon" v-show="isShowCriticalInfo" style="color:#dd4157"></i>
        <i class="fa-solid fa-message new_notice_icon" v-show="isShowNewInfo" style="color:#29aba4"></i>
      </div>

      <!-- よくある質問 -->
      <div class="navi_item_row"   @click="openLink('https://www.iflytek.co.jp/product-support/')">
        <i class="fa-solid fa-circle-question" style="margin-left:20px; cursor: pointer;"></i>
        <label style="margin-left:10px; cursor: pointer;">よくある質問/お問い合わせ</label>
      </div>

    </div>

    <div class="plan_area" v-show="isLoaduserData">
       <label class="info_label" for="">現在のプラン</label>
       <div class="user_data_label " @click="pushPage({name: 'Plan'})"> {{ userData.planData.planName }}</div>
    </div>

    <div class="cloud_storage_area" v-show="isLoaduserData">
      <label class="info_label" for="">クラウドスペース</label>
      <label class="info_label" for="" v-show="isExistCloudSpace"><div class="number_label"> {{ transByte2GB(userData.usedSpace) }}</div>GB / <div class="number_label">{{ transByte2GB(userData.totalSpace)}}</div>GB</label>
      <progress class="data_info_bar" v-bind:value="(userData.usedSpace/userData.totalSpace) * 100" max="100" v-show="isExistCloudSpace"></progress>
      <div class="user_data_label" v-show="!isExistCloudSpace" @click="openCloudSpaceDescription">クラウドスペース未受領</div>
    </div>

    <div class="dictation_time_area" v-show="isLoaduserData">
      <label class="info_label" for="">今月の文字起こし可能時間</label>
      <label class="info_label" for="" v-show="!isUnlimitedTranscription">残り<div v-bind:class="{'number_label': userData.remainTime != 0, 'overtime':  userData.remainTime <= 0}" >{{(userData.remainTime)/1000/60}}</div>分 / <div class="number_label">{{(userData.monthAvailableTime)/1000 /60}}</div>分</label>
      <progress class="data_info_bar"  v-bind:value="(userData.remainTime/userData.monthAvailableTime) * 100" max="100" v-show="!isUnlimitedTranscription"></progress>
      <div class="user_data_label" v-show="isUnlimitedTranscription" style="font-size:14px;" @click="openUnlimitedDictationDescription">∞ 無制限使い放題</div>
    </div>

    <div class="failed_get_user_data_area" v-show="!isLoaduserData">
      <label class="error_info" v-show="isShowErrorMsg">ユーザデータの読み込みに失敗しましたページを更新してください。<br><br>改善されない場合は、一度ログアウトして頂き、再度お試しください。</label>
    </div>

    <!-- <div class="media_area">
      <a href="https://www.youtube.com/channel/UC7M3UPM-cg32nb9-pKiFF8g" target="_blank"><i class="fa-brands fa-youtube sns_icon"></i></a>
      <a href="https://twitter.com/IflytekJ" target="_blank"><i class="fa-brands fa-twitter sns_icon"></i></a>
      <a href="https://www.instagram.com/iflytek_jp/" target="_blank"><i class="fa-brands fa-instagram sns_icon"></i></a>
      <a href="https://www.facebook.com/%E3%82%A2%E3%82%A4%E3%83%95%E3%83%A9%E3%82%A4%E3%83%86%E3%83%83%E3%82%AF-104951441210138/" target="_blank"><i class="fa-brands fa-facebook-f sns_icon"></i></a>
    </div> -->
    <div class="logout_btn_area">
      <div class="logout_btn" @click="logout"><i class="fa-solid fa-arrow-right-from-bracket" style="margin-right:10px;"></i>ログアウト</div>
    </div>

  </div>
</template>

<script>
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import axios from 'axios'
import Swal from 'sweetalert2'
//import DoughnutChart from '@/components/doughnut-chart.js'

export default {
  name: 'MainNavi',
  props: {
  },
  mixins: [utils, api],
  components: {
    //DoughnutChart,
  },
  data: function () {
    return {
      GB: 1024 * 1024 * 1024,
      MAXTIME: 60 * 60 * 3 * 1000,
      userData: {
        userName: "名前",
        usedSpace: 0,
        totalSpace: 0,
        remainTime: 0,
        monthAvailableTime: 0,
        planData: {
          planId: 50,
          planName: "",
          planExpireDate: 1660641797
        },

      },
      cloudChartData: {},
      timeChartData: {},
      isShowAccountSubRow: false,
      isShowInfoSubRow: false,
      isExistCloudSpace: false,
      isUnlimitedTranscription: true,
      isLoaduserData: false,
      isShowErrorMsg: false,
      showRemainTimeStr: "",
      showAvailableTime: "",
      isHoverThisSite:false,
      isShowNewInfo: false,
      isShowCriticalInfo: false
    }
  },
  created(){
  },
  mounted(){
    this.getUserData();
    this.checkNotice();
  },
  methods: {
    transByte2GB(byteNum){
       return (Number(byteNum) / this.GB).toFixed(2)
    },
    getUserData(){
      axios(this.getAxConf("get", "/users/usage", {}, this.$store.state.token))
      .then((response) => {

        if (response.data.success) {
          this.isLoaduserData = true;
          this.userData = response.data.data

          if(this.userData.totalSpace == 0){
            /**クラウド未受領 */
            this.isExistCloudSpace = false;
          }else{
             this.isExistCloudSpace = true;
          }

          if(this.userData.planData.planId !=0){
            /**ベーシックプラン以外 (無制限) */
            this.isUnlimitedTranscription = true;
          }else{
            this.isUnlimitedTranscription = false;
          }

          this.$emit('onNotifyPlanId', this.userData.planData.planId)

        }else{
          // TODO 通信失敗時の処理はどうする？
          this.isLoaduserData = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isShowErrorMsg = false;
        this.isLoaduserData = false;
      });
    },
    openCloudSpaceDescription(){
        Swal.fire({
          html: `
          <p>VOITERをアクティベートすることで<br>クラウドスペースを取得できます。<br>クラウドスペースを利用すると<br>VOITERとデータを共有することが可能です。<br>(クラウドスペースはVOITER購入者への特典となります。)</p>
          `,
          confirmButtonText: "とじる",
          confirmButtonAriaLabel: "とじる",
          confirmButtonColor: '#29aba4',
        })
    },
    openUnlimitedDictationDescription(){
      Swal.fire({
          html: `
          <p>お客様は現在、無制限で文字起こしをご利用になれます。</p>
          `,
          confirmButtonText: "とじる",
          confirmButtonAriaLabel: "とじる",
          confirmButtonColor: '#29aba4',
        })
    },
    openLink(linkurk){
      window.open(linkurk, '_blank')
    },
    checkNotice(){
      var localCount = localStorage.getItem('readcount')
      if(!localCount){
        localCount = 0;
      }
      axios(this.getAxConf("get", "/notice", {}, this.$store.state.token))
      .then((response) => {
        const dataArr = response.data.data
        const infoCount = dataArr.length;
        if(Number(infoCount) > Number(localCount)){
          this.existUnread = true;
          const recentType = dataArr[0].type; // 順番はAPI側で保障されている
          if(recentType == "03"){
            /**重要 */
            //this.showInfoToast(true);
             this.isShowCriticalInfo = true;
          }else{
            /**重要以外 */
            //this.showInfoToast(false);
            this.isShowNewInfo = true;
          }
        }
        localStorage.setItem('pageName', this.pagename);
      })
      .catch((err) => {
        console.log(err);
      });
    },
     logout(){
      // トークンを廃棄する処理
      localStorage.setItem('pageName', "");
      localStorage.setItem('token', "");
      localStorage.removeItem("itemData");
      this.pushPage({name: 'Login'});
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.leftnav {
  width: 20%;
  min-height: 100vh;
  background: #13618f;
  border-radius: 0px 15px 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
}

/* スクロールバー */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #13618f;
  border-radius: 5px;
}

/* .leftnav {
  width: 200px;
  height: max-content;
  background: #ffffff;
} */
.leftnav h3 {
  margin-left: 8px;
}
.leftnav ul {
  list-style: none;
  padding-inline-start: 0px;
  margin: 0px;
}
.leftnav li {
  padding: 14px 10px;
  border-left: 10px solid #ffffff;
}
.leftnav li:hover {
  border-left: none;
  background: linear-gradient(90deg, rgba(77, 101, 124, 0.1), transparent);
  box-shadow: -3px 4px 4px -6px black;
  border-radius: 0px 30px 30px 0px;
}

.logo_area{
  width: 100%;
  height: 10%;
  object-fit: contain;
}

.wave_logo{
  max-width: 8%;
  height: auto;
  width :auto;
  margin-top: 3px;
  margin-left:15px;
  object-fit: contain;
}

.new_notice_icon{
  text-align: right;
  animation: iconflash 2s linear infinite;
  width: max-content;
  position: absolute;
  right: 10px;
  font-size: 18px;
}


@keyframes iconflash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  
  
  100% {
    opacity: 1;
  }
}

.navi_area{
  width: 100%;
  height: 47%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.cloud_storage_area{
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dictation_time_area{
  width: 100%;
  height: 12%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan_area{
  width: 80%;
  height: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f0f8ff;
}

.failed_get_user_data_area{
  height: 38%;
  width: 80%;
}

.media_area{
  width: 80%;
  height: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f0f8ff;
  color: #FFF;
}

.sns_icon{
  color: #FFF;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.sns_icon:hover{
    color:#08ffc8;
}

.logout_btn_area{
  width: 80%;
  height: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;;
  border-top: 1px solid #f0f8ff;
}

.navi_item_row{
  width: 100%;
  height: max-content;
  color: #ffffff;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}
.navi_item_row:hover {
  /* border-left: none;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3), transparent);
  box-shadow: -3px 4px 4px -6px #08ffc8; */
  width: calc(100% - 30px);
  border-radius: 30px 30px 30px 30px;
  font-weight: bold;
  background-color: #08ffc8;
  z-index: 30;
  color: #13618f !important; 
}

.navi_item_row_sub{
  width: 80%;
  height: max-content;
  color: #ffffff;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  padding-left: 10px;
  margin-left: 20%;
}
.navi_item_row_sub:hover {
  border-left: 5px solid #08ffc8;
  color: #ffffff !important; 
  font-weight: bold;
}

.error_info{
  width: 80%;
  text-align: left;
  color: #FFF;
  font-size: 14px;
  font-weight: normal;
}

.info_label{
  width: 80%;
  text-align: center;
  color: #FFF;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.number_label{
  font-size: 20px;
  margin-left: 4px;
  margin-right: 4px;
  font-weight: bold;
}

.overtime{
  font-size: 20px;
  margin-left: 4px;
  margin-right: 4px;
  font-weight: bold;
  color: #dd4157;
}

.user_data_label{
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: #13618f;
    width: 100%;
    height: max-content;
    font-weight: bold;
    margin-top: 5px;  
    text-decoration: underline;
    cursor: pointer;
}

.user_data_label:hover {
  color: #08ffc8;
}

.logout_btn{
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    color: #ffffff;
    background-color: #13618f;
    border: 2px #13618f solid;
    border-radius: 10px;
    width: 90%;
    height: max-content;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    margin:  auto;
}

.logout_btn:hover{
    background-color: #dd4157;
    color:white;
    border: 2px #dd4157 solid;
}

.storagearea{
  text-align: center;
  margin: 10px;
}
.storagearea p{
  margin-bottom: 0px;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
}


progress {
  -webkit-appearance: none;
  border: 2px solid #ffffff;
  border-radius: 20px;
  margin-top: 5px;
}
::-webkit-progress-bar {
  background-color: #ffffff;
}
::-webkit-progress-value {
  background-color: #08ffc8;
}
.data_info_bar{
  height:10px;
  width: 90%;
}

</style>
