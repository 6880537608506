<template>
<div class="login_bg">
  <div class="login_contentarea">  
    <div class="deviceiamge">
        <img src="../assets/recorder.png" />
    </div>
    
    <div class="loginarea">
      <div class="loginlogo">
        <img src="../assets/RECSTATION_logo.png" />
      </div>
      <div class="loginwindow">
        <h4>
          <span class="titleselect">ログイン</span>
          <span class="titleselect linklike handcursor" @click="pushPage({name: 'Register'})">新規登録</span>
        </h4>
        <form>
          <div>
            <input v-model="email" name="email" type="email" placeholder="メールアドレス">
            <p class="errortext" v-if="errormessages[0]">
              {{ errormessages[0] }}
            </p>
          </div>

          <div>
            <input v-model="password" name="password" :type="inputType" placeholder="パスワード"/>
            <i v-show="!isPassShow" @click="toggleInputType" class="far fa-eye showpassicon handcursor"></i>
            <i v-show="isPassShow" @click="toggleInputType" class="far fa-eye-slash showpassicon handcursor"></i>
            <p class="errortext" v-if="errormessages[1]">
              {{ errormessages[1] }}
            </p>
          </div>
        </form>
        
        <div class="pwresetnote">
          <div v-bind:disabled="isProcessing()"
            @click="openResetWindow();" class="handcursor">パスワードを忘れた場合
          </div>
        </div>

        <span class="button loginbutton" 
          v-bind:disabled="isProcessing()"
          @click="submit">
          ログイン
        </span>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import processing from '@/mixins/processing.js'
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import axios from 'axios'
import Swal from 'sweetalert2';

export default {
  name: 'Login',
  mixins: [utils, api, processing],
  components:{
  },
  data() {
    return {
      errors: [],
      errormessages: [],
      email: "",
      password: "",
      code: "",
      isPassShow: false,
    };
  },
  computed: {
    inputType: function () {
      return this.isPassShow ? "text" : "password";
    },
  },
  created(){
  },
  mounted(){
  },
  methods:{
    showPasswordTestWindow(){
      Swal.fire({
        confirmButtonColor : '#68CFC3',
        animation : false,
        title: `パスワード表示テスト`,
        html:
          "<p onclick='alert(`ddd`)'>パスワード（大小英数字8字以上）</p>" +
          "<p><input id='pw1' type='password' class='swinput'></p>" +
          "<p>パスワード（確認）</p>" +
          "<p><input id='pw2' type='password' class='swinput'></p>" +

          '<i id="showeye" class="far fa-eye showpassicon handcursor" onclick="' +
            'document.getElementById(`pw1`).setAttribute(`type`, `text`); ' +
            'document.getElementById(`pw2`).setAttribute(`type`, `text`); ' +
            'document.getElementById(`showeye`).classList.add(`hide`); ' +
            'document.getElementById(`hideeye`).classList.remove(`hide`); ' +
            '"></i>' +
          '<i id="hideeye" class="far fa-eye-slash showpassicon handcursor hide" onclick="' +
            'document.getElementById(`pw1`).setAttribute(`type`, `password`); ' +
            'document.getElementById(`pw2`).setAttribute(`type`, `password`); ' +
            'document.getElementById(`hideeye`).classList.add(`hide`); ' +
            'document.getElementById(`showeye`).classList.remove(`hide`); ' +
            '"></i>' +
          '<style>' +
          'i{position: relative; bottom: 57px; left: 185px;}' +
          '.hide{display: none;}' +
          '</style>',
        confirmButtonText: "続行",
        confirmButtonAriaLabel: "続行",
      })
    },
    showCheckTestWindow(){
      Swal.fire({
        title: "チェックテスト",
        html: 
          this.getFileCheck() + 
          "<style>" + 
          this.getCheckStyle()+
          "</style>" ,
        input: "hide",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor : '#68CFC3',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        confirmButtonText: "ダウンロード",
        confirmButtonAriaLabel: "ダウンロード",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        preConfirm: () => {
          var elms = document.getElementsByName("checkbox")
          var contentType
          if(elms[0].checked && !elms[1].checked){
            contentType = 0
          }
          else if(!elms[0].checked && elms[1].checked){
            contentType = 1
          }
          else if(elms[0].checked && elms[1].checked){
            contentType = 2
          }
          console.log(contentType)
          
        }
      })
    },
    toggleInputType(){
      this.isPassShow = !this.isPassShow
    },
    /**
     * ログインしてホーム画面に遷移
     */
    async submit(){
      this.startProcessing()

      this.errors = 0;
      this.errormessages = [];
      if(!this.email){
        this.errors++;
        this.errormessages.push('メールアドレスを入力してください');
      }else{
        if(!this.isEmail(this.email)){
          this.errors++;
          this.errormessages.push('メールアドレスの形式で入力してください');
        }else{
          this.errormessages.push('');
        }
      }

      if(!this.password){
        this.errors++;
        this.errormessages.push('パスワードを入力してください');
      }else{
        if(!this.isAlpNum(this.password)){
          this.errors++;
          this.errormessages.push('半角英数で入力してください');
        }else{
          this.errormessages.push('');
        }
      }

      if (this.errors) {
        this.endProcessing()
        return false
      }else {

        var data = {
          "email": this.email,
          "password": this.password
        }
        await axios.post(this.getURL('/users/token'), data, this.getHeaders(""))
        .then((response) => {
          if (response.data.success) {

            this.$store.commit('setToken', response.data.data.token)
            localStorage.setItem('token', response.data.data.token);
            localStorage.removeItem("itemData");
            return this.pushPage({name: 'Home'})
          }else{
            this.showErrorWindow(response)
            this.endProcessing()
          }
        })
        .catch((response) => {
          this.showNetworkErrorWindow(response)
          this.endProcessing()
        })
      }
      
    },

  /**
   * パスワードリセットのウィンドウを表示
   */
    openResetWindow() {
      Swal.fire({
        confirmButtonColor : '#68CFC3',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        title: "アカウントのご確認",
        html: "<h4>メールアドレスを入力してください。</h4>",
        input: "text",
        inputPlaceholder: "メールアドレス",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "アカウントを確認",
        confirmButtonAriaLabel: "アカウントを確認",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              if (this.isEmail(value)) {
                resolve()
              } else {
                resolve('メールアドレスの形式で入力してください')
              }
            } else {
              resolve('メールアドレスを入力してください')
            }
          })
        },
        preConfirm: (value) => {
          const data = { tomail: value };
          return axios.post(this.getURL('/users/code/password'), data, this.getHeaders())
          .then((response) => {
            if (response.data.success) {
              this.email = value
              // return response.json(); 
            }else{
              return Swal.showValidationMessage(response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            confirmButtonColor : '#68CFC3',
            cancelButtonColor : '#808080',
            reverseButtons : true,
            animation : false,
            title: `アカウントのご確認`,
            html:
              "<h4>" + result.value + "に認証コードを送信しました。</h4>" +
              "<p>認証コード</p>",
            input: "text",
            showCancelButton: true,
            confirmButtonText: "次へ",
            confirmButtonAriaLabel: "次へ",
            cancelButtonText: "キャンセル",
            cancelButtonAriaLabel: "キャンセル",
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value) {
                  if (this.isAuthNum(value)) {
                    resolve()
                  } else {
                    resolve('数字４桁の認証コードを入力してください')
                  }
                } else {
                  resolve('数字４桁の認証コードを入力してください')
                }
              })
            },
            preConfirm: (value) => {
              var data = {
                code: value,
                mail: result.value
              }
              return axios.post(this.getURL('/users/verificationCode'), data, this.getHeaders())
              .then((response) => {
                if (response.data.success) {
                  // あとでcodeも送信する
                  this.code = value
                }else{
                  return Swal.showValidationMessage(response.data.message);
                }
              })
              .catch((err) => {
                console.log(err);
                return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
              });
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                confirmButtonColor : '#68CFC3',
                animation : false,
                title: `新しいパスワードを設定しましょう`,
                html:
                  '<p>パスワード（大小英数字8字以上）</p>' +
                  '<p><input id="pw1" type="password" class="swinput"></p>' +
                  '<p>パスワード（確認）</p>' +
                  '<p><input id="pw2" type="password" class="swinput"></p>' +

                  '<i id="showeye" class="far fa-eye showpassicon handcursor" onclick="' +
                    'document.getElementById(`pw1`).setAttribute(`type`, `text`); ' +
                    'document.getElementById(`pw2`).setAttribute(`type`, `text`); ' +
                    'document.getElementById(`showeye`).classList.add(`hide`); ' +
                    'document.getElementById(`hideeye`).classList.remove(`hide`); ' +
                    '"></i>' +
                  '<i id="hideeye" class="far fa-eye-slash showpassicon handcursor hide" onclick="' +
                    'document.getElementById(`pw1`).setAttribute(`type`, `password`); ' +
                    'document.getElementById(`pw2`).setAttribute(`type`, `password`); ' +
                    'document.getElementById(`hideeye`).classList.add(`hide`); ' +
                    'document.getElementById(`showeye`).classList.remove(`hide`); ' +
                    '"></i>' +
                  '<style>' +
                  'i{position: relative; bottom: 57px; left: 185px;}' +
                  '.hide{display: none;}' +
                  '</style>',
                input: "hidden",
                confirmButtonText: "続行",
                confirmButtonAriaLabel: "続行",
                inputValidator: (value) => {
                  var value1 = document.getElementById('pw1').value;
                  var value2 = document.getElementById('pw2').value;
                  value = value1;
                  return new Promise((resolve) => {
                    if(value != value2){
                      resolve('入力した値が異なります')
                    }
                    if (value1 || value2) {
                      if (this.isAlpNum(value1) && this.isLeastLength(value1, 8)) {
                        resolve()
                      } else {
                        resolve('半角英数８文字以上で入力してください')
                      }
                    } else {
                      resolve('パスワードを入力してください')
                    }
                  })
                },
                preConfirm: () => {
                  var data = {
                    email: this.email,
                    password: document.getElementById('pw1').value,
                    code: this.code,
                  }
                  return axios.put(this.getURL('/users/password'), data, this.getHeaders())
                  .then((response) => {
                    if (response.data.success) {
                      // return response.json(); 
                    }else{
                      return Swal.showValidationMessage(response.data.message);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
                  });
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire({
                    confirmButtonColor : '#68CFC3',
                    animation : false,
                    title: `新しいパスワードを設定しましょう`,
                    html: "<p>新しいパスワードの設定に成功しました。</p>",
                    confirmButtonText: "戻る",
                    confirmButtonAriaLabel: "戻る",
                  })
                  /*
                  .then(()=>{
                    var param = {
                      email: this.email,
                      password: document.getElementById('pw1').value
                    }
                    this.sendLogin(param)
                    .then((response) => {
                      if (!response.data.success) {
                        throw new Error(response);
                      }
                      var param = {
                        name: 'Home',
                        params: {
                          token: response.data.data.token
                        }
                      }
                      localStorage.setItem('token', response.data.data.token);
                      this.pushPage(param)
                    })
                    .catch((response) => {
                      this.showNetworkErrorWindow(response)
                    })
                  });

                  */
                }
              });
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.login_bg {
  background-image: url(../assets/bg_login.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  margin: 0px;
}
.login_contentarea {
  position: relative;
  display: flex;
  width: 1000px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.deviceiamge{
  position: absolute;
  top: calc(50% - 250px);
  left: 150px;
}
.deviceiamge > img {
  height: 500px;
}

.loginarea {
  position: absolute;
  top: calc(50% - 250px);
  right: 50px;
  width: 300px;
  z-index: 2;
  text-align: center;
}
.loginlogo > img {
  height: 50px;
}
.loginwindow{
  background-color: rgba(70,130,180,0.8);
  box-shadow: 5px 5px 15px #000000;
  padding: 20px;
  color: #ffffff;
  height: 350px;
}
.titleselect {
  margin: 0px 20px;
}
.loginwindow form {
  margin-top: 40px;
}
.loginwindow form p {
  text-align: left;
}
.loginwindow form div {
  height: 80px;
}
.loginwindow input {
  border: 0px;
  border-bottom: 1px solid #ffffff;
  background: rgba(11,11,11,0);
  width: 240px;
  padding: 5px 10px;
  color: #ffffff;
  font-size: 16px;
}
.loginwindow input::placeholder{
  color: #ffffff;
}
.loginwindow button {
  margin-top: 25px;
  padding: 5px 20px;
  font-size: 14px;
}
.pwresetnote {
  display: flex;
}
.pwresetnote > div {
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: auto;
  color: #ffffff;
  font-size: 12px;
}
.showpassicon{
  position: absolute;
  right: 30px;
  margin-top: 8px;
  color: #ffffff;
}
.loginbutton{
  position: absolute;
  top: 360px;
  left: 50px;
}
</style>