<template>
  <div id="app">
    <!--
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/login">Login</router-link>
    </div>
    -->
    <router-view/>
  </div>
</template>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons%7CMaterial+Icons+Outlined";
/* @import "https://fonts.googleapis.com/earlyaccess/notosansjp.css"; */


/**
https://encycolorpedia.jp/13618f

紺色
13618f

GR
29aba4

RED
dd4157

灰色
515151

薄い青
4682b4

白
ffffff

黒
221816

ミモザ(黄色)
fff462

白
dbe3ec

*/
:focus {
  outline: none;
}
a {
  text-decoration: none;
}
body {
  margin: 0px;
  color: #515151;
  background: #ffffff;
  font-family: 'Noto Sans JP', sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

/* スクロールバー */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #94acc6;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #13618f;
  border-radius: 5px;
}


table {
  border-collapse: collapse;
}
.linklike{
  color: #4682b4;
  text-decoration: underline;
}
.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}
.hidden {
  display: none;
}
.maincontent {
  /* margin-left: 10px; */
  width: 80%;
  height: 100vh;
  background: #f0f8ff;
  overflow-y: hidden;
  font-size: 16px;
}
.maincontent::-webkit-scrollbar {
  display: none;
}
.documentcontent {
  width: 100%;
  height: 100%;
  text-align: center;
}
.contentarea {
  /* margin-top: 55px; ヘッダーの分 */
  display: flex;
  width: 100%;
  height: 97%;
  margin-left: auto;
  margin-right: auto;
  background-color: #f0f8ff;
}
.toollist {
  display: flex;
  padding: 8px;
  margin-bottom: 5px;
  color: #808080;
  border-bottom: 1px solid #dcdcdc;
  font-size: 18px;
}

/* ボタン */
.button {
  display: inline-block;
  width: 200px;
  height: 54px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  line-height: 54px;
  outline: none;
  font-size: 14px;
}
.button::before,
.button::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: "";
}
.button,
.button::before,
.button::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.button {
  /* 初期デザインカラー
  background-color: #1e90ff;
  border: 2px solid #1e90ff;
  */
  background-color: #29aba4;
  border: 2px solid #29aba4;
  color: #fff;
  line-height: 50px;
  cursor: pointer;
}
.button:hover {
  background-color: #fff;
  /* 初期デザインカラー
  border-color: #009999;
  color: #009999;
  */
  border-color: #29aba4;
  color: #29aba4;
}
.canselbutton {
  background-color: #ffffff;
  border: 2px solid #808080;
  color: #808080;
}
.canselbutton:hover {
  background-color: #fff;
  /* 初期デザインカラー
  border-color: #009999;
  color: #009999;
  */
  border-color: #29aba4;
  color: #29aba4;
}
.smallbutton {
  font-size: 10px;
  width: 140px;
  line-height: 27px;
  height: 30px;
}
.attentionbutton {
  background-color: #ffffff;
  border: 2px solid #dd4157;
  color: #dd4157;
}
.attentionbutton:hover {
  background-color: #dd4157;
  border-color: #dd4157;
  color: #ffffff;
}
.ta-c {
    text-align: center;
}
.mr-5{
  margin-right: 5px;
}

.grayout {
  background: #c0c0c0;
  padding: 10px 0px 10px 0px;
}
.swinput {
  height: 2.625em;
  padding: 0 0.75em;
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  color: inherit;
  font-size: 1.125em;
  margin: 0px auto;
}
.halfblock {
  width: calc(50% - 40px);
  margin: 20px;
}
.errortext{
  color: #dd4157;
  font-size: 12px;
  font-weight: 600;
}
.errortext ul{
  padding-inline-start: 0px;
  list-style: none;
}
.handcursor{
  cursor: pointer;
}
.textcursor{
  cursor: text;
}
.arrowcursor{
  cursor: default;
}
.audioicon{
  /* color: #CAE7F2*/
  color: #13618f
}
.imageicon{
  color: #13618f
}
.videoicon{
  color: #13618f
}
</style>

<script>
// import Swal from 'sweetalert2';
export default {
  name: 'App',
  props: {
  },
  components: {
  },
  data: function () {
    return {
    }
  },
  created(){
  },
  watch: {
    /*
    // 画面遷移をキャッチしたいときに使う
    $route (to, from, next) {
      if(from.fullPath.includes("dictation")){
        Swal.fire({
          animation : false,
          html:
            "<p>" +
            "ページを離れようとしています。よろしいですか？" + 
            "</p>" +
            "<style>" +
            "p{font-size: 14px;}" +
            "</style>",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "移動する",
          confirmButtonAriaLabel: "移動する",
          cancelButtonText: "キャンセル",
          cancelButtonAriaLabel: "キャンセル",
          allowOutsideClick : false,
        }).then(value=>{
          if(value){
            next()
          }else{
            next(false)
          }
        })
      }
      
    }*/
  },
  methods: {
  },
}
</script>
