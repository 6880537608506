
/**
 * 入力制御（多重送信防止、ローディングなど）
 */
export default {
  data() {
    return {
      processing: false
    }
  },
  created(){
  },
  mounted(){
  },
  methods: {
    startProcessing: function () {
      this.processing = true
    },
    endProcessing: function () {
      this.processing = false
    },
    isProcessing: function () {
      return this.processing
    }
  },
}