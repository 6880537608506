<template>
  <modal name="loading" :draggable="false" :resizable="false" :clickToClose="false" width="150px" height="150px" class="loading">
    <div class="leftring">
      <vue-loaders name="ball-scale-ripple" color="#1E608F" scale="1.3"></vue-loaders>
    </div>
    <div class="rightring">
      <vue-loaders name="ball-scale-ripple" color="#1E608F" scale="2"></vue-loaders>
    </div>
    <p>now loading...</p>
  </modal>
</template>

<script>
export default {
  name: 'Loading',
  props: {
  },
  data: function () {
    return {
    }
  },
  methods: {
    stopLoading(){
      this.$modal.hide('loading');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.leftring{
  position: fixed;
  margin-left: 15px;
  margin-top: 50px;
  
}
.rightring{
  position: fixed;
  margin-left: 60px;
  margin-top: 50px;
}
p{
  position: fixed;
  margin-left: 25px;
  margin-top: 130px;
  color: #1E608F;
}
</style>
<style>
.loading .vm--overlay{
  background: rgba(255, 255, 255, 0.6) !important;
}
.loading .vm--modal{
  background: rgb(0 0 0 / 0%) !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
</style>
