<template>
  <div class="home_bg">
    <Header pagename="Low" />
    
    <div class="contentarea">
      <div class="infocontent">
        <div class="toollist">
          <div class="traillist">特定商取引法に基づく表記</div>
        </div>
        <div class="itemarea">
          <table>
            <tbody>
              <tr>
                <td>事業者</td>
                <td>iFLYTEK JAPAN AI SOLUTIONS 株式会社</td>
              </tr>
              <tr>
                <td>所在地</td>
                <td>〒110-0015 東京都台東区東上野2-2-4 2F</td>
              </tr>
              <tr>
                <td>屋号またはサービス名</td>
                <td>RECORDER STATION</td>
              </tr>
              <tr>
                <td>電話番号（受付時間）</td>
                <td>03-4360-3753（10:00〜17:00（土日祝を除く））</td>
              </tr>
              <tr>
                <td colspan="2" class="note">
                  本Webアプリ「RECORDER STATION」に関するお問い合わせは、ログイン後ヘルプからお問い合わせください。
ご不便をおかけいたしますが、ご理解ご協力のほどよろしくお願いいたします。
                </td>
              </tr>
              <tr>
                <td>メールアドレス</td>
                <td>support@recstation.jp</td>
              </tr>
              <tr>
                <td>販売価格</td>
                <td>サービスの詳細ページをご参照ください。</td>
              </tr>
              <tr>
                <td>サービス料金以外にお客様が負担すべき料金</td>
                <td>
                  消費税10%<br />
                  支払方法により、ご利用のクレジットカードの定めによる分割払い手数料
                </td>
              </tr>
              <tr>
                <td>支払方法</td>
                <td>
                  各種クレジットカード（Visa、MasterCard、JCB、American Express、Diners）から選択できます。<br />
                  本サイトでは、安全にクレジットカード決済を行うために決済代行会社を採用しています。<br />
                  カード会社の承認が取れない場合は、ご注文をお断りすることがあります。
                </td>
              </tr>
              <tr>
                <td>支払時期</td>
                <td>ご利用のクレジットカードの定めによります。</td>
              </tr>
              <tr>
                <td>サービスの引渡し時期</td>
                <td>信用承認と同時に、設定完了メールをお送りいたします。</td>
              </tr>
              <tr>
                <td>返品（キャンセル）</td>
                <td>サービスの性質上、返品（キャンセル）は承っておりませんのでご了承ください。
性質上、返品扱いではなく、不良時の対応となります。</td>
              </tr>
              <tr>
                <td>不良時の対応</td>
                <td>コンテンツファイルが破損等により、ダウンロード・閲覧とも不可能だった場合、弊社の裁量にて課金の取消を行います。
お客様のご利用環境による不具合等につきましては不良時に該当いたしません。</td>
              </tr>
              <tr>
                <td>ソフトウェアの動作環境</td>
                <td>
                  Google Chrome(最新版)<br />
                  上記推奨環境（推奨ブラウザ）外からのアクセスについては、動作保証を行っておりません。
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Low',
  props: {
    pagename: String,
  },
  components: {
    Header,
    Footer,
  },
  data: function() {
    return {
      target: {},
    }
  },created(){
  },
  methods: {
  },
}
</script>

<style scoped>
.infocontent {
  margin-left: 10px;
  width: 100%;
  background: #ffffff;
  overflow-y: scroll;
  font-size: 14px;
}
.infocontent::-webkit-scrollbar {
  display: none;
}
.infocategory {
  border: 1px solid #333333;
  padding: 5px;
  margin-right: 10px;
  border-radius: 3px;
}
.infosubdata {
  margin-bottom: 80px;
  display: flex;
}
.infocatedate {
  margin-left: auto;
}

.itemarea {
  margin-bottom: 20px;
  padding: 20px;
}
.itemarea table {
  width: 100%;
}
.itemarea tr {
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
}
.itemarea tr:nth-child(even) {
  background: #ffffff;
}
.itemarea td {
  padding: 30px 20px;
}
.itemarea td.note{
  padding: 0px 150px 20px 150px;
  color: #808080;
  font-size: 12px;
}
.itemarea p {
  padding: 20px;
}
.itemarea i {
  vertical-align: middle;
}

</style>
