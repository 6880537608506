<template>
  <div class="home_bg">
    <Header pagename="Purchase" />
    <div class="contentarea">
      <MainNavi pagename="purchase" />
      <div class="maincontent">
        <div class="documentcontent">
          <h2 style="text-align: center;">購入履歴</h2>
          <p>注文履歴の確認、関連するお支払いの表示などを行います。</p>
          <div class="purchasearea">
            <table>
              <thead>
                <tr>
                  <td style="text-align: center; width: 15%;">日付</td>
                  <td style="text-align: center; width: 50%;">内容</td>
                  <td style="text-align: center; width: 20%;">金額</td>
                  <td style="text-align: center; width: 15%;">領収書</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in items" :key="item.index">
                  <td style="text-align: center; width: 15%;">{{ item.paymentDay }}</td>
                  <td style="text-align: center; width: 60%;">{{ item.productName }}</td>
                  <td style="text-align: center; width: 10%;">{{ item.amount }}</td>
                  <td style="text-align: center; width: 15%;">
                    <span @click="inputUserName(item.paymentId)" class="button smallbutton">
                    ダウンロード
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainNavi from '@/components/MainNavi.vue'
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'Payment',
  props: {
    pagename: String,
  },
  mixins: [utils, api],
  components: {
    Header,
    Footer,
    MainNavi,
  },
  data: function() {
    return {
      items: [{
        paymentId: "123",
        productName: "使い放題プラン○月とか",
        amount: "￥2,180",
        paymentDay: "9月1日"
      }]
    }
  },created(){
  }, mounted(){
    this.setItems()
  },
  methods: {
    setItems(){
      axios(this.getAxConf("get", "/payment/history/list", {}, this.$store.state.token))
      .then((response) => {
        if (response.data.success) {
          this.items = response.data.data.paymentList;
        }else{
          return this.showErrorWindow(response);
        }
      })
      .catch((err) => {
        return this.showNetworkErrorWindow(err);
      });
    },
    inputUserName(paymentId){
      var id = paymentId;
       console.log("id : " + id)
      Swal.fire({
        title: "宛名を入力",
        html: 
          "<p>領収書の宛名を入力してください</p>" + 
          "<style>" + 
          ".note {font-size: 11px;}" +
          "</style>" ,
        input: "text",
        inputPlaceholder: "株式会社＊＊＊＊",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor : '#29aba4',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        confirmButtonText: "決定",
        confirmButtonAriaLabel: "決定",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        showLoaderOnConfirm: true,
        inputValidator: (regCode) => {
          return new Promise((resolve) => {
            if (regCode) {
              resolve()
            } else {
              resolve('宛名を入力してください。')
            }
          })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result)=>{
        if(!result.isConfirmed){
          return false
        }
        const data = {
          name: result.value,
        }
        console.log("id : " + id)
        this.getReceipt(id, data)
      })
    },
    getReceipt(paymentId,reqdata){
       axios.post(this.getURL('/payment/receipt/'+ paymentId), reqdata, this.getHeaders(this.$store.state.token))
      .then(result=>{
        if(result.data.success){
          this.dlReceipt(result.data.data.url)
        }else{
          Swal.fire({
            html: `
            <p>領収書URLの取得に失敗しました。</p>
            <p>` + result.data.message + `</p>
            `,
            confirmButtonText: "とじる",
            confirmButtonColor:"#29aba4",
            confirmButtonAriaLabel: "とじる",
          })
        }
      })
      .catch((err) => {
        Swal.fire({
          html: `
          <p>領収書URLの取得に失敗しました。</p>
          <p>` + err + `</p>
          `,
          confirmButtonText: "とじる",
          confirmButtonColor:"#29aba4",
          confirmButtonAriaLabel: "とじる",
        })
      });
    },
    dlReceipt(url){
      // axios(url)
      // .then(response=>{
      //   const filename = "領収書_RECORDERSTATION_" + this.getNowDate() + ".pdf"
      //   const blob = new Blob([response.data], { type: 'application/pdf' })
      //   const uri = URL.createObjectURL(blob)
      //   const link = document.createElement('a')
      //   link.download = filename
      //   link.href = uri
      //   link.click()
        
      // })
      const a = document.createElement("a")
      document.body.appendChild(a)
      a.download = "領収書_RECORDERSTATION.pdf";
      a.href = url
      a.click()
      a.remove()
    },
  },
}
</script>

<style>

/* スクロールバー */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #13618f;
  border-radius: 5px;
}

.purchasearea {
  margin-left: auto;
  margin-right: auto;
  width: 98%;
  height: 80vh;
  padding: 5px;
  overflow-y: auto;
  font-size: 12px;
}
.purchasearea table {
  border-collapse: collapse;
  width: 100%;
}
.purchasearea td {
  border-bottom: 1px solid #d3d3d3;
  padding: 10px;
}
</style>
