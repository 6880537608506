<template>
  <modal name="process" :draggable="false" :resizable="false" :clickToClose="false" width="150px" height="150px" class="process">
    <div class="leftring">
      <vue-loaders name="ball-scale-ripple" color="#1E608F" scale="1.3"></vue-loaders>
    </div>
    <div class="rightring">
      <vue-loaders name="ball-scale-ripple" color="#1E608F" scale="2"></vue-loaders>
    </div>
    <p>{{msgStr}}</p>
  </modal>
</template>

<script>
export default {
  name: 'Process',
  props: {
    message:String
  },
  watch:{
    message(newVal){
      this.msgStr = newVal
    },
  },
  data: function () {
    return {
      msgStr: ""
    }
  },
  methods: {
    stopLoading(){
      this.$modal.hide('process');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.leftring{
  position: fixed;
  margin-left: 15px;
  margin-top: 50px;
  
}
.rightring{
  position: fixed;
  margin-left: 60px;
  margin-top: 50px;
}
p{
  position: fixed;
  font-size: 18px;
  margin-left: 25px;
  margin-top: 130px;
  color: #1E608F;
}
</style>
<style>
.process {
    width: max-content !important;
    height: max-content !important;
    z-index: 999;
}
.process .vm--overlay{
  background: rgba(255, 255, 255, 0.6) !important;
  width: max-content;
}
.process .vm--modal{
  background: rgb(0 0 0 / 0%) !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
</style>
