<template>
  <div class="home_bg">
    <Header pagename="Plan" />
    <div class="contentarea">
    <MainNavi />
    <div class="planPageContentArea" v-show="isPlanDataLoaded">
      <div v-show="descriptionFlag" class="planPageOverlay">
        <div class="descriptionPopUp">
          <div class="joinPopupTitle">プランの変更</div>
          <div class="joinPopupContent">
            <div class="joinDesc" v-html="planDescription"></div>
          </div>
          <div class="joinPopupFooter">
            <div class="joinPopupBtn" @click="descriptionFlag=false">とじる</div>
            <div class="joinPopupBtn" v-show="isJoinPopupEnable" @click="clickJoinBtn">加入する</div>
          </div>
        </div>
      </div>
      <div class="plancontent">
        <div class="userPlanHr"></div>
        <div class="userPlanInfoRow">
          <div class="planDataArea">
            <div class="currentPlanHr"></div>
            <div class="currentPlanName">{{ userPlan.planName }}</div>
            <div class="currentPlanPeriod">プランの有効期間 :
              {{getCurrentPlanExpiredTxt}}</div>
          </div>
          <div class="nextPlanTriangle" v-show="isShowNextPlan"></div>
          <div class="planDataArea" v-show="isShowNextPlan">
            <div class="nextPlanHr"></div>
            <div class="nextPlanName">{{ userPlan.nextPlanName }}</div>
            <div class="nextPlanName">
              プランの開始日 : {{ userPlan.nextPlanApplicableDate.split(' ')[0].replaceAll('-','/') }}から適用
            </div>
          </div>
        </div>
        <div class="planMenuHr" v-show="!showPlanSelectWindow && userPlan.planId != 999"></div>
        <div class="planMenuArea" v-show="!showPlanSelectWindow && userPlan.planId != 999">
          <div class="MenuDescriptionArea">
            <div class="planMenuTitle">ベーシックプラン(無料)</div>
            <div class="MenuDescription">
              ベーシックプランでは、月に<b>3時間</b>までVOITERでの音声文字起こしをご利用いただけます。<br>※毎月月初に、文字起こし可能時間(3時間)が付与されます。</div>
          </div>
          <div class="MenuDescriptionArea">
            <div class="planMenuTitle">使い放題プラン(有料)</div>
            <div class="MenuDescription">
              VOITERでの音声文字起こしを<b>無制限</b>でご利用いただけます。
              毎月自動決済の月額支払いと購入後1年間有効な年額払いがお選びいただけます。
              その他にもアクティベーションコードを用いて加入することも可能です。<br>
              <div style="font-size:11px">
                ※料金は全て前払いとなります。<br>
                ※月額払いを選択した場合、加入月のサービス料金は加入日に応じた、日割計算となります<br>
                ※月額払いを選択した場合、毎月1日に自動でサービス料金が決済され、プランが自動更新されます<br>
                ※年額払いを選択した場合、プランは自動更新されません<br>
                ※決済完了後のキャンセル・払い戻しはできまかねます<br>
                ※プラン解約に関する制約はございません<br>
              </div>
            </div>
          </div>
        </div>

        <div style="openBtnRow" v-show="userPlan.planId != 999">
          <div class="openPlanSelectBtn" v-show="showPlanSelectWindow"
            @click="showPlanSelectWindow=!showPlanSelectWindow">とじる</div>
          <div class="openPlanSelectBtn" v-show="!showPlanSelectWindow"
            @click="showPlanSelectWindow=!showPlanSelectWindow">プランを変更する</div>
        </div>
        <div style="text-align: center;" v-show="showPlanSelectWindow">
          <div class="triangle" :class="{triangleAnime: showPlanSelectWindow}"></div>
        </div>

        <div class="planSelectRow" v-show="showPlanSelectWindow">
          <div class="planSelectWindow">
            <div class="planColumn">
              <div class="planJoinColumnTitle">ベーシックプラン</div>
              <div class="planBtn" @click="showPlanJoinDesc(getPlanData(0))" v-text="getBasicPlanJoinBtnText"></div>
            </div>
            <div class="planColumn">
              <div class="planJoinColumnTitle">使い放題プラン</div>
              <div class="planBtn" @click="showPlanJoinDesc(getPlanData(1))">2,180円 / 月</div>
              <div class="planBtn" @click="showPlanJoinDesc(getPlanData(10))">23,980円 / 年</div>
            </div>
            <div class="planColumn">
              <div class="planJoinColumnTitle">コード利用によるプラン加入</div>
              <div class="planBtn" @click="showPlanJoinDesc(getPlanData(11))">「文字起こしサービス1年プラン」をお持ちの方はこちら</div>
              <div class="planBtn" @click="showPlanJoinDesc(getPlanData(100))">法人プラン用のコードをお持ちの方はこちら</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Swal from 'sweetalert2'
import MainNavi from '@/components/MainNavi.vue'
import axios from "axios";
import api from "@/mixins/api.js";

export default {
  name: 'Plan',
  props: {
    pagename: String,
  },
  components: {
    Header,
    Footer,
    MainNavi
  },
  mixins: [api],
  data: function() {
    return {
      showPlanSelectWindow: false,
      isExistNextPlan: false,
      descriptionFlag: false,
      planDescription: "fdasfdsfdsfsd",
      isJoinPopupEnable:false,
      selectedPlan: null,
      handleGmoWindow: null,
      isPlanDataLoaded: false,
      planList: [
        {
          planId: 0,
          planDetail: "プランの説明",
          planName: "プラン名称",
          amount: "0", //税込み金額
          planEnable: true,
          joinable: true,
          payMethod: ["None"], // ["registrationCode"],
          descHtml:""
        }
      ],
      userPlan: {
        planId: 1,
        planName: "プラン名称",
        planExpireDate: "2024-03-31 23:59:59",
        nextPlan: 0,
        nextPlanName: "次のプラン名称",
        nextPlanApplicableDate: "2024-03-31 23:59:59",
      }
    }
  },
  computed:{
    viewPlanList: function(){
      return this.planList.filter(plan=>{
        return plan.planEnable
      })
    },
    getCurrentPlanExpiredTxt() {
      if(this.userPlan.planId == 0){
        return "無期限";
      }else if(this.userPlan.planId == 1 && this.userPlan.nextPlan == 1){
        return "毎月1日自動更新";
      }else if(this.userPlan.planId == 999){
        return "無期限"
      }
      return this.userPlan.planExpireDate.split(' ')[0].replaceAll('-','/') +"まで有効";
    },
     isShowNextPlan() {
      if(this.userPlan.planId == 0){
        return false;
      }else if(this.userPlan.planId == 1 ){
        if(this.userPlan.nextPlan != 1 ){
          /* 現在月額で次回ベーシック(=今月解約) */
          return true;
        }else{
          return false;
        }
      }else if(this.userPlan.planId == 999 ){
        return false;
      }else{
        return true;
      }
    },
    getBasicPlanJoinBtnText(){
      if(this.userPlan.planId == 0){
        return "現在加入中";
      }else if(this.userPlan.planId == 1 && this.userPlan.nextPlan == 1){
        return "月額プランを解約";
      }else if(this.userPlan.planId == 999){
        return "変更する"
      }
      return "変更する"
    },
    getPlanDescription(){
      var targetPlanData = this.getPlanData(this.userPlan.planId)
      if(targetPlanData != null){
        return targetPlanData.planDetail
      }else{
        return ""
      }

    }
  },
  created(){
    //console.log("Plan.vue created")
  },
  mounted(){
    //console.log("Plan.vue mounted")
    this.getPlanList()
    this.getUserPlan()
  },
  methods: {
    /**
     * プラン一覧の取得
     */
    getPlanList(){
      axios(this.getAxConf("get", "/plan/list", {}, this.$store.state.token))
      .then((result)=>{
        if(result.data.success){
          this.planList = result.data.data.planList
        }
      })
      .catch((err) => {
        Swal.fire({
          html: `
          <p>プラン一覧の取得に失敗しました。</p>
          <p>` + err + `</p>
          `,
          confirmButtonText: "とじる",
          confirmButtonAriaLabel: "とじる",
        })
      });
    },

    /**
     * ユーザの現在のプラン情報を取得
     */
    getUserPlan(){
      console.log("token : " + this.$store.state.token)
      axios(this.getAxConf("get", "/users/plan", {}, this.$store.state.token))
      .then((result)=>{
        if(result.data.success){
          this.userPlan = result.data.data
          this.isPlanDataLoaded = true;
        }else{
          this.showErrorWindow(result);
        }
      })
      .catch(() => {
        Swal.fire({
          html: `
          <p>現在のプランの取得に失敗しました。</p>`,
          confirmButtonText: "とじる",
          confirmButtonAriaLabel: "とじる",
        })
      });
    },

    /**
     * アクティベーションコードでプラン登録
     */
    contractRegistCode(plan){
      Swal.fire({
        title: "アクティベーションコードを入力",
        html: 
          "<p>アクティベーションコードを入力してください。</p>" + 
          "<p class='note'>※アクティベーションコードは、家電量販店や、各種販売代理店、公式オンラインショップ、弊社法人窓口等で購入できます。</p>" + 
          "<style>" + 
          ".note {font-size: 11px;}" +
          "</style>" ,
        input: "text",
        inputPlaceholder: "アクティベーションコードを入力",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor : '#29aba4',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        confirmButtonText: "決定",
        confirmButtonAriaLabel: "決定",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        showLoaderOnConfirm: true,
        inputValidator: (regCode) => {
          return new Promise((resolve) => {
            if (regCode) {
              resolve()
            } else {
              resolve('コードを入力してください。')
            }
          })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result)=>{
        if(!result.isConfirmed){
          return false
        }
        const data = {
          planId: plan.planId,
          howToJoin: "registrationCode",
          registrationCode: result.value,
        }
        this.changePlan(data)
      })
    },
    checkOpenGmo(url){
      Swal.fire({
        html: `
        <h4>決済画面に移動</h4>
        <p>
          「OK」を押すと、外部決済画面が別のタブで表示されます。<br />
        </p>
        <a href="https://www.iflytek.co.jp/policies/notation_sr502j/" target="_blank">特定商取引法に基づく表記はこちら</a>
        <style>
          p {font-size: 14px; text-aline: left;}
        </style>
        `,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor : '#29aba4',
        cancelButtonColor : '#808080',
        reverseButtons : true,
        animation : false,
        confirmButtonText: "開く",
        confirmButtonAriaLabel: "開く",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
      }).then(result=>{
        if(result.isConfirmed){
          this.handleGmoWindow = window.open(url, "_blank");
          // this.handleGmoWindow.addEventListener('beforeunload', () => {
          //   console.log("beforeunload")
          //   if(this.handleGmoWindow.location.href==='about:blank') return;
          //   alert('beforeunload');
          // }, false);
          const timer = setInterval(() => {
            if(this.handleGmoWindow.closed) {
              window.clearInterval(timer);
              this.handleGmoWindow = null;
              this.$router.go({path: this.$router.currentRoute.path, force: true})
            } 
          }, 500);
          return;
        }else{
          return;
        }
      })
    },

    /**
     * クレジット決済でプラン登録
     */
    contractCredit(plan){
        const data = {
          planId: plan.planId,
          howToJoin: "credit",
          registrationCode: "",
        }
        if(this.handleGmoWindow != null){
            Swal.fire({
              html: `
              <p>既に別のタブで決済画面を開いている状態です。</p>
              `,
              confirmButtonText: "とじる",
              confirmButtonAriaLabel: "とじる",
            });
            return;
        }else{
          this.changePlan(data)
        }
    },
    clickJoinBtn(){
      var payMethod = this.selectedPlan.payMethod;
      if(payMethod == "credit"){
        this.contractCredit(this.selectedPlan)
      }else if(payMethod == "registrationCode"){
        this.contractRegistCode(this.selectedPlan)
      }else{
        if(this.selectedPlan.planId == 0){
          /* ベーシックプランへ変更 */
          const data = {
            planId: this.selectedPlan.planId,
            howToJoin: "other",
            registrationCode: "",
          }
          this.changePlan(data)
        }
        return;
      }
      this.descriptionFlag=false;
    },

    /**
     * プラン変更API実行
     */
    changePlan(data){
      axios.put(this.getURL('/users/plan'), data, this.getHeaders(this.$store.state.token))
      .then(result=>{
        if(result.data.success){
          // クレジットの場合はGMOの画面を開く
          if(data.howToJoin=="credit"){
            if(result.data.data.paymentUrl){
              this.checkOpenGmo(result.data.data.paymentUrl);
                //window.open(result.data.data.paymentUrl, '_blank')
            }
          }else{
            this.showPlanFinishWindow(result.data.data)
            //this.$router.go({path: this.$router.currentRoute.path, force: true})
          }

          // 結果表示
          //this.showPlanFinishWindow(result.data.data)
        }else{
          Swal.fire({
          html: `
          <p>プランの登録に失敗しました。</p>
          <p>` + result.data.message + `</p>
          `,
          confirmButtonText: "とじる",
          confirmButtonAriaLabel: "とじる",
        })
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          html: `
          <p>プランの登録に失敗しました。</p>
          <p>` + err + `</p>
          `,
          confirmButtonText: "とじる",
          confirmButtonAriaLabel: "とじる",
        })
      });
    },

    /**
     * プラン変更結果ウィンドウを表示
     */
    showPlanFinishWindow(){
      Swal.fire({
        html: `
        <h5>プランの変更に成功しました。</h5>
        <style>
          p {font-size: 14px; text-aline: left;}
        </style>
        `,
        confirmButtonText: "とじる",
        confirmButtonAriaLabel: "とじる",
      }).then(()=>{
        // リロード
        this.$router.go({path: this.$router.currentRoute.path, force: true})
      })
    },
    openPlanSelectWin(){
      var element = document.documentElement;
      var bottom = element.scrollHeight - element.clientHeight;
      window.scroll(0, bottom);
      this.showPlanSelectWindow = !this.showPlanSelectWindow;
    },

    /**
     * ******************************************************
     * ユーティリティ
     * ******************************************************
    */
    getPlanData(plan_id){
      console.log(plan_id)
      var target = null;
      this.planList.forEach(function (planData) {
        if(planData.planId == plan_id){
          target = planData;
        }
      });
      return target;
    },
    getButtonStatus(plan){
      if(plan.planId == this.userPlan.planId){
        return 0
      }
      if(plan.joinable){
        return 1
      }else{
        return 2
      }
    },
    getButtontext(plan){
      if(plan.planId == this.userPlan.planId){
              return "加入中"
      }
      if(plan.joinable){
        return "このプランに加入する"
      }else{
        return "加入できません"
      }
    },
    getViewAmount(num){
      var yen = Number(num).toLocaleString()
      if(yen == 0){
        return "別途"
      }else{
        return '￥' + Number(num).toLocaleString()
      }
    },
    getViewExDate(dateStr){
      if(!dateStr){
        return ""
      }else{
        const y = dateStr.substr(0, 4)
        const m = dateStr.substr(5, 2)
        const d = dateStr.substr(8, 2)
        return y + "年" + m + "月" + d + "日" 
      }
    },
    getViewPlanname(name){
      if(!name){
        return "--"
      }else{
        return name.replace(" ", "\n")
      }
    },
    getTrimPlan(html){
      const text = html.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
      if(text.length < 50){
        return text
      }else{
        return text.substr(50, 10) + "…"
      }
    },
    showPlanJoinDesc(plan){
        this.planDescription = plan.descHtml;
        this.isJoinPopupEnable = plan.joinable
        this.selectedPlan = plan;
        this.descriptionFlag = true
    },
    closePlanDesc(){
         this.descriptionFlag = false
      },
  },
}
</script>

<style>

.planPageContentArea {
  display: flex;
  width: 80%;
  height: 100vh;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
}
.plancontent {
  margin: 10px;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.plancontent::-webkit-scrollbar {
  display: none;
}
.userPlanInfoRow{
  text-align: center;
  margin: auto;
  margin-top: 1px;
  margin-bottom: 1px;
  height: max-content;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.planDataArea{
  margin: 10px;
  height: max-content;
  width: max-content;
  max-width: 500px;
  padding: 20px;
  text-align: center;
  border: 5px solid #29aba4;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nextPlanTriangle{
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid #29aba4;
  transform: rotate(90deg);
}

.currentPlanName{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.currentPlanPeriod{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.nextPlanName{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.currentPlanDescription{
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.pageTitle{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.planMenuArea{

  height: max-content;
  padding: 5px;
  text-align: center;
  /* margin: auto; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.MenuDescriptionArea{
  padding: 15px 15px;
  text-align: center;
  box-shadow: 0px 1px 10px -5px black;
  border-radius: 8px;
  background: #ffffff;
  width: 45%;
  margin: 5px 5px;
  font-size: 14px;
}
.MenuDescriptionArea:hover{
  box-shadow: 0px 1px 15px -5px black;
  transition: box-shadow 0.2s;
}

.planMenuTitle{
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}

.MenuDescription{
  text-align: left;
}

.openBtnRow{
  width: 100%;
  text-align: center;
}

.openPlanSelectBtn{
  display: inline-block;
  width: max-content;
  height: max-content;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  outline: none;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  background-color: #29aba4;
  border: 2px solid #29aba4;
  color: #fff;
  cursor: pointer;
}

.openPlanSelectBtn:hover{
  display: inline-block;
  background-color: #ffffff;
  color: #29aba4;
}

.planSelectRow{
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.triangle{
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid #29aba4;
  transform: rotate(180deg);
}

.triangleAnime{
  animation: openPlanSelectWindowAnime2 0.3s 1 alternate;
}

@keyframes openPlanSelectWindowAnime{
  0% {  transform: rotate(30deg);}
  10% {  transform: rotate(45deg);}
  20% {   transform: rotate(60deg);}
  30% {  transform: rotate(75deg);}
  40% {   transform: rotate(90deg);}
  50% {  transform: rotate(105deg);}
  60% {  transform: rotate(120deg);}
  70% {  transform: rotate(135deg);}
  80% {  transform: rotate(150deg);}
  90% {   transform: rotate(165deg); }
  100% {  transform: rotate(180deg);}
}
@keyframes openPlanSelectWindowAnime2 {
  0% {
    transform: rotate(135deg);
    opacity: 0;
  }
  100% {
    transform: rotate(180deg);
    opacity: 1;
  }
}
.planSelectWindow{
  margin: auto;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 1px 10px -5px black;
  border-radius: 8px; 
  background: rgb(255, 255, 255);
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.planSelectWindow:hover{
  box-shadow: 0px 1px 15px -5px black;
  transition: box-shadow 0.2s;
}

.planColumn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px;
  padding: 15px;
  text-align: center;
  border: 3px solid #29aba4;
  border-radius: 15px;
}
.planJoinColumnTitle{
  font-size: 16px;
  font-weight: bold;
}
.planBtn{
  display: inline-block;
  height: max-content;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  outline: none;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  font-size: 14px;
  background-color: #29aba4;
  border: 2px solid #29aba4;
  color: #fff;
  cursor: pointer;
}

.planBtn:hover{
  display: inline-block;
  background-color: #ffffff;
  color: #29aba4;
}

.plancampaign{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  text-align: center;
}
.plancampaign img{
  width: 200px;
  filter:alpha(opacity=90 ,finishopacity=20 ,style=1) ;
}
.plancampaign h1{
}
.plancampaign h2{
}
.plancampaign p{
  width: 600px;
  text-align: left;
  margin: 0px auto;
}
.planarea{
  margin-bottom: 50px;
}

.planwindow {
  text-align: center;
  display: flex;
  width: 100%;
}
.cardarea{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.plancard {
  padding: 20px;
  text-align: center;
  box-shadow: 0px 1px 10px -5px black;
  border-radius: 8px;
  background: #ffffff;
  width: 250px;
  margin: 5px 5px;
}
.plancard:hover{
  box-shadow: 0px 1px 15px -5px black;
  transition: box-shadow 0.2s;
}
.joinedPlan{
  box-shadow: 0px 1px 10px -5px black, inset 0px 0px 0px 5px #29aba4;
}
.planheader {
  font-size: 12px;
  text-align: right;
  border-bottom: solid 1px #c0c0c0;
}
.cardimage {
  position: relative;
  bottom: 43px;
  margin-left: auto;
  width: 140px;
}
.cardimage img {
  width: 140px;
}
.imagespace {
  margin-bottom: 50px;
}
.planname{
  height: 50px;
  vertical-align: middle;
}
.planbutton {
  display: inline-block;
  width: 200px;
  height: 54px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-size: 14px;
  background-color: #29aba4;
  border: 2px solid #29aba4;
  color: #fff;
  line-height: 54px;
}
.joinedbutton{
  background: rgba(0,0,0,0);
  color: #29aba4;
  font-weight:bold;
  font-size: 20px;
}
.cantjoinebutton{
  background: #dddddd;
  color: #808080;
  border: 2px solid rgba(0,0,0,0);
}
.joinablebutton:hover{
  background: #29aba4;
  border: 2px solid #29aba4;
}
.detailtext{
  margin-top: 20px;
  text-align: left;
}
.plunsubtitle {
  display: flex;
  margin-top: 40px;
}
.plunsubtitle > div {
  margin-left: auto;
}
.subcard {
  padding: 20px;
}
.correct{
  text-decoration: line-through;
}

.planPageOverlay {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.joinPopupTitle{
  text-align: center;
  height: max-content;
  width: 100%;
  background-color: #29aba4;
  color: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 22px;
  padding: 5px 0px;
  margin-bottom: 15px;
} 
.joinPopupContent{
  text-align: center;
  margin: 20px;
  height: max-content;
} 
.joinPopupFooter{
  text-align: center;
  height: max-content;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: center;
} 
.joinDesc{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-family: "メイリオ", "Meiryo", "ＭＳ ゴシック",
    "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
} 
.descriptionPopUp{
  width: max-content;
  max-width: 90%;
  min-width: 400px;
  border-radius: 25px;
  z-index: 999;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.joinPopupBtn {
  width: max-content;
  height: max-content;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  outline: none;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  background-color: #29aba4;
  border: 2px solid #29aba4;
  color: #fff;
  cursor: pointer;
}

.userPlanHr{
  border-top: 2px dotted #29aba4;
  height: 1em;
  width: 70%;
  text-align: center;
  overflow: visible;
  margin: auto;
  margin-bottom: 1px;
  margin-top: 5px;
}
/* 「お客様のプラン情報」をUnicodeに変換 */
.userPlanHr::after {
  content: "\304A\5BA2\69D8\306E\30D7\30E9\30F3\60C5\5831";
  background: #f0f8ff;
  color: #29aba4;
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  top: -1em;
  padding: 0 3em;
}

.planMenuHr{
  border-top: 2px dotted #29aba4;
  height: 1em;
  width: 70%;
  text-align: center;
  overflow: visible;
  margin: auto;
  margin-bottom: 5px;
  margin-top: 30px;
}
/* 「プランメニュー」をUnicodeに変換 */
.planMenuHr::after {
  content: "\30D7\30E9\30F3\30E1\30CB\30E5\30FC";
  background: #f0f8ff;
  color: #29aba4;
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  top: -1em;
  padding: 0 3em;
}

.currentPlanHr {
  border-top: 2px solid #29aba4;
  height: 1em;
  text-align: center;
  overflow: visible;
  margin-bottom: 10px;
}
/* 「現在のプラン」をUnicodeに変換 */
.currentPlanHr::after {
  content: "\73FE\5728\306E\30D7\30E9\30F3";
  background: #29aba4;
  color: rgb(255, 255, 255);
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  top: -1em;
  padding: 0 1em;
}

.nextPlanHr {
  border-top: 2px solid #29aba4;
  height: 1em;
  text-align: center;
  overflow: visible;
  margin-bottom: 10px;
}
/* 「次回のプラン」をUnicodeに変換 */
.nextPlanHr::after {
  content: "\6B21\56DE\306E\30D7\30E9\30F3";
  background: #29aba4;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  top: -1em;
  padding: 0 1em;
}

.joinPopupBtn:hover{
  display: inline-block;
  background-color: #ffffff;
  color: #29aba4;
}

.pd_table {
    font-size: 12px;
    width: 100%;
}

.pd_table td {
    border-bottom: 1px solid #808080;
    padding: 10px 0px 10px 0px;
}

p {
    font-size: 14px;
}
</style>
