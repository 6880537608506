"use strict";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Storeを生成
const store = new Vuex.Store({
  state: {
    token: "",
  },
  getters: {},
  mutations: {
    setToken(state, data) {
      state.token = data;
    },
    clearToken(state) {
      state.token = "";
    },
  },
  actions: {},
});
export default store;
