<template>
  <div class="home_bg">
    <Header pagename="Guide" />
    <div class="contentarea">
      <HelpNavi pagename="guide" />
      <div class="maincontent">
        <div class="toollist">
           <div class="traillist handcursor" @click="pushPage({name: 'Home'})"><i class="fas fa-chevron-left"></i>マイスペース</div>
        </div>
        <div class="documentcontent guidecontent">

          <h3>スタートガイド</h3>
          <ul>
            <li @click="showDetailModal(1, 1, 3)"><span class="linklike handcursor">RECORDER STATIONの使い方</span></li>
            <li @click="showDetailModal(1, 2, 3)"><span class="linklike handcursor">推奨環境</span></li>
            <li @click="showDetailModal(1, 3, 3)"><span class="linklike handcursor">クラウドスペース内のファイルを管理する</span></li>
          </ul>

          <h3>クラウドスペースでのファイルの保管</h3>
          <ul>
            <li @click="showDetailModal(2, 1, 4)"><span class="linklike handcursor">クラウドスペースにファイルをアップロードする</span></li>
            <li @click="showDetailModal(2, 2, 4)"><span class="linklike handcursor">ファイルをダウンロードする</span></li>
            <li @click="showDetailModal(2, 3, 4)"><span class="linklike handcursor">サポートされているファイル形式</span></li>
            <li @click="showDetailModal(2, 4, 4)"><span class="linklike handcursor">ファイルを共有する</span></li>
          </ul>
          
          <h3>ファイルの整理</h3>
          <ul>
            <li @click="showDetailModal(3, 1, 4)"><span class="linklike handcursor">クラウドスペースのファイルを削除する</span></li>
            <li @click="showDetailModal(3, 2, 4)"><span class="linklike handcursor">テキストファイルを編集、共有する</span></li>
            <li @click="showDetailModal(3, 3, 4)"><span class="linklike handcursor">ファイルを検索する</span></li>
            <li @click="showDetailModal(3, 4, 4)"><span class="linklike handcursor">ファイル名を編集する</span></li>
          </ul>

          <h3>トラブルシューティング</h3>
          <ul>
            <li @click="showDetailModal(4, 1, 3)"><span class="linklike handcursor">レコーダーの通信に関する問題を修正する</span></li>
            <li @click="showDetailModal(4, 2, 3)"><span class="linklike handcursor">共有メールが届かない場合</span></li>
            <li @click="showDetailModal(4, 3, 3)"><span class="linklike handcursor">アカウントにログインできない</span></li>
          </ul>
          
        </div>
      </div>
    </div>
    <Footer />
    <GuideModal :category_id="category_id" :content_id="content_id" :category_name="category_name" :content_max="content_max"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HelpNavi from '@/components/HelpNavi.vue'
import GuideModal from '@/components/GuideModal.vue'
import utils from '@/mixins/utils.js'

export default {
  name: 'Guide',
  props: {
    pagename: String,
  },
  mixins: [utils],
  components: {
    Header,
    Footer,
    HelpNavi,
    GuideModal,
  },
  data: function() {
    return {
      category_id: "",
      content_id: "",
      categoryArr: [
        "操作ガイド",
        "スタートガイド",
        "クラウドスペースでのファイルの保管",
        "ファイルの整理",
        "トラブルシューティング"
      ]
    }
  },created(){
  },
  methods: {
    showDetailModal(category_id, content_id, content_max){
      this.category_name = this.categoryArr[category_id]
      this.category_id = category_id
      this.content_id = content_id
      this.content_max = content_max
      this.$modal.show('guide-modal');
    },
  },
}
</script>

<style>
.guiditem {
  margin: 50px 26px;
}
.guidecontent h3 {
  margin-bottom: 5px;
  margin-top: 25px;
}
.guidecontent ul {
  margin-top: 0px;
  list-style: none;
}
.guidecontent li {
  padding: 5px 0px;
}
</style>
