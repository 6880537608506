<template>
  <modal name="contact-modal" :draggable="false" :resizable="false" width="900px" height="70%">
    <div @click="closeModal" class="closecross handcursor">×</div>
    <div class="modalitemarea">
      <div class="whiteblind"></div>
      <iframe src="https://share.hsforms.com/1odbzJGFkSZKJq7kIqn3BlA4nvq8?wmode=transparent" ></iframe>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'ContactModal',
  props: {
  },
  data: function () {
    return {
    }
  },
  methods: {
    closeModal(){
      this.$modal.hide('contact-modal');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modalitemarea{
  padding: 50px;
}
.infocontent {
  margin-left: 10px;
  width: 100%;
  background: #ffffff;
  overflow-y: scroll;
  font-size: 14px;
}
.infocontent::-webkit-scrollbar {
  display: none;
}
.infocategory {
  border: 1px solid #333333;
  padding: 5px;
  margin-right: 10px;
  border-radius: 3px;
}
.cateinfo {
  border: 1px solid #1e90ff;
}
.catedisorder {
  border: 1px solid #ff4500;
}
.infosubdata {
  margin-bottom: 50px;
  display: flex;
}
.infocatedate {
  margin-left: auto;
}
.text-wrap{
  white-space: pre-wrap;
}
.backbutton{
  position: absolute;
  bottom: 20px;
  left: 250px;
}
iframe{
  width: 800px;
  border: none;
  height: calc(70vh - 70px);
  position: relative;
  top: -70px;
}
.closecross {
  position: relative;
  font-size: 30px;
  left: 865px;
  width: 20px;
}
.whiteblind{
  position: absolute;
  top: 20px;
  z-index: 100;
  width: calc(100% - 100px);
  height: 35px;
  background: #ffffff;
}
</style>
