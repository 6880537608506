<template>
  <div class="home_bg">
    <Header pagename="Security" />
    <div class="contentarea">
      <MainNavi pagename="security" />
      <div class="maincontent">
        <div class="documentcontent">
          <h2 style="text-align: center;">パスワード変更</h2>
          <p style="width: 100%;">
            パスワードは8文字以上32文字以下の大小英数字で入力してください。<br>再設定したパスワードは、このアカウントに紐づくレコーダーのアカウントにも適用されます。
          </p>
          <div class="updatepasswordarea">
            <p>現在のパスワード</p>
            <input type="password" v-model="editdata.currentpass"/>
            <p class="errortext" v-if="errormessages[0]">
              {{ errormessages[0] }}
            </p>
            <p>新しいパスワード</p>
            <input :type="inputType" v-model="editdata.newpass1"/>
            <p>新しいパスワード（確認）
              <i v-show="!isPassShow" @click="toggleInputType" class="far fa-eye showpassicon handcursor"></i>
              <i v-show="isPassShow" @click="toggleInputType" class="far fa-eye-slash showpassicon handcursor"></i>
            </p>
            <input :type="inputType" v-model="editdata.newpass2"/>
            <p class="errortext" v-if="errormessages[1]">
              {{ errormessages[1] }}
            </p>
            <div class="ta-c buttonarea">
              <span class="button canselbutton mr-5" @click="resetData">クリア</span>
              <span class="button " @click="sendData" v-bind:disabled="isProcessing()">変更</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainNavi from '@/components/MainNavi.vue'
import processing from '@/mixins/processing.js'
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import axios from 'axios'
import Swal from 'sweetalert2';

export default {
  name: 'Security',
  props: {
    pagename: String,
  },
  mixins: [utils, api, processing],
  components: {
    Header,
    Footer,
    MainNavi,
  },
  data: function() {
    return {
      editdata: {
        currentpass: '',
        newpass1: '',
        newpass2: '',
      },
      errors: [],
      errormessages: [],
      code: "",
      isPassShow: false,
    }
  },
  computed: {
    inputType: function () {
      return this.isPassShow ? "text" : "password";
    },
  },
  created(){
  },
  methods: {
    toggleInputType(){
      this.isPassShow = !this.isPassShow
    },
    resetData(){
      this.editdata = {
        currentpass: '',
        newpass1: '',
        newpass2: ''
      }
    },

    /**
     * パスワード変更
     */
    sendData(){
      this.startProcessing()

      this.errors = 0;
      this.errormessages = [];

      if(!this.editdata.currentpass){
        this.errors++;
        this.errormessages.push('現在のパスワードを入力してください');
      }else{
        if(!this.isAlpNum(this.editdata.currentpass)){
          this.errors++;
          this.errormessages.push('半角英数で入力してください');
        }else{
          this.errormessages.push('');
        }
      }

      if(this.editdata.newpass1 == "" || this.editdata.newpass2 == "" ){
        if(this.editdata.newpass1 == ""){
          this.errors++;
          this.errormessages.push('新しいパスワードを入力してください');
        }else if(this.editdata.newpass2 == "" ){
          this.errors++;
          this.errormessages.push('新しいパスワード（確認）を入力してください');
        }
      }else{
        if(this.editdata.newpass1 != this.editdata.newpass2){
          this.errors++;
          this.errormessages.push('新しいパスワードが一致しません');
        }else{
          if(!this.isAlpNum(this.editdata.newpass1) || !this.isLeastLength(this.editdata.newpass1, 8)){
            this.errors++;
            this.errormessages.push('半角英数８文字以上で入力してください');
          }else{
            if(!this.isMostLength(this.editdata.newpass1, 32)){
              this.errors++;
              this.errormessages.push('半角英数32文字以下で入力してください');
            }else{
              this.errormessages.push('');
            }
          }
        }
      }
      
      if (!this.errors) {
        this.errors = 0;
        this.errormessages = [];

        axios(this.getAxConf("get", "/users/data", {}, this.$store.state.token))
        .then((response) => {
          if (response.data.success) {
            var param = {
              "newPassword": this.editdata.newpass1,
              "oldPassword": this.editdata.currentpass,
              "version": response.data.data.version ? response.data.data.version : 0
            }
            axios.put(this.getURL('/users/security'), param, this.getHeaders(this.$store.state.token))
            .then((response) => {
              if (response.data.success) {
                Swal.fire({
                  confirmButtonColor : '#68CFC3',
                  animation : false,
                  html: 
                    "<p>更新しました。ログイン画面に戻ります。</p>" + 
                    "<p>レコーダーにログイン中の場合は、<br />一度ログアウトしてからご利用ください。</p>",
                  confirmButtonText: "OK",
                  confirmButtonAriaLabel: "OK",
                }).then(()=>{
                  localStorage.setItem('pageName', "")
                  localStorage.setItem('token', "")
                  this.pushPage({name: 'Login'})
                });
                // 入力欄をリセット
                this.editdata = {
                  currentpass: '',
                  newpass1: '',
                  newpass2: ''
                }
              }else{
                this.errors++
                this.errormessages.push(response.data.message)
              }
              this.endProcessing()
            })
            .catch((err) => {
              this.endProcessing()
              console.log(err);
              this.showNetworkErrorWindow(err)
            });
          }else{
            this.endProcessing()
            return this.showErrorWindow(response)
          }
        })
        .catch((err) => {
          this.endProcessing()
          return this.showNetworkErrorWindow(err)
        });
      }
      this.endProcessing()
    },
  },

}
</script>

<style>
.updatepasswordarea {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.updatepasswordarea p {
  margin-top: 20px;
}
.updatepasswordarea input {
  padding: 5px;
  width: 60%;
}
.pwresetnote {
  display: flex;
}
.pwresetnote > div {
  margin-top: 10px;
  margin-left: auto;
  color: #808080;
  font-size: 12px;
}
.showpassicon{
  margin-left: 5px;
  color: #808080;
}
</style>
