<template>
  <div class="leftnav">
        <h3>ヘルプ</h3>
        <ul class="handcursor">
          <li v-bind:class="{isSelectedCategory: guidepageFlg}"
              @click="pushPage({name: 'Guide'})">
              操作ガイド
          </li>
          <li v-bind:class="{isSelectedCategory: faqpageFlg}"
              @click="pushPage({name: 'Faq'})">
              よくあるご質問
          </li>
          <li v-bind:class="{isSelectedCategory: contactpageFlg}"
              @click="pushPage({name: 'Contact'})">
              お問い合わせ
          </li>
        </ul>
      </div>
</template>

<script>
import utils from '@/mixins/utils.js'
export default {
  name: 'HelpNavi',
  props: {
    pagename: String
  },
  mixins: [utils],
  components: {
  },
  data: function () {
    return {
      guidepageFlg: false,
      faqpageFlg: false,
      contactpageFlg: false,
    }
  },
  created(){
    this.setSelectedFlg();
  },
  methods: {
    setSelectedFlg(){
      if(this.pagename === "guide") {
        this.guidepageFlg = true;
      }else if(this.pagename === "faq") {
        this.faqpageFlg = true;
      }else if(this.pagename === "contact") {
        this.contactpageFlg = true;
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.leftnav {
  width: 200px;
  height: calc(100vh - 100px);
  background: #ffffff;
}
.leftnav h3 {
  margin-left: 8px;
}
.leftnav ul {
  list-style: none;
  padding-inline-start: 0px;
  margin: 0px;
}
.leftnav li {
  padding: 14px 10px;
  border-left: 10px solid #ffffff;
}
.leftnav li:hover {
  border-left: none;
  background: linear-gradient(90deg, rgba(77, 101, 124, 0.1), transparent);
  box-shadow: -3px 4px 4px -6px black;
  border-radius: 0px 30px 30px 0px;
}
.isSelectedCategory {
  /*
  border-left: 10px solid #1e90ff !important;
  background: linear-gradient(90deg, rgba(30, 144, 255, 0.2), transparent);
  */
  border-left: 10px solid #B5EAE1 !important;
  background: linear-gradient(90deg, rgba(181, 234, 225, 0.7), transparent);
  border-radius: 0px 30px 30px 0px;
}
</style>
