<template>
  <div class="home">
    <Header pagename="Home" @ds="detailSearch" />
    <div class="contentarea">
      <MainNavi v-on:onNotifyPlanId="onNotifyPlan"/>
      <div class="maincontent">
        <div class="home_main_area">
          
          <!-- 検索ボックス -->
          <div class="txt_search_area">
              <div style="text-align: center; margin: 10px;">
                <i class="fa-solid fa-magnifying-glass" style="font-size:24px;margin-right: 10px; color:#29aba4;"></i>
                <input class="minutes_search_box" id="search_txt" type="text" v-model="searchTargetWord" placeholder="議事録アイテムを検索">
              </div>
              <div class="clear_btn" @click="searchTargetWord=''">クリア</div>
          </div>

          <!-- 日付検索エリア -->
          <div class="date_search_area">
              <i class="fa-solid fa-calendar-days" style="font-size:24px;margin-right: 10px; color:#29aba4;"></i>
              <label style="margin-right:30px;">日付で検索 </label>
              <label>開始日: </label>
              <input class="minutes_search_date" type="date" id="start" name="trip-start" v-model="startDate">
              <label style="margin-left:5px; margin-right:5px; font-size:36px;"> </label>
              <label>終了日: </label>
              <input class="minutes_search_date" type="date" id="start" name="trip-start" v-model="endDate">
              <div class="clear_btn" @click="clearDate">クリア</div>
          </div>

          <!-- 各種ボタンエリア -->
          <div class="function_area">
            <!-- アップロードボタン -->
            <div class="tool_btn"  @click="opneUploadPopup()">
              <div class="tool_btn_icon" ><i class="fa-solid fa-file-arrow-up"></i></div>
              <div class="tool_btn_text">アップロード</div>
            </div>

            <!-- チェックダウンロードボタン -->
            <div class="tool_btn" @click="showDLWindow">
              <div class="tool_btn_icon" ><i class="fa-solid fa-cloud-arrow-down"></i></div>
              <div class="tool_btn_text">ダウンロード</div>
            </div>

            <!-- チェック項目削除ボタン -->
            <div class="tool_btn" @click="showTrashWindow">
              <div class="tool_btn_icon" ><i class="fa-solid fa-trash"></i></div>
              <div class="tool_btn_text">削除</div>
            </div>

            <!-- 更新ボタン -->
            <div class="tool_btn" @click="updateListData(selectTab)">
              <div class="tool_btn_icon" ><i class="fa-solid fa-rotate"></i></div>
              <div class="tool_btn_text">更新</div>
            </div>

          </div>

          <!-- タブエリア -->
          <div class="tab_area">
            <div class="tab_btn" v-bind:class="{tab_selected: selectTab == '502J'}" @click="changeTab('502J')">VOITERファイル</div>
            <div class="tab_btn" v-bind:class="{tab_selected: selectTab == 'File'}" @click="changeTab('File')">アップロードファイル</div>
            <div class="tab_btn" v-bind:class="{tab_selected: selectTab == 'Video'}" @click="changeTab('Video')">動画</div>
          </div>
          <div class="contents_main_area">
            <div class="contents_list_header_area">
            </div>
            <div class="contents_list_area" v-show="isExistItem">
              <div v-for="(item) in searchItems" :key="item.id" :id="item.id" class="handcursor" v-show="!item.isDelete">
                  <div class="contents_item" :id="item.id" v-show="item.sourceType == selectTab">
                    <div class="contents_item_column_check">
                      <span v-show="item.id !== 0" class="check_box_area">
                        <input type="checkbox" v-bind:id="'check' + item.id" name="checkbox" v-model="item.checked" />
                        <label v-bind:for="'check' + item.id" class="checkbox"></label>
                      </span>
                    </div>
                    <div class="contents_item_filetype_icon">
                      <dev v-show="item.fileType == '0'"><i class="fa-solid fa-music"></i></dev>
                      <dev v-show="item.fileType == '2'"><i class="fa-solid fa-camera" style="color:#13618f;"></i></dev>
                      <dev v-show="item.fileType == '3'"><i class="fa-solid fa-video"></i></dev>
                    </div>
                    <div class="content_item_info_area" @click="toDictation(item.id)">
                      <div class="content_item_basic_info">
                        <div class="content_item_upper" @click="toDictation(item.id)">
                          <div class="contents_item_column_name" @click="toDictation(item.id)"  v-html="item.showName"></div>
                        </div>
                        <div class="content_item_under">
                          <div class="content_item_icon">
                            <!-- <i class="fa-solid fa-calendar icon_common"></i> -->
                          </div>
                          <div class="contents_item_column_date" @click="toDictation(item.id)">{{ item.createTime.slice(0, -3) }}</div>
                          <div class="content_item_icon">
                            <i class="fa-solid fa-stopwatch icon_common"></i>
                          </div>
                          <div class="contents_item_column_duration" @click="toDictation(item.id)">{{ convertTimeHHMMSS(item.duration) }}</div>

                          <div class="content_item_icon">
                            <!-- <i class="fa-solid fa-user-group icon_common"></i> -->
                          </div>
                        </div>
                      </div>

                      <div class="content_item_icon"><i class="fa-solid fa-tags icon_common"></i></div>
                      <div class="content_item_keyword" v-html="item.keywords"></div>
                    </div>

                    <div class="contents_item_column_state">
                        <span v-show="item.minutesItemStatus == 1"><i
                          class="fa-regular fa-comment-dots processing"></i></span>
                        <span v-show="item.minutesItemStatus == 2"><i
                            class="fa-regular fa-circle-check completed"></i></span>
                        <span v-show="item.minutesItemStatus == 3"><i
                            class="far fa-times-circle errored"></i></span>
                    </div>
                  </div>
              </div>
              <!-- <div class="list_item_loading" v-show="!isLoadFinish" ><div class="loader" style="margin-right: 5px !important;"></div><div>読み込み中...</div></div> -->
            </div>
            <div class="contents_list_area" v-show="!isExistItem">
              <div style="width: 100%; text-align: center; color:#29aba4; font-size:18px; font-weight: bold; margin-top: 40px;" v-html="noFileMessage"></div>
            </div>
          </div>

          <!-- mouseover box -->
          <div v-show="hoverFlag" id="tooltips" class="tooltip_box" :style="tooltipPosition">
            <div class="tooltip_title">{{tooltipMessage}}</div>
            <div v-show="hoverItemStatus == 0">
            </div>
            <div v-show="hoverItemStatus == 1">
            </div>
            <div v-show="hoverItemStatus == 2">
              <div class="tooltip_row"><i class="fa-solid fa-tags icon_common"></i>
                <div v-for="(keyword_val) in showKeyword" :key="keyword_val" class="tooltip_row_item">
                  <u>{{keyword_val}}</u>
                </div>
              </div>
              <div class="tooltip_row"><i class="fa-solid fa-user-group icon_common"></i>
                <div v-for="(member_val) in showMember" :key="member_val" class="tooltip_row_item">{{member_val}}</div>
              </div>
            </div>
            <div v-show="hoverItemStatus == 3">
              もう一度議事録データの作成を開始することができます
            </div>
          </div>

          <!-- 議事録編集モードPOPUP -->
          <div class="overlay" v-show="isShowMinitWindow" @click.self="isShowMinitWindow=false">
            <div class="popupWindow">
              <div class="popupHeader">
                <div class="popupTitle">
                  <i class="fa-solid fa-circle-exclamation" style="margin-right: 10px;"></i>議事録アイテムを作成
                </div>
              </div>
              <div class="popupMainContent">
                <div class="popupRow ">
                  <div>
                    <h4>議事録編集機能では、かんたんに高度な編集機能を使うことができます。</h4>
                    <ul>
                      <li>音声を再生しながらラクラク編集</li>
                      <li>Word形式でかんたん保存</li>
                      <li>発言者の自動識別、自動分類</li>
                    </ul>

                    <div class="speakerConfig">
                      <h4>発言者の設定</h4>
                      <p class="note dictexlist">
                        AI音声認識機能によって、音声データに含まれる声紋を解析し、自動的に話し手を分類することができます。<br />
                        ※発言者の識別機能にとって、「人数」の設定はとても大切です。人数を正確に設定すると、とても高精度にそれぞれの発言を分類することができます。<br /><b style="font-size:16px;">発言者の人数を正しく指定することで、高精度に発言者を自動識別することができます</b>
                      </p>
                      <p class="note">※議事録アイテムの作成には、録音時間の1/3程度の時間がかかります。
                      </p>
                      <p>発言者の人数：
                        <select v-model="spkNum" class="advinput">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="0">自動解析</option>
                        </select>
                        (実際に録音中に発言した人数を入れてください)
                      </p>
                      <p>使用言語：
                        <select v-model="lang" class="advinput">
                          <option value="ja" selected>日本語</option>
                          <option value="en" selected>英語</option>
                        </select>
                      </p>
                    </div>
                    <p class="note ta-r">※旧バージョンの編集画面は<span class="linktext handcursor" @click="toOldDictation()">こちら</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="popupFooter">
                <div class="popupFooterBtn" @click="startUploadMinit()">
                  <i class="fa-solid fa-check" style="margin-right: 10px;"></i>作成する
                </div>
                <div class="popupFooterBtnCancel" @click="isShowMinitWindow=false">
                  <i class="fa-solid fa-xmark" style="margin-right: 10px;"></i>キャンセル
                </div>
              </div>
            </div>
          </div>

          <!-- ファイルアップロードPOPUP -->
          <div class="overlay" v-show="isShowUploadWindow" @click.self="isShowUploadWindow=false">
            <div class="popupWindow">
              <div class="popupHeader">
                <div class="popupTitle">
                  <i class="fa-solid fa-circle-exclamation" style="margin-right: 10px;"></i>ファイルアップロード
                </div>
              </div>
              <div class="popupMainContent">
                <!-- 1行目開始 -->
                <div class="popupRow">
                  <label class="popupDescribe">
                    <span class="">
                      この機能はβ版です。ぜひお試しいただき、ご感想をお寄せください。<br/>
                    </span>
                    <span class="dangercolor note">
                      本β版は、機能・動作・精度などの評価目的でリリースされており、機能・動作などが不十分な場合もあります。<br />また、将来的に一部の機能は別途費用が掛かる場合がございます。
                    </span>
                    <p>
                      VOITER以外の機器で録音した音声でも、ここからアップロードして文字起こしをすることができます。<br />
                      この文字起こしは、β版では<span class="dangercolor">『１日５回まで』</span>使うことができます。
                    </p>
                  </label>
                </div>

                <div class="popupRow">
                  <label class="popupDescribe"><input id="audio_file_chooser" type="file" accept=".wav, .aac, .mp3, wma, m4a" ref="choose_audio" @change="chooseAudioFile"></label>
                </div>
                <!-- 2行目開始 -->
                <div class="popupRow modeSelectArea">
                  <div class="dropArea" :class="{fileenter: isFilenter}" @dragenter="dragEnter" @dragleave="dragLeave"
                    @dragover.prevent @drop.prevent="dropFile" v-show="!isAudioLoading">
                    <i class="fa-regular fa-file-audio"></i>
                    <p>ここに音声ファイルをドラッグ＆ドロップしてください</p>
                    <p>（対応形式：.mp3 .aac .wav .wma .m4a)</p>
                  </div>
                  <div v-show="isAudioLoading" style="text-align: center; width: 100%; font-size:20px; color:#29aba4">音声を読み込み中です・・・・</div>
                </div>
              </div>
              <div class="popupFooter">
                <div class="popupFooterBtnFill" @click="isShowUploadWindow=false">
                  <i class="fa-solid fa-xmark" style="margin-right: 10px;"></i>キャンセル
                </div>
              </div>
            </div>
          </div>

          <!-- ファイルアップロード進行中POPUP -->
          <div class="overlay" v-show="isShowProgressWindow">
            <div class="popupWindow">
              <div class="popupHeader">
                <div class="popupTitle">
                  <i class="fa-solid fa-circle-exclamation" style="margin-right: 10px;"></i>ファイルをアップロード中...
                </div>
              </div>
              <div class="popupMainContent">
                <!-- 1行目開始 -->
                <div class="popupRow">
                  <label class="popupDescribe" >現在{{progressTxt}}アップロード中です。
                  </label>
                </div>
                <!-- 2行目開始 -->
                <div class="popupRow">
                  <progress class="uploadProgress"  v-bind:value="progressValue" max="100"></progress>
                </div>
              </div>
              <div class="popupFooter">
                <div class="popupFooterBtnFill" @click="uploadCancel">
                  <i class="fa-solid fa-xmark" style="margin-right: 10px;"></i>キャンセル
                </div>
              </div>
            </div>
          </div>
        <Footer />
        </div>
      </div>
    </div>
    <Loading />
  </div>
</template>

<script>
// @ is an alias to /src
import Loading from '@/components/Loading.vue'
import utils from '@/mixins/utils.js'
import api from '@/mixins/api.js'
import processing from '@/mixins/processing.js'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainNavi from '@/components/MainNavi.vue'
import Swal from 'sweetalert2'
import axios from "axios";
//import {saveAs} from "file-server"

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    MainNavi,
    Loading,
  },
  mixins: [utils, api, processing],
  props:{
  },
  data: function() {
    return {
      audioItems: [],
      uploadItems: [],
      videoItems: [],
      pageNum: 1, // 初期
      pageRow: 10, // これは固定
      totalCount: 0,
      createTime: "",
      fileType: "",
      fileName: "",
      filterSelected: 999,
      checkMaster: false,
      hiddenLength: 0,
      isBetaMode: true,
      selectTab: "502J",
      hoverFlag : false,
      isLoadFinish:false,
      tooltipPosition:{
        left: 0,
        top: 0,
      },
      tooltipMessage: "",
      showKeyword: [],
      showMember: [],
      hoverItemStatus: 0,
      estimateTime: "2022/1/1 00:00:00",
      msgMap: {
        0: "音声変換中です。もうしばらくお待ちください。",
        1: "文字起こし中です。もうしばらくお待ちください。",
        2: "音声変換に失敗しました。もう一度お試しいただくか、通常版をご利用ください。",
        3: "文字起こしに失敗しました。もう一度お試しいただくか、通常版をご利用ください。",
        4: "自然言語処理中です。もうしばらくお待ちください。",
        5: "自然言語処理に失敗しました。もう一度お試しいただくか、通常版をご利用ください。",
        6: "処理完了",
        999 :"不明"
      },
      isShowMinitWindow: false,
      isShowUploadWindow: false,
      isFilenter: false,
      uploadFiles: [],
      uploadTargetId: 0,
      spkNum: "",
      lang: "ja",
      isShowProgressWindow:false,
      progressTxt:"0%",
      progressValue: 0,
      canceler : null,
      searchTargetWord: "",
      startDate: "",
      endDate: "",
      isExistItem:false,
      noFileMessage: "",
      planId: -1,
      isAudioLoading:false,
      observeMinuteList: [],
    }
  },
  computed:{
    searchItems(){

      // if (this.isLoadFinish){
      //   this.switchNoFileListView(true)
      //   return;
      // }
      var targetItemList = null;
      if (this.selectTab == "502J"){
        targetItemList = this.audioItems
      }else if(this.selectTab == "File"){
        targetItemList = this.uploadItems
      }else if(this.selectTab == "Video"){
        targetItemList = this.videoItems
      }

      var searchResult = new Array()
      var tagetWord = this.searchTargetWord;
      var isEmptyStartDate = true;
      var isEmptyEndDate = true;
      if(this.startDate != "" ){
        isEmptyStartDate = false;
      }

      if(this.endDate != "" ){
        isEmptyEndDate = false;
      }

      var searchStartTime = new Date( this.startDate + " 00:00:00");
      var searchEndTime = new Date( this.endDate + " 23:59:59");

      if(tagetWord == "" && this.startDate == "" && this.endDate == ""){
        this.changeNoFileMessage("ファイルはありません");
        this.switchNoFileListView(true);
        if(targetItemList.length == 0){
          /**ファイルが1つもクラウド上に存在しない場合 */
          this.switchNoFileListView(false);
        }
        return targetItemList;
      }
    
      targetItemList.forEach(function (result_item) {
        var search_result_item_id = "";
        var search_result_item_showName = "";
        var search_result_item_keyword = "";
        var search_result_item_createTime = "";
        var search_result_item_duration = "";
        var search_result_filetype = "";
        var search_result_checked = false;

        var isIncludeTargetInShowName = (result_item.showName).indexOf(tagetWord, 0);
        if(result_item.keywords != null){
          var isIncludeTargetInKeyword = (result_item.keywords).indexOf(tagetWord, 0);
        }else{
          isIncludeTargetInKeyword = -1;
        }

        var itemDate = new Date(result_item.createTime);

        if(!isEmptyStartDate){
          if(searchStartTime.getTime() > itemDate.getTime()){
            return;
          }
        }

        if(!isEmptyEndDate){
          if(searchEndTime.getTime() < itemDate.getTime()){
            return;
          }
        }

        if(result_item.isDelete){
          return;
        }

        if((isIncludeTargetInShowName !== -1) || (isIncludeTargetInKeyword !== -1)){
          search_result_item_id = result_item.id;
          search_result_item_showName =result_item.showName;
          search_result_item_keyword = result_item.keywords;
          search_result_item_createTime = result_item.createTime;
          search_result_item_duration = result_item.duration;
          search_result_filetype = result_item.fileType;
          search_result_checked = result_item.checked;

          search_result_item_showName = search_result_item_showName.replace(tagetWord, "<b style='background:linear-gradient(transparent 60%, #fff462 30%);'>"+tagetWord+"</b>")

          if(result_item.keywords != null){
            search_result_item_keyword = search_result_item_keyword.replace(tagetWord, "<b style='background:linear-gradient(transparent 60%, #fff462 30%);'>"+tagetWord+"</b>")
          }

          searchResult.push({"id":search_result_item_id, "showName": search_result_item_showName, "createTime":search_result_item_createTime,
          "duration": search_result_item_duration, "keywords":search_result_item_keyword, "fileType": search_result_filetype, "checked": search_result_checked,"sourceType":result_item.sourceType})
        }
      });
      if(searchResult.length == 0){
        if(!isEmptyStartDate || !isEmptyEndDate){
          this.changeNoFileMessage("該当するファイルが存在しません");
        }else{
          this.changeNoFileMessage("「" + tagetWord + "」 に該当するファイルが存在しません");
        }
        this.switchNoFileListView(false)
      }else{
        this.switchNoFileListView(true)
      }
      return searchResult;
    }
  },
  created(){
  },
  mounted(){
    var now = new Date();
    const currentTime = now.getTime()
    var saveObj = JSON.parse(localStorage.getItem('itemData'));
    if(saveObj != null && saveObj != undefined){
      var timeDiff = currentTime - saveObj.saveTime;
      if(timeDiff > 24 * 60 * 60 * 1000){
        // 24時間以上離れている場合、データ再取得
        this.updateListData("all");
      }else{
        this.audioItems = saveObj.audio;
        this.uploadItems = saveObj.upload;
        this.videoItems = saveObj.video;
      }
    }else{
      //localstorageに存在しない場合
      this.updateListData("all");
    }
    this.recoveryBetaMode()
    setInterval(this.pollingUpdateList, 1000 * 60 * 10);
  },
  watch: {
    isBetaMode(){
      localStorage.setItem('isBeta', this.isBetaMode);
    },
  },
  methods: {
    onNotifyPlan(id){
      this.planId = id;
    },
    pollingUpdateList(){
      if (location.pathname.match("/home")) {
        this.updateListData("all");
      }
    },
    updateListData(type){ 
      if (type == "502J"){
        this.getFileList("0")
      }else if(type == "File"){
        this.getFileList("10")
      }else if(type == "Video"){
        this.getFileList("3")
      }else if(type == "all"){
        this.getFileList("0")
        this.getFileList("10")
        this.getFileList("3")
      }
    },
    saveItemList(){
      var now = new Date();
      const currentTime = now.getTime()

      var saveObj =  {
        saveTime: currentTime,
        audio: this.audioItems,
        upload: this.uploadItems,
        video: this.videoItems,
      }
      localStorage.setItem('itemData', JSON.stringify(saveObj));
    },
    showLoading(){
      try{
        this.$modal.show('loading')
      }catch{
        // console.log("loading icon isnot loaded...")
      }
    },
    hideLoading(){
      try{
        this.$modal.hide('loading')
      }catch{
        console.log("loading icon isnot loaded...")
      }
    },
    recoveryBetaMode(){
      var ib = localStorage.getItem('isBeta')
      if(ib == null){
        this.isBetaMode = false
      }else{
        if(ib == "true"){
          this.isBetaMode = true
        }else{
          this.isBetaMode = false
        }
      }
    },
    changeTab(tabType){
      this.selectTab = tabType;
      this.updateListData(tabType);
    },
    /**
     * 検索して表示
     * @param data 
     * param = {
        pageNum: pageNum,
        pageRow: pageRow,
        createTime: createTime,
        fileName: fileName,
        fileType: fileType
      }
     */
    getFileList(fileType){
      // var fileType = "";
      // if (this.selectTab == "502J"){
      //   fileType = "0"
      // }else if(this.selectTab == "File"){
      //   fileType = "10"
      // }else if(this.selectTab == "Video"){
      //   fileType = "3"
      // }
      const param = {
        pageNum: 1,
        pageRow: 10000,
        createTime: "",
        fileName: "",
        fileType: fileType
      }
  
      axios.post(this.getURL('/contents/list'), param, this.getHeaders(this.$store.state.token))
      .then((response) => {
        if(response.data.success){
          var projectList = []
          response.data.data.getProjectList.forEach(item=>{
            var tempItem = item
            tempItem.checked = false
            projectList.push(tempItem)
          })
          this.checkMaster = false

          if (fileType == "0"){
            this.audioItems = projectList
          }else if(fileType == "10"){
            this.uploadItems = projectList
          }else if(fileType == "3"){
            this.videoItems = projectList
          }
          this.saveItemList();
       }else{
         this.showErrorWindow(response)
       }
      })
      .catch((err) => {
          console.log(err);
      });
    },
    changeNoFileMessage(message){
      this.noFileMessage = message;
    },
    switchNoFileListView(isExist){
      this.isExistItem = isExist;
    },
    // true:表示しない false:表示する
    isFilterd(){
      return false
    },
    mouseOverAction(e){
      var itemsList = this.getCurrentItems();
      const target = itemsList.filter((item)=>{
        return item.id == e.target.parentElement.id
      })[0]

      if(!this.isBetaMode || target.fileType !== "0" ){
        return;
      }
      this.tooltipPosition = this.calcPopupShowPosition(e, 300, 150, 15);

      this.tooltipMessage = "";
      this.estimateTime = "";
      this.showKeyword =  new Array();
      this.showMember = new Array();

      //console.log(e)
      var isAdv = false;
      var status = 999;
      var keywords = "";
      var member = "";
      var estimateTime = ""
      itemsList.forEach(item => {
        if(item.id == e.target.parentElement.id){
          isAdv = item.isExistAdvanced;
          status = item.minutesItemStatus;
          keywords = item.keywords;
          member = item.member;
          estimateTime = item.taskEstimateTime;
        }
      });
      this.hoverItemStatus = status;
      this.tooltipMessage = "議事録データ未作成"

      if(isAdv){
        if(status == 0){
            this.tooltipMessage = "議事録データ未作成"
        }else if(status == 1){
            this.tooltipMessage = "議事録データ作成中...."
            this.estimateTime = estimateTime
        }else if(status == 2){
            this.tooltipMessage = "議事録データの概要"
            this.showKeyword = keywords ? keywords.replace("[","").replace("]","").split(",") : ["情報が取得できません"];
            this.showMember = member ? member.split(",")  : ["情報が取得できません"];
        }else if(status == 3){
            this.tooltipMessage = "議事録データ作成失敗"
        }
      }
      this.hoverFlag = true
    },
    mouseLeaveAction(){
      this.hoverFlag = false
    },
    toOldDictation() {
      this.pushPage({
        name: "Dictation",
        params: {
          item_id: this.uploadTargetId
        }
      });
    },
    toDictation(id){
      // βモードでなければ早期リターン
      // if(!this.isBetaMode){
      //   return this.pushPage({
      //     name: "Dictation",
      //     params: {
      //       item_id : id
      //     }
      //   });
      // }
      // 音声以外は早期リターン
      var targetItem;
      var itemsList = this.getCurrentItems();
      itemsList.forEach(item => {
        if(item.id == id){
          targetItem = item;
        }
      });
      if(targetItem.fileType !== "0"){
        return this.pushPage({
          name: "Dictation",
          params: {
            item_id : id
          }
        });
      }
      // ターゲットアイテムの議事録アイテム生成状況を確認する
      if(targetItem.minutesItemStatus == 0){
        // 未作成
        if(targetItem.duration == -1){
          /**現在音声変換中 */
          Swal.fire({
            html: `
            <p>現在音声を変換中です。もうしばらくお待ちください。</p>
            `,
            confirmButtonText: "とじる",
            confirmButtonColor:"#29aba4",
            confirmButtonAriaLabel: "とじる",
          })
          return;
        }

        this.isShowMinitWindow = true;
        this.uploadTargetId = id;
      }else if(targetItem.minutesItemStatus == 1){
        // 作成中
        Swal.fire({
          html: `
          <p>議事録データの作成中です。もうしばらくお待ちください。</p>
          `,
          confirmButtonText: "とじる",
          confirmButtonColor:"#29aba4",
          confirmButtonAriaLabel: "とじる",
        })
      }else if(targetItem.minutesItemStatus == 2){
        // 作成済み
        this.pushPage({
          name: "Dictex",
          params: {
            item_id : id
          }
        });

      }else if(targetItem.minutesItemStatus == 3){
        // 作成失敗
        Swal.fire({
          confirmButtonColor : '#dd4157',
          animation : false,
          html: `
          <p>
            議事録データの作成に失敗しました。<br />
            録音時間が極端に短かったり、無音の場合、議事録データとして作成できない場合があります。
          </p>
          `,
          showCancelButton: true,
          confirmButtonText: "もう一度開始する",
          confirmButtonAriaLabel: "もう一度開始する",
          cancelButtonText: "もどる",
          cancelButtonAriaLabel: "もどる",
        })
        .then((result)=>{
          if(result.isConfirmed){
            this.isShowMinitWindow = true;
            this.uploadTargetId = id;
          }
          return false
        })
      }
    },
    startUploadMinit(){
      var data = {
          isEasyCreation: false,
          spkNum: this.spkNum,
          lang: this.lang
        }
      // TODO: ローカル開発用に、開発サーバ向けのURLを指定
      /*
      const devdomain = 'https://test.recstation.jp:8080/'
      const devpath = 'cms11/v1/'
      var url = devdomain + devpath + 'contents/' + this.uploadTargetId + '/minutesItem'
      axios.post(url, data, this.getHeaders(this.$store.state.token))
      */
      axios.post(this.getURL('/contents/' + this.uploadTargetId + '/minutesItem'), data, this.getHeaders(this.$store.state.token))
        .then((response) => {
          if(response.data.success){
            this.isShowMinitWindow = false;
            Swal.fire({
              animation : false,
              html: `
              <p>議事録データの作成を開始しました。</p>
              <p>処理が終わるまで少々お待ちください。</p>
              `,
              confirmButtonText: "とじる",
              confirmButtonColor:"#29aba4",
              confirmButtonAriaLabel: "とじる",
            })
            //this.getAllList();
            var itemsList = this.getCurrentItems();
            itemsList.forEach(item => {
              if(item.id == this.uploadTargetId){
                this.$set(item, "minutesItemStatus", 1);
              }
            });
            this.observeMinuteList.push({"id":this.uploadTargetId, "type":this.selectTab, "isObserve":true});
            this.saveItemList();
          }else{
            Swal.fire({
              animation : false,
              html: `
              <p>議事録データの作成に失敗しました。</p>
              <p>` + response.data.message + `</p>
              `,
              confirmButtonText: "とじる",
              confirmButtonColor:"#29aba4",
              confirmButtonAriaLabel: "とじる",
            })
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
              animation : false,
              html: `
              <p>議事録データの作成に失敗しました。</p>
              <p>恐れ入りますが、時間をおいていただくか、他のファイルでお試しください。</p>
              `,
              confirmButtonText: "とじる",
              confirmButtonColor:"#29aba4",
              confirmButtonAriaLabel: "とじる",
            })
        });
    },
    allCheck(){
      var itemsList = this.getCurrentItems();
      const checkset = this.checkMaster
      itemsList.forEach((item) => {
        item.checked = !checkset
      })
    },
    getCurrentItems(){
      if (this.selectTab == "502J"){
        return this.audioItems
      }else if(this.selectTab == "File"){
        return this.uploadItems
      }else if(this.selectTab == "Video"){
        return this.videoItems
      }
    },
    /*
    oneCheck(id){
      var tempItems = []
      this.items.forEach((item) => {
        var tempItem = item
        if(item.id == id){
          tempItem.checked = !tempItem.checked
        }
        tempItems.push(tempItem)
      })
      this.items = tempItems
    },
    */
    clearDate(){
      this.startDate = "";
      this.endDate = "";
    },
    getShowName(showName){
      var nm = showName
      if(!showName) return ""
      if(showName.length > 30){
        nm = showName.substr( 0, 29 ) + "…"
      }
      return nm
    },

    /**
     * *****************************************
     * ツールウィンドウ
     * *****************************************
     */
    getCheckdItem(){
      var checkedList = []
      var itemsList = this.getCurrentItems();
      itemsList.forEach(item => {
        if(item.checked){
          checkedList.push(item)
        }
      });
      return checkedList
    },
    getCheckdId(){
      var checkedList = []
      var itemsList = this.getCurrentItems();
      if(itemsList.length){
        itemsList.forEach(item => {
          if(item.checked){
            checkedList.push(item.id)
          }
        });
      }
      return checkedList
    },
    showDLWindow(){
      if(!this.getCheckdId().length){
        Swal.fire({
                confirmButtonColor : '#29aba4',
                animation : false,
                html: "<p>項目が選択されていません。</p>",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
        return false
      }
      Swal.fire({
        title: "まとめてダウンロード",
        html: 
          "<p>大容量ファイルの場合は圧縮に時間がかかります。ダウンロードの準備ができましたらダウンロードURLをメールでお知らせします。</p>" + 
          this.getFileCheck() + 
          "<style>" + 
          this.getCheckStyle() +
          "</style>" ,
        input: "text",
        inputPlaceholder: 'メールアドレスを入力',
        confirmButtonColor : '#29aba4',
        cancelButtonColor : '#94acc6',
        reverseButtons : true,
        animation : false,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "ダウンロード",
        confirmButtonAriaLabel: "ダウンロード",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {

            var elms = document.getElementsByName("typeCB")
            if(elms[0].checked == false && elms[1].checked == false){
              resolve('ファイルを選択してください')
            }

            if (value) {
              if (this.isEmail(value)) {
                resolve()
              } else {
                resolve('メールアドレスの形式で入力してください。')
              }
            } else {
              resolve('メールアドレスを入力してください。')
            }
          })
        },
        preConfirm: (mail) => {
          var elms = document.getElementsByName("typeCB")
          var contentType = ''
          if(elms[0].checked && !elms[1].checked){
            contentType = "1"
          }
          else if(!elms[0].checked && elms[1].checked){
            contentType = "2"
          }
          else if(elms[0].checked && elms[1].checked){
            contentType = "3"
          }else{
            return false
          }
          const data = { 
            projectIdList: this.getCheckdId(),
            contentFlg: contentType,
            mail: mail
          }
          // return axios.post(this.getURL('/contents/download/zip'), data, this.getBlobHeaders(this.$store.state.token) )
          return axios.post(this.getURL('/contents/download/zip'), data, this.getHeaders(this.$store.state.token))
          .then((response) => {
            if(response.data.success){
              Swal.fire({
                confirmButtonColor : '#29aba4',
                animation : false,
                html: "<p>送信が完了しました。<br />ダウンロードの準備ができたらメールでお知らせします。</p>",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
            }else{
              return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })
    },

    generateZipFile(content, filename) {
      var blob = new Blob([ content ], { type : "application/zip" });

      if (window.navigator.msSaveBlob) {
        // Edgeの場合（サポートしていないが、参考までに）
        window.navigator.msSaveBlob(blob, filename);
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
          // document.getElementById("download").href = window.URL.createObjectURL(blob);
          return window.URL.createObjectURL(blob);
      }
    },
    showShareWindow(){
      if(!this.getCheckdId().length){
          Swal.fire({
                confirmButtonColor : '#29aba4',
                animation : false,
                html: "<p>項目が選択されていません。</p>",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
        return false
      }
      Swal.fire({
        title: "メールで共有",
        html: 
          "<p>メールアドレスを一行ずつ入力してください</p>" + 
          "<textarea id='mailinput' class='mailinput' placeholder='example@recstation.jp'></textarea>" + 
          this.getFileCheck() + 
          "<style>" + 
          ".mailinput {width:100%; height:70px; font-family:'メイリオ', 'Meiryo','ＭＳ ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;}" +
          this.getCheckStyle()+
          "</style>" ,
        input: "hide",
        confirmButtonColor : '#29aba4',
        cancelButtonColor : '#94acc6',
        reverseButtons : true,
        animation : false,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "送信",
        confirmButtonAriaLabel: "送信",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
        showLoaderOnConfirm: true,
        inputValidator: () => {
          return new Promise((resolve) => {
            var value = document.getElementById('mailinput').value
            if (value) {
              var mailArr = value.split(/\n/)
              mailArr.forEach(elm => {
                if (this.isEmail(elm)) {
                  // resolve()
                } else {
                  resolve('メールアドレスの形式で入力してください')
                }
              });
              resolve()
            } else {
              resolve('メールアドレスを入力してください')
            }
          })
        },
        preConfirm: () => {
          var elms = document.getElementsByName("typeCB")
          var contentType = ''
          if(elms[0].checked && !elms[1].checked){
            contentType = 0
          }
          else if(!elms[0].checked && elms[1].checked){
            contentType = 1
          }
          else if(elms[0].checked && elms[1].checked){
            contentType = 2
          }else{
            return false
          }

          var idList = this.getCheckdId()
          // 「;」を追加
          var mailstr = document.getElementById('mailinput').value
          var mailArr = mailstr.split(/\n/)
          var trimmedMailstr = ""
          mailArr.forEach(elm => {
            trimmedMailstr = trimmedMailstr + elm + ";"
          });

          const data = { 
            mail: trimmedMailstr, 
            projectIdList: idList,
            shareFlg: contentType
          }
          return axios.post(this.getURL('/contents/share'), data, this.getHeaders(this.$store.state.token))
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                confirmButtonColor : '#29aba4',
                animation : false,
                html: "<p>送信が完了しました</p>",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
            }else{
              return Swal.showValidationMessage(response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            return Swal.showValidationMessage("通信に失敗しました。しばらくしてからお試しください。");
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })
    },
    showTrashWindow(){
      if(!this.getCheckdId().length){
         Swal.fire({
                confirmButtonColor : '#29aba4',
                animation : false,
                html: "<p>項目が選択されていません。</p>",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
        return false
      }
      Swal.fire({
        confirmButtonColor : '#dd4157',
        cancelButtonColor : '#94acc6',
        reverseButtons : true,
        animation : false,
        title: 'データを削除',
        html: '<p>削除すると、音声・ビデオ・文字起こしテキストなどの情報は元に戻すことはできません。</p><p>本当によろしいですか？</p>',
        showCancelButton: true,
        confirmButtonText: "はい",
        confirmButtonAriaLabel: "はい",
        cancelButtonText: "キャンセル",
        cancelButtonAriaLabel: "キャンセル",
      }).then((result) => {
        if (result.isConfirmed) {
          
          const data = {
            projectIds: this.getCheckdId()
          }
          axios(this.getAxConf("delete", "/contents", data, this.$store.state.token))
          .then((response) => {

            if (response.data.success) {
              Swal.fire({
                confirmButtonColor : '#29aba4',
                animation : false,
                html: '<p>削除しました</p>',
                confirmButtonText: '一覧にもどる'
              }).then(
              )
              var itemsList = this.getCurrentItems();
              if(data.projectIds.length){
                data.projectIds.forEach(id => {
                  itemsList.forEach(item => {
                    if(item.id == id){
                      this.$set(item, "isDelete", true);
                      return;
                    }
                  });
                });
                this.saveItemList()
              }
            }else{
              this.showErrorWindow(response)
            }
          })
          .catch((err) => {
            console.log(err);
            this.showNetworkErrorWindow(err)
          });
        } 
      })
    },
    convertTimeHHMMSS(val){
      if(val == -1){
        return "音声変換中・・・"
      }

      if(val){
        var hhmmss = new Date(val).toISOString().substr(11, 8);
        return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
      }else{
        return "--:--"
      }
      
    },
    calcPopupShowPosition(e, popupWidth, popupHeight, margin){
      //console.log(popupWidth + ", " + popupHeight)
      //console.log("screen.availWidth : " + screen.availWidth + " , screen.availHeight : " + screen.availHeight + " , clientWidth: " + document.documentElement.clientWidth + " , clientHeight : " + document.documentElement.clientHeight)
      //console.log("screenX : " + e.screenX + " , screenY : " + e.screenY + " , clientX : " + e.clientX + " , clientY : " + e.clientY)
      var showPositionX = 0;
      var showPositionY = 0;

      if((screen.availWidth - e.screenX - margin) < popupWidth || (document.documentElement.clientWidth - e.clientX -margin) < popupWidth){
        //そのまま出せない場合
        showPositionX = e.pageX - popupWidth - margin;
      }else{
        showPositionX = e.pageX + margin;
      }

       if((screen.availHeight - e.screenY - margin) < popupHeight || (document.documentElement.clientHeight - e.clientY -margin) < popupHeight){
        showPositionY = e.pageY  - popupHeight - margin;
      }else{
        showPositionY = e.pageY + margin;
      }

      //console.log("X: " + showPositionX + " , Y: " + showPositionY)

      var position = {
        top: `${showPositionY}px`,
        left: `${showPositionX}px`,
      };

      return position;

    },
    convertMBSize(bite){
      const GB = 1024 * 1024
      return bite / GB ? (bite / GB).toFixed(1) : 0
    },
    getNowTime(){
      const date  = new Date()
      const y  = date.getFullYear()
      const mo = date.getMonth() + 1
      const d   = date.getDate()
      const h = date.getHours()
      const m = date.getMinutes()
      const s = date.getSeconds()
      return "" + y + mo + d + "-" + h + m + s 
    },
    getNowDate(){
      const date  = new Date()
      const y  = date.getFullYear()
      const mo = ( '00' + date.getMonth() + 1 ).slice( -2 );
      const d = ( '00' + date.getDate()).slice( -2 );
      return "" + y + mo + d
    },
    /**
     *******************************************
     * ファイルアップロード機能
     *******************************************
    */
    opneUploadPopup(){
      this.isShowUploadWindow = true;
      this.isAudioLoading = false;
      document.getElementById('audio_file_chooser').value = ''
    },
    dragEnter() {
      this.isFilenter = true;
    },
    dragLeave() {
      this.isFilenter = false;
    },
    chooseAudioFile(){
      this.uploadFiles = this.$refs.choose_audio.files[0]
      this.exeUplaodAudio();
    },
    dropFile() {
      var files = [...event.dataTransfer.files]
      if(files>1){
        Swal.fire({
          html: `
          <p>一度に1つのファイルしかアップロードできません。</p>
          `,
          confirmButtonColor:"#29aba4",
          confirmButtonText: "とじる",
          confirmButtonAriaLabel: "とじる",
        })
        this.uploadFiles = null;
        return;
      }else{
        this.uploadFiles = files[0]
      this.exeUplaodAudio();
      }
    },
    exeUplaodAudio(){
      if(this.planId == 0){
        Swal.fire({
                  html: `
                  <p>本機能は、1年目特典プランおよび、使い放題プランのお客様のみご利用いただけます。</p>
                  `,
                  confirmButtonColor:"#29aba4",
                  confirmButtonText: "とじる",
                  confirmButtonAriaLabel: "とじる",
                })
        this.uploadFiles = null;
        this.isShowUploadWindow = false;
        return;
      }

      // this.uploadFiles = [...event.dataTransfer.files]

      var audioData = new Audio();
      var thishandle = this;
      var file = this.uploadFiles
      if (!file.type.match('audio.*')) {
        Swal.fire({
                html: `
                <p>このファイルは音声ファイルではありません。</p>
                `,
                confirmButtonColor:"#29aba4",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
        this.uploadFiles = null;
        this.isShowUploadWindow = false;
        return;
      }

      if(file.type == "audio/vnd.dlna.adts"){
        thishandle.uploadAudioFile();
        return;
      }

      if(file.type.match('wma')){
        thishandle.uploadAudioFile();
        return;
      }
      
      const reader = new FileReader();

      reader.onerror = function(e){

        // 出力テスト
        console.log(reader.error);
        console.log(e)

      };
      this.isAudioLoading = true;
      reader.onload = (e) => {
        console.log(e)
        audioData.pause();
        audioData.src = reader.result;
        audioData.load(); // audioの読み込み

        audioData.addEventListener('error', function() {
            Swal.fire({
                html: `
                <p>音声の読み込みに失敗しました。音声ファイルの形式に問題があるか、音声が長すぎる可能性があります。</p>
                `,
                confirmButtonColor:"#29aba4",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
            })
            this.isAudioLoading = false;
        });
        
        audioData.addEventListener('loadedmetadata',function() {
            // console.log(audioData.duration); // 総時間の取得
            if(audioData.duration > 60 * 60 * 3){
              Swal.fire({
                html: `
                <p>アップロード可能な音声は3時間以内の音声のみとなります。</p>
                `,
                confirmButtonColor:"#29aba4",
                confirmButtonText: "とじる",
                confirmButtonAriaLabel: "とじる",
              })
              thishandle.uploadFiles = null;
              this.isShowUploadWindow = false;
              this.isAudioLoading = false;
              return;
            }else{
              thishandle.uploadAudioFile();
            }
        });
      };
      reader.readAsDataURL(file);
    },
    uploadAudioFile() {
      //var progressCallback = this.onUpdateUploadSeekBar;
        let form = new FormData();
        form.append('file', this.uploadFiles);

        // リクエスト情報を準備
        var reqData = this.getAxConf("post", "/contents/upload", form, this.$store.state.token)
        reqData.headers['content-type'] = 'multipart/form-data';
        reqData.onUploadProgress = (progressEvent) => {
          this.onUpdateUploadSeekBar(progressEvent.loaded, progressEvent.total)
        };
        reqData.cancelToken = new axios.CancelToken(cancel => {
          this.canceler = cancel
        })

        this.isShowUploadWindow = false;
        this.isShowProgressWindow = true;
        this.progressValue = 0;
        this.progressTxt = "0%";

        // 送信
        axios(reqData)
          .then((response) => {
            this.isFilenter = false;
            if (response.data.success) {
              this.updateListData("File")
              Swal.fire({
                confirmButtonColor: '#29aba4',
                animation: false,
                html: '<p>成功しました</p>',
                confirmButtonText: '一覧にもどる'
              }).then(()=>{
                  this.isShowProgressWindow = false;
                  // this.getList()
                }
              )
            } else {
              this.showErrorWindow(response)
              this.isShowProgressWindow = false;
            }
          }).catch((err) => {
            // console.log(err);
            if (axios.isCancel(err)) {
               Swal.fire({
                  html: `
                  <p>キャンセルしました。</p>
                  `,
                  confirmButtonText: "とじる",
                  confirmButtonColor:"#29aba4",
                  confirmButtonAriaLabel: "とじる",
                })
                this.isShowProgressWindow = false;
            }else{
              this.showNetworkErrorWindow(err)
              this.isShowProgressWindow = false;
            }
          });

    },
    uploadCancel(){
      this.isShowProgressWindow = false;
      this.canceler();
    },
    onUpdateUploadSeekBar(loaded, total) {
      var progress = loaded /  total * 100;
      this.progressValue = progress;
      this.progressTxt =  Math.floor(progress) + "%" ;
    },
  },
}
</script>
<style scoped>

/* スクロールバー */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #13618f;
  border-radius: 5px;
}

.home_main_area{
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.function_area{
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.txt_search_area{
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.date_search_area{
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.tab_area{
  width: 98%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin: auto;
  margin-bottom: 0px !important; 
}

.tab_btn{
  width: 49%;
  height: max-content;
  padding: 5px 0px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #94acc6;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  border-bottom: 5px solid #13618f;
  font-size: 18px;
}

.tab_btn:hover{
  font-weight: bold;
}

.tab_selected{
  width: 49%;
  height: max-content;
  padding: 5px 0px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #13618f;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  border-bottom: 5px solid #13618f;
  font-size: 18px;
}

.minutes_search_box{
  font-size: 24px;
  width: 60vw;
  box-sizing: border-box;
  letter-spacing: 1px;
  color: #000000;
  border: 1px solid #1b2538;
  border-radius: 4px;
}

.minutes_search_date{
  font: 18px sans-serif;
  box-sizing: border-box;
  letter-spacing: 1px;
  color: #000000;
  border: 1px solid #1b2538;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
}


.contents_main_area{
  vertical-align: top;
  display: flex;
  flex-direction: column;
  height: 64%;
  width: 98%;
  /* border: 3px solid #29aba4;
  justify-content:center;
  align-items: center;
  text-align: center; */
  margin: auto;
  margin-bottom: 5px !important;
  margin-top: 8px !important;
}

.contents_list_header_area{
  width: 95%;
  justify-content:center;
  display: flex;
  flex-direction: column;
  justify-content:center;
  margin-left: 10px;
  margin-right: 10px;
}

.contents_list_area{
  width: 98%;
  margin-left: 10px;
  margin-right: 10px;
  overflow-y: auto;
}

.contents_item_head{
  /* pointer-events: none;  */
  background-color: #29aba4;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.contents_item{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:center;
  /* background-color: #ffffff; */
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #94acc6;
}

.list_item_loading{
  color: #13618f;
  font-size: 18px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:center;
  /* background-color: #ffffff; */
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 4px;
  border-color: #13618f #94acc6 #94acc6;
  position: relative;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.contents_item:hover{
  background-color: rgb(100, 149, 237,0.3);
  padding-top: 20px;
  padding-bottom: 20px;
  border-left: 15px solid #13618f;
  border-top: 2px solid #13618f;
  border-bottom: 2px solid #13618f;
  border-right: 2px solid #13618f;
  width: 98%;
}

.content_item_info_area{
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content:center;
}

.content_item_basic_info{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:center;
}

.content_item_upper{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content:center;
}

.content_item_under{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  padding-top: 10px;
}

.contents_item_column_check{
  width: 5%;
  font-size: 15px;
  text-align: center;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  padding: 10px 0px;;
}

.check_box_area{
  height: 100%;
  width: 100%;
}

.contents_item_filetype_icon{
  width: 5%;
  font-size: 24px;
  text-align: center;
  color: #13618f;
  margin: auto;
}

.contents_item_column_name{
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #221816;
}

.contents_item_column_date{
  width: 45%;
  font-size: 15px;
  text-align: left;
  color: #94acc6;
}

.contents_item_column_duration{
  width: 45%;
  font-size: 15px;
  text-align: left;
  color: #94acc6;
}

.content_item_icon{
  width: 5%;
  font-size: 15px;
  text-align: right;
  color: #94acc6;
}

.content_item_keyword{
  width: 50%;
  font-size: 15px;
  text-align: left;
  color: #94acc6;
}

.content_item_person{
  width: 45%;
  font-size: 15px;
  text-align: left;
  color: #94acc6;
}

.contents_item_column_state{
  width: 5%;
  font-size: 20px;
  text-align: center;
  color: #13618f;
  margin: auto;
}

.icon_common{
  margin-right: 3px;
}

.currentpagebutton{
  background: #29aba4 !important;
}

/* チェックボックス */
input[type="checkbox"] {
  display: none;
}
.checkbox {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 13px;
  position: relative;
  width: auto;
}
.checkbox::before {
  background: #fff;
  border: 1px solid #94acc6;
  border-radius: 3px;
  content: "";
  display: block;
  height: 16px;
  left: 5px;
  position: absolute;
  top: 50%;
  width: 16px;
}
.checkbox::after {
  border-right: 6px solid #29aba4;
  border-bottom: 3px solid #29aba4;
  content: "";
  display: block;
  height: 20px;
  left: 7px;
  margin-top: -8px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translate3d(0, 2px, 0) scale3d(0.7, 0.7, 1);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  width: 9px;
}
input[type="checkbox"]:checked + .checkbox::before {
  border-color: #666;
}
input[type="checkbox"]:checked + .checkbox::after {
  opacity: 1;
  transform: rotate(45deg) scale3d(1, 1, 1);
}
.nameWidth{
  width: 280px;
}
.processWidth{
  width: 30px;
}
.processarea{
  padding: 10px 0px 14px 0px !important;
}
.processing{
  /*
  animation: gearProcess 3s linear infinite alternate;
  animation: gearProcess2 1s linear infinite alternate;
  */
  animation: comentProcess 4s linear infinite;
}
@keyframes gearProcess {
  0%   {
    color: #1e90ff;
    transform: rotate(0deg);
  }
  100% {
    color: #29aba4;
    transform: rotate(120deg);
  }
}
@keyframes gearProcess2 {
  0%   {
    color: #1e90ff;
    transform: rotate(-10deg);
  }
  100% {
    color: #29aba4;
    transform: rotate(10deg);
  }
}

@keyframes comentProcess {
  0%,50%,100%{
    opacity: 0;
    color: #ffffff;
  }
  10%, 30%{
    opacity: 1;
    color:#98cebb;
  }
  60%, 80%{
    opacity: 1;
    color:#29aba4;
  }
}
.completed{
  color: #29aba4;
}
.errored{
  color: #dd4157;
}

.overlay {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupWindow{
  position: fixed;
  inset: 0;
  margin: auto;
  height: max-content;
  width: max-content;
  border:  0px solid rgb(255, 255, 255);
  border-radius: 25px;
  z-index: 999;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 93%;
  max-width: 80%;
}
.popupHeader{
  width: 100%;
  height: max-content;
  background: #13618f;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  text-align: center;
}
.popupTitle{
  color: #FFF;
  text-align: center;
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content:center;
  align-items: center; 
  margin: 10px;
}
.popupMainContent{
  overflow-y: auto;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
}
.popupRow{
  display: flex;
  margin-top: 10px;
  width: 100%;
}
.popupFooter{
  width: 100%;
  height: 10%;
  display: flex;
  justify-content:center;
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-top: 20px;
}
.popupFooterBtn{
  border: 2px #13618f solid;
  border-bottom-left-radius: 22px;
  padding: 4px 8px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  background-color: #13618f;
  cursor: pointer;
  /* margin: 15px; */
  width: 50%;
  text-align: center;
}
.popupFooterBtn:hover{
  background-color: rgb(255, 255, 255);
  color: #13618f;
}
.popupFooterBtnCancel{
  border: 2px #94acc6 solid;
  border-bottom-right-radius: 22px;
  padding: 4px 8px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  background-color: #94acc6;
  color: #ffffff;
  cursor: pointer;
  /* margin: 15px; */
  width: 50%;
  /* height: max-content; */
  text-align: center;
}
.popupFooterBtnCancel:hover{
  background-color: rgb(255, 255, 255);
  color: #dd4157;
  border: 2px #dd4157 solid;
}
.popupFooterBtnFill{
  border: 2px #13618f solid;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 4px 8px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  background-color: #13618f;
  cursor: pointer;
  /* margin: 15px; */
  width: 100%;
  text-align: center;
}
.popupFooterBtnFill:hover{
  background-color: rgb(255, 255, 255);
  color: #13618f;
}
.uploadProgress{
  height:20px;
  width: 60vw;
}
.popupInputArea{
  width: max-content;
  height: max-content;
  text-align: center;
  display: flex;
  justify-content:center;
  margin: 10px;
}
.popupIconArea{
  display: flex;
  flex-direction: column;
  justify-content:center;
  width: max-content;
  height: max-content;
  margin-right: 10px;
}
.popupInputBox{
  font: 15px/24px sans-serif;
  box-sizing: border-box;
  transition: 0.3s;
  letter-spacing: 1px;
  color: #000000;
  border: 1px solid #1b2538;
  border-radius: 4px;
  width: max-content;
  height: max-content;
}
.popupInputLabel{
  font-size: 12px;
  text-align: center;
  width: 100px;
  height: max-content;
  color: #94acc6;
}
.popupInputIcon{
   font-size: 16px;
   text-align: center;
   color: #29aba4;
}
.popupLinerArea{
  display: flex;
  justify-content:center;
  align-items: center; 
  text-align: center;
  width: max-content;
  flex-wrap: wrap;
}
.popupVerticalHr{
  margin: 5px;
  width: 2px;
  height: 70%;
  background-color: rgba(63, 63, 63, 0.4);
}
.popupDescribe{
  text-align: center;
  font-size: 18px;
  color: #94acc6;
  width: 100%;
  height: max-content;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
}
.dictexlist{
  text-align: left;
}

.clear_btn{
  border: 2px #29aba4 solid;
  border-radius: 20px;
  padding: 4px 8px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  background-color: #29aba4;
  cursor: pointer;
  /* margin: 15px; */
  width: max-content;
  text-align: center;
  margin-left: 5px;;
}
.clear_btn:hover{
  background-color: rgb(255, 255, 255);
  color: #29aba4;
}

.note{
  font-size: 12px;
}
select.advinput{
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
}
.modeSelectArea{
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap
}
.modeTabArea{
  display: flex;
  width: 100%;
}
.leftTab, .rightTab{
  width: 50%;
  margin-right: 3px;
  margin-left: 3px;
  border-radius: 5px 5px 0px 0px;
  background: #94acc6;
  color: #666;
  text-align: center;
  padding: 5px;
  box-shadow: inset 0 -6px 10px -5px #94acc6;
}
.selectedMode{
  margin-right: 0px;
  margin-left: 0px;
  background: #29aba4;
  color: #ffffff;
  box-shadow: inset 0 0 0 #94acc6;
}
.modeDetailArea{
  width: calc(100% - 6px);
  background: #29aba4;
  padding: 15px 5px 5px 5px;
  border-radius: 0px 0px 5px 5px;
}
.modeDetailArea > div{
  background: #ffffff;
  border-radius: 5px;
  width: calc(100% - 40px);
  height: 270px;
  padding: 20px;
  overflow-x: hidden;
}
.dangercolor{
  color: #dd4157;
}
.linktext{
  color: #29aba4;
  text-decoration-line: underline;
}
.linktext:hover{
  color: #29aba4;
}
.ta-c{
  text-align: center;
}
.ta-r{
  text-align: right;
}
.speakerConfig{
  margin: 5px;
  padding: 5px 20px;
  border: #94acc6 solid 1px;
  border-radius: 6px;
}
.speakerConfig h4{
  margin: 0px;
}
.dropArea{
  padding: 20px;
  margin: 5px 20px;
  border-radius: 20px;
  border: 2px #13618f dashed;
  width: 100%;
  text-align: center;
}
.dropArea > i{
  font-size: 68px;
  color:#94acc6;
  pointer-events: none;
}
.dropArea > p{
  pointer-events: none;
}
.fileenter{
  border: 5px dashed #29aba4;
  background: rgb(229, 252, 252);
}

.tool_icon{
  font-size: 20px;
  color: #29aba4;
}

.tool_btn{
  display: flex;
  flex-direction: column;
  height:  max-content;
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content:center;
  background-color: #29aba4;
  cursor: pointer;
  position:  relative;
  border-radius: 15px;
}

.tool_btn:hover{
   background-color: #13618f;
}

.tool_btn_icon{
  text-align: center;
  height: 60%;
  color: #ffffff;
  font-size: 20px;
}
.tool_btn_text{
  text-align: center;
  height: 40%;
  font-size: 12px;
  color: #ffffff;
}

progress {
  -webkit-appearance: none;
  border: 6px solid #29aba4;
  border-radius: 20px;
}
::-webkit-progress-bar {
  background-color: #dad8d8;
}
::-webkit-progress-value {
  background-color: #29aba4;
}
</style>
