import Vue from "vue";
import VueRouter from "vue-router";
import Controller from "../views/Controller.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Home from "../views/Home.vue";
import Dictation from "../views/Dictation.vue";
import DictEx from "../views/DictEx.vue";
import User from "../views/User.vue";
import Security from "../views/Security.vue";
import Payment from "../views/Payment.vue";
import Purchase from "../views/Purchase.vue";
import Faq from "../views/Faq.vue";
import Contact from "../views/Contact.vue";
import Guide from "../views/Guide.vue";
import Plan from "../views/Plan.vue";
import Info from "../views/Info.vue";
import Low from "../views/Low.vue";

/**
 * ページごとのパスを設定する場合のルーティング
 */
Vue.use(VueRouter);

const routes = [
  // トップ（暫定）
  {
    path: "/rs_dev",
    name: "ControllerRs_dev",
    component: Controller,
    props: true,
  },

  // ログイン画面
  {
    path: "/rs_dev/login",
    name: "LoginRs_dev",
    component: Login,
  },

  // 新規登録画面
  {
    path: "/rs_dev/register",
    name: "RegisterRs_dev",
    component: Register,
  },

  // ホーム画面
  {
    path: "/rs_dev/home",
    name: "HomeRs_dev",
    component: Home,
    props: true,
  },

  // 編集画面
  {
    path: "/rs_dev/dictation",
    name: "DictationRs_dev",
    component: Dictation,
    props: true,
  },

  // 高度編集画面
  {
    path: "/rs_dev/dictex",
    name: "DictexRs_dev",
    component: DictEx,
    props: true,
  },

  // ユーザ情報画面
  {
    path: "/rs_dev/user",
    name: "UserRs_dev",
    component: User,
    props: true,
  },

  // セキュリティ画面
  {
    path: "/rs_dev/security",
    name: "SecurityRs_dev",
    component: Security,
    props: true,
  },

  // 決済方法画面
  {
    path: "/rs_dev/payment",
    name: "PaymentRs_dev",
    component: Payment,
    props: true,
  },

  // 購入履歴画面
  {
    path: "/rs_dev/purchase",
    name: "PurchaseRs_dev",
    component: Purchase,
    props: true,
  },

  // よくあるご質問画面
  {
    path: "/rs_dev/faq",
    name: "FaqRs_dev",
    component: Faq,
    props: true,
  },

  // お問い合わせ画面
  {
    path: "/rs_dev/contact",
    name: "ContactRs_dev",
    component: Contact,
    props: true,
  },

  // 操作ガイド画面
  {
    path: "/rs_dev/guide",
    name: "GuideRs_dev",
    component: Guide,
    props: true,
  },

  // プラン選択画面
  {
    path: "/rs_dev/plan",
    name: "PlanRs_dev",
    component: Plan,
    props: true,
  },

  // お知らせ一覧画面
  {
    path: "/rs_dev/info",
    name: "InfoRs_dev",
    component: Info,
    props: true,
  },

  // 特定商取引法に基づく表記画面
  {
    path: "/rs_dev/low",
    name: "LowRs_dev",
    component: Low,
    props: true,
  },

  // トップ（暫定）
  {
    path: "/parrot",
    name: "ControllerParrot",
    component: Controller,
    props: true,
  },

  // ログイン画面
  {
    path: "/parrot/login",
    name: "LoginParrot",
    component: Login,
  },

  // 新規登録画面
  {
    path: "/parrot/register",
    name: "RegisterParrot",
    component: Register,
  },

  // ホーム画面
  {
    path: "/parrot/home",
    name: "HomeParrot",
    component: Home,
    props: true,
  },

  // 編集画面
  {
    path: "/parrot/dictation",
    name: "DictationParrot",
    component: Dictation,
    props: true,
  },

  // 高度編集画面
  {
    path: "/parrot/dictex",
    name: "DictexParrot",
    component: DictEx,
    props: true,
  },

  // ユーザ情報画面
  {
    path: "/parrot/user",
    name: "UserParrot",
    component: User,
    props: true,
  },

  // セキュリティ画面
  {
    path: "/parrot/security",
    name: "SecurityParrot",
    component: Security,
    props: true,
  },

  // 決済方法画面
  {
    path: "/parrot/payment",
    name: "PaymentParrot",
    component: Payment,
    props: true,
  },

  // 購入履歴画面
  {
    path: "/parrot/purchase",
    name: "PurchaseParrot",
    component: Purchase,
    props: true,
  },

  // よくあるご質問画面
  {
    path: "/parrot/faq",
    name: "FaqParrot",
    component: Faq,
    props: true,
  },

  // お問い合わせ画面
  {
    path: "/parrot/contact",
    name: "ContactParrot",
    component: Contact,
    props: true,
  },

  // 操作ガイド画面
  {
    path: "/parrot/guide",
    name: "GuideParrot",
    component: Guide,
    props: true,
  },

  // プラン選択画面
  {
    path: "/parrot/plan",
    name: "PlanParrot",
    component: Plan,
    props: true,
  },

  // お知らせ一覧画面
  {
    path: "/parrot/info",
    name: "InfoParrot",
    component: Info,
    props: true,
  },

  // 特定商取引法に基づく表記画面
  {
    path: "/parrot/low",
    name: "LowParrot",
    component: Low,
    props: true,
  },

  // トップ（暫定）
  {
    path: "/",
    name: "Controller",
    component: Controller,
    props: true,
  },

  // ログイン画面
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  // 新規登録画面
  {
    path: "/register",
    name: "Register",
    component: Register,
  },

  // ホーム画面
  {
    path: "/home",
    name: "Home",
    component: Home,
    props: true,
  },

  // 編集画面
  {
    path: "/dictation",
    name: "Dictation",
    component: Dictation,
    props: true,
  },

  // 高度編集画面
  {
    path: "/dictex",
    name: "Dictex",
    component: DictEx,
    props: true,
  },

  // ユーザ情報画面
  {
    path: "/user",
    name: "User",
    component: User,
    props: true,
  },

  // セキュリティ画面
  {
    path: "/security",
    name: "Security",
    component: Security,
    props: true,
  },

  // 決済方法画面
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    props: true,
  },

  // 購入履歴画面
  {
    path: "/purchase",
    name: "Purchase",
    component: Purchase,
    props: true,
  },

  // よくあるご質問画面
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
    props: true,
  },

  // お問い合わせ画面
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    props: true,
  },

  // 操作ガイド画面
  {
    path: "/guide",
    name: "Guide",
    component: Guide,
    props: true,
  },

  // プラン選択画面
  {
    path: "/plan",
    name: "Plan",
    component: Plan,
    props: true,
  },

  // お知らせ一覧画面
  {
    path: "/info",
    name: "Info",
    component: Info,
    props: true,
  },

  // 特定商取引法に基づく表記画面
  {
    path: "/low",
    name: "Low",
    component: Low,
    props: true,
  },

  // 定義されていないパスへの対応。トップページへリダイレクトする。
  {
    path: "*",
    // redirect: '/login'
    name: "404",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
