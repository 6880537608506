<template>
  <div class="home_bg">
    <Header pagename="Contact"/>
    <div class="contentarea">
      <MainNavi pagename="contact"/>
      <div class="maincontent">
        <div class="documentcontent">
          <div class="clearblind"><h3>お問い合わせ</h3></div>
          <h4>お問い合わせ前にお読みください</h4>
          <p>
            <ol>
              <li>
                <span class="linklike handcursor" @click="pushPage({name: 'Guide'})">操作ガイド</span>、
                <span class="linklike handcursor" @click="pushPage({name: 'Faq'})">よくあるご質問</span>
                をぜひご活用ください。
              </li>
              <li>サービスの状況につきましては、お知らせのメンテナンス情報をご確認ください。</li>
              <li>お客様の問題を解決できるよう、できるかぎり具体的にご記入ください。2営業日以内にEメールを送信いたします。</li>
              <li>お客様に関する情報は
                <a href="https://www.iflytek.co.jp/policies/privacy_sr502j/" target="_blank">プライバシーポリシー</a>
                に同意の上、ご入力ください。個人情報の取り扱いに関するご質問もこちらからお問い合わせください。
              </li>
            </ol>
          </p>
          <div class="ta-c buttonarea">
              <!-- <span class="button" @click="showModal">問い合わせる</span> -->
              <span class="button"><a href="https://www.iflytek.co.jp/product-support/" target="_blank">問い合わせる</a></span>
            </div>
        </div>
      </div>
    </div>
    <ContactModal></ContactModal>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainNavi from '@/components/MainNavi.vue'
import ContactModal from '@/components/ContactModal.vue'
import utils from '@/mixins/utils.js'

export default {
  name: 'Contact',
  props: {
    pagename: String,
  },
  mixins: [utils],
  components: {
    Header,
    Footer,
    MainNavi,
    ContactModal,
  },
  data: function() {
    return {
    }
  },
  created(){
  },
  mounted(){
  },
  methods: {
    showModal(){
      this.$modal.show('contact-modal')
    },
  },
}
</script>

<style>

.buttonarea {
  margin: 40px;
}
</style>
