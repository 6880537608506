<template>
  <modal name="info-modal" :draggable="false" :resizable="false" width="75%" height="70%">
    <div class="itemarea modalitemarea">
      <h3 style="text-align: center;">{{ title }}</h3>
      <div class="infosubdata">
        <div class="infocatedate">
          <span v-show="category == '01'" class="infocategory cateinfo">
            お知らせ
          </span>
          <span v-show="category == '02'" class="infocategory catedisorder">
            障害情報
          </span>
          <span v-show="category == '03'" class="infocategory cateother">
            その他
          </span>
          {{ date }}
        </div>
      </div>
      <p class="text-wrap">
        {{ content }}
      </p>
    </div>
    <div class="ta-c backmbutton">
      <span v-show="isOpen" class="mbutton canselmbutton handcursor" @click="closeModal">一覧へ戻る</span>
    </div>
  </modal>
</template>


<script>
export default {
  name: 'InfoModal',
  props: {
    index: String,
    id: String,
    date: String,
    category: String,
    title: String,
    content: String,
  },
  data: function () {
    return {
      isOpen: false
    }
  },
  mounted(){
    this.isOpen = true
  },
  methods: {
    closeModal(){
      this.$modal.hide('info-modal');
      window.location.reload();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* スクロールバー */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #13618f;
  border-radius: 5px;
}

.modalitemarea{
  padding: 20px;
}
.infocontent {
  margin-left: 10px;
  width: 100%;
  background: #ffffff;
  overflow-y: scroll;
  font-size: 14px;
}
.infocontent::-webkit-scrollbar {
  display: none;
}
.infocategory {
  border: 1px solid #333333;
  padding: 5px;
  margin-right: 10px;
  border-radius: 3px;
}
.cateinfo {
  border: 3px solid #4682b4;
  color: #4682b4;
}
.catedisorder {
  border: 3px solid #dd4157;
  color: #dd4157;
}
.cateother{
  border: 3px solid #29aba4;
  color: #29aba4;
}
.infosubdata {
  margin-bottom: 20px;
  display: flex;
}
.infocatedate {
  margin-left: auto;
}
.text-wrap{
  white-space: pre-wrap;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}
.backmbutton{
  margin-top:5px;
  margin-bottom: 10px;;
}
/* ボタン */
.mbutton {
  display: inline-block;
  width: 200px;
  height: 54px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  line-height: 54px;
  outline: none;
  font-size: 14px;
}
.mbutton::before,
.mbutton::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: "";
}
.mbutton,
.mbutton::before,
.mbutton::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.mbutton {
  /* 初期デザインカラー
  background-color: #1e90ff;
  border: 2px solid #1e90ff;
  */
  background-color: #68CFC3;
  border: 2px solid #68CFC3;
  color: #fff;
  line-height: 50px;
  cursor: pointer;
}
.mbutton:hover {
  background-color: #fff;
  /* 初期デザインカラー
  border-color: #009999;
  color: #009999;
  */
  border-color: #68CFC3;
  color: #68CFC3;
}
.canselmbutton {
  background-color: #ffffff;
  border: 2px solid #808080;
  color: #808080;
}
.canselmbutton:hover {
  background-color: #fff;
  /* 初期デザインカラー
  border-color: #009999;
  color: #009999;
  */
  border-color: #68CFC3;
  color: #68CFC3;
}
</style>
